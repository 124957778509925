import { Link, redirect } from "react-router-dom";

import permissions from "@utils/permissions";

import Program from "../../../pages/ProgramOrder";
import Programs from "../../../pages/Programs";
import useOrderWindow from "../../orderWindows/useOrderWindow";
import { protectedRoute } from "../types";

const CurrentOrderWindowLink = ({ orderWindowId }) => {
  const { data: orderWindow } = useOrderWindow(orderWindowId as string);
  return (
    <Link to={`/pre-orders/${orderWindowId}`}>
      {orderWindow?.name || "Order Window"}
    </Link>
  );
};

const redirectProgram = ({ params }) => {
  const { programId, orderWindowId } = params;
  if (orderWindowId)
    return redirect(`/programs/${programId}/order/${orderWindowId}`);
  else return redirect(`/programs/${programId}`);
};

export default {
  path: "programs",
  allowedRoles: permissions.programs,
  children: [
    {
      path: "",
      handle: { crumb: () => "Programs" },
      element: <Programs />,
    },
    {
      path: ":type/:programId",
      loader: redirectProgram,
    },
    {
      path: ":type/:programId/:orderWindowId",
      loader: redirectProgram,
    },
    {
      path: ":programId",
      handle: { crumb: () => <Link to="/programs">Programs</Link> },
      element: <Program />,
    },
    {
      path: ":programId/order/:orderWindowId",
      handle: {
        crumb: ({ orderWindowId }) => (
          <CurrentOrderWindowLink orderWindowId={orderWindowId} />
        ),
      },
      element: <Program />,
    },
  ],
} as protectedRoute;
