export default function getAssignedToValue(budget, budgetLocation) {
  if (budgetLocation === "user") {
    return budget.users && budget.users.length > 0
      ? budget.users.map((u) => u.name).join(", ")
      : "---";
  }
  if (budgetLocation === "territory") {
    return budget.territories && budget.territories.length > 0
      ? budget.territories.map((t) => t.name).join(", ")
      : "---";
  }
  if (budgetLocation === "group") {
    return budget.groups && budget.groups.length > 0
      ? budget.groups.map((g) => g.name).join(", ")
      : "---";
  }
  return "---";
}
