import { useSelector } from "react-redux";

import { Organization } from "@models/Organization";

export default function useBudgetAssignedResource() {
  const budgetLocation: Organization["budgetLocation"] = useSelector(
    (state: any) => state.currentUser.organization.budgetLocation
  );
  const assignedResource = (
    {
      user: "users",
      territory: "territories",
      group: "groups",
    } as const
  )[budgetLocation!];

  return assignedResource;
}
