/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import _ from "lodash";

import { StyledButton } from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import { BlockCard } from "@features/ui";
import { User } from "@models/User";

import { useRemoveSupplierUserFromOrgMutation } from "../data";
import useNavigateToUser from "../useNavigateToUser";

const LabelValuePair = ({ label, value }: { label: string; value: string }) => (
  <div tw="">
    <div tw="text-neutral-700 text-sm tracking-wide">{label}</div>
    <div tw="text-neutral-800 text-lg">{value}</div>
  </div>
);

const ReadOnlySupplierUserForm = ({ user }: { user: User }) => {
  const confirm = useConfirm();
  const navigateToUser = useNavigateToUser();
  const removeSupplierUserMutation = useRemoveSupplierUserFromOrgMutation();

  const handleRemoveUser = async () => {
    if (await confirm("Are you sure you want to remove this supplier user?")) {
      removeSupplierUserMutation.mutate(user, {
        onSuccess: () => navigateToUser(null),
      });
    }
  };

  return (
    <>
      <BlockCard tw="space-y-4">
        <div tw="rounded-md bg-neutral-50 p-4 -mx-2 text-neutral-600 border border-neutral-200">
          <p>
            This supplier user is managed by the brandhub team. To request any
            changes, please contact support.
          </p>
        </div>
        <LabelValuePair label="Name" value={user.name} />
        <LabelValuePair label="Email" value={user.email} />
        <LabelValuePair label="Supplier" value={user.supplier?.name ?? "---"} />
      </BlockCard>
      <StyledButton tw="float-right mt-3" onClick={handleRemoveUser} danger>
        Remove access
      </StyledButton>
    </>
  );
};

export default ReadOnlySupplierUserForm;
