import {
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildOrderWindow, fetchOrderWindowPrograms } from "./helpers";
import { mapOrderWindow, mapOrderWindows } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  windows: [],
  filteredWindows: [],
  availableWindows: [],
  currentWindow: null,
  hasUpdated: false,
  hasSetWindows: false,
  error: null,
};

const orderWindowSlice = createSlice({
  name: "orderWindows",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getOrderWindowsSuccess(state, action) {
      const { orderWindows } = action.payload;
      state.windows = orderWindows;
      state.isLoading = false;
      state.error = null;
    },
    getSingleOrderWindowSuccess(state, action) {
      const { orderWindow } = action.payload;
      state.currentWindow = orderWindow;
      state.isLoading = false;
      state.error = null;
    },
    getFilteredOrderWindowsSuccess(state, action) {
      const { orderWindows, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.filteredWindows = orderWindows;
      state.isLoading = false;
      state.error = null;
    },
    getNextFilteredOrderWindowsSuccess(state, action) {
      const { orderWindows, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.filteredWindows = state.filteredWindows.concat(orderWindows);
      state.isNextLoading = false;
      state.error = null;
    },
    createOrUpdateOrderWindowSuccess(state, action) {
      const { orderWindow } = action.payload;
      let currentWindow = state.windows.find((w) => w.id === orderWindow.id);
      state.windows = !currentWindow
        ? state.windows.concat(orderWindow)
        : state.windows.map((w) => {
            if (w.id === orderWindow.id) {
              return orderWindow;
            } else return w;
          });
      state.currentWindow = orderWindow;
      state.hasUpdated = true;
      state.isUpdateLoading = false;
      state.error = null;
    },
    setAvailableWindows(state, action) {
      const { channelId, territoryId } = action.payload;
      let available;
      if (channelId) {
        available = state.windows.filter(
          (w) =>
            w.channel?.id === channelId &&
            w.territories.map((t) => t.id).includes(territoryId)
        );
      } else {
        available = state.windows.filter(
          (w) =>
            !w.channelId && w.territories.map((t) => t.id).includes(territoryId)
        );
      }
      state.availableWindows = available;
      state.hasSetWindows = true;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetCurrentWindow(state) {
      state.currentWindow = null;
    },
    resetOrderWindows(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.nextLink = null;
      state.windows = [];
      state.filteredWindows = [];
      state.availableWindows = [];
      state.currentWindow = null;
      state.hasUpdated = false;
      state.hasSetWindows = false;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsNextLoading,
  setIsUpdateLoading,
  getOrderWindowsSuccess,
  getFilteredOrderWindowsSuccess,
  getNextFilteredOrderWindowsSuccess,
  getSingleOrderWindowSuccess,
  createOrUpdateOrderWindowSuccess,
  setAvailableWindows,
  setHasUpdated,
  resetCurrentWindow,
  resetOrderWindows,
  setFailure,
} = orderWindowSlice.actions;

export default orderWindowSlice.reducer;

export const fetchOrderWindows = () => async (dispatch) => {
  try {
    dispatch(resetOrderWindows());
    dispatch(setIsLoading());
    const response = await axiosGet(
      "/api/order-windows?filter[is-active]=true"
    );
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrderWindows(response.data);
    dispatch(getOrderWindowsSuccess({ orderWindows: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Windows" }));
  }
};

export const fetchFilteredOrderWindows = (name) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    let queryString = name
      ? `/api/order-windows?filter[name]=${name}`
      : "/api/order-windows";
    const response = await axiosGetWithNext(queryString);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrderWindows(response.data.data);
    dispatch(
      getFilteredOrderWindowsSuccess({
        orderWindows: mappedData,
        nextLink: response.data.nextLink ?? null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Windows" }));
  }
};

export const fetchNextFilteredOrderWindows = (url) => async (dispatch) => {
  try {
    dispatch(setIsNextLoading());
    const response = await axiosGetWithNext(url);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapOrderWindows(response.data.data);
    dispatch(
      getNextFilteredOrderWindowsSuccess({
        orderWindows: mappedData,
        nextLink: response.data.nextLink ?? null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Windows" }));
  }
};

export const fetchOrderWindow = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/order-windows/${id}`);
    if (response.error) {
      throw response.error;
    }
    const programs = await fetchOrderWindowPrograms(id);
    const mappedData = mapOrderWindow(response.data, programs);
    dispatch(getSingleOrderWindowSuccess({ orderWindow: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Order Windows" }));
  }
};

export const createOrderWindow = (attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildOrderWindow(attributes);
    const response = await axiosPost("/api/order-windows", postData);
    if (response.error) {
      throw response.error;
    }
    const programs = await fetchOrderWindowPrograms(response.data.id);
    const mappedData = mapOrderWindow(response.data, programs);
    dispatch(createOrUpdateOrderWindowSuccess({ orderWindow: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(
      setError({ error: err.toString(), source: "Order Window Create" })
    );
  }
};

export const updateOrderWindow = (attributes, id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildOrderWindow(attributes);
    const response = await axiosPatch(`/api/order-windows/${id}`, patchData);
    if (response.error) {
      throw response.error;
    }
    const programs = await fetchOrderWindowPrograms(response.data.id);
    const mappedData = mapOrderWindow(response.data, programs);
    dispatch(createOrUpdateOrderWindowSuccess({ orderWindow: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(
      setError({ error: err.toString(), source: "Order Window Update " })
    );
  }
};
