import React from "react";

import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { useInput } from "../../hooks/inputs/useInput";

const MemoTextArea = React.memo(
  ({ value, setValue, formKey, label, rows, fullWidth, componentClass }) => {
    const { value: input, bind: bindInput } = useInput(value || "");

    return (
      <TextField
        size="small"
        fullWidth={fullWidth}
        className={componentClass}
        variant="outlined"
        color="secondary"
        multiline
        rows={rows}
        name={label}
        label={label}
        {...bindInput}
        onBlur={() => setValue(formKey, input)}
      />
    );
  }
);

MemoTextArea.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  formKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  componentClass: PropTypes.any.isRequired,
};

export default MemoTextArea;
