/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { useApiResource } from "@services/api";

const AppliedPromotions = ({ promotionIds }: { promotionIds: string[] }) => {
  const { data: promotions = [] } = useApiResource(
    promotionIds.length > 0 && "promotions",
    {
      filter: { ids: promotionIds },
    }
  );
  if (promotions.length === 0) return null;
  return (
    <div tw="rounded-xl bg-primary-50 p-3 -mx-3 py-2 text-primary-800">
      <h3 tw="text-primary-900">Applied Promotions</h3>
      <ul tw="mt-2">
        {promotions.map((promo) => (
          <li key={promo.id}>{promo.description}</li>
        ))}
      </ul>
    </div>
  );
};

export default AppliedPromotions;
