/** @jsxImportSource @emotion/react */
import "twin.macro";

import BlockCard from "../../ui/BlockCard";
import EditableTextList from "./EditableTextList";

const Goals = () => {
  return (
    <BlockCard title="Goals">
      <EditableTextList listName="goals" />
    </BlockCard>
  );
};

export default Goals;
