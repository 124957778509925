/** @jsxImportSource @emotion/react */
import "twin.macro";

import { memo, useState } from "react";

import { Chip, Typography } from "@mui/material";

import PropTypes from "prop-types";

import { Filters, useFilterParams } from "@features/filters";
import { usePaginatedProgramsQuery } from "@features/programs";

import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
} from "../../StyledComponents";
import OrderWindowProgramTable from "./OrderWindowProgramTable";

const ProgramPanel = ({
  classes,
  type,
  programs,
  setPrograms,
  territories,
  handleSubmit,
}) => {
  const [filterParams] = useFilterParams();

  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const { data: programList, ...tableProps } = usePaginatedProgramsQuery({
    params: {
      filter: {
        name: filterParams.q,
        groupIds:
          filterParams.groups && Object.values(filterParams.groups).flat(),
      },
    },
  });

  const handleAddPrograms = () => {
    const currentProgramIds = programs.map((p) => p.id);
    let programsToAdd = programList.filter((p) =>
      selectedPrograms.includes(p.id)
    );
    let newPrograms = [];
    programsToAdd.forEach((p) => {
      if (!currentProgramIds.includes(p.id)) {
        newPrograms.push(p);
      }
    });
    if (newPrograms.length > 0) {
      setPrograms(programs.concat(newPrograms));
    }
    setSelectedPrograms([]);
  };

  const handleRemoveProgram = (id) => {
    setPrograms(programs.filter((p) => p.id !== id));
  };

  return (
    <FixedHeightScrollLastChild tw="[--space-after-header: 120px]">
      <header tw="flex justify-between items-center">
        <Typography className={classes.headerText}>
          Select Programs to add to the Order Window
        </Typography>
        <div className={classes.innerConfigDiv}>
          {type === "edit" && (
            <StyledButton cta onClick={handleSubmit}>
              SAVE
            </StyledButton>
          )}
          <StyledButton
            cta
            disabled={selectedPrograms.length === 0 || territories.length === 0}
            onClick={handleAddPrograms}
          >
            ADD TO ORDER WINDOW
          </StyledButton>
        </div>
      </header>
      <Typography
        className={classes.bodyText}
        color="textSecondary"
        tw="max-w-prose mt-0!"
      >
        Programs can only be added once visibilty has been set. The order window
        has to be assigned to a minimum of one territory before programs can be
        added.
      </Typography>
      <div className={classes.chipDiv} tw="empty:hidden">
        {programs.map((p, i) => (
          <Chip
            style={{ margin: "2.5px" }}
            color="primary"
            key={`${i}-${p.id}`}
            label={p.name}
            onDelete={() => handleRemoveProgram(p.id)}
          />
        ))}
      </div>
      <Filters searchTitle="Search Programs" slots={["groups"]} />
      <OpaqueCard tw="p-0 overflow-hidden relative">
        <OrderWindowProgramTable
          programData={programList}
          selectedPrograms={selectedPrograms}
          setSelectedPrograms={setSelectedPrograms}
          {...tableProps}
        />
      </OpaqueCard>
    </FixedHeightScrollLastChild>
  );
};

ProgramPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  activeWindow: PropTypes.object,
  programs: PropTypes.array,
  setPrograms: PropTypes.func.isRequired,
  territories: PropTypes.array,

  handleSubmit: PropTypes.func.isRequired,
};

export default memo(ProgramPanel);
