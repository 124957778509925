import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  clearErrors,
  createChannel,
  setHasUpdated,
  updateChannel,
} from "@redux/slices/channelSlice";

import { useInput } from "../../../hooks/inputs/useInput";
import { StyledButton } from "../../StyledComponents";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ChannelModal = ({ open, handleClose, type, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { value, bind, reset, setValue } = useInput("");

  const [currentChannel, setCurrentChannel] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [localError, setLocalError] = useState(null);

  const { isUpdateLoading, hasUpdated, error, channels } = useSelector(
    (state) => state.channels
  );

  const handleReset = () => {
    dispatch(clearErrors());
    reset();
    setCurrentChannel(null);
    setLocalError(null);
  };

  const handleActiveSwitch = () => {
    setIsActive(!isActive);
  };

  const handleSubmit = () => {
    if (value.length === 0) {
      return setLocalError("You must input a Channel name before submitting");
    }
    const attributes = {
      name: value,
      isActive: isActive,
    };
    setLocalError(null);
    dispatch(
      type === "edit"
        ? updateChannel(id, attributes)
        : createChannel(attributes)
    );
  };

  useEffect(() => {
    if (
      !isUpdateLoading &&
      (type === "edit" || hasUpdated) &&
      (!currentChannel || currentChannel.id !== id)
    ) {
      let channel = channels.find((c) => c.id === id);
      if (channel) {
        setCurrentChannel(channel);
        setValue(channel.name);
        setIsActive(channel.isActive);
      } else {
        dispatch(setHasUpdated({ value: false }));
      }
    }
  }, [
    channels,
    currentChannel,
    dispatch,
    hasUpdated,
    id,
    isUpdateLoading,
    setCurrentChannel,
    setIsActive,
    setValue,
    type,
  ]);

  useEffect(() => {
    dispatch(setHasUpdated({ value: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      setLocalError(error);
    }
  }, [error]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          handleReset();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <div className={classes.titleBar}>
          <DialogTitle>
            <Typography className={classes.headerText}>
              {type === "edit" ? "Editing Channel" : "New Channel"}
            </Typography>
          </DialogTitle>
          <FormControlLabel
            style={{ marginRight: "70px" }}
            control={
              <Switch
                checked={isActive}
                onChange={handleActiveSwitch}
                name="active-switch"
              />
            }
            label={isActive ? "Active" : "Inactive"}
          />
        </div>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              handleReset();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <div className={classes.fullWidthCenterColumn}>
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="name"
              type="text"
              label="Name"
              {...bind}
              fullWidth
            />
            <StyledButton
              cta
              onClick={handleSubmit}
              style={{
                marginBottom: "20px",
                minWidth: "62px",
              }}
              loading={isUpdateLoading}
            >
              Submit
            </StyledButton>
            {!isUpdateLoading && localError && (
              <Typography
                className={classes.bodyText}
                style={{ color: "#920000" }}
              >
                {localError}
              </Typography>
            )}
            {!isUpdateLoading && hasUpdated && (
              <Typography className={classes.bodyText}>
                {type === "edit"
                  ? "Update Successful!"
                  : "New Channel Added Successfully!"}
              </Typography>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ChannelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default memo(ChannelModal);
