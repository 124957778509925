/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Tooltip } from "@mui/material";

import SearchableText from "@components/Table/SearchableText";
import { ItemPreviewImageButton, variantName } from "@features/items";
import { OrderSetVariantSummary } from "@models/OrderSetVariantSummary";
import { OrderVariant } from "@models/OrderVariant";

const InfoText = ({ title, children }) => {
  return (
    <Tooltip title={title}>
      <span tw="decoration-dashed underline underline-offset-4 decoration-neutral-300">
        {children}
      </span>
    </Tooltip>
  );
};

type ItemColumnProps = {
  row: OrderVariant | OrderSetVariantSummary;
  query?: string;
  imgProps?: Partial<React.ComponentProps<typeof ItemPreviewImageButton>>;
};

const ItemColumn: React.FC<ItemColumnProps> = ({ row, query, imgProps }) => {
  return (
    <div tw="flex gap-3 w-max max-w-sm items-start">
      <ItemPreviewImageButton
        item={row.variant.item}
        imageId={row.variant.imageId}
        {...imgProps}
      />

      <div>
        <div tw="flex gap-2 text-primary-600">
          <InfoText title="brandhub generated SKU">
            <SearchableText text={row.variant.variantSku} query={query} />
          </InfoText>
          {row.variant.item.customerIdentifier && (
            <span tw="text-neutral-600">/</span>
          )}
          {row.variant.item.customerIdentifier && (
            <InfoText title="Customer provided identifier">
              <SearchableText
                text={row.variant.item.customerIdentifier}
                query={query}
              />
            </InfoText>
          )}
        </div>

        <div tw="inline-block text-base leading-tight mt-2">
          <SearchableText text={row.variant.item.name} query={query} />
          {row.variant.selectedVariantOptions.length > 0 && (
            <div tw="text-sm text-neutral-500">{variantName(row.variant)}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemColumn;
