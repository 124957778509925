/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import SetOverview from "@components/Ordering/OrderSetOverview/SetOverview";
import SetTable from "@components/Ordering/OrderSetTable/SetTable";
import StripeModal from "@components/Ordering/StripeModal";
import SubmitOnBehalfModal from "@components/Ordering/SubmitOnBehalfModal";
import {
  Contained,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import WarningModal from "@components/Utility/Modals/WarningModal";
import OrderPatchLoading from "@components/Utility/OrderPatchLoading";
import OrderSetTutorial from "@components/tutorial/OrderSetTutorial";
import { DenyOrderSetsModal } from "@features/appovals";
import {
  BulkUploadOrdersButton,
  SetApprovalOverview,
} from "@features/orderSets";
import { useCheckoutOptions, useOrderLock } from "@features/ordering";
import { setError } from "@redux/slices/errorSlice";
import {
  approveOrderSet,
  clearCellState,
  deleteOrderSet,
  fetchOrderSet,
  submitOrderSet,
} from "@redux/slices/orders/currentOrderSetSlice";
import { approveOrDenyOrderSets } from "@redux/slices/orders/orderReviewSlice";
import {
  fetchUserPromotions,
  resetDiscount,
} from "@redux/slices/promotions/promotionSlice";
import {
  updateCurrentChannel,
  updateCurrentTerritory,
} from "@redux/slices/user/currentUserSlice";
import DocTitle from "@utility/DocTitle";
import { formatMoney } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";

import { useNoFetch } from "../hooks/useNoFetch";

/*
Handles displaying the current order set. Based on the origin of the order set, the title and functionaly
of the page can change. This view can be used for placing an order of any type, and also for reviewing
orders.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  orderControl: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  programOrderDescription: {
    boxSizing: "border-box",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    border: `2px solid ${theme.palette.secondary.light}`,
    width: "100%",
    margin: "20px auto",
  },
}));

const CurrentOrder = () => {
  const { origin, orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const typeMap = {
    inventory: "Inventory",
    "pre-order": "Pre Order",
    "on-demand": "On Demand",
  };

  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [currentWarning, setCurrentWarning] = useState({
    warning: null,
    arg: null,
    func: null,
  });
  const [stripeModal, setStripeModal] = useState({
    open: false,
    id: null,
    userName: null,
    amount: null,
    orderType: null,
  });
  const [submitOnBehalfModal, setSubmitOnBehalfModal] = useState({
    open: false,
    id: null,
    territoryId: null,
    channelId: null,
  });
  const [view, setView] = useState(
    origin === "approvals" ? "overview" : "order"
  );
  const [denyModalOpen, setDenyModalOpen] = useState(false);

  const {
    isLoading,
    isUpdateLoading,
    id,
    type,
    status,
    orderWindowId,
    territoryId,
    channelId,
    totalVariantPrice,
    totalEstFreight,
    totalEstTax,
    totalDiscount,
    orderSetVariants,
    orderSetOrders,
    errorCells,
    programOrderDescription,
    promotionIds,
    programId,
  } = useSelector((state) => state.currentOrderSet);
  const {
    name,
    role,
    id: userId,
    currentTerritoryId,
    currentChannelId,
    hasViewedOrderSet,
    organization: { includeShippingInStripeCharge, includeTaxesInStripeCharge },
  } = useSelector((state) => state.currentUser);

  const { allowStripeCheckout } = useCheckoutOptions();

  const { state } = useLocation();

  const navMap = {
    approvals: `/orders/approval${state?.query || ""}`,
    draft:
      type !== "pre-order"
        ? `/orders/draft/${type}`
        : `/programs/${programId}/order/${orderWindowId}`,
  };

  const { willRedirect } = useSelector((state) => state.globalState);

  const handleWarningOpen = () => {
    setCurrentWarning({
      warning: `You are attempting to delete Order Set #${id}, this action cannot be undone.`,
      arg: { type, id, orderWindowId, programId },
      func: handleDeleteOrderSet,
    });
    setIsWarningOpen(true);
  };

  const handleDeleteOrderSet = (args) => {
    dispatch(deleteOrderSet(args));
  };

  const handleWarningClose = () => {
    setIsWarningOpen(false);
    setCurrentWarning({ warning: null, arg: null, func: null });
  };

  const handleSubmit = (submitForId = null) => {
    if (orderSetOrders.length === 0) {
      return dispatch(
        setError({
          error: `You have no orders on this order-set, please edit the order-set and add addresses.`,
          source: "Submit Order",
        })
      );
    }
    const emptyOrders = orderSetOrders.filter((o) => o.count === 0);

    if (emptyOrders.length) {
      const emptyAddresses = emptyOrders.map(
        ({ address: a }) =>
          `${a.streetOne}, ${a.city} ${a.stateOrRegion}, ${a.zip}`
      );
      return dispatch(
        setError({
          error: `The following orders have no items assigned to them:
          
          ${emptyAddresses.join("\n")}

            Either add items, or remove this order from the order set.
            `,
          source: "Submit Order",
        })
      );
    } else {
      dispatch(submitOrderSet(type, id, submitForId));
      setView("overview");
    }
  };

  const handleApprove = () => {
    dispatch(approveOrderSet(id));
    navigate(navMap.approvals);
  };

  const handleDeny = (reason) => {
    dispatch(approveOrDenyOrderSets([id], "cancel", reason));
    navigate(navMap.approvals);
  };

  const handleStripeClose = () => {
    setStripeModal({
      open: false,
      id: null,
      userName: null,
      amount: null,
      orderType: null,
    });
  };

  const handleOpenSubmitOnBehalfModal = () =>
    setSubmitOnBehalfModal({
      open: true,
      id: id,
      territoryId: currentTerritoryId,
      channelId: currentChannelId,
    });

  const handleCloseSubmitOnBehalfModal = () =>
    setSubmitOnBehalfModal({
      open: false,
      id: null,
      territoryId: null,
      channelId: null,
    });

  const handleTitle = (origin) => {
    // eslint-disable-next-line default-case
    switch (origin) {
      case "approvals":
        return `Reviewing Order Set #${orderId}`;
      default:
        return `${typeMap[type]} Order Set #${orderId}`;
    }
  };

  const handleBackButtonName = (type, origin) => {
    // eslint-disable-next-line default-case
    switch (origin) {
      case "approvals":
        return "APPROVALS";
      default:
        return type === "pre-order" ? "VIEW PROGRAM" : "ADD ITEMS";
    }
  };

  const determineTotal = () => {
    const shipping = includeShippingInStripeCharge ? totalEstFreight : 0;
    const tax = includeTaxesInStripeCharge ? totalEstTax : 0;
    return totalVariantPrice + shipping + tax - totalDiscount;
  };

  useOrderLock();

  useEffect(() => {
    dispatch(resetDiscount());
    dispatch(fetchOrderSet(orderId));
    dispatch(fetchUserPromotions(userId));
    dispatch(clearCellState());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderId !== id && !isLoading && !willRedirect) {
      dispatch(fetchOrderSet(orderId));
      dispatch(fetchUserPromotions(userId));
      dispatch(clearCellState());
    }
  }, [
    orderId,
    id,
    dispatch,
    currentTerritoryId,
    isLoading,
    userId,
    willRedirect,
  ]);

  useEffect(() => {
    if (
      orderId === id &&
      origin === "draft" &&
      (["submitted", "approved"].includes(status) || promotionIds.length > 0) &&
      view === "order"
    ) {
      setView("overview");
    }
  }, [orderId, id, origin, view, status, promotionIds]);

  useEffect(() => {
    if (
      territoryId &&
      currentTerritoryId &&
      territoryId !== currentTerritoryId
    ) {
      dispatch(updateCurrentTerritory({ territory: territoryId }));
    }
  }, [currentTerritoryId, dispatch, origin, territoryId]);

  useEffect(() => {
    if (channelId && currentChannelId && channelId !== currentChannelId) {
      dispatch(updateCurrentChannel({ channel: channelId }));
    }
  }, [channelId, currentChannelId, dispatch, origin]);

  useNoFetch(dispatch);

  if (isLoading || !id || (origin === "draft" && !navMap.draft)) {
    return <Loading />;
  }

  return (
    <>
      <DocTitle title={"Current Order"} />
      {!isLoading && !hasViewedOrderSet && <OrderSetTutorial />}
      {isWarningOpen && currentWarning.warning && (
        <WarningModal
          {...currentWarning}
          handleClose={handleWarningClose}
          open={isWarningOpen}
        />
      )}
      {denyModalOpen && (
        <DenyOrderSetsModal
          singleId={id}
          handleClose={() => setDenyModalOpen(false)}
          handleDeny={handleDeny}
        />
      )}
      {submitOnBehalfModal.open && (
        <SubmitOnBehalfModal
          {...submitOnBehalfModal}
          handleSubmit={handleSubmit}
          handleClose={handleCloseSubmitOnBehalfModal}
        />
      )}
      {stripeModal.open && (
        <StripeModal {...stripeModal} handleClose={handleStripeClose} />
      )}
      <Contained tw="space-y-6">
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>
            {handleTitle(origin)}
          </Typography>
          <div className={classes.configButtons}>
            <div className={classes.innerConfigDiv}>
              {view === "order" && (
                <Typography className={classes.titleText}>
                  {`Total: ${formatMoney(totalVariantPrice, false)}`}
                </Typography>
              )}
              {((origin === "draft" &&
                status !== "submitted" &&
                status !== "approved") ||
                origin !== "draft") && (
                <StyledButton
                  outlined
                  startIcon={
                    <ExitToApp style={{ transform: "rotate(180deg)" }} />
                  }
                  component={Link}
                  to={navMap[origin] || "/"}
                >
                  {handleBackButtonName(type, origin)}
                </StyledButton>
              )}
              {origin === "approvals" &&
                type === "pre-order" &&
                allowStripeCheckout && (
                  <StyledButton
                    cta
                    onClick={() => {
                      setStripeModal({
                        open: true,
                        id: id,
                        userName: name,
                        amount: determineTotal(),
                        orderType: type,
                      });
                    }}
                  >
                    CREDIT CARD CHECKOUT
                  </StyledButton>
                )}
            </div>
          </div>
        </header>
        {view === "overview" &&
          (origin === "approvals" ? (
            <SetApprovalOverview
              setView={setView}
              origin={origin}
              handleWarningOpen={handleWarningOpen}
              handleSubmit={handleSubmit}
              setStripeModal={setStripeModal}
              determineTotal={determineTotal}
              handleApprove={handleApprove}
              handleOpenDenyModal={() => setDenyModalOpen(true)}
              handleOpenSubmitOnBehalfModal={handleOpenSubmitOnBehalfModal}
            />
          ) : (
            <SetOverview
              setView={setView}
              origin={origin}
              handleWarningOpen={handleWarningOpen}
              handleSubmit={handleSubmit}
              setStripeModal={setStripeModal}
              determineTotal={determineTotal}
              handleApprove={handleApprove}
              handleOpenDenyModal={() => setDenyModalOpen(true)}
              handleOpenSubmitOnBehalfModal={handleOpenSubmitOnBehalfModal}
            />
          ))}
        {view === "order" && (
          <>
            <OpaqueCard tw="p-0">
              <SetTable
                isLoading={isLoading}
                setVariants={orderSetVariants}
                orders={orderSetOrders}
                orderType={type}
                osId={id}
                orderSetStatus={status}
              />
            </OpaqueCard>
            {programOrderDescription && programOrderDescription.length > 0 && (
              <OpaqueCard>
                <Typography className={classes.bodyText}>
                  {programOrderDescription}
                </Typography>
              </OpaqueCard>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-end",
                margin: "10px",
              }}
            >
              <Typography
                className={classes.bodyText}
                color="textSecondary"
                style={{ marginRight: "10px" }}
              >
                * For Items that are pre-packed, please order the total number
                of individual units you would like, not the number of packs.
              </Typography>
              <Typography className={classes.titleText}>
                {`Total: ${formatMoney(totalVariantPrice, false)}`}
              </Typography>
            </div>
          </>
        )}
        {view !== "overview" && (
          <div tw="flex justify-between items-center">
            <div>
              {origin === "draft" &&
                status !== "submitted" &&
                status !== "approved" &&
                permissions.bulkOrderUpload.includes(role) && (
                  <BulkUploadOrdersButton />
                )}
            </div>
            <div tw="flex gap-3">
              {origin === "draft" &&
                status !== "submitted" &&
                status !== "approved" && (
                  <StyledButton
                    danger
                    onClick={handleWarningOpen}
                    disabled={isUpdateLoading}
                    id="tutorial-delete-order-set"
                    className="tutorial-highlight"
                  >
                    DELETE ORDER SET
                  </StyledButton>
                )}

              <StyledButton
                cta
                id="tutorial-order-set-overview"
                className="tutorial-highlight"
                onClick={() => {
                  dispatch(fetchOrderSet(id));
                  setView("overview");
                }}
                disabled={errorCells.length > 0 || isUpdateLoading}
              >
                ORDER OVERVIEW
              </StyledButton>

              {origin === "approvals" &&
                (status === "submitted" ||
                  (permissions.reviewApprovals.includes(role) &&
                    status === "approval-review")) && (
                  <>
                    <StyledButton
                      cta
                      onClick={() => handleApprove()}
                      disabled={errorCells.length > 0 || isUpdateLoading}
                    >
                      APPROVE
                    </StyledButton>
                    <StyledButton
                      danger
                      onClick={() => setDenyModalOpen(true)}
                      disabled={errorCells.length > 0 || isUpdateLoading}
                    >
                      DENY
                    </StyledButton>
                  </>
                )}
            </div>
          </div>
        )}
        <br />
        <br />
        <OrderPatchLoading />
      </Contained>
    </>
  );
};

CurrentOrder.propTypes = {};

export default CurrentOrder;
