/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useSelector } from "react-redux";

import { CircularProgress, LinearProgress } from "@mui/material";

import { Filters } from "@features/filters";

import { useFilterParams } from "../filters";
import ProgramCard from "../programs/ProgramCard";
import { usePaginatedProgramsQuery } from "./data";

type ProgramListProps = {
  orderWindowId?: string;
};
const ProgramList = ({ orderWindowId }: ProgramListProps) => {
  const [filterParams] = useFilterParams();
  const { currentTerritoryId, currentChannelId } = useSelector(
    (state: any) => state.currentUser
  );
  const {
    data: programs,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = usePaginatedProgramsQuery({
    filter: {
      orderWindowIds: orderWindowId ? [orderWindowId] : undefined,
      isDraft: false,
      isActive: true,
      name: filterParams.q,
      territoryIds: [currentTerritoryId],
      ...(currentChannelId && { channelIds: [currentChannelId] }),
    },
  });

  const fetchNext = () =>
    !isLoading && !isFetching && hasNextPage && fetchNextPage?.();

  const scrollRef = useBottomScrollListener(fetchNext, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  }) as any;

  return (
    <>
      <Filters searchTitle="Search Programs" slots={[]} />
      <div tw="overflow-hidden relative">
        {isLoading && programs.length === 0 && <CircularProgress />}
        {!isLoading && programs.length === 0 && (
          <div>
            <p>There are currently no programs that match your search...</p>
          </div>
        )}
        {programs.length > 0 && (
          <div
            ref={scrollRef}
            css={[
              tw`grid max-h-full gap-3 overflow-auto transition-opacity xl:grid-cols-2`,
              isLoading && tw`opacity-75`,
            ]}
          >
            {programs.map((p, i) => (
              <ProgramCard program={p} orderWindowId={orderWindowId} key={i} />
            ))}
            <div tw="h-1 w-full absolute left-0 bottom-0">
              {isFetching && <LinearProgress />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProgramList;
