/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Close } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { Bordered } from "./StyledComponents";

const Chip = ({
  children,
  onClose,
  showCloseButton = true,
  closeTitle = "Clear filter",
  ...props
}) => {
  return (
    <Bordered
      role="button"
      className="filter-anchor"
      css={[
        tw`h-10 px-3 text-filter text-neutral-600`,
        { b: tw`font-medium text-neutral-800` },
      ]}
      {...props}
    >
      <div>{children}</div>
      {showCloseButton && (
        <Tooltip title={closeTitle} enterDelay={300}>
          <IconButton
            edge="end"
            tw="float-right!"
            onClick={(e) => {
              e.stopPropagation();
              onClose(e);
            }}
          >
            <Close tw="text-base" />
          </IconButton>
        </Tooltip>
      )}
    </Bordered>
  );
};

export default Chip;
