import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { Program } from "@models/Program";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const programsKeyFactory = createQueryKeys("programs", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<Program>(`programs/${id}`).then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Program[]>("programs", {
        params,
      }),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Program[]>("programs", {
        params,
      }),
  }),
});

export const useProgramQuery = (id: string | null | undefined) => {
  return useQuery({
    ...programsKeyFactory.detail(id!),
    enabled: !!id,
    staleTime: 60_000,
  });
};

export const useProgramListQuery = (params?) => {
  return useQuery({
    ...programsKeyFactory.list({ ...params, skipPagination: true }),
    staleTime: 60_000,
    placeholderData: keepPreviousData,
  });
};

export const usePaginatedProgramsQuery = buildPaginatedQuery(
  programsKeyFactory.paginated,
  {
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  }
);

type ProgramPayload = {
  id: string;
  status?: "draft" | "complete";
  additionalFileCloudinaryId?: string | null;
  additionalFileName?: string;
  additionalFileResourceType?: string;
  cloudinaryId?: string;
  description?: string;
  goals?: string[];
  isActive?: boolean;
  isTerritoryExclusive?: boolean;
  name?: string;
  orderDescription?: string;
  channelId?: string | null;
  strategies?: string[];
  territories: { id: string }[];
  orderWindows?: { id: string }[];
  itemIds: (string | number)[];
};

type NewProgramPayload = Partial<Omit<ProgramPayload, "id">>;

const buildProgramPayload = ({ itemIds, ...data }: NewProgramPayload) => ({
  __type: "program",
  ...data,
  items: itemIds?.map((id) => ({ id: String(id), type: "item" })) ?? [],
  relationshipNames: ["territories", "orderWindows", "items"],
});

export const useCreateProgramMutation = () => {
  const { currentChannelId, territories } = useSelector(
    (state: any) => state.currentUser
  );
  const queryClient = useQueryClient();

  const defaults = {
    channelId: currentChannelId,
    territories,
    status: "draft" as const,
  };

  return useMutation({
    mutationFn: (data: NewProgramPayload) =>
      client
        .post<Program>(
          `programs`,
          buildProgramPayload({ ...defaults, ...data })
        )
        .then((res) => res.data),
    onSuccess: (program) => {
      queryClient.invalidateQueries({
        queryKey: programsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: programsKeyFactory.list._def,
      });
      queryClient.setQueryData<Program>(
        programsKeyFactory.detail(program.id).queryKey,
        program
      );
    },
  });
};

export const useUpdateProgramMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: ProgramPayload) =>
      client
        .update<Program>(`programs/${id}`, buildProgramPayload(data))
        .then((res) => res.data),
    onSuccess: (program) => {
      queryClient.invalidateQueries({
        queryKey: programsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: programsKeyFactory.list._def,
      });
      queryClient.setQueryData<Program>(
        programsKeyFactory.detail(program.id).queryKey,
        program
      );
    },
  });
};
