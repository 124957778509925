/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";

import {
  ControlledResourceAutocomplete,
  ControlledTextInput,
} from "@utils/forms";

import BlockCard from "../../../ui/BlockCard";

const ChampionWarehouse = () => {
  const { control } = useFormContext();

  const defaultVariant = useWatch({ name: "variants.default" });
  const warehouse = useWatch({ name: "warehouse" });

  if (!warehouse) return null;

  return (
    <BlockCard title="Warehouse Specs" tw="space-y-4">
      <ControlledResourceAutocomplete
        name="countryOfOriginId"
        resource={"countries"}
        resourceFilterName={false}
        control={control}
        label="Country of Origin"
      />
      <ControlledTextInput label="HTS Code" {...{ name: "htsCode", control }} />

      {defaultVariant.isActive && (
        <ControlledTextInput
          label="External Warehouse ID"
          {...{ name: "variants.default.externalWarehouseId", control }}
        />
      )}
    </BlockCard>
  );
};

export default ChampionWarehouse;
