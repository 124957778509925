/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useWatch } from "react-hook-form";

import { Item } from "@models/Item";
import useRoleIs from "@utils/useRoleIs";

import BlockCard from "../../../../ui/BlockCard";
import BulkVariantSelection from "./BulkVariantSelection";
import DuplicateVariants from "./DuplicateVariants";
import Options from "./Options";
import VariantImagesModal from "./VariantImagesModal";
import VariantsTable from "./VariantsTable";

type VariantsBlockProps = {
  item: Item;
};

const VariantsBlock = ({ item }: VariantsBlockProps) => {
  const variantOptions = useWatch({ name: "variantOptions" });
  const showVariantsTable = Object.values(variantOptions).flat().length > 0;
  const roleIs = useRoleIs();

  const [selectedVariants, setSelectedVariants] = useState<string[]>([]);
  const [variantImageModalSelection, setVariantImageModalSelection] = useState<
    string[]
  >([]);
  return (
    <BlockCard title="Options">
      <Options item={item} />
      {/* <Divider /> */}
      {showVariantsTable && (
        <>
          <BulkVariantSelection
            {...{
              selectedVariants,
              setSelectedVariants,
            }}
          />
          <VariantsTable
            {...{
              selectedVariants,
              setSelectedVariants,
              setVariantImageModalSelection,
            }}
          />
          {roleIs("super") && <DuplicateVariants item={item} />}
        </>
      )}
      {variantImageModalSelection.length > 0 && (
        <VariantImagesModal
          open
          variantSelection={variantImageModalSelection}
          handleClose={() => setVariantImageModalSelection([])}
        />
      )}
    </BlockCard>
  );
};

export default VariantsBlock;
