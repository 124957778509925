import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import Logo from "../assets/logo-dark.svg";
import DocTitle from "@utility/DocTitle";

/*
If a user is deactivated and attempts to log in, they are presented with this view.
*/

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  newUserWrapper: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    position: "fixed",
    top: 0,
    left: 0,
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
  },
  infoWrapper: {
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    position: "fixed",
    top: "20px",
    left: "20px",
    width: "150px",
    height: "auto",
    filter: "brightness(0%)",
  },
  logout: {
    position: "fixed",
    top: "20px",
    right: "20px",
  },
}));

const DeactivatedUser = () => {
  const classes = useStyles();

  const name = useSelector((state) => state.currentUser.name);
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocTitle title={"Deactivated User"} />
      <div className={classes.newUserWrapper}>
        <img src={Logo} className={classes.logo} alt="Logo" />
        <Button
          className={clsx(classes.logout, classes.button)}
          variant="contained"
          color="secondary"
          onClick={() => navigate("/logout")}
        >
          LOGOUT
        </Button>
        <Container className={classes.infoWrapper}>
          <div
            style={{
              height: "70vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container>
              <Grid item sm={2} xs={1} />
              <Grid item sm={8} xs={10} style={{ textAlign: "center" }}>
                <Typography className={classes.titleText} variant="h5">
                  {`Welcome to Brandhub ${name}`}
                </Typography>
                <br />
                <Typography className={classes.headerText}>
                  It looks like your account has been deactivated.
                </Typography>
                <br />
                <Typography className={classes.headerText}>
                  If you believe this was done incorrectly, please contact your
                  manager and let them know that you no longer have access to RTA.
                </Typography>
              </Grid>
              <Grid item sm={2} xs={1} />
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default DeactivatedUser;
