import { ReactNode, useContext, useMemo } from "react";

import {
  ConfirmContentProps,
  GlobalConfirmContext,
} from "./GlobalConfirmContext";

export function useConfirm() {
  const { setPromise, setConfirmContent } = useContext(GlobalConfirmContext);

  const confirm = (
    message: ReactNode,
    options?: Omit<ConfirmContentProps, "message">
  ) =>
    new Promise<boolean>((resolve) => {
      const confirmContent = {
        title: "Are you sure?",
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        message,
        ...options,
      };
      setPromise({ resolve });
      setConfirmContent(confirmContent);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => confirm, []);
}
