/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import { OpaqueCard } from "../StyledComponents";
import ImageWrapper from "../Utility/ImageWrapper";
import InfoPopover from "./InfoPopover";

export type CardData = {
  titleText: string;
  ariaOwnsState?: boolean;
  ariaOwnsText?: string;
  id: string;
  link: string | null;
  info: string;
  icon: string;
  onClick?: () => void;
};

const DashCard = ({
  data,
  onClick,
}: {
  data: CardData;
  onClick?: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      component={data.link ? Link : "div"}
      {...(data.link ? { to: data.link } : {})}
      className="group"
    >
      <OpaqueCard
        tw="overflow-visible h-full flex items-start gap-4 p-4 cursor-pointer"
        aria-owns={data.ariaOwnsState ? data.ariaOwnsText : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={onClick ? onClick : data.onClick}
      >
        <div tw="rounded-lg inline-flex items-center justify-center float-left flex-none bg-gradient-to-br from-neutral-100 to-neutral-200">
          {data.icon && (
            <ImageWrapper
              tw="w-16 h-16 lg:(w-24 h-24)"
              imgUrl={data.icon}
              alt={data.titleText}
              imgClass={undefined}
              id={data.id}
            />
          )}
        </div>
        <div tw="text-2xl font-bold tracking-wide text-neutral-700">
          <div tw="my-3">{data.titleText}</div>
          <div tw="h-1 w-1/2 rounded bg-primary-600 transition duration-300 origin-left group-hover:(scale-x-125 bg-primary-800)"></div>
        </div>
      </OpaqueCard>
      <InfoPopover
        id={data.id}
        info={data.info}
        open={!!anchorEl}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      />
    </Box>
  );
};

export default DashCard;
