import { memo, useState } from "react";

import { CircularProgress, Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import SingleUserAutoComplete from "../../Utility/AutoCompleteFields/SingleUserAutoComplete";
import OptionTable from "../../Utility/OptionTable";

const UserAssignment = ({
  classes,
  type,
  currentCostCenter,
  relationships,
  setRelationships,
  isUpdateLoading,
}) => {
  const [resetField, setResetField] = useState(false);

  const handleAddRelationship = (rel) => {
    let currentRel = relationships.find((r) => r.id === rel.id);
    if (!currentRel) setRelationships(relationships.concat(rel));
    setResetField(true);
  };

  const handleRemoveRelationship = (id) =>
    setRelationships(relationships.filter((r) => r.id !== id));

  if (type === "edit" && !currentCostCenter) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      <br />
      <br />
      <Typography className={clsx(classes.bodyText, classes.settingsMargin)}>
        A cost center can be assigned to multiple users, if a user is assigned
        multiple cost centers, users will be able to select which cost center to
        use when submiting an order.
      </Typography>

      <br />

      <SingleUserAutoComplete
        classes={classes}
        handleChange={handleAddRelationship}
        reset={resetField}
        setReset={setResetField}
        disabled={isUpdateLoading}
      />
      <br />
      {relationships.length > 0 && (
        <OptionTable
          headCells={[
            { id: "id", label: "Id" },
            { id: "name", label: "Name" },
          ]}
          options={relationships}
          handleRemove={handleRemoveRelationship}
          isRemoveable
        />
      )}
    </>
  );
};

UserAssignment.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  currentCostCenter: PropTypes.object,
  relationships: PropTypes.array,
  setRelationships: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isUpdateLoading: PropTypes.bool,
};

export default memo(UserAssignment);
