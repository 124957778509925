import _ from "lodash";

import { ItemRollup } from "@models/ItemRollup";
import client from "@services/api";
import asyncPool from "@utility/asyncPool";

/**
 * Returns all un-selected item rollups that have
 * the same skus as the selected item rollups
 */
export default async function getRelatedItemRollups(
  selectedItemRollups: ItemRollup[],
  orderType: "on-demand" | "pre-order"
): Promise<ItemRollup[]> {
  const rollupsWithVariantOptions = selectedItemRollups.filter(
    (ir) => ir.variant.selectedVariantOptions.length > 0
  );
  const rollupsBySku = _.groupBy(rollupsWithVariantOptions, "variant.item.sku");
  const uniqueItemSkus = Object.keys(rollupsBySku);

  const requests = await asyncPool(5, uniqueItemSkus, async (sku) => {
    const params = {
      filter: { sku, orderSetType: orderType },
    };
    return client.get(`/item-rollups`, { params }).then((res) => {
      const itemRollups = (res?.data ?? []) as ItemRollup[];
      return _.xorBy(itemRollups, rollupsBySku[sku], "itemNumber");
    });
  });

  return requests.results.flat();
}
