import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { fetchFilteredUsers } from "@redux/slices/user/usersSlice";

const UserAutoComplete = ({ handleChange, reset, setReset, filterType }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [currentUsers, setCurrentUsers] = useState([]);

  const isLoading = useSelector((state) => state.users.isLoading);
  const options = useSelector((state) => state.users.userList);
  const currentFiltersUser = useSelector((state) => state.filters.userIds);
  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handleUsers = (value) => {
    setCurrentUsers(value);
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchFilteredUsers(user, "active", [], null, null));
    }, 250);
  }, [user, dispatch]);

  useEffect(() => {
    if (user.length >= 1) {
      handleQuery();
    }
  }, [user, handleQuery, dispatch]);

  useEffect(() => {
    if (currentFiltersUser.length !== currentUsers.length) {
      setCurrentUsers(currentFiltersUser);
    }
  }, [currentFiltersUser, currentUsers.length]);

  useEffect(() => {
    if (reset) {
      setUser("");
      setCurrentUsers([]);
      setReset(false);
    }
  }, [reset, setUser, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        id="user-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={user}
        onInputChange={(_evt, value) => setUser(value)}
        onChange={(_evt, value) => {
          handleChange(value, "userIds", filterType);
          handleUsers(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        value={currentUsers}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Person"
            id="user-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

UserAutoComplete.propTypes = {
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default UserAutoComplete;
