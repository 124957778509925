/** @jsxImportSource @emotion/react */
import "twin.macro";

import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";

import _ from "lodash";

import { orderTypeLabel, useCheckoutOptions } from "@features/ordering";
import { updateOrderSetBudget } from "@redux/slices/orders/currentOrderSetSlice";
import { formatMoney } from "@utility/utilityFunctions";

import OrderSetCostCenters from "../../../components/Ordering/OrderSetOverview/OrderSetCostCenters";
import SetOverviewItemTable from "../../../components/Ordering/OrderSetOverview/SetOverviewItemTable";
import SetOverviewOrderTable from "../../../components/Ordering/OrderSetOverview/SetOverviewOrderTable";
import { OpaqueCard, StyledButton } from "../../../components/StyledComponents";
import AppliedBudget from "./AppliedBudget";
import AppliedPromotions from "./AppliedPromotions";

const LabelValuePair = ({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) => (
  <div tw="flex justify-between items-baseline">
    <div tw="text-neutral-500">{label}</div>
    <div tw="text-right text-neutral-700 font-medium">{value}</div>
  </div>
);

const SetApprovalOverview = ({
  setView,
  handleApprove,
  handleOpenDenyModal,
}) => {
  const dispatch = useDispatch();
  const {
    id,
    type,
    totalVariantPrice,
    totalEstFreight,
    totalEstTax,
    orderSetVariants,
    orderSetOrders,
    promotionIds,
    totalDiscount,
    budgetId,
    userName,
    checkoutType,
    orderWindowName,
    programName,
  } = useSelector((state: any) => state.currentOrderSet);
  const {
    usesCostCenters,
    includeShippingInBudgets,
    includeTaxesInBudgets,
    includeShippingInStripeCharge,
    includeTaxesInStripeCharge,
  } = useSelector((state: any) => state.currentUser.organization);

  const { allowBudgetCheckout } = useCheckoutOptions();

  const getTotalTax = () => {
    let tax = totalEstTax;
    if (checkoutType === "stripe") {
      tax = includeTaxesInStripeCharge ? totalEstTax : 0;
    }
    if (budgetId) {
      tax = includeTaxesInBudgets ? totalEstTax : 0;
    }
    return tax;
  };

  const getTotalFreight = () => {
    let shipping = totalEstFreight;
    if (
      (checkoutType === "stripe" && !includeShippingInStripeCharge) ||
      (budgetId && !includeShippingInBudgets)
    ) {
      shipping = 0;
    }
    return shipping;
  };

  const orderTotal =
    totalVariantPrice + totalEstFreight + getTotalTax() - totalDiscount;
  const chargedTotal =
    totalVariantPrice + getTotalFreight() + getTotalTax() - totalDiscount;

  useEffect(() => {
    if (!allowBudgetCheckout && budgetId) {
      dispatch(updateOrderSetBudget(id, null));
    }
  }, [id, allowBudgetCheckout, budgetId, dispatch]);

  return (
    <>
      <div tw="grid gap-6 xl:grid-cols-[1fr 340px] items-start">
        <div tw="xl:(sticky top-[var(--header-height)] order-last w-full)">
          <Typography tw="text-lg mb-2">Summary</Typography>
          <OpaqueCard tw="grid sm:grid-cols-2 xl:(flex flex-col) gap-8 gap-x-12">
            <div>
              <div tw="space-y-1">
                <LabelValuePair
                  label="Total Items"
                  value={
                    <span tw="font-normal text-neutral-500">
                      {orderSetVariants
                        .map((v) => v.count)
                        .reduce((a, b) => a + b, 0)}
                    </span>
                  }
                />

                <LabelValuePair
                  label="Total Item Cost"
                  value={formatMoney(totalVariantPrice, false)}
                />

                <LabelValuePair
                  label="Est. Freight"
                  value={formatMoney(totalEstFreight, false)}
                />

                <LabelValuePair
                  label="Est. Tax"
                  value={formatMoney(getTotalTax(), false)}
                />

                {totalDiscount > 0 && (
                  <LabelValuePair
                    label="Promotion"
                    value={`-${formatMoney(totalDiscount, false)}`}
                  />
                )}

                <LabelValuePair
                  label={
                    <span tw="text-neutral-700 font-medium">Order Total</span>
                  }
                  value={formatMoney(orderTotal, false)}
                />

                {orderTotal !== chargedTotal && (
                  <LabelValuePair
                    label={<span tw="text-neutral-700">Charged Total</span>}
                    value={formatMoney(chargedTotal, false)}
                  />
                )}
              </div>
            </div>
            <div tw="space-y-6">
              {usesCostCenters && <OrderSetCostCenters />}
              {allowBudgetCheckout && <AppliedBudget />}

              <AppliedPromotions promotionIds={promotionIds} />

              <div tw="space-y-1">
                <LabelValuePair
                  label="Order Type:"
                  value={orderTypeLabel(type)}
                />
                <LabelValuePair label="Orderer:" value={userName} />
                <LabelValuePair
                  label="Checkout Type:"
                  value={_.capitalize(checkoutType)}
                />
                {type === "pre-order" && (
                  <>
                    <LabelValuePair label="Program:" value={programName} />
                    <LabelValuePair
                      label="Order Window:"
                      value={orderWindowName}
                    />
                  </>
                )}
              </div>

              <StyledButton fullWidth outlined onClick={() => setView("order")}>
                EDIT ORDER
              </StyledButton>
              <div tw="flex gap-2">
                <StyledButton cta onClick={() => handleApprove()}>
                  APPROVE
                </StyledButton>
                <StyledButton danger onClick={() => handleOpenDenyModal()}>
                  DENY
                </StyledButton>
              </div>
            </div>
          </OpaqueCard>
        </div>
        <div tw="min-w-0 flex flex-col gap-6">
          <div>
            <Typography tw="text-lg mb-2">Order Overview</Typography>
            <OpaqueCard tw="py-0 px-4">
              {promotionIds.length > 0 && (
                <Typography tw="text-sm" color="textSecondary">
                  <em>* Pricing does not reflect discounts</em>
                </Typography>
              )}
              {orderSetOrders.length > 0 ? (
                <SetOverviewOrderTable
                  orders={orderSetOrders}
                  hasTax={totalEstTax > 0}
                />
              ) : (
                <>
                  <br />
                  <Typography tw="text-sm">
                    There are no orders in the current order set ...
                  </Typography>
                </>
              )}
            </OpaqueCard>
          </div>
          <div>
            <Typography tw="text-lg mb-2">Item Overview</Typography>
            <OpaqueCard tw="py-0 px-4">
              {promotionIds.length > 0 && (
                <Typography tw="text-sm" color="textSecondary">
                  <em>* Pricing does not reflect discounts</em>
                </Typography>
              )}
              {orderSetVariants.map((v) => v.count).reduce((a, b) => a + b, 0) >
              0 ? (
                <SetOverviewItemTable
                  orders={orderSetOrders}
                  variants={orderSetVariants}
                  type={type}
                />
              ) : (
                <>
                  <br />
                  <Typography tw="text-sm">
                    There are no items added to this order currently ...
                  </Typography>
                </>
              )}
            </OpaqueCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetApprovalOverview;
