import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import { DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { clearError } from "@redux/slices/errorSlice";

import { StyledButton } from "../../StyledComponents";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  confirmDeleteModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    textAlign: "center",
  },
}));

const ErrorModal = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const error = useSelector((state) => state.error.currentError);
  const source = useSelector((state) => state.error.errorSource);

  const handleClose = () => dispatch(clearError());

  if (!error) return null;

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={true}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon />
          </IconButton>
          <br />
          <div className={classes.confirmDeleteModal}>
            {error.includes("quantity exceeds available inventory") ? (
              <>
                <br />
                <Typography className={classes.headerText}>{error}</Typography>
                <br />
                <Typography className={classes.headerText}>
                  Please adjust the quantity ordered for this sku.
                </Typography>
                <br />
              </>
            ) : (
              <>
                <Typography className={classes.headerText}>
                  It looks like something went wrong, the following error has
                  occured:
                </Typography>
                <br />
                <Typography
                  className={classes.bodyText}
                  style={{ whiteSpace: "pre-wrap" }} // render line breaks (\n)
                >
                  {error}
                </Typography>
                {source && (
                  <>
                    <br />
                    <Typography
                      className={classes.bodyText}
                    >{`Source: ${source}`}</Typography>
                  </>
                )}
              </>
            )}
          </div>
          <br />
        </DialogContent>
        <DialogActions>
          {!error.includes("quantity exceeds available inventory") && (
            <StyledButton onClick={handleClose} href="/" outlined>
              GO BACK HOME
            </StyledButton>
          )}
          <StyledButton
            cta
            onClick={handleClose}
            style={{ marginLeft: "12px" }}
          >
            {error.includes("quantity exceeds available inventory")
              ? "DISMISS"
              : "DISMISS ERROR"}
          </StyledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorModal;
