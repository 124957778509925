import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";

import { clearTerritories } from "@redux/slices/territories/territorySlice";
import { removeUser, setIsLoading } from "@redux/slices/user/currentUserSlice";
import { useClearSwrCache } from "@services/api";

import { logoutUser } from "../api/userApi";

const Logout = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const clearCache = useClearSwrCache();

  useEffect(() => {
    dispatch(setIsLoading());
    dispatch(removeUser());
    dispatch(clearTerritories());
    logoutUser();
    logout({ returnTo: window.location.origin });
    clearCache();
  }, [dispatch, logout, clearCache]);
  return null;
};

export default Logout;
