/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Alert } from "@mui/material";

const ErrorComponent = (error) => {
  return (
    <Alert severity="error">
      <div>Something went wrong!</div>
      <pre tw="whitespace-pre">{error?.message}</pre>
    </Alert>
  );
};

export default ErrorComponent;
