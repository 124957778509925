import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { Address } from "@models/Address";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

type AddressPayload = Address & { userId: number | null };

type NewAddressPayload = Partial<Omit<AddressPayload, "id">>;

export const addressesKeyFactory = createQueryKeys("addresses", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client.get<Address>(`addresses/${id}`).then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Address[]>("addresses", {
        params,
      }),
  }),
});

export const useAddressQuery = (id: string | null | undefined) => {
  return useQuery({
    ...addressesKeyFactory.detail(id!),
    enabled: !!id,
    staleTime: 60_000,
  });
};

export const usePaginatedAddressesQuery = buildPaginatedQuery(
  addressesKeyFactory.paginated,
  {
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  }
);

/* Mutations */

const buildAddressPayload = ({ ...data }: NewAddressPayload) => ({
  __type: "address",
  type: "address",
  ...data,
  territoryId: data.territoryId,
  relationshipNames: ["country", "state", "user"],
  country: {
    type: "country",
    id: data.country,
  },
  state: {
    type: "state",
    id: data.state,
  },
  user: {
    type: "user",
    id: data.userId,
  },
});

export const useCreateAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: NewAddressPayload) =>
      client
        .post<Address>(`addresses`, buildAddressPayload(data))
        .then((res) => res.data),
    onSuccess: (address) => {
      queryClient.invalidateQueries({
        queryKey: addressesKeyFactory.paginated._def,
      });
      queryClient.setQueryData<Address>(
        addressesKeyFactory.detail(address.id).queryKey,
        address
      );
    },
  });
};

export const useUpdateAddressMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: AddressPayload) =>
      client
        .update<Address>(`addresses/${id}`, buildAddressPayload(data))
        .then((res) => res.data),
    onSuccess: (address) => {
      queryClient.invalidateQueries({
        queryKey: addressesKeyFactory.paginated._def,
      });
      queryClient.setQueryData<Address>(
        addressesKeyFactory.detail(address.id).queryKey,
        address
      );
    },
  });
};

export const useCreateAddressImportMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: NewAddressPayload) =>
      client
        .post<Address>(`addresses/import`, buildAddressPayload(data))
        .then((res) => res.data),
    onSuccess: (address) => {
      queryClient.invalidateQueries({
        queryKey: addressesKeyFactory.paginated._def,
      });
      queryClient.setQueryData<Address>(
        addressesKeyFactory.detail(address.id).queryKey,
        address
      );
    },
  });
};
