/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import DashOption from "@components/Settings/Dashboards/DashOption";
import DashboardSelector from "@components/Settings/Dashboards/DashboardSelector";
import { Contained, StyledButton } from "@components/StyledComponents";
import WarningModal from "@components/Utility/Modals/WarningModal";
import { updateDashboardOptions } from "@redux/slices/user/currentUserSlice";
import DocTitle from "@utility/DocTitle";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AdminDashboards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dashboardRole, setDashboardRole] = useState("orderer");
  const [currentDashboard, setCurrentDashboard] = useState({
    id: null,
    role: null,
    options: [],
  });
  const [warningModal, setWarningModal] = useState({
    open: false,
    warning: null,
    func: null,
    arg: null,
  });

  const {
    isUpdateLoading,
    organization: { dashboards },
  } = useSelector((state) => state.currentUser);

  const handleWarningClose = () => {
    setWarningModal({
      open: false,
      warning: null,
      func: null,
      arg: null,
    });
  };

  const handleDashboardSelect = (role) => {
    let updateOptions = currentDashboard.options.filter((o) => o.update);
    if (updateOptions.length === 0) {
      setDashboardRole(role);
    } else {
      setWarningModal({
        open: true,
        warning:
          "You currently have unsaved work on the current dashboard, would you like to continue without saving?",
        func: setDashboardRole,
        arg: role,
      });
    }
  };

  const handleUpdateOption = (id, key, value) => {
    let newOptions = currentDashboard.options.map((o) => {
      if (o.id === id) {
        return {
          ...o,
          [key]: value,
          update: true,
        };
      } else return o;
    });

    setCurrentDashboard({
      id: currentDashboard.id,
      role: currentDashboard.role,
      options: newOptions,
    });
  };

  const handleSave = () => {
    let updatedOptions = currentDashboard.options.filter((o) => o.update);
    dispatch(updateDashboardOptions(updatedOptions, dashboardRole));
  };

  useEffect(() => {
    if (!currentDashboard.id || currentDashboard.role !== dashboardRole) {
      let newDashboard = dashboards.find((d) => d.role === dashboardRole);
      let currentOptions = newDashboard.options
        .map((o) => ({
          ...o,
          update: false,
        }))
        .sort((a, b) =>
          a.displayName < b.displayName
            ? -1
            : a.displayName > b.displayName
            ? 1
            : 0
        )
        .sort((a, b) =>
          a.position < b.position ? -1 : a.position > b.position ? 1 : 0
        )
        .sort((a, b) => {
          if (a.isActive && b.isActive) return 0;
          if (a.isActive) return -1;
          if (b.isActive) return 1;
          return 0;
        });
      setCurrentDashboard({
        id: newDashboard.id,
        role: dashboardRole,
        options: currentOptions,
      });
    }
  }, [currentDashboard.id, currentDashboard.role, dashboardRole, dashboards]);

  const SaveButton = () => (
    <StyledButton
      cta
      onClick={handleSave}
      disabled={
        isUpdateLoading ||
        !currentDashboard.id ||
        !currentDashboard.options.find((o) => o.update)
      }
    >
      SAVE
    </StyledButton>
  );

  return (
    <>
      <DocTitle title={"Dashboard Admin"} />
      <Contained tw="max-w-screen-lg">
        {warningModal.open && (
          <WarningModal {...warningModal} handleClose={handleWarningClose} />
        )}
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Edit Dashboards</Typography>
          <div tw="flex items-center gap-4">
            <DashboardSelector
              classes={classes}
              dashboard={dashboardRole}
              setDashboard={handleDashboardSelect}
            />
            <SaveButton />
          </div>
        </div>
        <Typography
          className={classes.bodyText}
          color="textSecondary"
          tw="mt-2 max-w-prose"
        >
          <span tw="text-sm">
            Each role can have it's own custom dashboard in Brandhub. Below are
            the available dashboard options for the currently selected role.
            Each option correlates to a link on the user's dashboard. All these
            actions will always be available through normal navigation as well.
          </span>
        </Typography>
        <br />
        <div tw="space-y-3">
          {currentDashboard.options.map((o) => (
            <DashOption
              handleUpdateOption={handleUpdateOption}
              option={o}
              key={o.id}
            />
          ))}
        </div>
        <br />
        <div className={clsx(classes.center, classes.fullWidth)}>
          <SaveButton />
        </div>
        <br />
      </Contained>
    </>
  );
};

export default AdminDashboards;
