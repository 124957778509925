import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { QuotedItem } from "@models/QuotedItem";
import { RequestForQuote } from "@models/RequestForQuote";
import client, { RequestParams } from "@services/api";
import { QueryOptions, buildPaginatedQuery } from "@utils/reactQuery";

export const rfqsKeyFactory = createQueryKeys("request-for-quotes", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () =>
      client
        .get<RequestForQuote>(`request-for-quotes/${id}`)
        .then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<RequestForQuote[]>("request-for-quotes", { params })
        .then((res) => res.data),
  }),
  quotedItems: (params) => ({
    queryKey: [params],
    queryFn: () => client.get<QuotedItem[]>("quoted-items", { params }),
  }),
});

export const useRfqQuery = (id: string | null | undefined) => {
  return useQuery({
    ...rfqsKeyFactory.detail(id!),
    enabled: !!id,
    staleTime: 60_000,
  });
};

export const useRfqListQuery = (
  params: RequestParams,
  options?: QueryOptions<RequestForQuote[]>
) => {
  return useQuery({
    ...rfqsKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: 60_000,
    ...options,
  });
};

export const usePaginatedQuotedItemsQuery = buildPaginatedQuery(
  rfqsKeyFactory.quotedItems,
  {
    placeholderData: keepPreviousData,
    staleTime: 60_000,
  }
);
