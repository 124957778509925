import { useEffect, useMemo } from "react";

import {
  InfiniteData,
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import _ from "lodash";

import client, { ClientResponse, RequestParams } from "@services/api";

type PageParam = string | null;

type CustomOptions = {
  initialPagesToLoad?: number;
};

type TOptions<T> = Omit<
  UndefinedInitialDataInfiniteOptions<
    T,
    Error,
    InfiniteData<T>,
    readonly any[],
    PageParam
  >,
  "queryKey" | "queryFn" | "getNextPageParam" | "initialPageParam"
> &
  CustomOptions;

export default function buildPaginatedQuery<T>(
  queryFactory: (...params: any[]) => {
    queryKey: readonly any[];
    queryFn: (
      params: any
    ) => Promise<ClientResponse<T[]>> | ClientResponse<T[]>;
  },
  defaultOptions?: TOptions<ClientResponse<T[]>>
) {
  function usePaginatedQuery(
    params: RequestParams,
    options?: TOptions<ClientResponse<T[]>>
  ) {
    const { initialPagesToLoad, ...queryOptions } = {
      ...defaultOptions,
      ...options,
    };

    const query = queryFactory(params);
    const infiniteQuery = useInfiniteQuery({
      queryKey: query.queryKey,
      queryFn: (fnParams) =>
        fnParams.pageParam
          ? client.get<T[]>(fnParams.pageParam)
          : query.queryFn(fnParams),
      getNextPageParam: (lastPage) =>
        lastPage?.links.next?.replace("/api/", ""),
      initialPageParam: null,
      ...queryOptions,
    });

    const pagesLoaded = infiniteQuery.data?.pages.length ?? 0;

    const data = useMemo(
      () => _.flatten(infiniteQuery.data?.pages.map((page) => page.data)),
      [infiniteQuery.data]
    );
    const meta = useMemo(
      () => infiniteQuery.data?.pages[0]?.meta,
      [infiniteQuery.data]
    );

    useEffect(() => {
      if (infiniteQuery.isFetching || pagesLoaded === 0 || !initialPagesToLoad)
        return;
      if (pagesLoaded < initialPagesToLoad && infiniteQuery.hasNextPage) {
        infiniteQuery.fetchNextPage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesLoaded, infiniteQuery.isFetching]);

    return {
      ...infiniteQuery,
      data,
      meta,
    };
  }

  return usePaginatedQuery;
}
