/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ExitToAppTwoTone } from "@mui/icons-material";

import { Contained, StyledButton } from "@components/StyledComponents";
import { ItemCatalogView, ItemViewButtons } from "@features/items";
import { useIsOrderWindowOrderable } from "@features/orderWindows";
import {
  AddToCartButton,
  VariantSelectionModal,
  useCurrentDraftOrderSet,
  useOrderLock,
} from "@features/ordering";
import {
  ProgramDetails,
  useProgramItems,
  useProgramQuery,
} from "@features/programs";
import { Item } from "@models";
import DocTitle from "@utility/DocTitle";

/*
The program view displays all information for the selected program, and also gives access to view all items
in the program. From there the user can also create a pdf based on the items in the program.
*/

const ProgramOrder = () => {
  const { programId, orderWindowId } = useParams() as unknown as Record<
    string,
    string
  >;

  const {
    orderSet,
    isLoading: isOrderSetLoading,
    orderSetVariants,
    createOrderSetVariants,
  } = useCurrentDraftOrderSet("pre-order", { orderWindowId, programId });
  const { data: program } = useProgramQuery(programId);
  const { data, ...tableProps } = useProgramItems(programId);
  const items = data || [];

  const [variantSelectionItem, setVariantSelectionItem] = useState<null | Item>(
    null
  );

  const selectedOrderWindow =
    orderWindowId &&
    program?.orderWindows.find((ow) => ow.id === orderWindowId);

  const isOrderWindowOrderable = useIsOrderWindowOrderable();
  const isProgramOrderable =
    selectedOrderWindow && isOrderWindowOrderable(selectedOrderWindow);

  const { role } = useSelector((state: any) => state.currentUser);

  const addToOrderSet = async (item: Item) => {
    const defaultVariant = item.variants.find((v) => v.isDefault);

    if (defaultVariant?.isActive) {
      await createOrderSetVariants([defaultVariant.id]);
    } else {
      setVariantSelectionItem(item);
    }
  };

  useOrderLock();

  return (
    <>
      <DocTitle title={"Program Order"} />
      {variantSelectionItem && (
        <VariantSelectionModal
          item={variantSelectionItem}
          onClose={() => setVariantSelectionItem(null)}
          orderType="pre-order"
          {...{
            orderSetVariants,
            createOrUpdateOrderSet: createOrderSetVariants,
          }}
        />
      )}
      <Contained tw="space-y-3 pb-3">
        <ProgramDetails programId={programId} items={items} />

        <div tw="flex gap-4 items-center justify-between">
          {orderWindowId &&
            role !== "read-only" &&
            (orderSet ? (
              <StyledButton
                cta
                startIcon={<ExitToAppTwoTone />}
                to={`/orders/open/draft/${orderSet.id}`}
              >
                View Order
              </StyledButton>
            ) : (
              <StyledButton
                cta
                startIcon={<ExitToAppTwoTone />}
                onClick={() => createOrderSetVariants([])}
                loading={isOrderSetLoading}
                disabled={!isProgramOrderable}
              >
                Start Order
              </StyledButton>
            ))}
          {!orderWindowId && (
            <h3 tw="text-lg text-neutral-600">Item Preview</h3>
          )}
          <ItemViewButtons />
        </div>

        <ItemCatalogView
          rows={items}
          rowActions={
            orderSet
              ? (item) => (
                  <AddToCartButton
                    item={item}
                    orderSetVariants={orderSetVariants}
                    addToOrderSet={addToOrderSet}
                  />
                )
              : undefined
          }
          {...tableProps}
        />
      </Contained>
    </>
  );
};
export default ProgramOrder;
