/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { OpaqueCard, StyledButton } from "@components/StyledComponents";
import { Quote } from "@models/Quote";

import CompareQuotesModal from "./CompareQuotesModal";

const CompareQuotes = ({ quotes }: { quotes: Quote[] }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {modalOpen && (
        <CompareQuotesModal
          quotes={quotes}
          onClose={() => setModalOpen(false)}
        />
      )}
      {!modalOpen && quotes.length > 1 && (
        <OpaqueCard tw="p-4 fixed left-1/2 -translate-x-1/2 bottom-16">
          <StyledButton cta onClick={() => setModalOpen(true)}>
            Compare Quotes
          </StyledButton>
        </OpaqueCard>
      )}
    </>
  );
};

export default CompareQuotes;
