import React, { memo, useState } from "react";

import { Cancel } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { StyledButton } from "@components/StyledComponents";

import { useInput } from "../../hooks/inputs/useInput";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const CancelRollupItemModal = ({ open, ids, handleCancel, handleClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const { value, bind, reset } = useInput("");

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          reset();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              reset();
              handleClose();
            }}
            size="large"
          >
            <Cancel fontSize="large" color="secondary" />
          </IconButton>
          <div className={classes.centeredModal}>
            <Typography className={classes.headerText}>
              Deleting this Purchase Order Item will cancel all order items on
              approved orders that contain this item.
            </Typography>
            <br />
            <Typography className={classes.bodyText}>
              Please provide a reason for canceling this Purchase Order Rollup.
            </Typography>
            <br />
            <TextField
              fullWidth
              multiline
              rows="4"
              variant="outlined"
              color="secondary"
              name="note"
              type="text"
              label="Cancelation Reason"
              {...bind}
            />
            <br />
            <br />
            <StyledButton
              cta
              loading={loading}
              onClick={() => {
                setLoading(true);
                handleCancel(ids, value);
              }}
              disabled={value.length === 0}
            >
              CANCEL PURCHASE ORDER
            </StyledButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CancelRollupItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  ids: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(CancelRollupItemModal);
