import { useEffect, useState, useTransition } from "react";

const useDebouncedValue = <T>(value: T, delay: number): T => {
  const [_pending, startTransition] = useTransition();
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      startTransition(() => setDebouncedValue(value));
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebouncedValue;
