export const buildOrderSet = ({
  territoryId,
  channelId,
  programId,
  orderWindowId,
  budgetId,
  costCenterId,
  type,
}) => {
  return {
    data: {
      type: "order-set",
      attributes: {
        type: type,
        "territory-id": territoryId,
        "channel-id": channelId,
        "program-id": programId,
        "order-window-id": orderWindowId,
        "budget-id": budgetId,
        "cost-center-id": costCenterId,
      },
    },
  };
};

export const buildSetVariant = (id, osId) => {
  return {
    data: {
      type: "order-set-variant",
      attributes: {
        "order-set-id": osId,
        "variant-id": id,
      },
    },
  };
};

export const buildSetVariantPatch = (id, field, value) => {
  return {
    data: {
      type: "order-set-variant",
      id: id,
      attributes: {
        [field]: value,
      },
    },
  };
};

export const buildOrderVariantPatch = (id, field, value) => {
  return {
    data: {
      type: "order-variant",
      id: id,
      attributes: {
        [field]: value,
      },
    },
  };
};

export const buildOrder = (osId, addressId, type) => {
  return {
    data: {
      type: "order",
      attributes: {
        "order-set-id": osId,
        "address-id": addressId,
        type: type,
      },
    },
  };
};

export const buildOrderPatch = (id, note, attn) => {
  return {
    data: {
      type: "order",
      id: id,
      attributes: {
        notes: note,
        attn: attn,
      },
    },
  };
};

export const buildApproveOrDenyRequest = (id, type, note) => {
  return type === "approve"
    ? {}
    : {
        id: id,
        "cancelation-type": "denial",
        "cancelation-note": note,
      };
};

export const buildOrderCancelRequest = (
  id,
  note,
  cancelationType = "order"
) => {
  return {
    id: id,
    "cancelation-type": cancelationType,
    "cancelation-note": note,
  };
};

export const buildAddressChangeRequest = (id, addressId) => {
  return {
    id: id,
    "address-id": addressId,
  };
};

export const buildPromotionRequest = (ids, codes) => {
  return {
    "promotion-ids": ids.map((id) => +id),
    "promotion-codes": codes,
  };
};

export const sortOrderVariants = (orders) => {
  orders.forEach((order) => {
    let currentVariants = [...order.variants].sort((a, b) =>
      a.sku < b.sku ? -1 : a.sku > b.sku ? 1 : 0
    );
    let skuGroup = currentVariants.reduce((group, v) => {
      group[v.item.sku] = group[v.item.sku] || [];
      group[v.item.sku].push(v);
      return group;
    }, {});
    let skus = Object.keys(skuGroup);

    let newVariants = skus.reduce((updatedVariants, sku) => {
      skuGroup[sku].sort((a, b) =>
        a.orderPosition < b.orderPosition
          ? -1
          : a.orderPosition > b.orderPosition
          ? 1
          : 0
      );

      return (updatedVariants = updatedVariants.concat([...skuGroup[sku]]));
    }, []);
    order.variants = newVariants;
  });
};
