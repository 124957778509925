/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import PropTypes from "prop-types";

import { StyledButton } from "@components/StyledComponents";
import ReactQueryTable from "@components/Table/ReactQueryTable";
import { usePaginatedBudgetsQuery } from "@features/budgets";
import { Filters } from "@features/filters";
import { updateOrderSetBudget } from "@redux/slices/orders/currentOrderSetSlice";
import { formatMoneyString } from "@utility/utilityFunctions";

import { calculateBudgetTotal } from "./helpers";

const BudgetSelectModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const {
    id: orderId,
    totalVariantPrice,
    totalEstFreight,
    totalEstTax,
    budgetId,
  } = useSelector((state: any) => state.currentOrderSet);
  const {
    id: userId,
    currentTerritoryId,
    organization: {
      includeShippingInBudgets,
      includeTaxesInBudgets,
      budgetLocation,
    },
  } = useSelector((state: any) => state.currentUser);

  const [selectedBudget, setSelectedBudget] = useState(budgetId);
  const [filters, setFilters] = useState<Record<string, any>>({});

  const { data: budgets, ...tableProps } = usePaginatedBudgetsQuery({
    filter: {
      ...(filters.q && { name: filters.q }),
      isActive: true,
      ...(budgetLocation === "user" && { userIds: [userId] }),
      ...(budgetLocation === "territory" && {
        territoryIds: [currentTerritoryId],
      }),
    },
  });

  const handleClick = (budgetObj) => {
    setSelectedBudget(budgetObj.id);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex justify-between items-center">
        Select a budget for this order
        <IconButton onClick={handleClose} edge="end">
          <CancelIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Filters slots={[]} filterState={filters} setFilterState={setFilters} />
        <ReactQueryTable
          {...tableProps}
          rows={budgets}
          columns={[
            {
              id: "name",
              label: "Name",
              render: (name, budget) => (
                <div tw="flex gap-2 items-center">
                  <Checkbox
                    tw="-my-3"
                    edge="start"
                    checked={+selectedBudget === +budget.id}
                  />
                  {name}
                </div>
              ),
            },
            {
              id: "_available",
              label: "Amount Available",
              align: "right",
              render: (_, budget) =>
                formatMoneyString(
                  calculateBudgetTotal(
                    budget,
                    budgetId,
                    includeShippingInBudgets,
                    includeTaxesInBudgets,
                    totalEstFreight,
                    totalEstTax,
                    totalVariantPrice
                  )
                ),
            },
          ]}
          onRowClick={(budget) => handleClick(budget)}
          maxHeight={400}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton outlined onClick={handleClose}>
          Cancel
        </StyledButton>
        <StyledButton
          onClick={() => {
            dispatch(updateOrderSetBudget(orderId, selectedBudget));
            handleClose();
          }}
          cta
        >
          Done
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

BudgetSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BudgetSelectModal;
