import { Controller } from "react-hook-form";

import { ToggleButtons } from "@features/ui";

import { useFilterContext } from "../filterContext";

const OrderGroupBy = ({ submit }) => {
  const { control } = useFilterContext();
  return (
    <Controller
      control={control}
      name={"orderGroupBy"}
      render={({ field }) => (
        <ToggleButtons
          leftTitle={"By Item"}
          rightTitle={"By Order"}
          selectedValue={field.value === "item" ? "left" : "right"}
          onLeftSelect={() => {
            field.onChange("item");
            submit();
          }}
          onRightSelect={() => {
            field.onChange("order");
            submit();
          }}
        />
      )}
    />
  );
};

export default OrderGroupBy;
