import { axiosOrApiError } from "./clientHelpers";

const removeFromSwrCache = (mutate, requestPromise) =>
  new Promise(async (resolve, reject) => {
    try {
      const cache = await mutate(requestPromise, {
        populateCache: (response, cache) => {
          if (response.error) {
            throw axiosOrApiError(response.error);
          }

          if (Array.isArray(cache.data)) {
            return {
              ...cache,
              data: cache.data.filter((entity) => entity.id !== response.id),
            };
          }
          return response;
        },
        // Since the API already gives us the updated information,
        // we don't need to revalidate here.
        revalidate: false,
      });
      return resolve(cache);
    } catch (E) {
      reject(E);
    }
  });

export default removeFromSwrCache;
