import { formatDistance, isValid } from "date-fns";
import add from "date-fns/add";
import { format } from "date-fns/esm";
import isAfter from "date-fns/isAfter";
import subMonths from "date-fns/subMonths";
import { camelCase, kebabCase, mapKeys } from "lodash";

// Builds an object that has the same nested attributes as the given
// comma-separated string `path`
export const buildFromPath = (path, val = {}) => {
  let pointer = null;

  const obj = path.split(".").reduce((obj, seg, i, a) => {
    const isLast = i === a.length - 1;
    if (!pointer) pointer = obj;
    pointer[seg] = isLast ? val : {};
    pointer = pointer[seg];
    return obj;
  }, {});
  return obj;
};

export const kebabCaseKeys = (obj) => mapKeys(obj, (_, k) => kebabCase(k));
export const camelCaseKeys = (obj) => mapKeys(obj, (_, k) => camelCase(k));

// A simple wait function that returns a promise to be used when setting timers
export const wait = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const upCase = (string, splitter) => {
  if (!string) return string;
  return string
    .split(splitter || " ")
    .map((s) => s[0].toUpperCase() + s.slice(1))
    .join(" ");
};

/*
Takes in the current filters from the filterSlice, and generates a query object that
is stored in the url of each view, which is then used to fetch data based on the url
if the user navigates with the back or forward keys, or shares a link with a colleague.
*/
export const buildQueryObject = (filters) => {
  let arrayFilters = [
    "groupIds",
    "territoryIds",
    "channelIds",
    "programIds",
    "userIds",
    "stateIds",
    "budgetGroupIds",
    "favoriteItems",
    "orderWindowIds",
    "promotionIds",
    "purchaserIds",
    "supplierIds",
  ];

  let ignoreKeys = [
    "chipList",
    "defaultFilters",
    "clearFilters",
    "clearSku",
    "clearCustomerIdentifier",
    "clearName",
    "clearOrderId",
    "sorted",
    "currentTerritoryId",
    "orderWindowId",
    "useOrderWindowTerritoryId",
    "channelId",
  ];
  let strippedFilters = Object.keys(filters).reduce((acc, filter) => {
    if (!ignoreKeys.includes(filter)) {
      if (
        (arrayFilters.includes(filter) && filters[filter].length > 0) ||
        (!arrayFilters.includes(filter) && filters[filter])
      ) {
        acc[filter] = filters[filter];
      }
    }
    return acc;
  }, {});

  return strippedFilters;
};

const intlFormater = new Intl.NumberFormat("en-US");
export const formatNumber = intlFormater.format;

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatMoney = (value, ops) =>
  formatMoneyCents(isNaN(value) ? 0 : value, ops ? 4 : 2);

export const formatMoneyCents = (value, decimals = 2) =>
  `$${parseFloat(value / 100).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}`;

export const formatMoneyString = (value) => USDollar.format(value ?? 0);

//handles incoming money values that are strings
export const stringToCents = (numString) => {
  return parseFloat(numString) * 100;
};

//Used in order tables to ensure order numbers match pack size
export const roundUp = (value, rounder) => {
  if (rounder === 1) {
    return value;
  }
  if (value % rounder === 0) {
    return value;
  }
  let multiplier = Math.floor(value / rounder);
  if (multiplier === 0) {
    return rounder;
  }
  let roundedUp = multiplier * rounder + rounder;
  return roundedUp;
};

//Used for filtering purposes, just a quick call to modify an array
export const separateByComma = (filter, arg, key) => {
  if (typeof arg !== "object") {
    return `filter[${filter}]%5B%5D=${arg}`;
  } else {
    if (key && typeof arg[0] === "object") {
      return arg
        .map((index) => `filter[${filter}]%5B%5D=${index[key]}`)
        .join("&");
    } else {
      return arg.map((a) => `filter[${filter}]%5B%5D=${a}`).join("&");
    }
  }
};

export const fromDate = (date) => {
  return format(date, "MM/dd/yyyy");
};

// Since we don't save the time zone in the database, new Date() will
// try to use the local time zone, which is not what we want.
export const utcDate = (dateOrDateString) => {
  const date = new Date(dateOrDateString);
  const hourOffset = date.getTimezoneOffset() / 60;
  return add(date, { hours: hourOffset });
};

export const utcToLocalDate = (dateOrDateString) => {
  const date = new Date(dateOrDateString);
  const hourOffset = date.getTimezoneOffset() / 60;
  return add(date, { hours: -hourOffset });
};

export const formatUtcDate = (
  dateOrDateString,
  formatString = "MM/dd/yyyy"
) => {
  if (!dateOrDateString) return "";
  const date = utcDate(dateOrDateString);
  if (!isValid(date)) return date.toString();
  return format(date, formatString);
};

export const formatDistanceFromNow = (date) =>
  formatDistance(date, new Date(), {
    addSuffix: true,
  });

//handles formatting dates coming from the api to a MM/DD/YYYY format
export const formatDateString = (date) => {
  if (typeof date !== "string") return date;
  const [a, b, c] = date.split("-");
  return [b, c, a].join("/");
};

export const chunkArray = (ar, chunk) => {
  let newAr = [];
  let length = ar.length;

  if (chunk >= length || chunk === 0) {
    return ar;
  }

  for (let i = 0; i < length; i += chunk) {
    let tempAr = ar.slice(i, i + chunk);
    newAr.push(tempAr);
  }

  return newAr;
};

const hasNonNumbers = (text) => {
  let numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
  let textAr = text.split("");
  let hasNon = false;
  while (hasNon === false && textAr.length > 0) {
    hasNon = textAr.some((char) => !numbers.includes(char));
    textAr.shift();
  }
  return hasNon;
};

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

const formatCSVDate = (date) => {
  if (!date || date.length === 0) return null;
  let dateObject = new Date(date);
  if (isValidDate(dateObject)) {
    let fourMonthsAgo = subMonths(new Date(), 4);

    if (isAfter(dateObject, fourMonthsAgo)) {
      return dateObject;
    } else return "error";
  } else return "error";
};

/*
When a user uploads a shipping document to a purchase order, this function is used
to parse that data, and format it as needed, and marks any cells with an error if needed.
*/
export const mapShippingCSV = (data) => {
  console.log(data);
  const mappedData = data
    .filter((dataPoint) => dataPoint.errors.length === 0)
    .map((dataPoint) => ({
      id: dataPoint.data["Param Item Id"],
      carrier:
        dataPoint.data.Carrier.length > 0
          ? dataPoint.data.Carrier.trim().toLowerCase()
          : null,
      method:
        dataPoint.data["Ship Method"].trim().length > 0
          ? dataPoint.data["Ship Method"].trim()
          : null,
      "actual-ship-date": formatCSVDate(dataPoint.data["Actual Ship Date"]),
      "shipped-qty":
        typeof dataPoint.data["Shipped Quantity"] === "number"
          ? dataPoint.data["Shipped Quantity"]
          : dataPoint.data["Shipped Quantity"].trim().length > 0 &&
            !isNaN(parseInt(dataPoint.data["Shipped Quantity"].trim()))
          ? parseInt(dataPoint.data["Shipped Quantity"].trim())
          : null,
      "package-count":
        typeof dataPoint.data["Package Count"] === "number"
          ? dataPoint.data["Package Count"]
          : dataPoint.data["Package Count"].trim().length > 0 &&
            !isNaN(parseInt(dataPoint.data["Package Count"].trim()))
          ? parseInt(dataPoint.data["Package Count"].trim())
          : null,
      "tracking-number":
        dataPoint.data["Tracking Number"].trim().length > 0
          ? dataPoint.data["Tracking Number"].trim()
          : null,
      tax: dataPoint.data["Tax"]
        ? dataPoint.data["Tax"].toString().trim().length === 0
          ? "0"
          : hasNonNumbers(dataPoint.data["Tax"].toString().trim())
          ? "error"
          : dataPoint.data["Tax"].toString().trim()
        : "0",
    }));

  return mappedData;
};

// Does some calculations to return a formated order total.
export const handleOrderGrandTotal = (order, organization) => {
  const {
    includeShippingInBudgets,
    includeTaxesInBudget,
    includeShippingInStripeCharge,
    includeTaxesInStripeCharge,
  } = organization;

  let freight = 0;
  let tax = 0;
  let total;

  switch (order.checkoutType) {
    case "budget":
      if (order.actFreight !== 0) {
        freight = includeShippingInBudgets ? order.actFreight : 0;
      } else {
        freight = includeShippingInBudgets ? order.estFreight : 0;
      }

      tax = includeTaxesInBudget ? order.estTax : 0;

      total = order.totalPrice + freight + tax;
      break;
    case "stripe":
      if (order.actFreight !== 0) {
        freight = includeShippingInStripeCharge ? order.actFreight : 0;
      } else {
        freight = includeShippingInStripeCharge ? order.estFreight : 0;
      }

      tax = includeTaxesInStripeCharge ? order.estTax : 0;

      total = order.totalPrice + freight + tax;
      break;
    default:
      freight = order.actFreight !== 0 ? order.actFreight : order.estFreight;
      total = order.totalPrice + freight + order.estTax;
  }

  return formatMoney(total, false);
};

export const flattenObject = (input, keyName) => {
  var result = {};
  for (const key in input) {
    const newKey = keyName ? `${keyName}.${key}` : key;
    if (typeof input[key] === "object" && !Array.isArray(input[key])) {
      result = { ...result, ...flattenObject(input[key], newKey) };
    } else {
      result[newKey] = input[key];
    }
  }
  return result;
};
