/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";

import { useState } from "react";

import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import OpenInFullRoundedIcon from "@mui/icons-material/OpenInFullRounded";
import { Checkbox, FormLabel, IconButton } from "@mui/material";

import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { StyledButton } from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import client from "@services/api";
import { CldImage } from "@services/cloudinary";

import { ImageCarousel } from "../../../images";
import BlockCard from "../../../ui/BlockCard";
import saveImageOrder from "../../data/saveImageOrder";
import { useItemImages } from "../ItemImageContext";

const MediaGrid = styled.div({
  "&": tw`grid grid-cols-4 gap-4`,
  "& > *:first-of-type": tw`col-span-2 row-span-2`,
  // override above line if only one item exists
  "& > *:last-of-type": tw`col-span-1 row-span-1`,
});

const MediaBox = tw.div`relative border border-neutral-300 aspect-square rounded-md overflow-hidden origin-top-left! select-none`;

const Image = ({
  img,
  selected,
  handleToggleSelect,
  isSorting,
  openModal,
  showCheckboxes,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id: img.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div tw="rounded-md bg-neutral-200">
      <MediaBox
        // css={[isDragging && tw`origin-center!`]}
        className="group"
        ref={setNodeRef}
        style={style}
      >
        <CldImage
          publicId={img.cloudinaryId}
          size="medium"
          tw="w-full h-full object-contain object-center bg-white"
        />
        <div
          css={[
            tw`absolute inset-0 transition-opacity bg-neutral-900/50`,
            tw`opacity-0 group-hover:opacity-100`,
            isSorting && tw`opacity-0!`,
          ]}
        >
          <div tw="absolute inset-0 flex items-center justify-center">
            <IconButton onClick={openModal}>
              <OpenInFullRoundedIcon tw="text-white" />
            </IconButton>
          </div>
          <div tw="flex items-center justify-between">
            <IconButton
              // size="small"
              tw="cursor-move!"
              onClick={() => {}}
              ref={setActivatorNodeRef}
              {...attributes}
              {...listeners}
            >
              <DragIndicatorRoundedIcon tw="text-white" />
            </IconButton>
          </div>
        </div>
        <Checkbox
          css={[
            tw`absolute top-0 right-0 opacity-0`,
            tw`group-hover:(opacity-100 text-white!)`,
            showCheckboxes && !isSorting && tw`opacity-100`,
            isSorting && tw`opacity-0!`,
          ]}
          size="small"
          checked={selected}
          onChange={handleToggleSelect}
        />
      </MediaBox>
    </div>
  );
};

const MediaBlock = () => {
  const confirm = useConfirm();
  const {
    handleUpload,
    images: savedImages,
    setImages: setSavedImages,
  } = useItemImages();
  // For images, we update directly in this component.
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [isSorting, setIsSorting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carouselOpenAt, setCarouselOpenAt] = useState<null | number>(null);

  const handleDelete = async () => {
    if (
      !(await confirm(
        "Are you sure you want to delete these images? This can't be undone."
      ))
    )
      return;
    setLoading(true);
    await Promise.all(
      selectedImages.map((imgId) => client.delete(`images/${imgId}`))
    );
    setSavedImages((imgArray) =>
      imgArray.filter((img) => !selectedImages.includes(img.id))
    );
    setSelectedImages([]);
    setLoading(false);
  };

  const handleSort = (event) => {
    const { active, over } = event;
    setIsSorting(false);

    if (over?.id && active.id !== over.id) {
      const oldIndex = savedImages.findIndex((img) => img.id === active.id);
      const newIndex = savedImages.findIndex((img) => img.id === over.id);

      const newOrder = arrayMove(savedImages, oldIndex, newIndex);
      setSavedImages(newOrder);
      saveImageOrder(newOrder.map(({ id }) => id));
    }
  };

  const handleToggleSelect = (id) => {
    setSelectedImages((idArray) =>
      idArray.includes(id)
        ? idArray.filter((imgId) => imgId !== id)
        : [...idArray, id]
    );
  };

  const handleToggleAll = () => {
    setSelectedImages((idArray) =>
      idArray.length === savedImages.length
        ? []
        : savedImages.map((img) => img.id)
    );
  };

  return (
    <BlockCard title="Media">
      <ImageCarousel
        open={carouselOpenAt !== null}
        startIndex={carouselOpenAt as number}
        handleClose={() => setCarouselOpenAt(null)}
        images={savedImages}
      />
      <DndContext onDragEnd={handleSort} onDragStart={() => setIsSorting(true)}>
        <MediaGrid tw="max-w-lg">
          <SortableContext items={savedImages.map(({ id }) => id)}>
            {savedImages.map((image, index) => (
              <Image
                img={image}
                key={image.id}
                isSorting={isSorting}
                selected={selectedImages.includes(image.id)}
                showCheckboxes={selectedImages.length > 0}
                handleToggleSelect={() => handleToggleSelect(image.id)}
                openModal={() => setCarouselOpenAt(index)}
              />
            ))}
          </SortableContext>
          <MediaBox
            role="button"
            onClick={handleUpload}
            tw="
          bg-neutral-50 border-2 cursor-pointer 
          font-medium text-neutral-500 
          flex items-center justify-center"
          >
            Add
          </MediaBox>
        </MediaGrid>
        {selectedImages.length > 0 && (
          <div tw="flex justify-between items-center">
            <FormLabel tw="flex items-center">
              <Checkbox
                indeterminate={selectedImages.length !== savedImages.length}
                checked={selectedImages.length === savedImages.length}
                onChange={handleToggleAll}
              />
              Selected Files
            </FormLabel>
            <StyledButton
              tw="text-red-600"
              danger
              onClick={handleDelete}
              loading={loading}
            >
              Delete selected
            </StyledButton>
          </div>
        )}
      </DndContext>
    </BlockCard>
  );
};

export default MediaBlock;
