import format from "date-fns/format";
import { axiosGetWithNext } from "src/api/axiosCalls";

export const fetchOrderWindowPrograms = async (id) => {
  let nextLink = "start";
  let programs = [];
  while (nextLink) {
    let response = await axiosGetWithNext(
      nextLink === "start"
        ? `/api/programs?filter[order-window-ids][]=${id}`
        : nextLink
    );
    if (response.error) {
      nextLink = null;
      throw response.error;
    }
    nextLink = response.data.nextLink;
    programs = programs.concat(response.data.data);
  }
  return programs;
};

export const buildOrderWindow = (attrs) => {
  const territoryArray = attrs.territories.map((t) => ({
    type: "territory",
    id: t.id,
  }));
  const programArray = attrs.programs.map((p) => ({
    type: "program",
    id: p.id,
  }));
  return {
    data: {
      type: "order-window",
      attributes: {
        name: attrs.name,
        "open-date": format(attrs.openDate, "yyyy-MM-dd"),
        "close-date": format(attrs.closeDate, "yyyy-MM-dd"),
        "review-close-date": format(attrs.reviewCloseDate, "yyyy-MM-dd"),
        "in-market-date": format(attrs.inMarketDate, "yyyy-MM-dd"),
        "channel-id": attrs.channelId,
      },
      relationships: {
        territories: {
          data: territoryArray,
        },
        programs: {
          data: programArray,
        },
      },
    },
  };
};
