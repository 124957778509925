import { useState } from "react";
import { useSelector } from "react-redux";

import { TextField } from "@mui/material";

import PropTypes from "prop-types";

import { StyledButton } from "../StyledComponents";
import UserAutoComplete from "../Utility/AutoCompleteFields/UserAutoComplete";
import PromotionRedemptionTypeSelector from "../Utility/Selectors/PromotionRedemptionTypeSelector";
import PromotionStatusSelector from "../Utility/Selectors/PromotionStatusSelector";
import PromotionTypeSelector from "../Utility/Selectors/PromotionTypeSelector";
import { FilterRow, MoreFiltersSection } from "./FilterComponents";

const FiltersPromotions = ({
  reset,
  setReset,
  handleFilters,
  sku,
  bindSku,
  handleSearch,
}) => {
  const isLoading = useSelector((state) => state.globalState.isLoading);
  const { promotionRedemptionType, promotionStatus, promotionType } =
    useSelector((state) => state.filters);

  const [redemptionType, setRedemptionType] = useState(
    promotionRedemptionType || ""
  );
  const [status, setStatus] = useState(promotionStatus || "");
  const [type, setType] = useState(promotionType || "");

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <div>
      <FilterRow>
        <TextField
          color="secondary"
          fullWidth
          name="itemNumber"
          type="text"
          label="Sku"
          variant="outlined"
          size="small"
          value={sku}
          {...bindSku}
          onKeyUp={handleKeyUp}
          disabled={isLoading}
        />

        <StyledButton cta onClick={handleSearch} disabled={isLoading}>
          SEARCH
        </StyledButton>
      </FilterRow>

      <MoreFiltersSection>
        <FilterRow>
          <PromotionStatusSelector
            handleStatus={handleFilters}
            status={status}
            setStatus={setStatus}
            filterType="promotion"
          />

          <PromotionTypeSelector
            handleType={handleFilters}
            type={type}
            setType={setType}
            filterType="promotion"
          />

          <PromotionRedemptionTypeSelector
            handleType={handleFilters}
            type={redemptionType}
            setType={setRedemptionType}
            filterType="promotion"
          />

          <UserAutoComplete
            handleChange={handleFilters}
            reset={reset}
            setReset={setReset}
            filterType="promotion"
          />
        </FilterRow>
      </MoreFiltersSection>
    </div>
  );
};

FiltersPromotions.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  sku: PropTypes.string,
  bindSku: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default FiltersPromotions;
