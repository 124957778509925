/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import { Budget } from "@models";
import { ResourceAutocomplete } from "@utils/forms";

const BudgetSearch = ({ handleChange, reset, setReset }) => {
  const [value, setValue] = useState<null | Budget>(null);

  useEffect(() => {
    if (reset) {
      setValue(null);
      setReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, setReset]);

  return (
    <ResourceAutocomplete
      label="Budgets"
      resource="budgets"
      value={value}
      onChange={(e, val) => {
        setValue(val);
        handleChange(val);
      }}
    />
  );
};

export default BudgetSearch;
