/** @jsxImportSource @emotion/react */
import "twin.macro";

import { OpaqueCard } from "@components/StyledComponents";
import { Quote } from "@models/Quote";

import CompletedQuote from "./CompletedQuote";

type PurchaserQuoteProps = {
  quote: Quote;
};
const PurchaserQuote = ({ quote }: PurchaserQuoteProps) => {
  const isComplete = ["complete", "awarded"].includes(quote.status);
  return (
    <div tw="space-y-6">
      <OpaqueCard tw="p-6">
        {isComplete && <CompletedQuote quote={quote} />}
      </OpaqueCard>
    </div>
  );
};

export default PurchaserQuote;
