import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import PropTypes from "prop-types";

import { getTracking, resetTracking } from "@redux/slices/trackingSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const TrackingModal = ({ open, handleClose, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [viewEvents, setViewEvents] = useState(false);

  const { isLoading, trackingData, error } = useSelector(
    (state) => state.tracking
  );

  useEffect(() => {
    dispatch(getTracking(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={() => {
          dispatch(resetTracking());
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              dispatch(resetTracking());
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          {isLoading && (
            <div className={classes.centeredModal}>
              <br />
              <br />
              <CircularProgress color="secondary" />
              <br />
              <br />
            </div>
          )}
          {!isLoading && error && (
            <div className={classes.centeredModal}>
              <br />
              <Typography
                className={clsx(classes.headerText, classes.settingsMargin)}
              >
                There was an error while retrieving tracking data:
              </Typography>
              <Typography className={classes.bodyText}>{error}</Typography>
              <br />
            </div>
          )}
          {!isLoading && !trackingData && !error && (
            <div className={classes.centeredModal}>
              <br />
              <br />
              <Typography className={classes.headerText}>
                Unable to retreive tracking information.
              </Typography>
              <br />
              <br />
            </div>
          )}
          {!isLoading && trackingData && (
            <div className={classes.centeredModal}>
              <Typography className={classes.titleText}>
                {`Tracking Information for Order Item #${id}`}
              </Typography>
              <br />
              <Typography
                className={clsx(classes.bodyText, classes.settingsMargin)}
              >
                <b>Tracking Number:</b>
                {` ${trackingData.trackingNumber}`}
              </Typography>
              <Typography
                className={clsx(classes.bodyText, classes.settingsMargin)}
              >
                <b>Shipping Status:</b>
                {` ${trackingData.status}`}
              </Typography>
              <Typography
                className={clsx(classes.bodyText, classes.settingsMargin)}
              >
                <b>Ship Date:</b>
                {` ${trackingData.shipDate}`}
              </Typography>
              <Typography
                className={clsx(classes.bodyText, classes.settingsMargin)}
              >
                <b>Estimated Delivery:</b>
                {` ${trackingData.estimatedDeliveryDate}`}
              </Typography>
              <Typography
                className={clsx(classes.bodyText, classes.settingsMargin)}
              >
                <b>Actual Delivery:</b>
                {` ${trackingData.deliveryDate}`}
              </Typography>
              <br />
              <Divider className={classes.fullWidth} />
              <br />
              <Typography
                className={clsx(classes.headerText, classes.settingsMargin)}
              >
                Tracking Events:
              </Typography>
              {trackingData.events.length === 0 && (
                <Typography className={classes.bodyText} color="textSecondary">
                  <em>No recorded events.</em>
                </Typography>
              )}
              {trackingData.events.length > 0 && !viewEvents && (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={() => setViewEvents(true)}
                >
                  VIEW EVENTS
                </Button>
              )}
              {trackingData.events.length > 0 && viewEvents && (
                <>
                  {trackingData.events.map((e, i) => (
                    <div key={i} className={classes.fullWidthCenterColumn}>
                      <Typography
                        className={clsx(
                          classes.bodyText,
                          classes.settingsMargin
                        )}
                      >
                        <b>Date:</b>
                        {` ${e.date}`}
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.bodyText,
                          classes.settingsMargin
                        )}
                      >
                        <b>Time:</b>
                        {` ${e.time}`}
                      </Typography>
                      {e.city && e.state && e.zip && (
                        <Typography
                          className={clsx(
                            classes.bodyText,
                            classes.settingsMargin
                          )}
                        >
                          <b>City / State / Zip:</b>
                          {` ${e.city} / ${e.state} / ${e.zip}`}
                        </Typography>
                      )}
                      <Typography
                        className={clsx(
                          classes.bodyText,
                          classes.settingsMargin
                        )}
                      >
                        <b>Status:</b>
                        {` ${e.status}`}
                      </Typography>
                      {i !== trackingData.events.length - 1 && (
                        <>
                          <br />
                          <Divider className={classes.fullWidth} />
                          <br />
                        </>
                      )}
                    </div>
                  ))}
                  <br />
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => setViewEvents(false)}
                  >
                    HIDE EVENTS
                  </Button>
                </>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

TrackingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default TrackingModal;
