import { Link, Navigate } from "react-router-dom";

import Approvals from "src/pages/Approvals";
import HelpScoutAuth from "src/pages/HelpScoutAuth";
import ItemQuotes from "src/pages/ItemQuotes";
import OrderArchive from "src/pages/OrderArchive";
import Quote from "src/pages/Quote";
import QuotedItems from "src/pages/QuotedItems";
import Quotes from "src/pages/Quotes";
import ReportDashboard from "src/pages/ReportDashboard";
import RequestForQuote from "src/pages/RequestForQuote";

import { DynamicRFQCrumb } from "@features/rfqs";
import permissions from "@utils/permissions";

import App from "../../../App";
import Budgets from "../../../pages/Budgets";
import CheckInventory from "../../../pages/CheckInventory";
import CurrentOrder from "../../../pages/CurrentOrder";
import Dashboard from "../../../pages/Dashboard";
import DeactivatedUser from "../../../pages/DeactivatedUser";
import ErrorPage from "../../../pages/ErrorPage";
import FourOhFour from "../../../pages/FourOhFour";
import Help from "../../../pages/Help";
import ItemCatalog from "../../../pages/ItemCatalog";
import Logout from "../../../pages/Logout";
import Luzmo from "../../../pages/Luzmo";
import OrderHistory from "../../../pages/OrderHistory";
import OrderHistoryOrder from "../../../pages/OrderHistoryOrder";
import PlaceOrder from "../../../pages/PlaceOrder";
import PreOrders from "../../../pages/PreOrders";
import Profile from "../../../pages/Profile";
import PurchaseOrder from "../../../pages/PurchaseOrder";
import PurchaseOrderHistory from "../../../pages/PurchaseOrderHistory";
import PurchaseOrderRollup from "../../../pages/PurchaseOrderRollup";
import Reports from "../../../pages/Reports";
import VariantAllocations from "../../../pages/VariantAllocations";
import { protectedRoute } from "../types";
import adminRouter from "./admin";
import beaconRouter from "./beacons";
import programRouter from "./programs";

const root: protectedRoute[] = [
  { path: "/login", element: <Navigate to="/" /> },
  { path: "/helpscout-auth/:slug", element: <HelpScoutAuth /> },
  {
    path: "/*",
    // even logged out users should have access to this.
    element: <App />,
    errorElement: <ErrorPage />,
    handle: { crumb: () => <Link to="/">Home</Link> },
    children: [
      { path: "", element: <Dashboard /> },
      { path: "deactivatedUser", element: <DeactivatedUser /> },
      { path: "help", element: <Help /> },
      { path: "logout", element: <Logout /> },
      {
        path: "items",
        allowedRoles: permissions.items,
        element: <ItemCatalog />,
        handle: { crumb: () => "Item Catalog" },
      },
      {
        path: "items/archive",
        allowedRoles: permissions.items,
        element: <ItemCatalog isArchive={true} />,
        handle: { crumb: () => "Catalog Archive" },
      },
      {
        path: "analytics/:slug",
        allowedRoles: permissions.reporting,
        element: <Luzmo />,
      },
      {
        path: "budgets",
        allowedRoles: permissions.budgets,
        element: <Budgets />,
      },
      {
        path: "reports/inventory-check",
        allowedRoles: permissions.reporting,
        element: <CheckInventory />,
      },
      {
        path: "pre-orders/:orderWindowId",
        handle: { crumb: () => "Order Window" },
        allowedRoles: permissions.programs,
        element: <PreOrders />,
      },
      {
        path: "profile/:type",
        allowedRoles: permissions.profile,
        element: <Profile />,
      },

      {
        path: "reports-dashboard",
        handle: { crumb: () => "Report Dashboard" },
        allowedRoles: [...permissions.reporting, "orderer"],
        element: <ReportDashboard />,
      },
      {
        path: "reports/:type",
        handle: { crumb: () => <Link to="/reports-dashboard">Reports</Link> },
        allowedRoles: [...permissions.reporting, "orderer"],
        element: <Reports />,
      },
      {
        path: "allocations/:id",
        allowedRoles: permissions.admin,
        element: <VariantAllocations />,
      },
      {
        path: "orders",
        handle: { crumb: () => <Link to="/orders/history">Orders</Link> },
        allowedRoles: permissions.orders,
        children: [
          { path: "draft/:orderType", element: <PlaceOrder /> },
          { path: "history", element: <OrderHistory /> },
          { path: "history/:orderId", element: <OrderHistoryOrder /> },
          { path: "open/:origin/:orderId", element: <CurrentOrder /> },
          { path: "archive", element: <OrderArchive /> },
          {
            path: "approval",
            allowedRoles: permissions.orderReview,
            element: <Approvals />,
          },
        ],
      },
      {
        path: "purchase-orders/:type/:poId",
        allowedRoles: permissions.purchaseOrders,
        element: <PurchaseOrder />,
      },
      {
        path: "purchasing/purchase-order-history",
        handle: {
          crumb: () => (
            <Link to="purchasing/purchase-order-history">Purchase Orders</Link>
          ),
        },
        allowedRoles: permissions.purchaseOrders,
        element: <PurchaseOrderHistory />,
      },
      {
        path: "purchasing/purchase-order-rollup",
        handle: { crumb: () => "Purchase Order Rollup" },
        allowedRoles: permissions.purchaseOrderRollup,
        element: <PurchaseOrderRollup />,
      },

      {
        path: "rfqs",
        handle: { crumb: () => <Link to="/rfqs">Quote Requests</Link> },
        allowedRoles: permissions.rfqs,
        children: [
          { path: "", element: <QuotedItems /> },
          {
            path: ":id",
            element: <RequestForQuote />,
            handle: { crumb: () => <DynamicRFQCrumb /> },
          },
          { path: "item/:itemId", element: <ItemQuotes /> },
        ],
      },
      {
        path: "quotes",
        handle: { crumb: () => <Link to="/quotes">Quotes</Link> },
        allowedRoles: permissions.quotes,
        children: [
          { path: "", allowedRoles: permissions.supplier, element: <Quotes /> },
          { path: ":id", element: <Quote /> },
        ],
      },
      programRouter,
      adminRouter,
      beaconRouter,
      {
        path: "*",
        element: <FourOhFour />,
      },
    ],
  },
];
export default root;
