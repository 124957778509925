import { useSelector } from "react-redux";

export default function useDefaultApprovalStatus() {
  const {
    role,
    organization: { approvalFlows },
  } = useSelector((state: any) => state.currentUser);

  let status = "submitted";
  if (["admin", "super"].includes(role) && approvalFlows.length > 1) {
    status = "approval-review";
  }
  return status;
}
