import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { Territory } from "@models/Territory";
import client, { RequestParams } from "@services/api";

type TerritoryPayload = Territory;
type NewTerritoryPayload = Partial<Omit<Territory, "id">>;

export const territoriesKeyFactory = createQueryKeys("territories", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<Territory[]>("territories", { params })
        .then((res) => res.data),
  }),
});

export const useTerritoriesListQuery = (params?: RequestParams) => {
  return useQuery({
    ...territoriesKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });
};

/* Mutations */

const buildTerritoryPayload = (data) => ({
  __type: "territories",
  relationshipNames: ["countries", "states"],
  ...data,
});

export const useCreateTerritoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: NewTerritoryPayload) =>
      client
        .post<Territory>(`territories`, buildTerritoryPayload(data))
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: territoriesKeyFactory.list._def,
      });
    },
  });
};

export const useUpdateTerritoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: TerritoryPayload) =>
      client
        .update<Territory>(`territories/${id}`, buildTerritoryPayload(data))
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: territoriesKeyFactory.list._def,
      });
    },
  });
};
