import React, { memo } from "react";

import { AddToPhotosTwoTone } from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import SingleTerritoryAutoComplete from "../../Utility/AutoCompleteFields/SingleTerritoryAutoComplete";
import OptionTable from "../../Utility/OptionTable";

const TerritorySection = ({
  classes,
  handleAddTerritory,
  handleAllTerritories,
  resetField,
  setResetField,
  currentTerritories,
  handleRemoveTerritory,
}) => {
  return (
    <>
      <Typography className={classes.headerText}>
        Territory Assignment *
      </Typography>
      <br />
      <section
        className={clsx(classes.settingsMargin, classes.fullWidthCenterColumn)}
      >
        <div className={classes.fullWidthSpaceBetween}>
          <SingleTerritoryAutoComplete
            classes={classes}
            handleChange={handleAddTerritory}
            reset={resetField}
            setReset={setResetField}
          />
          <Tooltip title="Assign All">
            <IconButton onClick={handleAllTerritories} size="large">
              <AddToPhotosTwoTone color="secondary" />
            </IconButton>
          </Tooltip>
        </div>
        {currentTerritories.length > 0 && (
          <OptionTable
            headCells={[
              { id: "id", label: "Id" },
              { id: "name", label: "Name" },
            ]}
            options={currentTerritories}
            handleRemove={handleRemoveTerritory}
            isRemoveable
          />
        )}
      </section>
    </>
  );
};

TerritorySection.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAllTerritories: PropTypes.func.isRequired,
  handleAddTerritory: PropTypes.func.isRequired,
  resetField: PropTypes.bool.isRequired,
  setResetField: PropTypes.func.isRequired,
  currentTerritories: PropTypes.array,
  handleRemoveTerritory: PropTypes.func.isRequired,
};

export default memo(TerritorySection);
