/** @jsxImportSource @emotion/react */
import "twin.macro";

import { ReactNode } from "react";

import { OpaqueCard } from "@components/StyledComponents";

interface BlockProps {
  id?: string;
  title?: string;
  titleElement?: ReactNode | ReactNode[];
  [x: string]: any;
}

const BlockCard = ({
  id,
  title,
  children,
  titleElement,
  ...props
}: React.ComponentPropsWithoutRef<"div"> & BlockProps) => (
  <OpaqueCard tw="p-6" id={id}>
    <div tw="flex justify-between items-center mb-3 -mt-2">
      {title && <div tw="font-medium">{title}</div>}
      {titleElement}
    </div>
    <div {...props}>{children}</div>
  </OpaqueCard>
);

export default BlockCard;
