/** @jsxImportSource @emotion/react */
import { styled } from "twin.macro";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BlueGreenLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 12,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: value === 100 ? "#00C489" : "#0099cc",
  },
}));

export default BlueGreenLinearProgress;
