/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { Alert, CircularProgress } from "@mui/material";

import { useItemListQuery } from "@features/items";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import cld from "@services/cloudinary";

import { useBeaconProgram } from "../../hooks/beaconHooks";
import { OpaqueCard } from "../StyledComponents";
import ImageWrapper from "../Utility/ImageWrapper";
import BlueGreenLinearProgress from "./BlueGreenLinearProgress";

const ItemList = ({ setVariantSku }) => {
  const dispatch = useDispatch();
  const { programId, state, variantSku } = useParams();
  const { data } = useItemListQuery({
    filter: { programIds: [programId] },
  });
  const { variants, isLoading, error } = useBeaconProgram(
    { programId, state },
    "variants"
  );

  const items = data || [];
  const itemLookup = items.reduce((obj, v) => {
    obj[v.id] = v;
    return obj;
  }, {});

  return (
    <OpaqueCard tw="px-2 h-full">
      {error && <Alert severity="error">Error: {error.message}</Alert>}
      {isLoading && <CircularProgress size={24} tw="p-4" />}
      {items.length > 0 &&
        variants.map((v) => {
          const item = itemLookup[v.itemId];
          const itemImg = item?.images[0]?.cloudinaryId;
          const percent = Math.min(
            (v.beaconsPinged / v.estBeaconsExpected) * 100,
            100
          );
          return (
            <div
              role="link"
              css={[
                tw`flex gap-6 px-3 py-2 transition rounded-lg cursor-pointer hover:bg-neutral-100 text-neutral-700`,
                variantSku === v.variantSku && tw`bg-primary-50!`,
              ]}
              key={v.variantSku}
              onClick={() => setVariantSku(v.variantSku)}
            >
              <ImageWrapper
                id={item.id}
                tw="flex-none w-20 h-20 bg-white max-w-none rounded-lg outline outline-gray-100 object-cover object-center cursor-pointer"
                alt={item.name}
                imgUrl={cld.url(itemImg)}
                handleClick={(e) => {
                  e.stopPropagation();
                  dispatch(setItemPreview({ id: item.id }));
                }}
              />
              <div tw="grow">
                <div tw="text-lg">{item.name}</div>
                <div tw="text-sm text-primary-600">{v.variantSku}</div>
                <div>
                  <div tw="text-neutral-500 text-xs text-right mb-1">
                    {`${v.beaconsPinged} / ${v.estBeaconsExpected}`}
                  </div>
                  <BlueGreenLinearProgress
                    variant="determinate"
                    value={percent}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </OpaqueCard>
  );
};

export default ItemList;
