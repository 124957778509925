/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import _, { pick } from "lodash";

import {
  OpaqueCard,
  SectionTitle,
  StyledButton,
} from "@components/StyledComponents";
import SmartTable from "@components/Table/SmartTable";
import { RfqSummary } from "@features/rfqs";
import { SaveFormBanner } from "@features/ui";
import { Quote } from "@models/Quote";
import client, { useMutateError } from "@services/api";
import asyncPool from "@utility/asyncPool";
import { formatNumber } from "@utility/utilityFunctions";
import {
  intValidation,
  moneyAdornment,
  moneyValidation,
  usePersistFormChanges,
} from "@utils/forms";
import { ControlledTextInput } from "@utils/forms/ControlledInputs";

import {
  useQuoteActionMutation,
  useUpdateQuoteMutation,
} from "../data/quoteMutations";
import CostTable from "./CostTable";
import saveQuoteCosts from "./saveQuoteCosts";

const TwoCol = tw.div`grid gap-4 lg:grid-cols-2`;
const Card = tw(OpaqueCard)`p-6`;

type QuoteFormProps = {
  quote: Quote;
};

const formDataFromQuote = (quote: Quote) => ({
  ...pick(quote, [
    "id",
    "brandingArea",
    "colorOptions",
    "decorationOptions",
    "description",
    "ideas",
    "itemDimensions",
    "moq",
    "preProCost",
    "preProLeadTimeInDays",
    "pricingBasedOn",
    "supplierQuoteNumber",
  ]),
  pricingTiers: _(quote.pricingTierQuotes)
    .map((pt) => _.pick(pt, ["id", "cost", "leadTimeInDays"]))
    .sortBy("pricingTierQty")
    .keyBy("id")
    .value(),
  costs: _(quote.costs)
    .map((c) => _.omit(c, ["quote"]))
    .value(),
});

type QuoteFormType = ReturnType<typeof formDataFromQuote>;

const QuoteForm = ({ quote }: QuoteFormProps) => {
  const [loading, setLoading] = useState(false);
  const setMutateError = useMutateError();
  const formMethods = useForm({
    defaultValues: formDataFromQuote(quote),
  });

  const updateQuoteMutation = useUpdateQuoteMutation();
  const doQuoteAction = useQuoteActionMutation();

  const {
    control,
    reset,
    setError,
    formState: { isDirty, dirtyFields },
    handleSubmit,
  } = formMethods;

  usePersistFormChanges(`quote-${quote.id}`, formMethods);
  const reg = (name, rules?) => ({ control, name, rules });

  const handleComplete = async (data: QuoteFormType) => {
    // Validate fields for a completed quote.
    const requiredFields = [
      "moq",
      "itemDimensions",
      "pricingBasedOn",
      ...quote.pricingTierQuotes.map((pt) => [
        `pricingTiers.${pt.id}.cost`,
        `pricingTiers.${pt.id}.leadTimeInDays`,
      ]),
    ].flat() as (keyof QuoteFormType)[];

    const validationErrors = requiredFields.filter(
      (field) => !_.get(data, field)
    );
    if (validationErrors.length > 0) {
      validationErrors.forEach((field) => {
        setError(field, {
          type: "validation",
          message: "Field is required",
        });
      });
      return;
    }
    await handleSave(data);
    doQuoteAction.mutate({ id: quote.id, action: "complete" });
  };

  const handleSave = async (data: QuoteFormType) => {
    setLoading(true);
    try {
      const dirtyPricingTiers = _(data.pricingTiers)
        .pick(Object.keys(dirtyFields.pricingTiers ?? {}))
        .values()
        .value();

      const quoteCosts = Object.values(data.costs)
        .flat()
        .filter((cost) => cost.id || cost.name);

      const costsRes = await saveQuoteCosts(quote.id, quoteCosts, quote.costs);
      if (costsRes.errors) setMutateError(costsRes.errors[0]);

      const pricingTierRes = await asyncPool(
        5,
        dirtyPricingTiers,
        async ({ id, ...tier }) =>
          client.update(`pricing-tier-quotes/${id}`, tier)
      );
      if (pricingTierRes.errors) setMutateError(pricingTierRes.errors[0]);

      await updateQuoteMutation.mutateAsync(
        _.omit(data, ["pricingTiers", "costs"])
      );
    } catch (err) {
      setMutateError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = useCallback(() => {
    if (!quote) return;
    reset(formDataFromQuote(quote));
  }, [quote, reset]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <FormProvider {...formMethods}>
      {isDirty && (
        <SaveFormBanner
          handleSave={handleSubmit(handleSave)}
          handleReset={handleReset}
          isLoading={loading}
        />
      )}
      <div tw="space-y-6">
        <Card>
          <RfqSummary rfq={quote.requestForQuote} hidePricingTiers />
        </Card>
        <Card>
          <TwoCol>
            <ControlledTextInput
              label="Supplier Quote Number"
              {...reg("supplierQuoteNumber")}
            />
            <ControlledTextInput label="MOQ *" {...reg("moq", intValidation)} />
          </TwoCol>
        </Card>
        <OpaqueCard tw="p-0">
          <SmartTable
            rows={_.sortBy(quote.pricingTierQuotes, "pricingTierQty")}
            columns={[
              {
                id: "pricingTierQty",
                label: "Qty",
                render: (int) => (
                  <div tw="text-base my-2">{formatNumber(int)}</div>
                ),
              },
              {
                id: "cost",
                label: "Cost per Unit *",
                render: (_, { id }) => (
                  <ControlledTextInput
                    InputProps={moneyAdornment}
                    tw="min-w-[8em]!"
                    {...reg(`pricingTiers.${id}.cost`, moneyValidation)}
                  />
                ),
              },
              {
                id: "leadTimeInDays",
                label: "Lead Time (days) *",
                render: (_, { id }) => (
                  <ControlledTextInput
                    {...reg(`pricingTiers.${id}.leadTimeInDays`, intValidation)}
                  />
                ),
              },
            ]}
            error={null}
          />
        </OpaqueCard>

        <OpaqueCard tw="p-0">
          <CostTable />
        </OpaqueCard>
        <Card>
          <SectionTitle>Item details</SectionTitle>
          <TwoCol>
            <ControlledTextInput
              label="Item Dimensions *"
              {...reg("itemDimensions")}
            />
            <ControlledTextInput
              label="Pricing Based On *"
              {...reg("pricingBasedOn")}
            />
            <ControlledTextInput
              label="Branding Area"
              {...reg("brandingArea")}
            />
            <ControlledTextInput
              label="Color Options"
              {...reg("colorOptions")}
            />
            <ControlledTextInput
              label="Decoration Options"
              {...reg("decorationOptions")}
            />
          </TwoCol>
        </Card>
        <Card>
          <SectionTitle>Pre-production</SectionTitle>
          <div tw="flex gap-4">
            <ControlledTextInput
              label="Item Pre Pro. Cost"
              InputProps={moneyAdornment}
              {...reg("preProCost", moneyValidation)}
            />
            <ControlledTextInput
              label="Item Pre Pro. Lead Time (in days)"
              {...reg("preProLeadTimeInDays", intValidation)}
            />
          </div>
        </Card>

        <Card tw="space-y-6">
          <ControlledTextInput
            label="Notes"
            multiline
            minRows={2}
            maxRows={5}
            {...reg("description")}
          />
          <ControlledTextInput
            label="Ideas for cost savings, enhancements or other ideas"
            multiline
            minRows={2}
            maxRows={5}
            {...reg("ideas")}
          />
        </Card>

        <StyledButton
          tw="justify-self-end"
          cta
          onClick={handleSubmit(handleComplete)}
        >
          Save and complete
        </StyledButton>
      </div>
    </FormProvider>
  );
};

export default QuoteForm;
