import axios from "axios";
import Jsona from "jsona";

import { handleErrors } from "./apiFunctions";

const dataFormatter = new Jsona();

const writeHeaders = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

export const axiosGet = async (url, jsonFormat = true) => {
  const response = { status: "", error: null, data: null };
  await axios
    .get(url)
    .then((res) => {
      let data = jsonFormat ? dataFormatter.deserialize(res.data) : res.data;
      response.status = "ok";
      response.data = data;
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosGetWithNext = async (url) => {
  const response = { status: "", error: null, data: null };
  await axios
    .get(url)
    .then((res) => {
      let dataObject = { data: null, totalEntries: null, nextLink: null };
      let data = dataFormatter.deserialize(res.data);
      dataObject.data = data;
      dataObject.totalEntries = res.data.meta
        ? res.data.meta.total_entries
        : null;
      dataObject.nextLink = res.data.links ? res.data.links.next : null;
      response.status = "ok";
      response.data = dataObject;
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosPatch = async (url, patchData) => {
  const response = { status: "", error: null, data: null };
  await axios
    .patch(url, patchData, writeHeaders)
    .then((res) => {
      let data = dataFormatter.deserialize(res.data);
      response.status = "ok";
      response.data = data;
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosPost = async (url, postData, jsonFormat = true) => {
  const response = { status: "", error: null, data: null };
  await axios
    .post(url, postData, writeHeaders)
    .then((res) => {
      let data;
      if (jsonFormat && typeof res.data !== "string") {
        data = dataFormatter.deserialize(res.data);
      } else {
        data = res.data;
      }
      response.status = "ok";
      response.data = data;
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosDelete = async (url, deleteData) => {
  const response = { status: "", error: null };
  await axios
    .delete(url, deleteData, writeHeaders)
    .then(() => {
      response.status = "ok";
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};
