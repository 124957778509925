/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Radio } from "@mui/material";

import { ControlledRadioGroup } from "../../../utils/forms";
import { useFilterContext } from "../filterContext";
import Chip from "./Chip";

const statuses = {
  active: "Active",
  inactive: "Inactive",
};

const UserActiveStatusPopper = ({ titlePrefix = "" }) => {
  const { control } = useFilterContext();

  const programStatusOptions = Object.keys(statuses);

  return (
    <div tw="min-w-[200px]">
      <h4 tw="text-neutral-600 my-4 px-3">{titlePrefix}Status is</h4>

      <ControlledRadioGroup
        tw="w-full"
        name="userActiveStatus"
        control={control}
      >
        {programStatusOptions.map((key) => (
          <label
            key={key}
            tw="flex items-center px-3 border-t border-neutral-200 text-sm hover:bg-neutral-100 cursor-pointer"
          >
            <Radio size="small" value={key} edge="start" />
            {statuses[key]}
          </label>
        ))}
      </ControlledRadioGroup>
    </div>
  );
};

const UserActiveStatusFilterTile = ({ titlePrefix = "", ...props }) => {
  const { filterValues } = useFilterContext();
  const userActiveStatus = filterValues["userActiveStatus"];
  const userActiveStatusText = userActiveStatus && statuses[userActiveStatus];

  return (
    <Chip {...(props as any)}>
      <span>{titlePrefix}User is&nbsp;</span>
      <b>{userActiveStatusText}</b>
    </Chip>
  );
};

const UserActiveStatusFilter = {
  Chip: UserActiveStatusFilterTile,
  Popper: UserActiveStatusPopper,
};

export default UserActiveStatusFilter;
