import client from "@services/api";

import { ItemFormData } from "../maps";

const saveVariablePricing = (
  itemId: string,
  variablePricingFormData: ItemFormData["variablePricing"],
  usesVariablePricing: boolean
) =>
  Promise.all(
    variablePricingFormData.map((vp) => {
      const requestBody = {
        ...vp,
        itemId,
      };

      if (!usesVariablePricing || vp.shouldDelete) {
        if (vp.id) return client.delete(`variable-pricing/${vp.id}`);
        else return null;
      } else if (vp.id) {
        return client.update(`variable-pricing/${vp.id}`, requestBody);
      } else {
        return client.post(`variable-pricing`, requestBody);
      }
    })
  );
export default saveVariablePricing;
