import { useEffect } from "react";
import { useSelector } from "react-redux";

import { AutocompleteValue } from "@mui/material";

import { routeResourceTypes } from "@services/api";

import {
  ResourceAutocomplete,
  ResourceAutocompleteProps,
} from "./ResourceAutocomplete";

type Multiple<Single extends boolean | undefined> = Single extends true
  ? false
  : true;

type FilterSyncResourceAutocompleteProps<
  TResourceName extends keyof routeResourceTypes,
  Single extends boolean | undefined,
  DisableClearble extends boolean = false,
> = {
  handleChange: (
    val: AutocompleteValue<
      routeResourceTypes[TResourceName],
      Multiple<Single>,
      DisableClearble,
      false
    >,
    key: string,
    filterType: string
  ) => void;
  single: Single;
  reset: boolean;
  setReset: (val: boolean) => void;
  filterType: string;
  filterName: string;
} & Omit<
  ResourceAutocompleteProps<
    TResourceName,
    routeResourceTypes[TResourceName],
    Multiple<Single>,
    DisableClearble,
    false
  >,
  "multiple"
>;

const FilterSyncResourceAutocomplete = <
  TResourceName extends keyof routeResourceTypes,
  Single extends boolean = false,
  DisableClearble extends boolean = false,
>({
  handleChange,
  reset,
  setReset,
  filterType,
  filterName,
  single,
  ...props
}: FilterSyncResourceAutocompleteProps<
  TResourceName,
  Single,
  DisableClearble
>) => {
  const filterValue = useSelector((state: any) => state.filters[filterName]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset, setReset]);

  const multiple = !single as Multiple<Single>;

  return (
    <ResourceAutocomplete
      value={filterValue}
      onChange={(_, val) => {
        handleChange(val, filterName, filterType);
      }}
      renderTags={() => null}
      multiple={multiple}
      {...props}
    />
  );
};

export default FilterSyncResourceAutocomplete;
