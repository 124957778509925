import { jsonToCSV } from "react-papaparse";

import fileSaver from "file-saver";

import { formatCsvBlob } from "./formatCsvBlob";

export function downloadAsCsv(data: any[][], filename: string) {
  // parse json data to csv text
  const csvData = jsonToCSV(data);
  const blob = formatCsvBlob(csvData);
  fileSaver(blob, filename);
}
