import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

const PromotionStatusSelector = ({
  handleStatus,
  status,
  setStatus,
  filterType,
}) => {
  const currentStatus = useSelector((state) => state.filters.promotionStatus);
  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const statuses = [
    { id: "active", label: "Active" },
    { id: "inactive", label: "Inactive" },
  ].map((type, index) => (
    <MenuItem value={type.id} key={index}>
      <Typography variant="body2">{type.label}</Typography>
    </MenuItem>
  ));

  const handleChangeSelect = (evt) => {
    setStatus(evt.target.value);
    handleStatus(evt.target.value, "promotionStatus", filterType);
  };

  useEffect(() => {
    if (status !== currentStatus) {
      setStatus(currentStatus);
    }
  });

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        disabled={isGlobalLoading}
      >
        <InputLabel id="promotion-status-select">Status</InputLabel>
        <Select
          label={"Status"}
          name="promotion-status"
          labelId="promotion-status-select"
          id="promotion-status"
          value={status || ""}
          onChange={handleChangeSelect}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {[...statuses]}
        </Select>
      </FormControl>
    </>
  );
};

PromotionStatusSelector.propTypes = {
  handleStatus: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  filterType: PropTypes.string,
};

export default PromotionStatusSelector;
