import { axiosPost } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildApproveOrDenyRequest } from "./helpers";

let initialState = {
  isUpdateLoading: false,
  selectedOrderSetIds: [],
};

const orderReviewSlice = createSlice({
  name: "orderReview",
  initialState,
  reducers: {
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    approveOrDenySuccess(state) {
      state.selectedOrderSetIds = [];
      state.isUpdateLoading = false;
    },
    setOrderSetSelection(state, action) {
      state.selectedOrderSetIds = action.payload;
    },
  },
});

export const {
  setIsUpdateLoading,
  approveOrDenySuccess,
  setOrderSetSelection,
} = orderReviewSlice.actions;

export default orderReviewSlice.reducer;

export const approveOrDenyOrderSets = (ids, type, note) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    let errors = [];
    let validIds = [];
    await Promise.all(
      ids.map(async (id) => {
        let postData = buildApproveOrDenyRequest(id, type, note);
        let response = await axiosPost(
          `/api/order-sets/${id}/${type}`,
          postData
        );
        if (response.error) {
          errors.push({ id: id, error: response.error });
          return false;
        } else {
          validIds.push(response.data.id);
        }
      })
    );
    if (validIds.length > 0) {
      dispatch(approveOrDenySuccess({ orderSetIds: validIds }));
    }
    if (errors.length > 0) {
      throw new Error(
        `The following Order Set Reqests failed: ${errors
          .map((e) => `${e.id}: ${e.error}`)
          .join(", ")}`
      );
    }
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setError({ error: err.toString(), source: "Order Review" }));
    dispatch(patchFailure());
  }
};
