/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Add } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "@components/StyledComponents";
import WithPermission, {
  permissions,
} from "@components/Utility/WithPermission";
import { useFilterParams } from "@features/filters";
import { Filters } from "@features/filters";
import {
  ItemCatalogView,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";
import {
  AddToCartButton,
  VariantSelectionModal,
  useCurrentDraftOrderSet,
} from "@features/ordering";
import { CreateInventoryPurchaseOrderModal } from "@features/purchaseOrders";
import { Item } from "@models";
import DocTitle from "@utility/DocTitle";

import FourOhFour from "./FourOhFour";

const when = (condition, value) => (condition ? [value] : []);

const useFilteredItems = (orderType: string, filters: Record<string, any>) => {
  const { currentTerritoryId, currentChannelId } = useSelector(
    (state: any) => state.currentUser
  );
  return usePaginatedItemsQuery({
    filter: {
      isActive: true,
      isOrderable: true,
      orderType,
      territoryIds: [currentTerritoryId],
      channelIds: currentChannelId && [currentChannelId],
      query: filters.q,
      favorites: filters.favorites,
      programIds: filters.programs,
      groupIds: filters.groups && Object.values(filters.groups).flat(),
      hasInventory: filters.inStock,
    },
  });
};

const PlaceOrder = () => {
  const [filterParams] = useFilterParams();
  const orderType = useParams().orderType as "inventory" | "on-demand";
  const productView: "table" | "grid" = useSelector(
    (state: any) => state.globalState.productView
  );

  const [inventoryPOItemId, setInventoryPOItemId] = useState<null | string>(
    null
  );

  const [variantSelectionItem, setVariantSelectionItem] = useState<null | Item>(
    null
  );
  const { orderSet, isLoading, orderSetVariants, createOrderSetVariants } =
    useCurrentDraftOrderSet(orderType);

  const { data: items, ...tableProps } = useFilteredItems(
    orderType,
    filterParams
  );

  const addToOrderSet = async (item: Item) => {
    const defaultVariant = item.variants.find((v) => v.isDefault);

    if (defaultVariant?.isActive) {
      await createOrderSetVariants([defaultVariant.id]);
    } else {
      setVariantSelectionItem(item);
    }
  };

  if (!["inventory", "on-demand"].includes(orderType)) {
    return <FourOhFour />;
  }

  return (
    <>
      {inventoryPOItemId && (
        <CreateInventoryPurchaseOrderModal
          itemId={inventoryPOItemId}
          handleClose={() => setInventoryPOItemId(null)}
        />
      )}
      {variantSelectionItem && (
        <VariantSelectionModal
          item={variantSelectionItem}
          onClose={() => setVariantSelectionItem(null)}
          {...{
            orderType,
            orderSetVariants,
            createOrUpdateOrderSet: createOrderSetVariants,
          }}
        />
      )}
      <DocTitle
        title={`${orderType === "inventory" ? "Inventory" : "On-Demand"} Order`}
      />
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">
            {orderType === "inventory"
              ? "Place an Inventory Order"
              : "Place an On-Demand Order"}
          </h2>
          <div tw="flex gap-2">
            <StyledButton
              cta
              disabled={!orderSet?.id}
              loading={isLoading}
              to={`/orders/open/draft/${orderSet?.id}`}
            >
              VIEW ORDER
            </StyledButton>
          </div>
        </div>
        <div tw="flex gap-2 items-start justify-between">
          <Filters
            searchTitle="Search Items"
            slots={[
              "favorites",
              ...when(orderType === "inventory", "inStock"),
              "groups",
              "programs",
            ]}
            {...(orderType === "inventory" && {
              defaultValues: { inStock: true },
            })}
          />
          <ItemViewButtons />
        </div>
        <ItemCatalogView
          rows={items}
          query={filterParams.q}
          rowActions={(item) => (
            <div
              css={[
                tw`flex items-center gap-2`,
                productView === "table" && tw`flex-row-reverse`,
              ]}
            >
              {orderType === "inventory" && (
                <WithPermission allow={permissions.createPurchaseOrder}>
                  <Tooltip title="Restock Inventory">
                    <IconButton
                      size="small"
                      edge="end"
                      css={[
                        tw`text-primary-600 bg-primary-50! hover:(bg-primary-100/50 text-primary-800)`,
                        productView === "grid" &&
                          tw`transition-opacity opacity-0 group-hover:opacity-100`,
                      ]}
                      onClick={() => setInventoryPOItemId(item.id)}
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                </WithPermission>
              )}

              <AddToCartButton
                item={item}
                orderSetVariants={orderSetVariants}
                addToOrderSet={addToOrderSet}
              />
            </div>
          )}
          {...tableProps}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default PlaceOrder;
