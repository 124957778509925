import React, { memo } from "react";

import { CancelTwoTone } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OptionTable = ({
  headCells,
  options,
  handleRemove,
  disabled,
  isRemoveable,
}) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => {
              return (
                <TableCell
                  className={classes.headerText}
                  key={headCell.id}
                  align="left"
                >
                  {headCell.label}
                </TableCell>
              );
            })}
            {isRemoveable && <TableCell padding="checkbox" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {options.map((row, i) => (
            <TableRow key={i}>
              {headCells.map((cell) => (
                <TableCell key={`${i}-${row.id}-${cell.id}`} align="left">
                  {cell.render
                    ? cell.render(_.get(row, cell.id), row)
                    : _.get(row, cell.id)}
                </TableCell>
              ))}
              {isRemoveable && (
                <TableCell padding="checkbox" align="center">
                  <IconButton
                    onClick={() => handleRemove(row.id)}
                    disabled={disabled || !!row.variantId}
                    size="large"
                  >
                    <CancelTwoTone
                      color={row.variantId ? "primary" : "secondary"}
                      style={{ ...(row.variantId && { opacity: 0.5 }) }}
                    />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OptionTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  handleRemove: PropTypes.func,
  disabled: PropTypes.bool,
  isRemoveable: PropTypes.bool.isRequired,
};

export default memo(OptionTable);
