/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { DownloadRounded, OpenInNewRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { CloudinaryFile } from "@models/CloudinaryFile";
import { CldImage } from "@services/cloudinary";

import getFileUrl from "../getCloudinaryFileUrl";

const CloudinaryFileSlide = ({ file }: { file: CloudinaryFile }) => {
  const downloadUrl = getFileUrl(file);
  const viewUrl = getFileUrl(file, { forDownload: false });
  return (
    <div tw="relative min-h-full flex flex-col items-center justify-between">
      <div />
      {file.resourceType === "image" && (
        <div tw="drop-shadow-xl p-4 pb-6">
          <CldImage
            publicId={`${file.cloudinaryId}.jpg`}
            size="large"
            // tw="w-auto max-h-[calc(100% - 2em)] "
          />
        </div>
      )}
      {file.resourceType !== "image" && (
        <div tw="aspect-square w-48 flex items-center justify-center text-3xl font-bold text-neutral-400 drop-shadow-lg bg-white rounded-xl">
          .{file.format}
        </div>
      )}
      <div tw="self-stretch flex gap-2 items-center">
        <Tooltip title="Download File">
          <IconButton size="small" href={downloadUrl}>
            <DownloadRounded />
          </IconButton>
        </Tooltip>
        {file.resourceType === "image" && (
          <Tooltip title="View original in new tab">
            <IconButton size="small" href={viewUrl} target="_blank">
              <OpenInNewRounded />
            </IconButton>
          </Tooltip>
        )}
        <div>
          {file.fileName}.{file.format}
        </div>
        {file.pdfPageCount > 0 && (
          <div tw="text-neutral-500">
            ({file.pdfPageCount} page{file.pdfPageCount > 1 ? "s" : ""})
          </div>
        )}
      </div>
    </div>
  );
};

export default CloudinaryFileSlide;
