/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { ReactNode, useCallback, useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useNavigate } from "react-router-dom";

import {
  CircularProgress,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { get } from "lodash";

import { ResourceError } from "@features/errors";
import { useFilterParams } from "@features/filters";

export type Column<R> = {
  id: string;
  label: ReactNode;
  align?: "left" | "right" | "center";
  sort?: string;
  render?: (cell: any, row: R) => ReactNode;
  renderText?: (cell: any, row: R) => string;
  to?: (row: R) => string;
};

export type PaginatedResourceProps = {
  isLoading?: boolean;
  isFetching?: boolean;
  hasNextPage?: boolean;
  isPlaceholderData?: boolean;
  error?: any;
  fetchNextPage?: () => void;
};

export type ReactQueryTableProps<R> = PaginatedResourceProps & {
  rows: R[];
  columns: Column<R>[];
  onRowClick?: (row: R) => void;
  filterState?: Record<string, any>;
  setFilterState?: (x: Record<string, any>) => void;
  maxHeight?: number;
  tableSize?: "small" | "medium";
  rowProps?: (row: R) => TableRowProps;
  noResultsText?: ReactNode;
};

const splitSort = (str: string): ["asc" | "desc", string] =>
  str.startsWith("-") ? ["desc", str.slice(1)] : ["asc", str];

const TableHeader = ({ columns, filterValues, setFilterValues }) => {
  let { sort: sortParam } = filterValues;
  const [sortDirection, sortValue] = splitSort(sortParam ?? "");

  const handleSort = (value) => {
    if (sortValue === value) {
      const switchSortDirection = sortDirection === "desc" ? "" : "-";
      sortParam = switchSortDirection + value;
    } else {
      sortParam = "-" + value;
    }
    setFilterValues({ ...filterValues, sort: sortParam });
  };

  return (
    <TableHead
      css={{
        "&": tw`relative z-10`,
        th: tw`py-3 text-xs tracking-wider uppercase whitespace-nowrap text-neutral-500`,
      }}
    >
      <TableRow>
        {columns.map(({ id, align, label, sort }) => (
          <TableCell key={id} align={align}>
            {sort ? (
              <TableSortLabel
                tw="w-full -mr-4"
                active={sort === sortValue}
                direction={(sort === sortValue && sortDirection) || "desc"}
                onClick={() => handleSort(sort)}
              >
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ReactQueryTable = <R extends any>({
  rows,
  isLoading,
  isFetching,
  isPlaceholderData,
  hasNextPage,
  error,
  fetchNextPage,
  columns,
  onRowClick,
  noResultsText = "No results match your search...",
  filterState,
  setFilterState,
  maxHeight,
  rowProps,
}: ReactQueryTableProps<R>) => {
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);

  // this is the default filter state
  const [filterParams, setFilterParams] = useFilterParams();

  const filterValues = filterState || filterParams;
  const setFilterValues = setFilterState || setFilterParams;

  const fetchNext = () =>
    !isLoading && !isFetching && hasNextPage && fetchNextPage?.();

  const scrollRef = useBottomScrollListener(fetchNext, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  }) as any;

  const heightRef = useCallback(
    (node: HTMLElement | null) => {
      // if (tableRef) tableRef.current = node;
      if (maxHeight && node !== null) {
        const nodeHeight = node.getBoundingClientRect().height;
        setHeight(Math.min(nodeHeight, maxHeight));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxHeight, rows]
  );

  const handleNavigate = (url: string) => (e) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(url, "_blank");
    } else {
      navigate(url, { state: { query: window.location.search } });
    }
  };

  useEffect(() => {
    if (isLoading || !scrollRef.current) return;
    scrollRef.current.scrollTop = 0;
  }, [isLoading, scrollRef]);

  if (isLoading && rows.length === 0) return <CircularProgress tw="m-6" />;

  return (
    <div tw="relative h-full overflow-hidden">
      <TableContainer
        css={[
          tw`max-h-full`,
          isPlaceholderData &&
            tw`transition-opacity opacity-50 pointer-events-none`,
          maxHeight && { height: `${height}px` },
        ]}
        ref={scrollRef}
      >
        <Table stickyHeader css={{ td: tw`align-top` }} ref={heightRef}>
          <TableHeader
            columns={columns}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
          />
          <TableBody>
            {!isLoading && rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Typography>{noResultsText}</Typography>
                </TableCell>
              </TableRow>
            )}
            {rows.map((row, i) => (
              <TableRow
                key={i}
                css={[
                  tw`hover:bg-neutral-50`,
                  onRowClick && tw`cursor-pointer`,
                ]}
                onClick={() => onRowClick?.(row)}
                {...rowProps?.(row)}
              >
                {columns.map((col) => (
                  <TableCell
                    key={col.id}
                    align={col.align ?? "left"}
                    onClick={col.to && handleNavigate(col.to?.(row))}
                    css={col.to && tw`cursor-pointer`}
                  >
                    {col.render
                      ? col.render(get(row, col.id), row)
                      : get(row, col.id)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div tw="h-1 w-full absolute left-0 bottom-0">
        {isFetching && !isLoading && <LinearProgress />}
      </div>
      {error && (
        <div tw="absolute inset-0 bg-white/50 backdrop-blur-sm p-4 pt-16">
          <ResourceError error={error} />
        </div>
      )}
    </div>
  );
};

export default ReactQueryTable;
