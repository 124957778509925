import { ReactNode } from "react";
import { useSelector } from "react-redux";

import permissions from "@utils/permissions";

interface WithChild {
  children: ReactNode;
}
interface Allow extends WithChild {
  allow: string | readonly string[];
  deny?: never;
}
interface Deny extends WithChild {
  allow?: never;
  deny: string | readonly string[];
}
type WithPermissionProps = Allow | Deny;

const insureArray = (val) =>
  val === undefined ? [] : Array.isArray(val) ? val : [val];

const WithPermission = ({ allow, deny, children }: WithPermissionProps) => {
  const { role } = useSelector((state: any) => state.currentUser);
  const allowArray = insureArray(allow);
  const denyArray = insureArray(deny);
  return (!allowArray.length || allowArray.includes(role)) &&
    !denyArray.includes(role) ? (
    <>{children}</>
  ) : null;
};

export default WithPermission;
export { permissions };
