/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import Close from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const BulkBudgetUploadEmailErrorModal = ({
  emailErrorModalOpen,
  setEmailErrorModalOpen,
  uploadEmailErrors,
}: {
  emailErrorModalOpen: boolean;
  setEmailErrorModalOpen: (open: boolean) => void;
  uploadEmailErrors: { email: string; budgetName: string }[];
}) => {
  return (
    <Dialog
      open={emailErrorModalOpen}
      onClose={() => setEmailErrorModalOpen(false)}
    >
      <DialogTitle tw="flex justify-between items-start">
        Upload Failed
        <IconButton onClick={() => setEmailErrorModalOpen(false)} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {uploadEmailErrors.length > 0 ? (
          <>
            <Typography color="textSecondary">
              The following user emails weren't found in your organization:
            </Typography>
            <Box tw="max-h-[500px] overflow-y-auto">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Budget Name</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadEmailErrors.map((error, index) => (
                    <TableRow key={index}>
                      <TableCell>{error.budgetName}</TableCell>
                      <TableCell>{error.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        ) : (
          <Typography color="textSecondary">No email errors found.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BulkBudgetUploadEmailErrorModal;
