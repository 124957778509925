import { Link } from "react-router-dom";

import ErrorComponent from "@components/Beacons/ErrorComponent";
import permissions from "@utils/permissions";

import Beacon from "../../../pages/Beacon";
import Beacons from "../../../pages/Beacons";
import { protectedRoute } from "../types";

export default {
  path: "beacons",
  handle: { crumb: () => <Link to="/beacons">Beacons</Link> },
  errorElement: <ErrorComponent />,
  allowedRoles: permissions.beacons,
  children: [
    // List future or active programs that have beacons
    { path: "", element: <Beacons /> },
    // Past programs with beacons
    { path: "archive", element: <Beacons /> },
    // Single program with beacon
    {
      path: ":programId",
      element: <Beacon />,
    },
    {
      path: ":programId/:view",
      element: <Beacon />,
    },
    {
      path: ":programId/:view/:state",
      element: <Beacon />,
    },
    {
      path: ":programId/:view/:state/:variantSku",
      element: <Beacon />,
    },
  ],
} as protectedRoute;
