/** @jsxImportSource @emotion/react */
import "twin.macro";

import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const SkuInfoIcon = ({ sku }) => {
  const title = sku ? "Brandhub generated item #" : "Customer-provided SKU";

  return (
    <Tooltip title={title}>
      <InfoOutlined tw="text-base ml-2 cursor-pointer" color="secondary" />
    </Tooltip>
  );
};

export default SkuInfoIcon;
