import { axiosGetWithNext } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { buildFilters, buildSort } from "../../../api/apiFunctions";
import { setError } from "../errorSlice";
import { mapPrograms } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  programList: [],
  currentProgram: null,
  hasUpdated: false,
  error: null,
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    getProgramsSuccess(state, action) {
      const { programs, nextLink } = action.payload;
      state.programList = programs;
      state.nextLink = nextLink;
      state.isLoading = false;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const { setIsLoading, getProgramsSuccess, setFailure } =
  programsSlice.actions;

export default programsSlice.reducer;

export const fetchPrograms = (filters) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    let sort = buildSort(filters);
    let queryString = buildFilters(filters, "", sort, "/api/programs");
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapPrograms(response.data.data);
    dispatch(
      getProgramsSuccess({
        programs: mappedData,
        nextLink: response.data.nextLink ?? null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Programs" }));
  }
};
