import {
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";
import { separateByComma, stringToCents } from "@utility/utilityFunctions";

import { buildFilters } from "../../../api/apiFunctions";
import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildDiscountRequest, buildPromotion } from "./helpers";
import { mapPromotion, mapPromotions } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  promotionList: [],
  currentUserPromotions: [],
  totalDiscount: 0,
  currentPromotion: null,
  hasUpdated: false,
  error: null,
};

const promotionSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsNextLoading(state) {
      state.isNextLoading = false;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getPromotionsSuccess(state, action) {
      const { promotions, nextLink } = action.payload;
      state.promotionList = promotions;
      state.nextLink = nextLink;
      state.isLoading = false;
      state.error = null;
    },
    getNextPromotionsSuccess(state, action) {
      const { promotions, nextLink } = action.payload;
      state.promotionList = state.promotionList.concat(promotions);
      state.nextLink = nextLink;
      state.isNextLoading = false;
      state.error = null;
    },
    getSinglePromotionSuccess(state, action) {
      const { promotion } = action.payload;
      state.currentPromotion = promotion;
      state.isLoading = false;
      state.error = null;
    },
    getUserPromotionsSuccess(state, action) {
      const { promotions } = action.payload;
      state.currentUserPromotions = promotions;
      state.error = null;
    },
    getDiscountSuccess(state, action) {
      const { discount } = action.payload;
      state.totalDiscount = discount;
      state.isUpdateLoading = false;
      state.error = null;
    },
    createOrUpdatePromotionSuccess(state, action) {
      const { promotion } = action.payload;
      let currentPromotion = state.promotionList.find(
        (p) => p.id === promotion.id
      );
      state.promotionList = !currentPromotion
        ? state.promotionList.concat(promotion)
        : state.promotionList.map((p) => {
            if (p.id === promotion.id) {
              return promotion;
            } else return p;
          });
      state.currentPromotion = promotion;
      state.hasUpdated = true;
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateRedeemedPromotions(state, action) {
      const { ids, falseIds } = action.payload;
      state.currentUserPromotions = state.currentUserPromotions.map((p) => {
        if (ids.includes(+p.id)) {
          return {
            ...p,
            hasRedeemed: true,
          };
        } else if (falseIds.includes(+p.id)) {
          return {
            ...p,
            hasRedeemed: false,
          };
        } else return { ...p };
      });
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetCurrentPromotion(state) {
      state.currentPromotion = null;
    },
    resetDiscount(state) {
      state.totalDiscount = 0;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsNextLoading,
  setIsUpdateLoading,
  getPromotionsSuccess,
  getNextPromotionsSuccess,
  getUserPromotionsSuccess,
  getSinglePromotionSuccess,
  getDiscountSuccess,
  createOrUpdatePromotionSuccess,
  updateRedeemedPromotions,
  setHasUpdated,
  resetDiscount,
  resetCurrentPromotion,
  setFailure,
} = promotionSlice.actions;

export default promotionSlice.reducer;

export const fetchPromotions = (filters) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    let queryString = buildFilters(filters, "", "", "/api/promotions");
    const response = await axiosGetWithNext(queryString);
    if (response.error) throw response.error;
    const mappedData = mapPromotions(response.data.data, false);
    dispatch(
      getPromotionsSuccess({
        promotions: mappedData,
        nextLink: response.data.nextLink ?? null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Promotions" }));
  }
};

export const fetchNextPromotions = (url) => async (dispatch) => {
  try {
    dispatch(setIsNextLoading());
    const response = await axiosGetWithNext(url);
    if (response.error) throw response.error;
    const mappedData = mapPromotions(response.data.data, false);
    dispatch(
      getNextPromotionsSuccess({
        promotions: mappedData,
        nextLink: response.data.nextLink ?? null,
      })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Promotions" }));
  }
};

export const fetchUserPromotions = (id) => async (dispatch) => {
  try {
    const response = await axiosGet(
      `/api/promotions?${separateByComma(
        "user-ids",
        id
      )}&filter[is-active]=true`
    );
    if (response.error) throw response.error;
    const mappedData = mapPromotions(response.data, true);
    dispatch(getUserPromotionsSuccess({ promotions: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Promotions" }));
  }
};

export const fetchPromotion = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/promotions/${id}`);
    if (response.error) throw response.error;
    const mappedData = mapPromotion(response.data, false);
    dispatch(getSinglePromotionSuccess({ promotion: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Promotions" }));
  }
};

export const getDiscount = (ids, codes, orderSetId) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    const postData = buildDiscountRequest(ids, codes, orderSetId);
    const response = await axiosPost("/api/promotions/get-discount", postData);
    if (response.error) throw response.error;
    dispatch(
      getDiscountSuccess({ discount: stringToCents(response.data.discount) })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Promotions" }));
  }
};

const fetchUsers = async (allUsers, ids) => {
  if (!allUsers) return { status: "ok", ids: ids };

  let userIds = [];
  let queryString = "/api/users?page[size]=50";

  const initialResponse = await axiosGetWithNext(queryString);
  if (initialResponse.error)
    return { status: "error", error: initialResponse.error };
  userIds = userIds.concat(initialResponse.data.data.map((u) => +u.id));

  let nextLink = initialResponse.data.nextLink;

  if (nextLink) {
    const pageSize = initialResponse.data.data.length;
    let fetchCount =
      Math.ceil(initialResponse.data.totalEntries / pageSize) - 1;

    for (let i = 0; i < fetchCount; i++) {
      let response = await axiosGetWithNext(nextLink);
      if (response.error) return { status: "error", error: response.error };
      userIds = userIds.concat(response.data.data.map((u) => +u.id));
      nextLink = response.data.nextLink;
    }
  }

  return { status: "ok", ids: userIds };
};

export const createPromotion = (attrs, allUsers, ids) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const userIds = await fetchUsers(allUsers, ids);
    if (userIds.error) throw userIds.error;
    let postAttrs = {
      ...attrs,
      users: userIds.ids,
    };
    const postData = buildPromotion(postAttrs);
    const response = await axiosPost("/api/promotions", postData);
    if (response.error) throw response.error;
    const mappedData = mapPromotion(response.data);
    dispatch(createOrUpdatePromotionSuccess({ promotion: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Promotions" }));
  }
};

export const updatePromotion =
  (attrs, allUsers, ids, id) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const userIds = await fetchUsers(allUsers, ids);
      if (userIds.error) throw userIds.error;
      let patchAttrs = {
        ...attrs,
        users: userIds.ids,
      };
      const patchData = buildPromotion(patchAttrs);
      const response = await axiosPatch(`/api/promotions/${id}`, patchData);
      if (response.error) throw response.error;
      const mappedData = mapPromotion(response.data);
      dispatch(createOrUpdatePromotionSuccess({ promotion: mappedData }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(setError({ error: err.toString(), source: "Promotions" }));
    }
  };
