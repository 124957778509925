import React, { memo } from "react";
import { useDispatch } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { createSetupFee } from "@redux/slices/purchaseOrders/purchaseOrderSlice";

import { useInput } from "../../hooks/inputs/useInput";
import { useNumberOnlyInput } from "../../hooks/inputs/useNumberOnlyInput";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddSetupFeeModal = ({ open, handleClose, purchaseOrderId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { value: desc, bind: bindDesc } = useInput("");
  const { value: cost, bind: bindCost } = useNumberOnlyInput("");

  const handleSubmit = () => {
    dispatch(
      createSetupFee({
        "is-setup-fee": true,
        "setup-fee-desc": desc.trim(),
        "actual-cost": cost.trim(),
        "purchase-order-id": +purchaseOrderId,
      })
    );
    handleClose();
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {`Purchase Order ${purchaseOrderId} Setup Fee`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.fullWidthCenterColumn}>
            <TextField
              size="small"
              className={classes.settingsMargin}
              variant="outlined"
              color="secondary"
              name="setup-fee-desc"
              type="text"
              label="Setup Fee Description"
              multiline
              rows={2}
              {...bindDesc}
              fullWidth
            />
            <TextField
              size="small"
              variant="outlined"
              color="secondary"
              name="setup-fee-cost"
              type="text"
              label="Setup Fee Cost"
              {...bindCost}
              fullWidth
            />
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={desc.length === 0 || cost.length === 0}
            >
              SUBMIT
            </Button>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddSetupFeeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  purchaseOrderId: PropTypes.string.isRequired,
};

export default memo(AddSetupFeeModal);
