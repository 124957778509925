export const buildSupplier = (attrs) => {
  const userArray = attrs.users.map((u) => ({
    type: "user",
    id: u.id,
  }));

  const orgArray = attrs.organizations.map((oId) => ({
    type: "organization",
    id: oId,
  }));

  return {
    data: {
      type: "supplier",
      attributes: {
        name: attrs.name,
        "is-active": attrs.isActive,
      },
      relationships: {
        users: {
          data: userArray,
        },
        organizations: {
          data: orgArray,
        },
      },
    },
  };
};
