import { useQuery } from "@tanstack/react-query";

import { VariantCategory } from "@models/VariantCategory";
import client from "@services/api";

export const useVariantCategoriesListQuery = () => {
  return useQuery({
    queryKey: ["variant-categories"],
    queryFn: () =>
      client
        .get<VariantCategory[]>("variant-categories")
        .then((res) => res.data),
    staleTime: Infinity,
  });
};
