import { useSelector } from "react-redux";

import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { Item } from "@models/Item";
import client from "@services/api";
import { BodyWithId, buildPaginatedQuery } from "@utils/reactQuery";

export const itemsKeyFactory = createQueryKeys("items", {
  detail: (id: string) => ({
    queryKey: [id],
    queryFn: () => client.get<Item>(`items/${id}`).then((res) => res.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<Item[]>("items", {
          params,
        })
        .then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Item[]>("items", {
        params,
      }),
  }),
});

export const useItemQuery = (id: string | null | undefined) => {
  return useQuery({
    ...itemsKeyFactory.detail(id!),
    enabled: !!id,
    staleTime: 60_000,
  });
};

export const useItemListQuery = (params?) => {
  return useQuery({
    ...itemsKeyFactory.list({
      skipPagination: true,
      ...params,
    }),
    placeholderData: keepPreviousData,
  });
};

export const usePaginatedItemsQuery = buildPaginatedQuery(
  itemsKeyFactory.paginated,
  {
    staleTime: 1000 * 60 * 5,
    placeholderData: keepPreviousData,
  }
);

type CreateItemPayload = {
  programId?: string;
  territories?: any[];
  channelId?: string;
};

export const useCreateItemMutation = () => {
  const { currentChannelId, territories } = useSelector(
    (state: any) => state.currentUser
  );

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: CreateItemPayload) =>
      client
        .post<Item>(`items`, {
          status: "draft",
          channelId: currentChannelId,
          territories,
          ...data,
          ...(data.programId && { programs: [{ id: data.programId }] }),
          relationshipNames: ["territories", "programs"],
        })
        .then((res) => res.data),
    onSuccess: (item) => {
      queryClient.invalidateQueries({
        queryKey: itemsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: itemsKeyFactory.list._def,
      });
      queryClient.setQueryData<Item>(
        itemsKeyFactory.detail(item.id).queryKey,
        item
      );
    },
  });
};

type Flexible<T> = {
  // makes string values satisfy number type
  [Key in keyof T]: [T[Key]] extends [number]
    ? number | string
    : [T[Key]] extends [number | undefined]
    ? number | string | undefined
    : T[Key];
};

type UpdateItemPayload = Flexible<
  Partial<
    Omit<
      Item,
      | "id"
      | "variants"
      | "images"
      | "promotions"
      | "sku"
      | "programs"
      | "groups"
    >
  >
> & {
  id: string;
  territories: BodyWithId[];
  channels: BodyWithId[];
  groups?: BodyWithId[];
};

export const useUpdateItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: UpdateItemPayload) =>
      client
        .update<Item>(`items/${id}`, {
          ...data,
          relationshipNames: ["territories", "groups", "channels"],
        })
        .then((res) => res.data),
    onSuccess: (item) => {
      queryClient.invalidateQueries({
        queryKey: itemsKeyFactory.paginated._def,
      });
      queryClient.invalidateQueries({
        queryKey: itemsKeyFactory.list._def,
      });
      queryClient.setQueryData<Item>(
        itemsKeyFactory.detail(item.id).queryKey,
        item
      );
    },
  });
};
