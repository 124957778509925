import React, { memo } from "react";

import { Button, CircularProgress, Divider, Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import { upCase } from "@utility/utilityFunctions";

import OptionTable from "../../Utility/OptionTable";

const ReviewPanel = ({
  classes,
  type,
  formData,
  allUsers,
  currentUsers,
  currentItems,
  handleSubmit,
  promotion,
}) => {
  if (type === "edit" && !promotion) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      <Typography className={clsx(classes.headerText, classes.settingsMargin)}>
        General:
      </Typography>
      <Typography className={classes.bodyText}>
        {`Description: ${
          formData.description.length > 0
            ? formData.description
            : "Not set, required to submit"
        }`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Start Date: ${formData.startDate}`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Expiration Date: ${formData.expirationDate}`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Type: ${
          formData.type.length > 0
            ? upCase(formData.type, "-")
            : "Not set, required to submit"
        }`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Redemption Type: ${
          formData.redemptionType.length > 0
            ? upCase(formData.redemptionType, "-")
            : "Not set, required to submit"
        }`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Discount Type: ${
          formData.discountType.length > 0
            ? upCase(formData.discountType, "-")
            : "Not set, required to submit"
        }`}
      </Typography>
      {formData.discountType.length > 0 &&
        formData.discountType !== "free-shipping" && (
          <>
            {formData.discountType === "dollar" && (
              <Typography className={classes.bodyText}>
                {`Dollars Off: ${
                  formData.dollarsOff.length > 0
                    ? `$${formData.dollarsOff}`
                    : "Not set, required to submit"
                }`}
              </Typography>
            )}
            {formData.discountType === "percent" && (
              <Typography className={classes.bodyText}>
                {`Percent Off: ${
                  formData.percentOff.length > 0
                    ? `%${formData.percentOff}`
                    : "Not set, required to submit"
                }`}
              </Typography>
            )}
          </>
        )}
      {formData.type === "item" && formData.isOneTimeUse && (
        <Typography className={classes.bodyText}>
          {`Max Quantity: ${
            formData.maxQty.length > 0 ? formData.maxQty : "Not set"
          }`}
        </Typography>
      )}
      <Typography className={classes.bodyText}>
        {`Is One Time Use: ${formData.isOneTimeUse ? "Yes" : "No"}`}
      </Typography>
      <br />
      <Divider className={classes.fullWidth} />
      <br />
      <Typography className={clsx(classes.headerText, classes.settingsMargin)}>
        User Assignment
      </Typography>
      {promotion && promotion.promotionCode && (
        <Typography className={classes.bodyText}>
          {`Promotion Code: ${promotion.promotionCode}`}
        </Typography>
      )}
      {allUsers && (
        <Typography className={classes.bodyText}>
          {formData.redemptionType === "assignment"
            ? "Assigned to all users."
            : "Code sent to all users."}
        </Typography>
      )}
      {currentUsers.length > 0 && (
        <Typography className={classes.bodyText}>
          {`${
            formData.redemptionType === "assignment"
              ? "Assigned Users:"
              : "Users receiving Code:"
          } ${
            currentUsers.length <= 20
              ? currentUsers.map((u) => u.name).join(", ")
              : `${currentUsers
                  .slice(0, 19)
                  .map((u) => u.name)
                  .join(", ")}, ... plus ${currentUsers.length - 20} users.`
          }`}
        </Typography>
      )}
      <br />
      <Divider className={classes.fullWidth} />
      <br />
      <Typography className={clsx(classes.headerText, classes.settingsMargin)}>
        Item Assignment
      </Typography>
      {formData.type === "order" && (
        <Typography className={classes.bodyText}>
          There are no items assigned to Order type promotions.
        </Typography>
      )}
      {formData.type === "item" && currentItems.length === 0 && (
        <Typography className={classes.bodyText}>
          There are no items selected for this promotion.
        </Typography>
      )}
      {formData.type === "item" && currentItems.length > 0 && (
        <OptionTable
          headCells={[
            { id: "sku", label: "Sku" },
            { id: "customerIdentifier", label: "Product Identifier" },
            { id: "name", label: "Name" },
          ]}
          options={currentItems}
          handleRemove={null}
          disabled={true}
          isRemoveable={false}
        />
      )}
      <br />
      <br />
      <Button
        className={clsx(classes.button, classes.settingsMargin)}
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        style={{ minWidth: "62px" }}
      >
        {type === "new" ? "SUBMIT" : "SAVE"}
      </Button>
    </>
  );
};

ReviewPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  formData: PropTypes.object.isRequired,
  allUsers: PropTypes.bool.isRequired,
  currentUsers: PropTypes.array.isRequired,
  currentItems: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  promotion: PropTypes.object,
};

export default memo(ReviewPanel);
