import format from "date-fns/format";

import cloudinary from "@services/cloudinary";
import { formatDateString, stringToCents } from "@utility/utilityFunctions";

import { mapCostCenter } from "../costCenterSlice";
import { mapAllocations, mapItems } from "../items/maps";

const handleVariantNames = (options) => {
  let name = options
    .filter((v) => v.name.length)
    .map((vs) => `${vs["variant-category"].name}: ${vs.name}`)
    .join(", ");

  return name.length > 0 ? name : "---";
};

const handleCancelationNote = (type, note) => {
  const typeMap = {
    denial: "Denied",
    order: "Canceled",
  };

  if (type && note) {
    return `${typeMap[type]}: ${note}`;
  } else return null;
};

export const mapOrderSets = (orderSets) => {
  const mappedOrderSets = orderSets.map((os) => ({
    id: os.id,
    territoryId: os.territory?.id ?? null,
    territoryName: os.territory?.name ?? "---",
    channelId: os.channel?.id ?? null,
    programId: os.program?.id ?? null,
    programName: os["program-name"] ?? null,
    programOrderDescription: os["program-order-description"] ?? null,
    orderWindowId: os["order-window"]?.id ?? null,
    orderWindowName: os["order-window-name"] ?? null,
    submittedAt: os["submitted-at"]
      ? format(new Date(os["submitted-at"]), "MM/dd/yyyy")
      : "---",
    totalPrice: stringToCents(os["total-price"]),
    states: os["state-names"] ?? "---",
    status: os.status,
    type: os.type,
    user: os.user.name,
    variantIds: os["order-set-variants"].map((v) => v.variant.id),
    budgetId: os["budget-id"] ?? null,
    promotionIds: os["promotion-ids"],
  }));
  return mappedOrderSets;
};

export const mapOrderVariants = (variants) => {
  if (!variants || variants.length === 0) {
    return [];
  }
  const mappedVariants = variants.map((v) => ({
    id: v.id,
    name: v.variant?.item?.name,
    orderId: v.order.id,
    state: v.state ?? null,
    region: v.region ?? null,
    stateOrRegion: v.state || v.region || "",
    addressName: v["address-name"],
    streetAddressOne: v["street-address-1"],
    streetAddressTwo: v["street-address-2"],
    zip: v.zip,
    submittedAt: v["order-submitted-at"]
      ? format(new Date(v["order-submitted-at"]), "MM/dd/yyyy")
      : "",
    orderStatus: v["order-status"],
    orderType: v["order-type"],
    user: v["order-user-name"],
    program: v["program-name"] ?? "",
    shipDate: v["actual-ship-date"]
      ? formatDateString(v["actual-ship-date"])
      : "",
    cancelationNote: v["cancelation-note"],
    cancelationType: v["cancelation-type"],
    canceledCount: v["canceled-qty"],
    price: stringToCents(v["price"]),
    inMarketDate: v["in-market-date"]
      ? formatDateString(v["in-market-date"])
      : "",
    poInMarketDate: v["po-in-market-date"]
      ? formatDateString(v["po-in-market-date"])
      : null,
    poId: v["po-id"] ?? "",
    budgetId: v["budget-id"],
    isRush: v["is-rush"],
    count: v.qty,
    sku: v.variant["variant-sku"],
    warehouseId: v.variant["external-warehouse-id"],
    variantId: v.variant.id,
    status: v.status,
    actFreight: stringToCents(v["total-actual-shipping-cost"]),
    totalPrice: stringToCents(v["total-price"]),
    discount: stringToCents(v["promotion-discount"]),
    estFreight: stringToCents(v["total-estimated-shipping-cost"]),
    estTax: stringToCents(v["total-estimated-tax"]),
    trackingData: {
      number: v["tracking-data"]?.number ?? null,
      carrier: v["tracking-data"]?.carrier ?? null,
    },
    item: mapItems([v.variant.item])[0],
    orderPosition: v.variant["order-position"],
    variantNames:
      v.variant["selected-variant-options"].length > 0
        ? handleVariantNames(v.variant["selected-variant-options"])
        : null,
    variantOptionIds:
      v.variant["selected-variant-options"].length > 0
        ? v.variant["selected-variant-options"]
            .filter((v) => v.name?.length)
            .map((v) => v.id)
        : null,
    costCenterName: v["cost-center-name"],
    budget: v["budget-name"],
    userExternalId: v["order-user-external-id"],
    addressExternalId: v["address-external-id"],
    orderExpeditedDate: v["order-expedited-date"],
    orderCheckoutType: v["order-checkout-type"],
    promotionNames: v["promotion-names"],
  }));
  return mappedVariants;
};

export const mapSetVariants = (variants) => {
  const mappedVariants = variants.map((v) => ({
    id: v.id,
    variantId: v.variant.id,
    orderSetId: v["order-set"].id,
    item: mapItems([v.variant.item])[0],
    isCustomizable: v.variant.item["is-customizable"] ?? false,
    customizationDescription:
      v.variant.item["customization-description"] ?? null,
    customization: v.customization ?? null,
    count: 0,
    originalCount: 0,
    price:
      stringToCents(v.variant.item.price) + stringToCents(v.variant.upcharge),
    totalPrice: 0,
    onHand: v.variant["global-available-to-order-qty"],
    sku: v.variant["variant-sku"],
    warehouseId: v.variant["external-warehouse-id"],
    inMarketDate: v["in-market-date"]
      ? formatDateString(v["in-market-date"])
      : null,
    standardDeliveryDate: v["standard-delivery-date"]
      ? formatDateString(v["standard-delivery-date"])
      : null,
    isRush: v.isRush ? true : false,
    orderPosition: v.variant["order-position"],
    allocations: mapAllocations(v.variant["variant-allocations"]),
    variantNames:
      v.variant["selected-variant-options"].length > 0
        ? v.variant["selected-variant-options"]
            .filter((v) => v.name.length)
            .map((vs) => `${vs["variant-category"].name}: ${vs.name}`)
            .join(", ")
        : null,
    variantOptionIds:
      v.variant["selected-variant-options"].length > 0
        ? v.variant["selected-variant-options"]
            .filter((v) => v.name?.length)
            .map((v) => v.id)
        : null,

    imageUrl: v.variant.image
      ? cloudinary.url(v.variant.image["cloudinary-id"])
      : null,
  }));
  return mappedVariants;
};

export const mapOrders = (orders) => {
  if (!orders || orders.length === 0) {
    return [];
  }
  const mappedOrders = orders.map((order) => {
    const variants = mapOrderVariants(order["order-variants"]);

    return {
      id: order.id,
      address: {
        id: order.address.id,
        name: order.address.name,
        streetOne: order.address["street-address-1"],
        streetTwo: order.address["street-address-2"],
        city: order.address.city,
        state: order.address?.state?.code,
        region: order.address.region ?? null,
        stateOrRegion: order.address?.state?.code || order.address.region || "",
        zip: order.address.zip,
        country: order.address.country.name,
        phoneNumber: order.address["phone-number"],
      },
      attn: order.attn,
      notes: order.notes ?? "---",
      cancelationNote: handleCancelationNote(
        order["cancelation-type"],
        order["cancelation-note"]
      ),
      status: order.status,
      territory: order["territory-name"],
      territoryId: order["territory-id"],
      program: order["program-name"] ?? "",
      user: order.user.name,
      userEmail: order.user.email,
      userId: order.user.id,
      submittedBy: order["submitted-by"],
      submittedAt: order["submitted-at"]
        ? format(new Date(order["submitted-at"]), "MM/dd/yyyy")
        : "",
      approvedAt: order["approved-at"]
        ? format(new Date(order["approved-at"]), "MM/dd/yyyy")
        : "",
      type: order.type,
      checkoutType: order["checkout-type"] ?? null,
      actFreight: stringToCents(order["total-actual-shipping-cost"]),
      actTax:
        order["total-actual-tax"] && stringToCents(order["total-actual-tax"]),
      totalPrice: stringToCents(order["total-price"]),
      discount: stringToCents(order["total-promotion-discount"]),
      estFreight: stringToCents(order["total-estimated-shipping-cost"]),
      estTax: stringToCents(order["total-estimated-tax"]),
      count: order["total-quantity"],
      warehouse: order.warehouse,
      variants,
      costCenterName: order["cost-center-name"],
      budget: order["budget-names"].join(", "),
      userExternalId: order["user-external-id"],
      addressExternalId: order["address-external-id"],
      orderSetId: order["order-set-id"],
      isExpedited: order["is-expedited"],
      expeditedDate: order["expedited-date"],
      isForEvent: order["is-for-event"],
      promotionNames:
        variants.length > 0 && variants[0].promotionNames
          ? variants[0].promotionNames
          : null,
    };
  });
  return mappedOrders;
};

export const mapOrderSet = (orderSet, orders) => {
  return {
    id: orderSet.id,
    type: orderSet.type,
    territoryId: orderSet.territory.id,
    channelId: orderSet.channel?.id ?? null,
    programId: orderSet.program?.id ?? null,
    programOrderDescription: orderSet["program-order-description"] ?? null,
    budgetId: orderSet["budget-id"] ?? null,
    costCenter:
      orderSet["cost-center"] && mapCostCenter(orderSet["cost-center"]),
    orderWindowId: orderSet["order-window"]?.id ?? null,
    status: orderSet.status,
    orders: mapOrders(orders),
    setVariants: mapSetVariants(orderSet["order-set-variants"]),
    promotionIds: orderSet["promotion-ids"],
    totalDiscount: stringToCents(orderSet["total-promotion-discount"]),
    userName: orderSet.user.name,
    checkoutType: orderSet["checkout-type"] ?? null,
    programName: orderSet["program-name"],
    orderWindowName: orderSet["order-window-name"],
  };
};
