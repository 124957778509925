import React from "react";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const SessionExpiredModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const { loginWithPopup } = useAuth0();

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        style={{ zIndex: "15000" }}
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.centeredModal}>
            <Typography className={classes.headerText}>
              Your current session has expired, please login to continue
            </Typography>
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => {
                handleClose();
                loginWithPopup();
              }}
            >
              Login
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SessionExpiredModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SessionExpiredModal;
