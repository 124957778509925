/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Radio } from "@mui/material";

import { without } from "lodash";

import { orderTypeLabel, useOrderTypes } from "@features/ordering";
import { OrderType } from "@models";
import { ControlledRadioGroup } from "@utils/forms";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";

const OrderTypePopper = ({ hideOptions }: { hideOptions?: OrderType[] }) => {
  const { control } = useFilterContext();
  const orderTypes = useOrderTypes();
  let orderTypeOptions = Object.keys(orderTypes);

  if (hideOptions) orderTypeOptions = without(orderTypeOptions, ...hideOptions);

  return (
    <div tw="min-w-[200px]">
      <h4 tw="text-neutral-600 my-4 px-3">Order type is</h4>

      <ControlledRadioGroup tw="w-full" name="orderType" control={control}>
        {orderTypeOptions.map((key) => (
          <label
            key={key}
            tw="flex items-center px-3 border-t border-neutral-200 text-sm hover:bg-neutral-100 cursor-pointer"
          >
            <Radio size="small" value={key} edge="start" />
            {orderTypes[key]}
          </label>
        ))}
      </ControlledRadioGroup>
    </div>
  );
};

const OrderTypeFilterChip = (props) => {
  const { filterValues } = useFilterContext();
  const orderType = filterValues["orderType"];
  const orderTypeText = orderType && orderTypeLabel(orderType as OrderType);

  return (
    <Chip {...props}>
      <span>Order Type is&nbsp;</span>
      <b>{orderTypeText}</b>
    </Chip>
  );
};

const OrderTypeFilter = {
  Chip: OrderTypeFilterChip,
  Popper: OrderTypePopper,
};

export default OrderTypeFilter;
