/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

// import colors from "tailwindcss/colors";÷

const CircularProgressWithLabel = ({ value }) => {
  const [progress, setProgress] = useState(value);

  useEffect(() => {
    setProgress(value);
  }, [value]);

  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        tw="absolute top-0 left-0 opacity-10"
        variant="determinate"
        size={100}
        thickness={5}
        value={100}
        color="primary"
      />
      <CircularProgress
        variant="determinate"
        size={100}
        thickness={5}
        value={progress}
        color="secondary"
      />
      <div tw="absolute inset-0 flex justify-center items-center">
        <Typography tw="text-2xl font-bold text-neutral-700">{`${Math.round(
          value
        )}%`}</Typography>
      </div>
    </Box>
  );
};

export default CircularProgressWithLabel;
