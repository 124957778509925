/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useMatches } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";

const BreadcrumbTrail = () => {
  let matches = useMatches();
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing params to each one
    .map((match) => match.handle.crumb(match.params));

  return (
    <Breadcrumbs aria-label="breadcrumb" tw="text-sm text-neutral-600">
      {crumbs.map((crumb, index) => (
        <span key={index}>{crumb}</span>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbTrail;
