import React from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { id: "id", label: "Id" },
  { id: "name", label: "Name" },
  { id: "userNames", label: "Assigned Users" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  cellRoot: {
    minWidth: "500px",
  },
}));

const SupplierTable = ({
  handleSupplierClick,
  suppliers,
  isLoading,
  isModalOpen,
}) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: "400px" }}>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {(!isLoading || (isLoading && isModalOpen)) &&
            suppliers.length === 0 && (
              <TableRow>
                <TableCell align="left" colSpan={3}>
                  <Typography className={classes.headerText}>
                    {`There are currently no suppliers that match your search..`}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          {(!isLoading || (isLoading && isModalOpen)) &&
            suppliers.length > 0 &&
            suppliers.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => {
                  handleSupplierClick(row.id, "edit");
                }}
              >
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left" classes={{ root: classes.cellRoot }}>
                  {row.userNames}
                </TableCell>
              </TableRow>
            ))}
          {isLoading && !isModalOpen && (
            <TableRow>
              <TableCell align="left" colSpan={3}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SupplierTable.propTypes = {
  handleSupplierClick: PropTypes.func.isRequired,
  suppliers: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default SupplierTable;
