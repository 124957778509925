/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import TerritoryModal from "@components/Settings/Territories/TerritoryModal";
import TerritoryTable from "@components/Settings/Territories/TerritoryTable";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import { useTerritoriesListQuery } from "@features/territories";
import DocTitle from "@utility/DocTitle";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AdminTerritories = () => {
  const classes = useStyles();

  const [modal, handleModal] = useState(false);
  const [modalType, setModalType] = useState("edit");
  const [currentTerritoryId, setCurrentTerritoryId] = useState(null);

  const {
    data: territories,

    isLoading,
    isValidating,
    error,
  } = useTerritoriesListQuery({});

  const handleModalClose = () => {
    handleModal(false);
    setCurrentTerritoryId(null);
  };

  const handleModalOpen = (id, type) => {
    setCurrentTerritoryId(id);
    setModalType(type);
    handleModal(true);
  };

  return (
    <>
      <DocTitle title={"Territory Admin"} />
      <FixedHeightScrollLastChild>
        {modal && (
          <TerritoryModal
            open={modal}
            handleClose={handleModalClose}
            type={modalType}
            territory={territories?.find(
              (terr) => terr.id === currentTerritoryId
            )}
            isValidating={isValidating}
            isLoading={isLoading}
            error={error}
          />
        )}
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Territory Admin</Typography>

          <StyledButton cta onClick={() => handleModalOpen(null, "new")}>
            NEW TERRITORY
          </StyledButton>
        </div>
        <OpaqueCard tw="p-0 overflow-hidden">
          <TerritoryTable
            handleTerritoryClick={handleModalOpen}
            territories={territories}
            isLoading={isLoading}
          />
        </OpaqueCard>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default AdminTerritories;
