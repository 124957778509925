import { endOfDay } from "date-fns";

import { OrderWindow } from "../../models";
import { utcDate } from "../../utility/utilityFunctions";

export function isOrderWindowActive(orderWindow: OrderWindow) {
  const now = new Date();
  return (
    utcDate(orderWindow.openDate) < now &&
    endOfDay(utcDate(orderWindow.closeDate)) > now
  );
}
