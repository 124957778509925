import { memo, useState } from "react";
import { useSelector } from "react-redux";

import { Button, CircularProgress, Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import ChannelSection from "./ChannelSection";
import TerritorySection from "./TerritorySection";

const VisibilityPanel = ({
  classes,
  type,
  activeWindow,
  channel,
  setChannel,
  territories,
  setTerritories,
  programs,
  setPrograms,
  isUpdateLoading,
}) => {
  const [resetField, setResetField] = useState(false);

  const { territoryList } = useSelector((state) => state.territories);
  const {
    organization: { usesChannels },
  } = useSelector((state) => state.currentUser);

  const handleAddTerritory = (territory) => {
    setTerritories(territories.concat(territory));
    setResetField(true);
  };
  const handleAllTerritories = () => setTerritories([...territoryList]);

  const handleRemoveTerritory = (id) =>
    setTerritories(territories.filter((t) => t.id !== id));

  const handleAddChannel = (channel) => {
    setChannel(channel);
    setResetField(true);
  };

  const handleRemoveChannel = (_id) => setChannel(null);

  if (type === "edit" && !activeWindow) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      <Typography className={clsx(classes.bodyText, classes.settingsMargin)}>
        Control the visibility of the order window by assigning it to channels,
        and territories. Users will only be able to see order windows that share
        channels and territories that the user is assigned to.
      </Typography>
      <Typography
        className={clsx(classes.bodyText, classes.settingsMargin)}
        color="textSecondary"
      >
        <i>
          Note: Channels will only be visible if your organization has channels
          set up - This section is only editable if no programs have been added
          to the order window as the visibility of the window determines what
          programs can or cannot be added to it.
        </i>
      </Typography>
      {programs.length > 0 && (
        <div
          className={clsx(
            classes.fullWidthCenterColumn,
            classes.settingsMargin
          )}
        >
          <Typography
            className={clsx(classes.bodyText, classes.settingsMargin)}
          >
            To update visibility, you must first reset your current program
            assignments for this order window
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => setPrograms([])}
          >
            RESET PROGRAMS
          </Button>
        </div>
      )}
      {usesChannels && (
        <>
          <ChannelSection
            classes={classes}
            handleAddChannel={handleAddChannel}
            resetField={resetField}
            setResetField={setResetField}
            isUpdateLoading={isUpdateLoading}
            currentChannel={channel}
            handleRemoveChannel={handleRemoveChannel}
            currentItems={programs}
          />
          <br />
        </>
      )}
      <TerritorySection
        classes={classes}
        handleAddTerritory={handleAddTerritory}
        handleAllTerritories={handleAllTerritories}
        handleRemoveTerritory={handleRemoveTerritory}
        resetField={resetField}
        setResetField={setResetField}
        currentTerritories={territories}
      />
    </>
  );
};

VisibilityPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  activeWindow: PropTypes.object,
  channel: PropTypes.object,
  setChannel: PropTypes.func.isRequired,
  territories: PropTypes.array,
  setTerritories: PropTypes.func.isRequired,
  programs: PropTypes.array,
  setPrograms: PropTypes.func.isRequired,
  isUpdateLoading: PropTypes.bool.isRequired,
};

export default memo(VisibilityPanel);
