import { UploadInfo } from "cloudinary-core";

import client from "@services/api";

export const postImageToAPI = async (itemId: string, result: UploadInfo) => {
  return client.post("images", {
    itemId,
    cloudinaryId: result.public_id,
    originalUrl: result.secure_url,
    position: 0,
    type: "large",
  });
};
