import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

const SingleTerritoryAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  disabled,
  noMargin,
}) => {
  const [open, setOpen] = useState(false);
  const [territory, setTerritory] = useState("");
  const [currentTerritory, setCurrentTerritory] = useState("");

  const { territoryList: options } = useSelector((state) => state.territories);

  const handleTerritories = (value) => {
    setCurrentTerritory(value);
  };

  useEffect(() => {
    if (reset) {
      setTerritory("");
      setCurrentTerritory("");
      if (setReset) {
        setReset(false);
      }
    }
  }, [reset, setTerritory, setReset]);

  return (
    <>
      <Autocomplete
        autoHighlight
        fullWidth
        freeSolo
        className={!noMargin ? classes.settingsMargin : null}
        id="territory-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={territory}
        onInputChange={(_evt, value) => setTerritory(value)}
        onChange={(_evt, value) => {
          handleChange(value);
          handleTerritories(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name ?? ""}
        options={options}
        value={currentTerritory}
        disabled={disabled}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Territory"
            id="territory-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

SingleTerritoryAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SingleTerritoryAutoComplete;
