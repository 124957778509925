const imageSizeOptions = {
  thumbnail: {
    width: 100,
    height: 100,
    crop: "limit",
  },
  medium: {
    width: 300,
    height: 300,
    crop: "limit",
  },
  large: {
    width: 800,
    height: 800,
    crop: "limit",
  },
};

export type ImageSize = keyof typeof imageSizeOptions;

export default imageSizeOptions;
