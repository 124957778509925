import { intersectionBy } from "lodash";
import { OrderWindow, Program } from "../../models";

export function isOrderWindowAssignableToProgram(
  orderWindow: OrderWindow,
  program: Program
) {
  const res = {
    isAssignable: true,
    reason: "",
  };
  if (orderWindow.channel?.id !== program.channel?.id) {
    res.isAssignable = false;
    res.reason = "Conflicting channel visibility";
  }
  if (
    intersectionBy(orderWindow.territories, program.territories, "id")
      .length === 0
  ) {
    res.isAssignable = false;
    res.reason = "Conflicting territory visibility";
  }

  return res;
}
