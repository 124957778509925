import { useEffect } from "react";

import { useProgramListQuery } from "@features/programs/";

import { isStateCode } from "../components/Beacons/helpers";
import { buildFilters, useApiResourcePaginated } from "../services/api";
import useSetParams from "./useSetParams";

export const useBeaconParams = () =>
  useSetParams("/beacons/:programId/:view/:state/:variantSku");
// useSWR de-dups the request so this hook can be called at different places
// on the same page without sending 2 network requests

export const useBeaconProgram = (
  { programId, state, variantSku },
  groupBy = ""
) => {
  const { data, size, setSize, percentLoaded, ...others } =
    useApiResourcePaginated(`beacons/${programId}/${groupBy}`, {
      filter: buildFilters({
        states: isStateCode(state) || state === "Unspecified" ? state : null,
        variantSku,
      }),
    });

  useEffect(() => {
    // Load next page once previous page
    if (!groupBy && size === 1 && percentLoaded > 0) setSize(1 / percentLoaded);
  }, [groupBy, size, setSize, percentLoaded]);

  return {
    [groupBy || "beacons"]: data ?? [],
    ...others,
  };
};

export const useProgramsWithBeacons = () => {
  const { data, ...others } = useProgramListQuery({
    filter: { hasBeacons: true },
  });

  return {
    programs: data?.data ?? [],
    ...others,
  };
};
