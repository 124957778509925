/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { HelpOutlineRounded, InfoOutlined } from "@mui/icons-material";

import PaperTooltip from "./PaperTooltip";

type HelperTextLabelProps = {
  title?: string;
  children: React.ReactNode;
  icon?: "info" | "question";
};

const HelperTextLabel: React.FC<HelperTextLabelProps> = ({
  title,
  children,
  icon,
}) => {
  return (
    <PaperTooltip title={title} placement="top" arrow>
      <span tw="inline-flex items-center gap-1">
        <span tw="relative after:(absolute inset-0 w-full border-b border-dashed border-spacing-4 border-neutral-400  pointer-events-none)">
          {children}
        </span>
        {icon === "question" && (
          <HelpOutlineRounded tw="text-primary-600 text-xl" />
        )}
        {icon === "info" && <InfoOutlined tw="text-primary-600 text-xl" />}
      </span>
    </PaperTooltip>
  );
};

export default HelperTextLabel;
