import {
  IJsonPropertiesMapper,
  IModelPropertiesMapper,
  SwitchCaseJsonMapperOptionsType,
  SwitchCaseModelMapperOptionsType,
  TAnyKeyValueObject,
  TJsonaModel,
  TJsonaRelationships,
} from "jsona/lib/JsonaTypes";
import {
  JsonPropertiesMapper,
  ModelPropertiesMapper,
  RELATIONSHIP_NAMES_PROP,
} from "jsona/lib/simplePropertyMappers";
import { isPlainObject } from "jsona/lib/utils";
import _ from "lodash";

const MODEL_TYPE_KEY = "__type";

const transformAttributes = (
  transformer: Function | undefined,
  attributes: Record<string, any>
) => {
  if (!attributes) return attributes;

  if (typeof transformer === "function") {
    return transformer(attributes);
  }
  return attributes;
};

// Takes a camelCased object and converts to api request format
export class JsonApiModelMapper
  extends ModelPropertiesMapper
  implements IModelPropertiesMapper
{
  switchAttributes: boolean;
  switchRelationships: boolean;
  switchType: boolean;
  switchChar: string;
  regex: RegExp;

  transformers: Record<string, any>;

  constructor(transformers = {}, options?: SwitchCaseModelMapperOptionsType) {
    super();

    const {
      switchAttributes = true,
      switchRelationships = true,
      switchType = true,
      switchChar = "-",
    } = options || {};

    this.switchAttributes = switchAttributes;
    this.switchRelationships = switchRelationships;
    this.switchType = switchType;
    this.switchChar = switchChar;
    this.regex = new RegExp(/([a-z][A-Z0-9])/g);

    this.transformers = transformers;
  }

  getType(model: TJsonaModel) {
    const type = model[MODEL_TYPE_KEY];

    if (!this.switchType || !type) {
      return type;
    }

    return this.convertFromCamelCaseString(type);
  }

  getAttributes(model: TJsonaModel) {
    let exceptProps = ["id", MODEL_TYPE_KEY, RELATIONSHIP_NAMES_PROP];

    if (Array.isArray(model[RELATIONSHIP_NAMES_PROP])) {
      exceptProps.push(...model[RELATIONSHIP_NAMES_PROP]);
    } else if (model[RELATIONSHIP_NAMES_PROP]) {
      console.warn(
        `Can't getAttributes correctly, '${RELATIONSHIP_NAMES_PROP}' property of ${model.type}-${model.id} model
                isn't array of relationship names`,
        model[RELATIONSHIP_NAMES_PROP]
      );
    }

    let camelCasedAttributes = _.omit(model, exceptProps) as TAnyKeyValueObject;

    camelCasedAttributes = transformAttributes(
      this.transformers[this.getType(model)],
      camelCasedAttributes
    );

    if (!this.switchAttributes || !camelCasedAttributes) {
      return camelCasedAttributes;
    }

    return this.convertFromCamelCase(camelCasedAttributes);
  }

  getRelationships(model: TJsonaModel) {
    const camelCasedRelationships = super.getRelationships(model);

    if (!this.switchRelationships || !camelCasedRelationships) {
      return camelCasedRelationships;
    }

    return this.convertFromCamelCase(camelCasedRelationships);
  }

  private convertFromCamelCase(stuff: unknown) {
    if (Array.isArray(stuff)) {
      return stuff.map((item) => this.convertFromCamelCase(item));
    }

    if (isPlainObject(stuff)) {
      const converted = {};
      Object.entries(stuff).forEach(([propName, value]) => {
        const kebabName = this.convertFromCamelCaseString(propName);
        converted[kebabName] = this.convertFromCamelCase(value);
      });
      return converted;
    }

    return stuff;
  }

  private convertFromCamelCaseString(camelCaseString: string) {
    return camelCaseString.replace(
      this.regex,
      (g) => g[0] + this.switchChar + g[1].toLowerCase()
    );
  }
}

// Receives a JSON:API response and converts it to a camelCased object
export class JsonApiJsonMapper
  extends JsonPropertiesMapper
  implements IJsonPropertiesMapper
{
  camelizeAttributes: boolean;
  camelizeRelationships: boolean;
  camelizeType: boolean;
  camelizeMeta: boolean;
  switchChar: string;
  regex: RegExp;

  transformers: Record<string, any>;

  constructor(transformers = {}, options?: SwitchCaseJsonMapperOptionsType) {
    super();

    const {
      camelizeAttributes = true,
      camelizeRelationships = true,
      camelizeType = true,
      camelizeMeta = false,
      switchChar = "-",
    } = options || {};

    this.camelizeAttributes = camelizeAttributes;
    this.camelizeRelationships = camelizeRelationships;
    this.camelizeType = camelizeType;
    this.camelizeMeta = camelizeMeta;
    this.switchChar = switchChar;
    this.regex = new RegExp(`${this.switchChar}([a-z0-9])`, "g");

    this.transformers = transformers;
  }

  createModel(type: string): TJsonaModel {
    if (!this.camelizeType) {
      return { [MODEL_TYPE_KEY]: type };
    }

    const camelizedType = this.convertToCamelCaseString(type);
    return { [MODEL_TYPE_KEY]: camelizedType };
  }

  setAttributes(model: TJsonaModel, attributes: TAnyKeyValueObject) {
    let attributesWithModelType = attributes;
    const transformedAttributes = transformAttributes(
      this.transformers[model[MODEL_TYPE_KEY]],
      this.convertToCamelCase(attributesWithModelType)
    );

    if (!this.camelizeAttributes) {
      return super.setAttributes(model, transformedAttributes);
    }

    Object.assign(model, transformedAttributes);
  }

  setMeta(model: TJsonaModel, meta: TAnyKeyValueObject) {
    if (!this.camelizeMeta) {
      return super.setMeta(model, meta);
    }

    model.meta = this.convertToCamelCase(meta);
  }

  setRelationships(model: TJsonaModel, relationships: TJsonaRelationships) {
    // call super.setRelationships first, just for not to copy paste setRelationships logic
    super.setRelationships(model, relationships);

    if (!this.camelizeRelationships) {
      return;
    }

    // then change relationship names case if needed
    model[RELATIONSHIP_NAMES_PROP].forEach((kebabName, i) => {
      const camelName = this.convertToCamelCaseString(kebabName);
      if (camelName !== kebabName) {
        model[camelName] = model[kebabName];
        delete model[kebabName];
        model[RELATIONSHIP_NAMES_PROP][i] = camelName;
      }
    });
  }

  private convertToCamelCase(stuff: unknown) {
    if (Array.isArray(stuff)) {
      return stuff.map((item) => this.convertToCamelCase(item));
    }

    if (isPlainObject(stuff)) {
      const converted = {};
      Object.entries(stuff).forEach(([propName, value]) => {
        const camelName = this.convertToCamelCaseString(propName);
        converted[camelName] = this.convertToCamelCase(value);
      });
      return converted;
    }

    return stuff;
  }

  convertToCamelCaseString(notCamelCaseString: string) {
    return notCamelCaseString.replace(this.regex, (g) => g[1].toUpperCase());
  }
}
