/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useWatch } from "react-hook-form";
import { Link } from "react-router-dom";

import { sortBy } from "lodash";

import { ResourceError } from "@features/errors";
import { isOrderWindowActive } from "@features/orderWindows";
import { OrderWindow, Program } from "@models";
import { formatUtcDate, utcDate } from "@utility/utilityFunctions";

import BlockCard from "../../../ui/BlockCard";
import { useItemPrograms } from "../../helpers";

const today = new Date();
today.setHours(0, 0, 0, 0);

const ProgramCard = ({ program }: { program: Program }) => {
  const sortedOrderWindows = sortBy(program.orderWindows, "openDate");
  const activeOrderWindow = sortedOrderWindows.find(isOrderWindowActive);
  const nextUpcomingOw = sortedOrderWindows.find(
    (ow) => utcDate(ow.openDate) > today
  );
  const lastOw = sortedOrderWindows
    .reverse()
    .find((ow) => utcDate(ow.closeDate) < today);

  const displayOw: [string, OrderWindow] | null = activeOrderWindow
    ? ["Active", activeOrderWindow]
    : nextUpcomingOw
    ? ["Coming up", nextUpcomingOw]
    : lastOw
    ? ["Last Available", lastOw]
    : null;

  const isActive = program.status === "complete" && activeOrderWindow;

  return (
    <div
      key={program.id}
      tw="
        hover:bg-neutral-50 transition-colors rounded py-1.5 px-3 -mx-3 
        first-of-type:-mt-1.5 last:-mb-1.5"
    >
      <div tw="leading-snug">
        <Link
          to={`/admin/programs/${program.id}`}
          target="_blank"
          tw="hover:underline text-neutral-800"
        >
          {program.name}
        </Link>
      </div>
      {program.status === "draft" && (
        <span tw="text-xs tracking-wide rounded px-1 py-0.5 bg-neutral-100 text-neutral-700">
          DRAFT
        </span>
      )}
      {program.status === "complete" && displayOw && (
        <div tw="text-sm text-neutral-500 mt-0.5">
          <span css={[isActive && tw`font-medium text-primary-600`]}>
            {displayOw[0]}
          </span>{" "}
          {formatUtcDate(displayOw[1].openDate, "MMM d")} -{" "}
          {formatUtcDate(displayOw[1].closeDate, "PP")}
        </div>
      )}
    </div>
  );
};

const Programs = () => {
  const id = useWatch({ name: "id" });
  const { data, error, isLoading } = useItemPrograms(id);

  // Sort by whether the program has an active order window or not
  const programs = sortBy(data, (p) =>
    p.orderWindows.find(isOrderWindowActive) ? 0 : 1
  );

  return (
    <BlockCard title="Programs" tw="flex flex-col">
      {isLoading && <div>Loading...</div>}
      {error && <ResourceError error={error} />}
      {programs.length === 0 && (
        <p tw="text-neutral-600 font-light">
          This item doesn't appear on any programs.
        </p>
      )}
      {programs.map((program) => (
        <ProgramCard key={program.id} program={program} />
      ))}
    </BlockCard>
  );
};

export default Programs;
