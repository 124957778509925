/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { DeleteRounded } from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { sortBy } from "lodash";

import { StyledButton } from "@components/StyledComponents";
import { Item } from "@models/Item";
import {
  ControlledTextInput as TextInput,
  intValidation,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";

const TieredPricingTable = ({ item }: { item: Item }) => {
  const {
    setValue,
    getValues,
    control,
    formState: { isDirty },
    resetField,
  } = useFormContext();

  const controlled = (name: string, rules?: any) => ({ name, control, rules });

  const [variablePricingState, setVariablePricingState] = useState<any[]>([]);

  const getActiveVariablePricing = useCallback(
    (data?: any[] | null) =>
      sortBy(
        [...(data ?? getValues("variablePricing"))].flatMap((vp, i) =>
          // Saved Index represent the original index of the vp, before sorting
          vp.shouldDelete ? [] : [{ ...vp, savedIndex: i }]
        ),
        (i) =>
          [null, undefined].includes(i?.maxQuantity) ? Infinity : +i.maxQuantity
      ),
    [getValues]
  );

  const updateVariablePricingState = () => {
    setVariablePricingState(getActiveVariablePricing());
  };
  const handleAddTier = (maxQuantity?: number | null) => {
    const vps = getActiveVariablePricing();
    const allVps = getValues("variablePricing");
    const qty =
      maxQuantity !== undefined
        ? maxQuantity
        : (+vps.at(-2)?.maxQuantity || 0) + 100;

    // See if there's a vp set to be deleted we can use instead
    const deletedIndex = allVps.findIndex((vp) => vp.shouldDelete);
    const deletedVP = allVps[deletedIndex];
    const vpIndex = deletedVP ? deletedIndex : vps.length;

    setValue(
      `variablePricing.${vpIndex}`,
      {
        id: deletedVP?.id,
        maxQuantity: qty?.toString(),
        cost: (+vps.at(-2)?.cost || item.cost).toFixed(2),
        price: (+vps.at(-2)?.price || item.price).toFixed(2),
      },
      { shouldDirty: true }
    );
    updateVariablePricingState();
  };

  const handleDelete = (vp) => {
    if (vp.id) {
      setValue(
        `variablePricing.${vp.savedIndex}`,
        { id: vp.id, shouldDelete: true },
        { shouldDirty: true }
      );
    } else {
      const vps = [...getValues("variablePricing")];
      vps.splice(vp.savedIndex, 1);
      setValue("variablePricing", vps, { shouldDirty: true });
    }
    updateVariablePricingState();
  };

  useEffect(() => {
    const activeVariablePricing = getActiveVariablePricing();
    updateVariablePricingState();
    if (activeVariablePricing.length === 0) {
      handleAddTier(null);
      handleAddTier(100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isDirty) return;
    // reset the variablePricingState
    // for some reason, the form-state won't change if we don't call this
    resetField("variablePricing");
    let vps = getActiveVariablePricing();
    setVariablePricingState(vps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.variablePricing, isDirty]);

  if (variablePricingState.length === 0) return null;

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Max Quantity</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {variablePricingState.map((vp) => (
              <TableRow key={vp.savedIndex}>
                <TableCell>
                  {vp.maxQuantity === null || vp.maxQuantity === undefined ? (
                    <span tw="flex-1 whitespace-nowrap text-base">
                      Over {variablePricingState.at(-2)?.maxQuantity || 0}:
                    </span>
                  ) : (
                    <TextInput
                      {...controlled(
                        `variablePricing.${vp.savedIndex}.maxQuantity`,
                        intValidation
                      )}
                      onBlur={() => updateVariablePricingState()}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <TextInput
                    {...moneyAdornment}
                    {...controlled(
                      `variablePricing.${vp.savedIndex}.price`,
                      moneyValidation
                    )}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    {...moneyAdornment}
                    {...controlled(
                      `variablePricing.${vp.savedIndex}.cost`,
                      moneyValidation
                    )}
                  />
                </TableCell>
                <TableCell tw="px-0">
                  {![null, undefined].includes(vp.maxQuantity) && (
                    <IconButton title="Delete" onClick={() => handleDelete(vp)}>
                      <DeleteRounded />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledButton tw="block" cta onClick={() => handleAddTier()}>
        Add Tier
      </StyledButton>
    </>
  );
};

export default TieredPricingTable;
