import { useMutation, useQueryClient } from "@tanstack/react-query";

import { RequestForQuote } from "@models/RequestForQuote";
import client from "@services/api";
import { BodyWithId } from "@utils/reactQuery";

import { rfqsKeyFactory } from "./RfqQueries";

export const useCancelRfqMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) =>
      client
        .post<RequestForQuote>(`request-for-quotes/${id}/cancel`, {})
        .then((res) => res.data),
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: rfqsKeyFactory.list._def });
      const listQueriesSnapshot = queryClient.getQueriesData<RequestForQuote[]>(
        {
          queryKey: rfqsKeyFactory.list._def,
        }
      );

      queryClient.setQueriesData<RequestForQuote[]>(
        {
          queryKey: rfqsKeyFactory.list._def,
        },
        (rfqs) =>
          rfqs?.map((rfq) =>
            rfq.id === id ? { ...rfq, status: "canceled" } : rfq
          )
      );

      return () => {
        listQueriesSnapshot.forEach(([key, rfqs]) => {
          queryClient.setQueryData<RequestForQuote[]>(key, rfqs);
        });
      };
    },
    onError: (_, __, rollback) => rollback?.(),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: rfqsKeyFactory.list._def });
    },
  });
};

export const useUpdateRfqMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: BodyWithId) =>
      client
        .put<RequestForQuote>(`request-for-quotes/${id}`, data)
        .then((res) => res.data),
    onSuccess: (rfq) => {
      queryClient.invalidateQueries({ queryKey: rfqsKeyFactory.list._def });
      queryClient.setQueryData<RequestForQuote>(
        rfqsKeyFactory.detail(rfq.id).queryKey,
        rfq
      );
    },
  });
};

export const useSendRfqMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, supplierIds }: { id: string; supplierIds: number[] }) =>
      client
        .post<RequestForQuote>(
          `request-for-quotes/${id}/send`,
          { data: { "supplier-ids": supplierIds } },
          { serializeBody: false }
        )
        .then((res) => res.data),
    onSettled: (_, __, { id }) => {
      queryClient.invalidateQueries({ queryKey: rfqsKeyFactory.list._def });
      queryClient.invalidateQueries({
        queryKey: rfqsKeyFactory.detail(id).queryKey,
      });
    },
  });
};

export const useCreateRfqMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (itemId: string) =>
      client
        .post<RequestForQuote>("request-for-quotes", { itemId })
        .then((res) => res.data),
    onSuccess: (rfq) => {
      queryClient.invalidateQueries({ queryKey: rfqsKeyFactory.list._def });
      queryClient.setQueryData<RequestForQuote>(
        rfqsKeyFactory.detail(rfq.id).queryKey,
        rfq
      );
    },
  });
};

export const useCopyRfqMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (rfqId: string) =>
      client
        .post<RequestForQuote>(`request-for-quotes/${rfqId}/copy`, {})
        .then((res) => res.data),
    onSuccess: (rfq) => {
      queryClient.invalidateQueries({ queryKey: rfqsKeyFactory.list._def });
      queryClient.setQueryData<RequestForQuote>(
        rfqsKeyFactory.detail(rfq.id).queryKey,
        rfq
      );
    },
  });
};
