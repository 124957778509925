import React from "react";

import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { useInput } from "../../hooks/inputs/useInput";

const MemoTextField = React.memo(
  ({
    value,
    setValue,
    formKey,
    label,
    disabled,
    fullWidth,
    componentClass,
  }) => {
    const { value: input, bind: bindInput } = useInput(value || "");

    return (
      <TextField
        size="small"
        fullWidth={fullWidth}
        className={componentClass}
        variant="outlined"
        color="secondary"
        name={label}
        label={label}
        {...bindInput}
        disabled={disabled}
        onBlur={() => setValue(formKey, input)}
      />
    );
  }
);

MemoTextField.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  formKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool.isRequired,
  componentClass: PropTypes.any.isRequired,
};

export default MemoTextField;
