import { mapCountries } from "../user/maps";

export const mapTerritories = (terrArray, orgId) => {
  return terrArray
    ?.map((terr) => ({
      name: terr.name,
      id: terr.id,
      stateCodes: terr.states
        .map((s) => s.code)
        .sort()
        .join(", "),
      states: terr.states,
      countries: mapCountries(terr.countries),
      orgId: terr.organization.id,
      allowStandardCheckout: terr["allow-standard-checkout"],
      allowBudgetCheckout: terr["allow-budget-checkout"],
      allowStripeCheckout: terr["allow-stripe-checkout"],
    }))
    .filter((terr) => (orgId ? terr.orgId === orgId : true))
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
};
