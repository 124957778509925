/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { saveAs } from "file-saver";
import useSWR from "swr";

import client from "@services/api";
import { formatCsvBlob } from "@utils/csv";

import ReportRow from "./ReportRow";
import { setReportStatus } from "./globalReportSlice";
import { RStatus, ReportStatus, TReportState } from "./types";

type CsvReportDownloadProps = {
  report: TReportState;
};

const CsvReportDownload = ({ report }: CsvReportDownloadProps) => {
  const fullFileName = report.filename + ".csv";
  const dispatch = useDispatch();

  const { data, error } = useSWR(report.url, (key) =>
    client.get(key, { deserializeOutput: false })
  );

  const setStatus = useCallback(
    (status: RStatus) => dispatch(setReportStatus({ id: report.id, status })),
    [dispatch, report.id]
  );

  const onDownload = async () => {
    if (!data) return;
    const csvBlob = formatCsvBlob(data);
    saveAs(csvBlob, fullFileName);
    setStatus(ReportStatus.DOWNLOADED);
  };

  useEffect(() => {
    if (error) setStatus(ReportStatus.FAILED);
    if (data) setStatus(ReportStatus.COMPLETE);
  }, [data, error, setStatus]);

  useEffect(() => {}, [error, setStatus]);

  return (
    <ReportRow
      filename={fullFileName}
      report={report}
      error={error}
      percentLoaded={report.status === ReportStatus.LOADING ? 0.5 : 1}
      onDownload={onDownload}
    />
  );
};

export default CsvReportDownload;
