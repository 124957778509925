import _ from "lodash";

const asyncPool = async <T, R>(
  poolLimit: number,
  iterable: T[],
  iteratorFn: (item: T, iterable: T[]) => Promise<R> | R,
  onPromiseComplete?: (R) => void
): Promise<{ results: R[]; errors?: Error[] }> => {
  const ret: Promise<R>[] = []; // Array to store promises' results
  const executing = new Set<Promise<R>>(); // Set to track executing promises

  for (const item of iterable) {
    const p = Promise.resolve().then(() => iteratorFn(item, iterable)); // Create a promise for each item

    ret.push(p);
    executing.add(p); // Add the promise to the executing set

    const clean = () => {
      executing.delete(p); // Cleanup function to remove the promise from the executing set
    };

    p.catch(_.noop)
      .then((r) => onPromiseComplete?.(r))
      .finally(clean);

    if (executing.size >= poolLimit) {
      await Promise.race(executing).catch(_.noop); // Wait for the fastest executing promise if poolLimit is reached
    }
  }

  const settled = await Promise.allSettled(ret); // Wait for all promises to settle
  const results = _(settled)
    .groupBy("status")
    .mapValues((v) => _.map(v, (r: any) => r.value ?? r.reason))
    .value();

  return { results: results.fulfilled ?? [], errors: results.rejected }; // Return an object with results and errors
};

export default asyncPool;
