import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Add, Remove } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import PropTypes from "prop-types";

import {
  createGroup,
  createGroupCategory,
  setHasUpdated,
  updateGroupCategory,
} from "@redux/slices/groupCategories/groupCategorySlice";
import { ControlledCheckboxInput, ControlledSelectInput } from "@utils/forms";

import { StyledButton } from "../../StyledComponents";
import CategoryGroupList from "./CategoryGroupList";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  controlledTextField: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

const getFormData = (data) => {
  return {
    name: "",
    categoryType: "",
    isRequired: false,
    limitsAvailability: false,
    priorityLevel: 1,
    groups: [],
    ...data,
  };
};

const GroupCategoryModal = ({ open, handleClose, type, setType, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: getFormData({}),
  });

  const { isUpdateLoading, hasUpdated, error, categories } = useSelector(
    (state) => state.groupCategories
  );

  const [newGroups, setNewGroups] = useState([]);
  const [localError, setLocalError] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  const groupValue = watch("group");
  const groupsValue = watch("groups");
  const priority = watch("priorityLevel");

  const onSubmit = (data) => {
    if (data.name.length === 0) {
      return setLocalError("You must input a name for the Category");
    }

    if (type === "new" ? newGroups.length === 0 : groupsValue.length === 0) {
      return setError("group", {
        type: "manual",
        message: "At least one group is required",
      });
    }

    let groupsWithUpdatedNames = [];
    if (currentCategory) {
      groupsWithUpdatedNames = currentCategory.groups.map((g, index) => ({
        ...g,
        name: data.groups[index].name,
      }));
    }

    const modifiedGroups = groupsWithUpdatedNames.filter((g, index) => {
      return g.name !== currentCategory.groups[index].name;
    });

    const attributes = {
      name: data.name,
      type: data.categoryType,
      priorityLevel: priority,
      isRequired: data.isRequired,
      limitsAvailability: data.limitsAvailability,
      groups: type === "new" ? newGroups : modifiedGroups,
    };
    setLocalError(null);

    dispatch(
      type === "edit"
        ? updateGroupCategory(currentCategory.id, attributes)
        : createGroupCategory(attributes)
    );
  };

  const handleAddGroup = (event) => {
    event.preventDefault();
    if (groupValue && groupValue.trim() !== "") {
      let newGroupArr = [];
      if (type === "new") {
        newGroupArr = [...newGroups, { name: groupValue.trim() }];
        setNewGroups(newGroupArr);
      } else {
        const newGroupObj = {
          name: groupValue.trim(),
          groupCategoryId: currentCategory.id,
        };
        dispatch(createGroup(newGroupObj));
      }
      setValue("group", ""); // Resetting the group field
    }
  };

  const sortAndFilterPriorities = () => {
    let priorityList = categories
      .map((c) => c.priorityLevel)
      .sort((a, b) => a - b);
    if (currentCategory) {
      priorityList = priorityList.filter(
        (p) => p !== currentCategory.priorityLevel
      );
    }
    return priorityList;
  };

  const handleSubPriority = () => {
    let priorityList = sortAndFilterPriorities();
    // Iterate through the array in reverse order
    for (let i = priorityList.length; i >= 0; i--) {
      // Check if one less than the current priority exists in the array
      if (!priorityList.includes(priority - 1)) {
        if (priority - 1 === 0) {
          setValue("priorityLevel", 1);
        } else {
          setValue("priorityLevel", priority - 1);
        }
        return;
      } else {
        // Find the next missing priority below the current one
        let nextMissingPriority = priority - 2;
        while (priorityList.includes(nextMissingPriority)) {
          nextMissingPriority--;
        }
        if (nextMissingPriority !== 0) {
          setValue("priorityLevel", nextMissingPriority);
        }
        return;
      }
    }
  };

  const handleAddPriority = () => {
    let priorityList = sortAndFilterPriorities();
    // If the current priority is larger than any other priority, set it to one more than it
    if (priority >= priorityList[priorityList.length - 1]) {
      setValue("priorityLevel", priority + 1);
      return;
    }
    // Find the next available gap in priorities
    for (let i = 0; i < priorityList.length - 1; i++) {
      if (
        priority < priorityList[i] &&
        priorityList[i] + 1 !== priorityList[i + 1] &&
        priorityList[i] + 1 !== priority
      ) {
        setValue("priorityLevel", priorityList[i] + 1);
        return;
      }
    }

    // If no gaps are found, set it to one higher than the highest priority
    setValue("priorityLevel", priorityList[priorityList.length - 1] + 1);
  };

  const handleKeyPressOnGroupField = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddGroup(event);
    }
  };

  useEffect(() => {
    if (error) {
      setLocalError(error);
    }
  }, [error]);

  useEffect(() => {
    if (type === "new") {
      const priorityLevels = sortAndFilterPriorities();
      const newPriorityLevel =
        priorityLevels.length > 0
          ? priorityLevels[priorityLevels.length - 1] + 1
          : 1;
      setValue("priorityLevel", newPriorityLevel);
    }
    dispatch(setHasUpdated({ value: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === "edit" && id) {
      const categoryToEdit = categories.find((c) => c.id === id);
      if (categoryToEdit) {
        reset(
          getFormData({ ...categoryToEdit, categoryType: categoryToEdit.type })
        );
        setCurrentCategory(categoryToEdit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id, categories]);

  useEffect(() => {
    if (hasUpdated && type === "new") {
      setType("edit");
      const categoryToEdit = categories.find(
        (c) => c.name === getValues("name")
      );
      setCurrentCategory(categoryToEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdated, type]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          reset();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {type === "edit" ? `Editing ${watch("name")}` : "New Category"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              reset();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <div className={classes.fullWidthCenterColumn}>
              <TextField
                size="small"
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                label="Name"
                {...register("name", { required: "Name is required" })}
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                style={{ marginTop: 6 }}
              />

              <Typography className={classes.headerText}>
                Category Options
              </Typography>

              <ControlledSelectInput
                control={control}
                name="categoryType"
                options={["Organization", "Item"].map((option) => ({
                  id: option.toLowerCase(),
                  name: option,
                }))}
                label="Type"
                rules={{ required: "Category type is required" }}
              />

              <br />
              <Typography
                className={clsx(classes.settingsMargin, classes.headerText)}
              >
                Priority Level:
              </Typography>
              <Typography className={classes.bodyText} color="textSecondary">
                <em>* Priority Level must be unique by category type</em>
              </Typography>
              <br />
              <ButtonGroup
                variant="contained"
                color="secondary"
                aria-label="priority selector"
              >
                <Button
                  className={classes.button}
                  onClick={() => handleSubPriority()}
                >
                  <Remove />
                </Button>
                <Button variant="outlined">{priority}</Button>
                <Button
                  className={classes.button}
                  onClick={() => handleAddPriority()}
                >
                  <Add />
                </Button>
              </ButtonGroup>
              <br />
              <FormGroup>
                <ControlledCheckboxInput
                  control={control}
                  name="isRequired"
                  label="Is Required"
                />
                <ControlledCheckboxInput
                  control={control}
                  name="limitsAvailability"
                  label="Limits Availability"
                />
              </FormGroup>
              <br />
              <Typography className={classes.headerText}>
                Category Groups
              </Typography>

              <div className={classes.controlledTextField}>
                <TextField
                  size="small"
                  variant="outlined"
                  color="secondary"
                  label="Add Group"
                  {...register("group")}
                  fullWidth
                  error={!!errors.group}
                  helperText={errors.group ? errors.group.message : ""}
                  onKeyDown={handleKeyPressOnGroupField}
                />
                <IconButton
                  onClick={handleAddGroup}
                  disabled={!groupValue}
                  size="large"
                >
                  <AddIcon fontSize="large" color="secondary" />
                </IconButton>
              </div>

              <CategoryGroupList
                id={currentCategory?.id}
                register={register}
                newCategoryGroups={type === "new" ? newGroups : undefined}
              />

              <StyledButton
                cta
                type="submit"
                style={{
                  float: "right",
                  marginBottom: "20px",
                  minWidth: "62px",
                }}
              >
                {isUpdateLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "SUBMIT"
                )}
              </StyledButton>
              {!isUpdateLoading && localError && (
                <Typography
                  className={classes.bodyText}
                  style={{ color: "#920000" }}
                >
                  {localError}
                </Typography>
              )}
              {!isUpdateLoading && hasUpdated && (
                <Typography className={classes.bodyText}>
                  {type === "edit"
                    ? "Update Successful!"
                    : "New Category Added Successfully!"}
                </Typography>
              )}
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

GroupCategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  categories: PropTypes.array,
};

export default GroupCategoryModal;
