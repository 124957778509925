/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { TextInput } from "@utils/forms";

type DenyOrderModalProps = {
  handleClose: () => void;
  handleDeny: (id: string, reason: string) => Promise<void>;
  orderId: string;
};

const DenyOrderModal: React.FC<DenyOrderModalProps> = ({
  handleClose,
  handleDeny,
  orderId,
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");

  const handleSubmit = async () => {
    if (!reason) return;
    setLoading(true);
    await handleDeny(orderId, reason);
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
    >
      <DialogTitle tw="flex justify-between items-start">
        Deny Order
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h3 tw="text-lg font-medium">
          Please provide a reason for denying order #{orderId}
        </h3>

        <TextInput
          tw="mt-3"
          autoFocus
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton
          role="button"
          outlined
          disabled={loading}
          onClick={handleClose}
        >
          Cancel
        </StyledButton>
        <StyledButton
          cta
          role="submit"
          disabled={reason.length === 0}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DenyOrderModal;
