import { useEffect, useState } from "react";

import { Tooltip } from "@mui/material";

import { format, formatDistance } from "date-fns";

// a component that updates every minute to show how long ago `date` was
const DateFromNow = ({ date }: { date: Date }) => {
  const [_, setTick] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setTick((t) => t + 1);
    }, 60_000);
    return () => clearInterval(timer);
  }, []);
  return (
    <Tooltip title={format(date, "PPpp")}>
      <span>
        {formatDistance(date, new Date(), { addSuffix: true }).replace(
          "about ",
          "~"
        )}
      </span>
    </Tooltip>
  );
};

export default DateFromNow;
