import { formatDateString } from "@utility/utilityFunctions";

import { mapChannels } from "../channelSlice";
import { mapPrograms } from "../programs/maps";
import { mapTerritories } from "../territories/maps";

export const mapOrderWindow = (orderWindow, programs) => {
  return {
    id: orderWindow.id,
    name: orderWindow.name,
    openDate: formatDateString(orderWindow["open-date"]),
    closeDate: formatDateString(orderWindow["close-date"]),
    reviewCloseDate: formatDateString(orderWindow["review-close-date"]),
    inMarketDate: formatDateString(orderWindow["in-market-date"]),
    channel: orderWindow.channel ? mapChannels([orderWindow.channel])[0] : null,
    territories: mapTerritories(orderWindow.territories),
    programs: programs ? mapPrograms(programs) : [],
  };
};

export const mapOrderWindows = (orderWindows) => {
  const mappedOrderWindows = orderWindows.map((ow) => mapOrderWindow(ow));
  return mappedOrderWindows;
};
