/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useDispatch } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";

import { Item } from "@models/Item";
import { setItemPreview } from "@redux/slices/items/itemSlice";
import { CldImage } from "@services/cloudinary";
import { ImageSize } from "@services/cloudinary";

import { itemsKeyFactory } from "../data";
import { getItemPreviewImage } from "../getItemPreviewImage";

const ItemPreviewImageButton: React.FC<
  {
    item: Item;
    imageSize?: ImageSize;
    imageId?: string | number | null;
  } & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ item, imageSize, imageId, ...props }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  return (
    <button
      tw="flex-shrink-0 w-20 relative rounded-md cursor-pointer overflow-hidden
          after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
          hover:after:opacity-20"
      onClick={(e) => {
        e.stopPropagation();
        queryClient.setQueryData<Item>(
          itemsKeyFactory.detail(item.id).queryKey,
          item
        );
        dispatch(setItemPreview({ id: item.id, focusImageId: imageId }));
      }}
      {...props}
    >
      <CldImage
        tw="w-full aspect-square object-contain 
                  border-2 border-neutral-200
                bg-white rounded-md"
        image={getItemPreviewImage(item, imageId)}
        size={imageSize ?? "thumbnail"}
      />
    </button>
  );
};

export default ItemPreviewImageButton;
