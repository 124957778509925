import { axiosGetWithNext } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";
import { query } from "@services/api";

import { setError } from "../errorSlice";
import { mapUsers } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  userList: [],
  allUserList: [],
  nextLink: null,
  error: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    getUsersSuccess(state, action) {
      const { users, nextLink } = action.payload;
      state.nextLink = nextLink;
      state.userList = [...users];
      state.isLoading = false;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.error = error;
    },
  },
});

export const { setIsLoading, setIsNextLoading, getUsersSuccess, setFailure } =
  usersSlice.actions;

export default usersSlice.reducer;

export const fetchFilteredUsers =
  (name, status = "active", roles = [], territoryId = null, channelId = null) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      let queryString =
        "/api/users?" +
        query({
          filter: {
            status: status,
            nameOrEmail: name,
            roles: roles.length > 0 ? roles : null,
            territoryIds: territoryId && [territoryId],
            channelIds: channelId && [channelId],
          },
        });
      const response = await axiosGetWithNext(queryString);
      if (response.error) {
        throw response.error;
      }
      const mappedData = mapUsers(response.data.data);
      dispatch(
        getUsersSuccess({
          users: mappedData,
          nextLink: response.data.nextLink ? response.data.nextLink : null,
        })
      );
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "User Update" }));
    }
  };
