import _ from "lodash";

import { Budget } from "@models/Budget";

export type BudgetFormValues = {
  name: string;
  isActive: boolean;
  startDate: string | null;
  expirationDate: string | null;
  totalAmount: string;
  users: string[];
  territories: string[];
  groups: string[];
};

const defaultValues: BudgetFormValues = {
  name: "",
  isActive: false,
  startDate: null,
  expirationDate: null,
  totalAmount: "",
  users: [],
  territories: [],
  groups: [],
};

const stripValues = (entities: any[]): string[] => _.map(entities, "id");

const formValuesFromBudget = (budget: Budget | undefined) => {
  if (!budget) return defaultValues;
  return {
    name: budget.name,
    isActive: budget.isActive,
    startDate: budget.startDate,
    expirationDate: budget.expirationDate,
    totalAmount: Number(budget.totalAmount).toFixed(2),
    users: stripValues(budget.users),
    territories: stripValues(budget.territories),
    groups: stripValues(budget.groups),
  };
};

export default formValuesFromBudget;
