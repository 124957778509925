import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { label: "Id", id: "id" },
  { label: "Name", id: "name" },
  { label: "Users", id: "users" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const CostCenterTable = ({
  costCenters,
  isLoading,
  scrollRef,
  handleCostCenterClick,
}) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: "100%" }} ref={scrollRef}>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {!isLoading && costCenters.length === 0 && (
            <TableRow>
              <TableCell aligh="left" colSpan={10}>
                <Typography className={classes.headerText}>
                  There are currently no costCenters that match your search...
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            costCenters.length > 0 &&
            costCenters.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => handleCostCenterClick(row.id, "edit")}
              >
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">
                  {row.users.map(({ name }) => name).join(", ")}
                </TableCell>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={10}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CostCenterTable.propTypes = {
  costCenters: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
  handleCostCenterClick: PropTypes.func.isRequired,
};

export default CostCenterTable;
