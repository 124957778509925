import { useDispatch, useSelector } from "react-redux";

import { useCheckoutOptions } from "@features/ordering";
import { setError } from "@redux/slices/errorSlice";

import { usePaginatedBudgetsQuery } from "./data";

// Fetches the available budgets to a user.  Filters by user or territory based on org setting.
export function useUserBudgetsPaginated() {
  const dispatch = useDispatch();
  const { allowBudgetCheckout } = useCheckoutOptions();
  const {
    id: userId,
    currentTerritoryId,
    organization: { budgetLocation },
  } = useSelector((state: any) => state.currentUser);

  const { data, ...query } = usePaginatedBudgetsQuery(
    {
      filter: {
        isActive: true,
        ...(budgetLocation === "user" && { userIds: [userId] }),
        ...(budgetLocation === "territory" && {
          territoryIds: [currentTerritoryId],
        }),
      },
    },
    {
      enabled: allowBudgetCheckout,
    }
  );

  if (query.error) {
    dispatch(
      setError({ error: query.error.message, location: "use User Budgets" })
    );
  }

  return { budgets: data, ...query };
}
