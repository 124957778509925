/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Skeleton } from "@mui/material";

import BudgetSelectModal from "@components/Ordering/OrderSetOverview/BudgetSelectModal";
import { calculateBudgetTotal } from "@components/Ordering/OrderSetOverview/helpers";
import { useBudgetQuery } from "@features/budgets";
import { useCheckoutOptions } from "@features/ordering";
import { updateOrderSetBudget } from "@redux/slices/orders/currentOrderSetSlice";
import { formatMoneyString } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

const AppliedBudget = () => {
  const dispatch = useDispatch();
  const roleIs = useRoleIs();
  const [isBudgetSelectOpen, setBudgetSelectOpen] = useState(false);
  const {
    budgetId,
    totalVariantPrice,
    id: orderId,
    totalEstFreight,
    totalEstTax,
    isUpdateLoading,
    status,
  } = useSelector((state: any) => state.currentOrderSet);

  const { includeShippingInBudgets, includeTaxesInBudgets } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const { requireBudget } = useCheckoutOptions();

  const { data: budget, isLoading: isBudgetLoading } = useBudgetQuery(budgetId);

  const canEditBudget =
    status === "draft" ||
    (status === "submitted" && roleIs(permissions.orderReview)) ||
    (status === "approval-review" && roleIs(permissions.reviewApprovals));

  const handleBudgetSelectClose = () => {
    setBudgetSelectOpen(false);
  };

  const budgetValue = budget
    ? calculateBudgetTotal(
        budget,
        budgetId,
        includeShippingInBudgets,
        includeTaxesInBudgets,
        totalEstFreight,
        totalEstTax,
        totalVariantPrice
      )
    : 0;

  const determineSelectedBudgetText = () => {
    if (isUpdateLoading || isBudgetLoading) return <Skeleton />;
    if (!budget) return "No Budget Selected";
    return budget.name;
  };

  return (
    <>
      {isBudgetSelectOpen && (
        <BudgetSelectModal
          open={isBudgetSelectOpen}
          handleClose={handleBudgetSelectClose}
        />
      )}
      <div>
        <div tw="flex justify-between items-center">
          <div>Budget</div>
          {canEditBudget && (
            <div tw="flex gap-2">
              <Button
                size="small"
                tw="text-primary-800 min-w-0"
                onClick={() => setBudgetSelectOpen(true)}
              >
                Edit
              </Button>
              {budgetId && !requireBudget && (
                <Button
                  size="small"
                  tw="text-primary-800"
                  onClick={() => dispatch(updateOrderSetBudget(orderId, null))}
                >
                  Remove
                </Button>
              )}
            </div>
          )}
        </div>
        <p tw="text-neutral-700">{determineSelectedBudgetText()}</p>
        {budget && (
          <p tw="text-neutral-600 text-sm">
            Available: {formatMoneyString(budgetValue)}
          </p>
        )}
      </div>
    </>
  );
};

export default AppliedBudget;
