/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import AddressModal from "@components/Profile/Addresses/AddressModal";
import {
  FixedHeightScrollLastChild,
  StyledButton,
} from "@components/StyledComponents";
import {
  AddressBookTable,
  usePaginatedAddressesQuery,
} from "@features/addresses";
import { useFilterParams } from "@features/filters";
import { Filters } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import DocTitle from "@utility/DocTitle";

/*
The address admin view gives admin users access to every address in their organization, regardless of how
addresses are assigned. In Brandhub, organizations can decide to assign addresses at a user, or a territory
level.
*/

const useStyles = makeStyles((theme: any) => ({
  ...theme.global,
}));

const AddressAdmin = () => {
  const classes = useStyles() as any;
  const [addressModal, setAddressModal] = useState<any>({
    open: false,
    type: null,
    id: null,
    forOrder: false,
    orderSetId: null,
    orderType: null,
  });

  const [filterParams] = useFilterParams();

  const params = {
    filter: {
      searchTerm: filterParams.q,
      territoryIds: filterParams.territories,
      userIds: filterParams.users,
      stateIds: filterParams.states,
    },
    sort: filterParams.sort,
  };

  useReport("addresses", ["addresses", params]);

  const { data, ...tableProps } = usePaginatedAddressesQuery(params);

  const addresses = data ?? [];

  const handleModalClose = () => {
    setAddressModal({
      open: false,
      type: "",
      id: null,
    });
  };

  const handleAddressClick = (id, type) => {
    setAddressModal({
      open: true,
      type,
      id,
    });
  };

  return (
    <>
      {addressModal.open && (
        <AddressModal {...addressModal} handleClose={handleModalClose} />
      )}
      <DocTitle title={"Address Admin"} />
      <FixedHeightScrollLastChild>
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>Address Admin</Typography>
          <div tw="flex items-center gap-4">
            <RequestReportButton reportName="addresses" />
            <StyledButton cta onClick={() => handleAddressClick(null, "new")}>
              NEW ADDRESS
            </StyledButton>
          </div>
        </header>

        <Filters
          searchTitle="Search Addresses"
          slots={["users", "territories", "states"]}
          defaultValues={{ sort: "name" }}
        />
        <AddressBookTable
          showUserColumn
          rows={addresses}
          query={filterParams.q}
          onRowClick={(row) => handleAddressClick(row.id, "edit")}
          {...tableProps}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

AddressAdmin.propTypes = {};

export default AddressAdmin;
