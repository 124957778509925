import React from "react";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const WarningModal = ({ warning, func, arg, open, handleClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.centeredModal}>
            <Typography className={classes.headerText}>{warning}</Typography>
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => {
                func(arg);
                handleClose();
              }}
            >
              CONFIRM
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

WarningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  warning: PropTypes.string.isRequired,
  func: PropTypes.func,
  arg: PropTypes.any,
};

export default WarningModal;
