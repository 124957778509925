/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { useItemQuery } from "@features/items";
import { useWarehouseOptions, warehouseLabel } from "@features/warehouses";
import { SelectInput } from "@utils/forms";

import ItemVariantInventoryQtyForm from "./ItemVariantInventoryQtyForm";

export type TVariantQty = { id: string; qty: number };

const ItemVariantInventoryQtyModal = ({
  itemId,
  modalTitle,
  onClose,
  onSubmit,
  disabledSkus,
}: {
  modalTitle: string;
  itemId: string;
  onClose: () => void;
  onSubmit: (variantQtys: TVariantQty[], warehouse: string) => void;
  disabledSkus?: string[];
}) => {
  const [editingWarehouse, setEditingWarehouse] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const { isUpdateLoading } = useSelector((state: any) => state.purchaseOrder);
  const { data: item } = useItemQuery(itemId);
  const warehouseOptions = useWarehouseOptions();

  const formMethods = useForm<Record<string, string>>({ defaultValues: {} });
  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods;

  const handleCreateInventoryPO = (data: Record<string, string>) => {
    const variantQtys: TVariantQty[] = Object.entries(data)
      .filter(([_, qty]) => +qty)
      .map(([id, qty]) => ({ id, qty: +qty }));
    if (variantQtys.length === 0) return;

    onSubmit(variantQtys, warehouse);
  };

  const handleSetWarehouse = (warehouseId: string) => {
    setWarehouse(warehouseId);
    setEditingWarehouse(false);
  };

  useEffect(
    () => {
      if (!item) return;
      setWarehouse(item.warehouse ?? warehouseOptions[0].id);
      if (!item.warehouse && warehouseOptions.length > 1) {
        setEditingWarehouse(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!item, item?.warehouse, warehouseOptions]
  );

  return (
    <FormProvider {...formMethods}>
      <Dialog open onClose={onClose} fullWidth>
        <DialogTitle tw="flex justify-between items-start">
          {modalTitle}
          <IconButton onClick={onClose} edge="end">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!item && <CircularProgress />}
          {item && (
            <ItemVariantInventoryQtyForm
              item={item}
              disabledSkus={disabledSkus}
            />
          )}
        </DialogContent>
        <DialogActions tw="justify-between px-6 pb-3">
          {item ? (
            <>
              {editingWarehouse && (
                <SelectInput
                  label="Warehouse"
                  options={warehouseOptions}
                  value={warehouse}
                  onChange={(e) => handleSetWarehouse(e.target.value)}
                />
              )}
              {!editingWarehouse && warehouse && (
                <div tw="flex gap-2 items-center">
                  <span>
                    Warehouse:{" "}
                    <b tw="font-medium ">{warehouseLabel(warehouse)}</b>
                  </span>
                  {warehouseOptions.length > 1 && (
                    <Button
                      variant="text"
                      tw="normal-case text-primary-600"
                      onClick={() => setEditingWarehouse(true)}
                    >
                      Change
                    </Button>
                  )}
                </div>
              )}
            </>
          ) : (
            <CircularProgress />
          )}
          <StyledButton
            cta
            onClick={handleSubmit(handleCreateInventoryPO)}
            loading={isUpdateLoading}
            disabled={!isDirty || !warehouse}
          >
            Submit
          </StyledButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default ItemVariantInventoryQtyModal;
