/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { StyledButton } from "@components/StyledComponents";
import { CloudinaryFileCarousel, FileCard } from "@features/cloudinaryFiles";
import useConfirm from "@features/confirm";
import { CloudinaryFile } from "@models/CloudinaryFile";
import { RequestForQuote } from "@models/RequestForQuote";
import client, { useMutateError } from "@services/api";
import { useOpenCloudinaryWidget } from "@services/cloudinary";

const RfqAttachments = ({ rfq }: { rfq: RequestForQuote }) => {
  const openCloudinaryWidget = useOpenCloudinaryWidget();
  const setMutateError = useMutateError();
  const confirm = useConfirm();
  const [savedFiles, setSavedFiles] = useState(rfq.cloudinaryFiles);
  const [modalInitialPage, setModalInitialPage] = useState<null | number>(null);

  const canModifyFiles = rfq.status === "draft";

  const handleUploadFile = () =>
    openCloudinaryWidget({
      onSuccess: (file) =>
        client
          .post("cloudinary-files", {
            cloudinaryId: file.info.public_id,
            fileName: file.info.original_filename,
            format: file.info.format ?? file.info.public_id.split(".").at(-1),
            resourceType: file.info.resource_type,
            pdfPageCount: file.info.pages ?? 0,
            requestForQuoteId: rfq.id,
          })
          .then((data) =>
            setSavedFiles([...savedFiles, data?.data as CloudinaryFile])
          )
          .catch(setMutateError),
    });

  const handleDeleteFile = async (fileId: string) =>
    (await confirm(
      "Are you sure you want to delete this file from this RFQ?"
    )) &&
    client
      .delete(`cloudinary-files/${fileId}`)
      .then(() => setSavedFiles(savedFiles.filter((f) => f.id !== fileId)))
      .catch(setMutateError);

  return (
    <div>
      <div tw="flex flex-wrap gap-4">
        {savedFiles.map((file, i) => (
          <FileCard
            file={file}
            key={file.id}
            onDelete={canModifyFiles ? handleDeleteFile : undefined}
            onClick={() => setModalInitialPage(i)}
          />
        ))}
      </div>
      {canModifyFiles && (
        <StyledButton
          size="small"
          tw="mt-3 normal-case font-normal text-neutral-600"
          outlined
          onClick={handleUploadFile}
        >
          Add Attachment
        </StyledButton>
      )}
      {!canModifyFiles && savedFiles.length === 0 && (
        <p tw="text-neutral-600 text-lg font-light">Non provided.</p>
      )}
      <CloudinaryFileCarousel
        files={savedFiles}
        open={modalInitialPage !== null}
        handleClose={() => setModalInitialPage(null)}
        startIndex={modalInitialPage || 0}
      />
    </div>
  );
};

export default RfqAttachments;
