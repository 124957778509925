import { z } from "zod";

const statusEnum = z.enum(["draft", "complete"]);

const nameValidation = { name: z.string().min(1, "Name can't be empty") };

const draftProgramSchema = z
  .object({
    status: z.literal(statusEnum.enum.draft),
    ...nameValidation,
  })
  .passthrough();

const completeProgramSchema = z
  .object({
    status: z.literal(statusEnum.enum.complete),
    ...nameValidation,
    description: z
      .string()
      .min(1, "Description is required to complete this program"),
  })
  .passthrough();

export const programSchema = z.discriminatedUnion("status", [
  draftProgramSchema,
  completeProgramSchema,
]);
