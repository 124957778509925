import React, { memo } from "react";
import { useSelector } from "react-redux";

import { FormControl, MenuItem, Select, Typography } from "@mui/material";

import PropTypes from "prop-types";

import { upCase } from "@utility/utilityFunctions";

const DashboardSelector = ({ classes, dashboard, setDashboard }) => {
  const {
    role,
    organization: { dashboards },
  } = useSelector((state) => state.currentUser);

  const handleChangeSelect = (evt) => {
    setDashboard(evt.target.value);
  };

  return (
    <FormControl size="small">
      <Select
        name="dashboards"
        id="dashboards"
        value={dashboard}
        onChange={handleChangeSelect}
      >
        {[...dashboards]
          .filter((d) => {
            if (role === "super") return true;
            return d.role !== "super";
          })
          .map((d, i) => (
            <MenuItem value={d.role} key={i}>
              <Typography className={classes.bodyText}>
                {upCase(d.role, "-")}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

DashboardSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  dashboard: PropTypes.string.isRequired,
  setDashboard: PropTypes.func.isRequired,
};

export default memo(DashboardSelector);
