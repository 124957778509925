import { axiosGet, axiosPatch, axiosPost } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildCategory, buildGroup } from "./helpers";
import { mapCategories, mapGroups } from "./maps";

let initialState = {
  isLoading: true,
  isUpdateLoading: false,
  isGroupsLoading: false,
  categories: [],
  groups: [],
  hasUpdated: false,
  error: null,
};

const groupCategorySlice = createSlice({
  name: "groupCategories",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setIsGroupsLoading(state) {
      state.isGroupsLoading = true;
    },
    getCategoriesSuccess(state, action) {
      const { categories } = action.payload;
      state.categories = categories;
      state.isLoading = false;
      state.error = null;
    },
    getGroupsSuccess(state, action) {
      const { groups } = action.payload;
      state.groups = groups;
      state.isGroupsLoading = false;
      state.error = null;
    },
    createCategorySuccess(state, action) {
      const { category } = action.payload;
      state.categories = [...state.categories, category].sort((a, b) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      );
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    updateCategorySuccess(state, action) {
      const { category } = action.payload;
      state.categories = state.categories.map((cat) =>
        cat.id === category.id
          ? { groups: cat.groups, ...category }
          : { ...cat }
      );
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    createOrUpdateGroupSuccess(state, action) {
      const { group, id } = action.payload;
      state.categories = state.categories.map((cat) =>
        cat.id === id
          ? {
              ...cat,
              groups: [...cat.groups, group].sort((a, b) =>
                a.name < b.name ? -1 : a.name > b.name ? 1 : 0
              ),
            }
          : { ...cat }
      );
      state.isUpdateLoading = false;
      state.error = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    clearErrors(state) {
      state.error = null;
    },
    clearGroups(state) {
      state.groups = [];
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.isGroupsLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  setIsGroupsLoading,
  getCategoriesSuccess,
  getGroupsSuccess,
  createCategorySuccess,
  updateCategorySuccess,
  createOrUpdateGroupSuccess,
  setHasUpdated,
  clearErrors,
  clearGroups,
  setFailure,
} = groupCategorySlice.actions;

export default groupCategorySlice.reducer;

export const fetchGroupCategories = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const categoryResp = await axiosGet("/api/group-categories");
    if (categoryResp.error) {
      throw categoryResp.error;
    }
    let mappedData = mapCategories(categoryResp.data);
    mappedData = await Promise.all(
      mappedData.map(async (cat) => {
        let groupResp = await axiosGet(
          `/api/groups?filter[group-category-id]=${cat.id}`
        );
        if (groupResp.error) {
          throw groupResp.error;
        }
        let mappedGroups = mapGroups(groupResp.data);
        return {
          ...cat,
          groups: mappedGroups,
        };
      })
    );
    dispatch(getCategoriesSuccess({ categories: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Group Categories" }));
  }
};

export const createGroupCategory = (attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildCategory(attributes);
    const response = await axiosPost("/api/group-categories", postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapCategories([response.data])[0];
    if (attributes.groups.length > 0) {
      let groups = [];
      for (let i = 0; i < attributes.groups.length; i++) {
        let groupData = buildGroup({
          name: attributes.groups[i].name,
          groupCategoryId: mappedData.id,
        });
        let groupResp = await axiosPost("/api/groups", groupData);
        if (groupResp.error) {
          throw groupResp.error;
        }
        let mappedGroup = mapGroups([groupResp.data])[0];
        groups.push(mappedGroup);
      }
      mappedData.groups = groups;
    }
    dispatch(createCategorySuccess({ category: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Group Categories" }));
  }
};

export const updateGroupCategory = (id, attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildCategory(attributes);
    const response = await axiosPatch(`/api/group-categories/${id}`, patchData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapCategories([response.data])[0];

    if (attributes.groups.length > 0) {
      for (let i = 0; i < attributes.groups.length; i++) {
        let groupData = buildGroup({
          name: attributes.groups[i].name,
          groupCategoryId: mappedData.id,
        });
        let groupResp = await axiosPatch(
          `/api/groups/${attributes.groups[i].id}`,
          groupData
        );
        if (groupResp.error) {
          throw groupResp.error;
        }
      }
      // Update the groupData with the latest groups
      const response = await axiosGet(
        `/api/groups?filter[group-category-id]=${mappedData.id}`
      );
      if (response.error) {
        throw response.error;
      }

      let mappedGroups = mapGroups(response.data);
      mappedData.groups = mappedGroups;
    }
    dispatch(updateCategorySuccess({ category: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Group Categories" }));
  }
};

export const createGroup = (attributes) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildGroup(attributes);
    const response = await axiosPost("/api/groups", postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapGroups([response.data])[0];
    dispatch(
      createOrUpdateGroupSuccess({
        group: mappedData,
        id: attributes.groupCategoryId,
      })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Group Categories" }));
  }
};
