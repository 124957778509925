export const buildDiscountRequest = (ids, codes, orderSetId) => {
  return {
    "promotion-ids": ids.map((id) => +id),
    "promotion-codes": codes,
    "order-set-id": +orderSetId,
  };
};

export const buildPromotion = (attrs) => {
  const itemArray =
    attrs.items && attrs.items.length > 0
      ? attrs.items.map((i) => ({
          type: "item",
          id: `${i}`,
        }))
      : [];
  const userArray = attrs.users.map((u) => ({
    type: "user",
    id: `${u}`,
  }));
  return {
    data: {
      type: "promotion",
      attributes: {
        "start-date": attrs.startDate,
        "expiration-date": attrs.expirationDate,
        "percent-off":
          attrs.percentOff && attrs.percentOff.length > 0
            ? attrs.percentOff
            : null,
        "dollars-off":
          attrs.dollarsOff && attrs.dollarsOff.length > 0
            ? attrs.dollarsOff
            : null,
        type: attrs.type,
        "redemption-type": attrs.redemptionType,
        "discount-type": attrs.discountType,
        "is-one-time-use": attrs.isOneTimeUse,
        description: attrs.description,
        "max-qty":
          attrs.maxQty && attrs.maxQty.length > 0 ? +attrs.maxQty : null,
        "order-type": attrs.orderType,
      },
      relationships: {
        items: {
          data: itemArray,
        },
        users: {
          data: userArray,
        },
      },
    },
  };
};
