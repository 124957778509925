import React from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { id: "name", label: "Name" },
  { id: "openDate", label: "Open Date" },
  { id: "closeDate", label: "Close Date" },
  { id: "reviewCloseDate", label: "Review Close Date" },
  { id: "inMarketDate", label: "In Market Date" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OrderWindowTable = ({
  handleWindowClick,
  windows,
  isLoading,
  scrollRef,
}) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: "100%" }} ref={scrollRef}>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {!isLoading && windows.length === 0 && (
            <TableRow>
              <TableCell aligh="left" colSpan={5}>
                <Typography className={classes.headerText}>
                  There are currently no order windows that match your search...
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            windows.length > 0 &&
            windows.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => handleWindowClick(row.id, "edit")}
              >
                {headCells.map((cell) => (
                  <TableCell align="left" key={cell.id}>
                    {row[cell.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={5}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OrderWindowTable.propTypes = {
  handleWindowClick: PropTypes.func.isRequired,
  windows: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any.isRequired,
};

export default OrderWindowTable;
