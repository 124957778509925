import { PricingTier } from "@models/RequestForQuote";
import client from "@services/api";
import asyncPool from "@utility/asyncPool";

export default async function updatePricingTiers(
  rfqId: string,
  pricingTiers: PricingTier[],
  newQuantities: string[]
) {
  // figure out which tier needs to be create and deleted
  const tierByQuantity = Object.fromEntries(
    pricingTiers.map((tier) => [tier.qty, tier])
  );
  const idsToDelete = new Set(pricingTiers.map((tier) => tier.id));
  const requests: any[][] = [];
  newQuantities.forEach((qty) => {
    if (tierByQuantity[qty]) {
      idsToDelete.delete(tierByQuantity[qty].id);
    } else {
      requests.push([
        "post",
        { type: "pricing-tier", qty: +qty, requestForQuoteId: rfqId },
      ]);
    }
  });
  requests.push(...[...idsToDelete].map((id) => ["delete", id]));

  return await asyncPool(5, requests, async ([method, body]) => {
    switch (method) {
      case "post":
        await client.post("pricing-tiers", body);
        break;
      case "delete":
        await client.delete(`pricing-tiers/${body}`);
        break;
    }
  });
}
