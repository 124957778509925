import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Button, CircularProgress, Typography } from "@mui/material";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

import {
  setFailure,
  setIsUpdateLoading,
  submitStripePayment,
} from "@redux/slices/orders/currentOrderSetSlice";
import {
  setFailure as patchFailure,
  setIsLoading,
} from "@redux/slices/patchOrderSlice";

const options = {
  style: {
    base: {
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      iconColor: "#0099cc",
    },
  },
};

const MIN_STRIPE_PAYMENT = 0.5;

const StripeCheckout = ({
  classes,
  id,
  userName,
  amount,
  orderType,
  handleClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);

  const { isUpdateLoading } = useSelector((state) => state.currentOrderSet);

  const paymentRequired = MIN_STRIPE_PAYMENT < amount;

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    try {
      let paymentId = null;

      dispatch(setIsLoading());
      dispatch(setIsUpdateLoading());

      if (paymentRequired) {
        const result = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: userName,
          },
        });
        if (result.error) {
          throw result.error.message;
        }
        paymentId = result.paymentMethod.id;
      }

      const data = {
        "payment-method-id": paymentId,
        "amount-in-cents": Math.floor(amount),
        "order-set-id": id,
      };

      dispatch(submitStripePayment(id, data, orderType));
      handleClose();
    } catch (err) {
      dispatch(
        setError({ error: err.toString(), source: "Credit Card Payments" })
      );
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      handleClose();
    }
  };

  const handleCardChange = (evt) => {
    if (evt.error) setError(evt.error.message);
    else setError(null);
  };

  if (!stripe)
    return (
      <Alert severity="error">
        Something went wrong initializing Stripe. Unable to accept payment at
        this time.
      </Alert>
    );

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%", height: "auto" }}>
      {paymentRequired && (
        <>
          <div className={classes.inputCell}>
            <CardNumberElement onChange={handleCardChange} options={options} />
          </div>
          <br />
          <div className={classes.fullWidthSpaceBetween}>
            <div className={classes.inputCell} style={{ width: "69%" }}>
              <CardExpiryElement
                onChange={handleCardChange}
                options={options}
              />
            </div>
            <div className={classes.inputCell} style={{ width: "29%" }}>
              <CardCvcElement onChange={handleCardChange} options={options} />
            </div>
          </div>
        </>
      )}
      {error && (
        <>
          <Typography className={classes.bodyText} color="textSecondary">
            <em>{error}</em>
          </Typography>
        </>
      )}
      <br />
      <Button
        type="submit"
        disabled={paymentRequired && (!stripe || !elements || isUpdateLoading)}
        className={classes.button}
        variant="contained"
        color="secondary"
      >
        {isUpdateLoading ? (
          <CircularProgress size={28} color="secondary" />
        ) : (
          "SUBMIT"
        )}
      </Button>
    </form>
  );
};

StripeCheckout.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  orderType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default StripeCheckout;
