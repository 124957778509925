/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ExitToApp, HelpTwoTone } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import PropTypes from "prop-types";

import GeneralPanel from "@components/BrandHQ/Promotions/GeneralPanel";
import ItemPanel from "@components/BrandHQ/Promotions/ItemPanel";
import ReviewPanel from "@components/BrandHQ/Promotions/ReviewPanel";
import UserPanel from "@components/BrandHQ/Promotions/UserPanel";
import { Contained } from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import SuccessModal from "@components/Utility/Modals/SuccessModal";
import { InfoTooltipWide } from "@components/Utility/StyledComponents/InfoTooltip";
import { setError } from "@redux/slices/errorSlice";
import { setItemSelection } from "@redux/slices/items/itemSlice";
import {
  createPromotion,
  fetchPromotion,
  setHasUpdated,
  updatePromotion,
} from "@redux/slices/promotions/promotionSlice";
import DocTitle from "@utility/DocTitle";
import { formatMoney } from "@utility/utilityFunctions";

/*
Similar to the other create views, this handles creating and updating for all promotions
in the current organizaiton. Panels for this flow can be found at ../components/BrandHQ/Promotions.
*/

const formatISODate = (date) => {
  const dateArray = date.split("/");
  return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
};

const defaultForm = {
  description: "",
  startDate: format(new Date(), "MM/dd/yyyy"),
  expirationDate: format(addDays(new Date(), 30), "MM/dd/yyyy"),
  percentOff: "",
  dollarsOff: "",
  type: "",
  redemptionType: "",
  discountType: "",
  isOneTimeUse: false,
  maxQty: "",
};

const keyMap = {
  description: "Description",
  type: "Type",
  redemptionType: "Redemption Type",
  discountType: "Discount Type",
  dollar: "Dollars Off",
  percent: "Percent Off",
  users: "User Assignment",
  items: "Item Assignment",
};

const tabProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  twoWideContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  twoWide: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  formWrapper: {
    width: "70%",
    maxWidth: "1250px",
    padding: "20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      width: "85%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  tabs: {
    display: "flex",
    justifyContent: "space-between",
  },
  chipDiv: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    alignItems: "center",
  },
}));

const PromotionCreate = () => {
  const { type, id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabActions = useRef();

  const [promotion, setPromotion] = useState(null);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [allUsers, setAllUsers] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [formData, setFormData] = useState(defaultForm);
  const [successModal, setSuccessModal] = useState({
    open: false,
    message: null,
    options: [],
  });
  const [localError, setLocalError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const { isLoading, isUpdateLoading, hasUpdated, currentPromotion } =
    useSelector((state) => state.promotions);

  const handleTab = (_evt, value) => {
    setTabIndex(value);
  };

  const handleFormUpdate = (key, value) =>
    setFormData({ ...formData, [key]: value });

  const handleTooltipClose = () => setTooltipOpen(false);

  const reset = () => {
    setPromotion(null);
    setCurrentUsers([]);
    setAllUsers(false);
    setCurrentItems([]);
    setFormData(defaultForm);
    setLocalError(null);
    dispatch(setHasUpdated({ value: false }));
  };

  const verifyForm = () => {
    let keys = Object.keys(formData);
    let verified = [];
    let index = 0;

    let exempt = ["percentOff", "dollarsOff", "isOneTimeUse", "maxQty"];

    while (index < keys.length) {
      if (!exempt.includes(keys[index]) && formData[keys[index]].length === 0)
        verified.push(keys[index]);
      index += 1;
    }

    if (!allUsers && currentUsers.length === 0) verified.push("users");
    if (formData.type === "item" && currentItems.length === 0)
      verified.push("items");
    if (formData.discountType === "dollar" && formData.dollarsOff.length === 0)
      verified.push("dollar");
    if (formData.discountType === "percent" && formData.percentOff.length === 0)
      verified.push("percent");

    return verified.length > 0
      ? verified.map((field) => keyMap[field]).join(", ")
      : false;
  };

  const handleSubmit = () => {
    let notVerified = verifyForm();
    if (notVerified) {
      setLocalError(
        `You must complete form before submitting, the following fields are missing: ${notVerified}`
      );
    } else {
      setLocalError(null);
      if (type === "edit") {
        dispatch(
          updatePromotion(
            {
              ...formData,
              percentOff:
                formData.percentOff.length > 0
                  ? `${parseFloat(formData.percentOff) / 100}`
                  : "",
              startDate: formatISODate(formData.startDate),
              expirationDate: formatISODate(formData.expirationDate),
              items: currentItems.map((i) => i.id),
            },
            allUsers,
            currentUsers.map((u) => u.id),
            id
          )
        );
      } else {
        dispatch(
          createPromotion(
            {
              ...formData,
              percentOff:
                formData.percentOff.length > 0
                  ? `${parseFloat(formData.percentOff) / 100}`
                  : "",
              startDate: formatISODate(formData.startDate),
              expirationDate: formatISODate(formData.expirationDate),
              items: currentItems.map((i) => i.id),
            },
            allUsers,
            currentUsers.map((u) => u.id)
          )
        );
      }
    }
  };

  const handleSuccessClose = (arg) => {
    if (arg === "new") {
      reset();
      setSuccessModal({
        open: false,
        message: null,
        options: [],
      });
      setTabIndex(0);
    } else if (arg === "continue") {
      setSuccessModal({
        open: false,
        message: null,
        options: [],
      });
      dispatch(setHasUpdated({ value: false }));
    } else {
      reset();
      navigate("/admin/promotions");
    }
  };

  useEffect(() => {
    if (
      type === "edit" &&
      !promotion &&
      currentPromotion &&
      currentPromotion.id &&
      currentPromotion.id === id
    ) {
      setPromotion(currentPromotion);
      setFormData({
        description: currentPromotion.description,
        startDate: currentPromotion.startDate,
        expirationDate: currentPromotion.expirationDate,
        percentOff: currentPromotion.percentOff
          ? `${parseFloat(currentPromotion.percentOff) * 100}`
          : "",
        dollarsOff: currentPromotion.dollarsOff
          ? formatMoney(currentPromotion.dollarsOff, false)
              .split(",")
              .join("")
              .split("$")
              .join("")
          : "",
        type: currentPromotion.type,
        redemptionType: currentPromotion.redemptionType,
        discountType: currentPromotion.discountType,
        isOneTimeUse: currentPromotion.isOneTimeUse,
        maxQty: currentPromotion.maxQty ? `${currentPromotion.maxQty}` : "",
        orderType: currentPromotion.orderType ?? "",
      });
      setCurrentUsers(currentPromotion.users);
      setCurrentItems(currentPromotion.items);
    }
  }, [currentPromotion, id, promotion, type]);

  useEffect(() => {
    reset();
    dispatch(setItemSelection({ items: [] }));
    // handleFilterDrawer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localError) {
      dispatch(setError({ error: localError, source: "Promotion Create" }));
      setLocalError(null);
    }
  }, [localError, dispatch]);

  useEffect(() => {
    if (hasUpdated && !successModal.open) {
      setSuccessModal({
        open: true,
        message:
          type === "edit"
            ? "Promotion Updated Successfully!"
            : "Promotion Created Successfully",
        options:
          type === "edit"
            ? [
                { label: "CONTINUE EDITING", arg: "continue" },
                { label: "BACK TO PROMOTIONS", arg: "back" },
              ]
            : [
                { label: "CREATE NEW PROMOTION", arg: "new" },
                { label: "BACK TO PROMOTIONS", arg: "back" },
              ],
      });
    }
  }, [hasUpdated, successModal.open, type]);

  useEffect(() => {
    if (type === "edit") {
      dispatch(fetchPromotion(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocTitle title={`${type === "edit" ? "Edit" : "Create"} Promotion`} />
      {successModal.open && (
        <SuccessModal {...successModal} handleClose={handleSuccessClose} />
      )}
      {((type === "edit" &&
        (!currentPromotion || currentPromotion.id !== id)) ||
        isLoading ||
        isUpdateLoading) && <Loading opacity="75%" />}
      {((type === "edit" && currentPromotion && currentPromotion.id === id) ||
        type === "new") && (
        <Contained tw="flex flex-col items-center w-full">
          <header className={classes.titleBar} tw="w-full">
            <Typography className={classes.headerText}>
              {type === "edit"
                ? `Editing Promotion, Id: ${currentPromotion.id}`
                : "New Promotion"}
            </Typography>
            <div className={classes.innerConfigDiv}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                startIcon={
                  <ExitToApp style={{ transform: "rotate(180deg)" }} />
                }
                component={Link}
                to={"/admin/promotions"}
              >
                BACK TO PROMOTIONS
              </Button>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <InfoTooltipWide
                  PopperProps={{
                    disablePortal: true,
                    style: { width: "500px" },
                  }}
                  style={{ marginLeft: "10px" }}
                  onClose={handleTooltipClose}
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  placement="right-end"
                  title={
                    <>
                      <Typography className={classes.headerText}>
                        Promotions Help:
                      </Typography>
                      <br />
                      <Typography
                        className={clsx(
                          classes.settingsMargin,
                          classes.bodyText
                        )}
                      >
                        Type: A promotion can be one of two types, Item or
                        Order. Order promotions can be applied to an entire
                        group of orders when checking out, while item promotions
                        can only be applied to specified items.
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.settingsMargin,
                          classes.bodyText
                        )}
                      >
                        Redemption Type: Promotions can be redeemed in two ways,
                        either by a promotion code, or by the user selecting an
                        assigned promotion before submitting an order. If
                        promotion code is selected, upon creating the promotion
                        a code is generated and sent to all users that were
                        selected
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.settingsMargin,
                          classes.bodyText
                        )}
                      >
                        Discount Type: The discount type determines what kind of
                        discount will be applied, either dollars off, percent
                        off, or free shipping.
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.settingsMargin,
                          classes.bodyText
                        )}
                      >
                        Max Quantity: A max quantity will determine the amount
                        of an item that the promotion will be applied to on an
                        order. Anything ordered in excess of the max qty will
                        not be discounted. Max Quantity can only be set on item
                        type promotions that are one time use.
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.settingsMargin,
                          classes.bodyText
                        )}
                      >
                        One Time Use: Determines whether a user can only apply
                        the promotion one time, or continue to apply the
                        promotion during the duration of the promotions active
                        cycle.
                      </Typography>
                    </>
                  }
                >
                  <IconButton
                    onClick={() => setTooltipOpen(!tooltipOpen)}
                    style={{ padding: 0, marginLeft: "10px" }}
                    size="large"
                  >
                    <HelpTwoTone fontSize="large" color="secondary" />
                  </IconButton>
                </InfoTooltipWide>
              </ClickAwayListener>
            </div>
          </header>
          <Tabs
            tw="w-full my-4"
            value={tabIndex}
            onChange={handleTab}
            indicatorColor="secondary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="item create sections"
            classes={{ flexContainer: classes.tabs }}
            action={tabActions}
          >
            <Tab label="General" {...tabProps(0)} />
            <Tab label="User Assignment" {...tabProps(1)} />
            <Tab label="Item Assignment" {...tabProps(2)} />
            <Tab label="Review" {...tabProps(3)} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <GeneralPanel
              classes={classes}
              type={type}
              formData={formData}
              handleFormUpdate={handleFormUpdate}
              handleSubmit={handleSubmit}
              promotion={promotion}
            />
          </TabPanel>
          <TabPanel
            className={clsx(
              classes.fullWidthCenterColumn,
              classes.justifyCenter
            )}
            value={tabIndex}
            index={1}
          >
            <UserPanel
              classes={classes}
              type={type}
              formData={formData}
              currentUsers={currentUsers}
              setCurrentUsers={setCurrentUsers}
              allUsers={allUsers}
              setAllUsers={setAllUsers}
              handleSubmit={handleSubmit}
              promotion={promotion}
            />
          </TabPanel>
          <TabPanel
            className={clsx(
              classes.fullWidthCenterColumn,
              classes.justifyCenter
            )}
            value={tabIndex}
            index={2}
          >
            <ItemPanel
              classes={classes}
              type={type}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
              formData={formData}
              // handleFilterDrawer={handleFilterDrawer}
              // filtersOpen={filtersOpen}
              handleSubmit={handleSubmit}
              promotion={promotion}
            />
          </TabPanel>
          <TabPanel
            className={clsx(
              classes.fullWidthCenterColumn,
              classes.justifyCenter
            )}
            value={tabIndex}
            index={3}
          >
            <ReviewPanel
              classes={classes}
              type={type}
              formData={formData}
              allUsers={allUsers}
              currentUsers={currentUsers}
              currentItems={currentItems}
              handleSubmit={handleSubmit}
              promotion={promotion}
            />
          </TabPanel>
        </Contained>
      )}
    </>
  );
};

PromotionCreate.propTypes = {};

export default PromotionCreate;
