/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { DashboardGrid } from "../StyledComponents";
import DashCard from "./DashCard";

const SupplierDash = () => {
  const { supplierCounts } = useSelector((state: any) => state.currentUser);

  const cardData = [
    {
      titleText: "New Purchase Orders",
      ariaOwnsText: "new",
      id: "new-parent",
      link: `/purchasing/purchase-order-history?purchaseOrderStatus%5B%5D=submitted`,
      info: supplierCounts.new
        ? `View new purchase orders, you currently have ${supplierCounts.new} new purchase orders to view.`
        : "View new purchase orders.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273998/prod/Icons/SELECT_Icons_Top_Products_-_Best_Sellers_hge1gt.png",
      count: supplierCounts.new,
    },
    {
      titleText: "Active Purchase Orders",
      ariaOwnsText: "in-progress",
      id: "in-progress-parent",
      link: `/purchasing/purchase-order-history?purchaseOrderStatus%5B%5D=in-progress`,
      info: supplierCounts.inProgress
        ? `View active purchase orders, you currently have ${supplierCounts.inProgress} active purchase orders to view.`
        : "View active purchase orders.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Distribution_bvwczo.png",
      count: supplierCounts.inProgress,
    },
    {
      titleText: "Complete Purchase Orders",
      ariaOwnsText: "complete",
      id: "complete-parent",
      link: `/purchasing/purchase-order-history?purchaseOrderStatus%5B%5D=complete`,
      info: "View completed purchase orders.",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617273999/prod/Icons/SELECT_Icons_Inventory_hqowaj.png",
    },
    {
      titleText: "All Purchase Orders",
      ariaOwnsText: "all",
      id: "all-parent",
      link: `/purchasing/purchase-order-history`,
      info: "View all purchase orders",
      icon: "https://res.cloudinary.com/brandhub/image/upload/v1617274000/prod/Icons/SELECT_Icons_Analytics_hsy1lt.png",
    },
  ];

  return (
    <DashboardGrid>
      {cardData.map((data, index) => (
        <DashCard key={index} data={data} />
      ))}
    </DashboardGrid>
  );
};

export default SupplierDash;
