import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";

import { ItemRollup } from "@models/ItemRollup";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const itemRollupsKeyFactory = createQueryKeys("item-rollups", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<ItemRollup[]>("item-rollups", {
        params: params,
      }),
  }),
});

export const usePaginatedItemRollupsQuery = buildPaginatedQuery(
  itemRollupsKeyFactory.paginated,
  {
    staleTime: 60_000,
    placeholderData: keepPreviousData,
  }
);
