/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useParams } from "react-router-dom";

import { Contained } from "@components/StyledComponents";
import { useOrderWindow } from "@features/orderWindows";
import { ProgramList } from "@features/programs";

import FourOhFour from "./FourOhFour";
import DocTitle from "@utility/DocTitle";

const PreOrders = () => {
  const { orderWindowId } = useParams();
  const { data, error } = useOrderWindow(orderWindowId as string);
  const orderWindowName = data?.name;

  if (error?.status === 404) {
    <FourOhFour />;
  }
  return (
    <>
      <DocTitle title={`${orderWindowName ?? "Pre-Orders"}`}/>
      <Contained
        tw="space-y-6 pb-3 flex-col flex"
        className="body-height-after-header"
      >
        <h2 tw="font-medium text-neutral-700 text-xl">{orderWindowName}</h2>

        <ProgramList orderWindowId={orderWindowId} />
      </Contained>
    </>
  );
};

export default PreOrders;
