import Tooltip from "@mui/material/Tooltip";
import withStyles from "@mui/styles/withStyles";

export const InfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    maxWidth: "350px",
    boxSizing: "border-box",
    padding: "10px",
    color: theme.palette.text.primary,
    fontSize: "1rem",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}))(Tooltip);

export const InfoTooltipWide = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    maxWidth: "450px",
    width: "450px",
    boxSizing: "border-box",
    padding: "10px",
    color: theme.palette.text.primary,
    fontSize: "1rem",
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}))(Tooltip);
