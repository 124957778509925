import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import { FavoriteAddresses } from "@models/FavoriteAddresses";
import client, { RequestParams } from "@services/api";

type NewAddressFavoriteSetsPayload = {
  name: string;
  territoryId: string;
  addresses: string[];
};

type AddressFavoriteSetsPayload = NewAddressFavoriteSetsPayload & {
  id: string;
};

export const addressFavoriteSetsKeyFactory = createQueryKeys(
  "address-favorite-sets",
  {
    list: (params) => ({
      queryKey: [params],
      queryFn: () =>
        client
          .get<FavoriteAddresses[]>("address-favorite-sets", {
            params,
          })
          .then((res) => res.data),
    }),
  }
);

export const useFavoriteAddressesListQuery = (params: RequestParams) => {
  return useQuery({
    ...addressFavoriteSetsKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: 60_000,
    placeholderData: keepPreviousData,
  });
};

/* Mutations */

const buildAddressFavoriteSetsPayload = ({
  territoryId,
  name,
  addresses,
}: NewAddressFavoriteSetsPayload | AddressFavoriteSetsPayload) => ({
  __type: "address-favorite-sets",
  name,
  territory: { id: territoryId, type: "territory" },
  addresses: addresses.map((id) => {
    return {
      type: "address",
      id,
    };
  }),
  relationshipNames: ["addresses", "territory"],
});

export const useCreateFavoriteAddressesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: NewAddressFavoriteSetsPayload) =>
      client
        .post<FavoriteAddresses>(
          `address-favorite-sets`,
          buildAddressFavoriteSetsPayload(data)
        )
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: addressFavoriteSetsKeyFactory.list._def,
      });
    },
  });
};

export const useUpdateFavoriteAddressesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: AddressFavoriteSetsPayload) => {
      return client
        .update<FavoriteAddresses>(
          `address-favorite-sets/${id}`,
          buildAddressFavoriteSetsPayload(data)
        )
        .then((res) => res.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: addressFavoriteSetsKeyFactory.list._def,
      });
    },
  });
};

export const useRemoveFavoriteAddressesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.delete(`address-favorite-sets/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: addressFavoriteSetsKeyFactory.list._def,
      });
    },
  });
};
