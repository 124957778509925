export const buildCategory = (attributes) => {
  return {
    data: {
      type: "group-category",
      attributes: {
        name: attributes.name,
        type: attributes.type,
        "priority-level": attributes.priorityLevel,
        "is-required-on-item": attributes.isRequired,
        "limits-availability": attributes.limitsAvailability,
      },
    },
  };
};

export const buildGroup = (attributes) => {
  return {
    data: {
      type: "group",
      attributes: {
        name: attributes.name,
        "group-category-id": attributes.groupCategoryId,
      },
    },
  };
};
