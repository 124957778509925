/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { forwardRef } from "react";

import {
  ButtonProps,
  ButtonTypeMap,
  CircularProgress,
  Button as MuiButton,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type ButtonOptions = {
  loading?: boolean;
};

const LoadingButton: OverridableComponent<ButtonTypeMap<ButtonOptions>> =
  forwardRef(function <C extends React.ElementType>(
    { loading, ...props }: ButtonProps<C, ButtonOptions>,
    ref: React.Ref<HTMLButtonElement> | null
  ) {
    return (
      <MuiButton
        ref={ref}
        {...props}
        {...(loading && {
          endIcon: <CircularProgress size={16} tw="text-inherit" />,
          disabled: true,
        })}
      />
    );
  });

export default LoadingButton;
