/** @jsxImportSource @emotion/react */
import "twin.macro";

const SearchableText = ({
  text,
  query,
  maxLength,
  ...props
}: {
  text: string | null;
  query?: string;
  maxLength?: number;
  [x: string]: any;
}) => {
  if (!text) return null;

  const clippedText =
    text.slice(0, maxLength) +
    (maxLength && text.length > maxLength ? "…" : "");

  if (!query) return <>{clippedText}</>;

  const qStart = text.toLowerCase().indexOf(query.toLowerCase());
  const qEnd = qStart + query.length;
  let start = maxLength ? Math.max(0, qStart - maxLength / 2) : 0;
  let end = maxLength ? start + maxLength : text.length;

  if (qStart === -1) return <>{clippedText}</>;
  if (start !== 0) start = text.indexOf(" ", start);
  if (end < text.length) {
    const nextSpace = text.indexOf(" ", end);
    end = nextSpace !== -1 ? nextSpace : text.length;
  }

  return (
    <span {...props}>
      {start > 0 && "…"}
      {text.slice(start, qStart)}
      <b tw="bg-primary-100">{text.slice(qStart, qEnd)}</b>
      {text.slice(qEnd, end)}
      {end < text.length && " …"}
    </span>
  );
};

export default SearchableText;
