/** @jsxImportSource @emotion/react */
import tw, { styled } from "twin.macro";

import { useEffect, useState } from "react";
import { useTransition } from "react";
import { Link, useLocation } from "react-router-dom";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, List, ListItem, ListItemButton } from "@mui/material";

import { isEqual } from "lodash";

import { useBreakpoint } from "@utils/tailwind";

import logoIconWhiteUrl from "../../assets/logo-icon-white.svg";
import logoSelectWhiteColorUrl from "../../assets/logo-select-white-color.svg";
import useUserMenu from "./useUserMenu";

const Dot = styled.div(({ isActive }) => [
  tw`relative flex-shrink-0 w-1 h-1 m-3 mr-1 rounded-full bg-neutral-200`,
  isActive &&
    tw`bg-primary-600 before:(absolute -inset-2 rounded-full opacity-10) before:bg-primary-600`,
]);

// const StyledBadge = styled(Badge)({
//   "& .MuiBadge-badge": tw`border-2 border-neutral-800 aspect-square bg-primary-600 text-neutral-900`,
// });
const Badge = tw.div`rounded-sm w-4 h-4 text-xs flex items-center justify-center bg-primary-600 text-white font-bold`;

const isUrlMatch = (menuItem, url) =>
  menuItem.exactMatch
    ? menuItem.to === url
    : url.startsWith(new URL(menuItem.to, window.location).pathname);

const Sidebar = ({ setOpen }) => {
  const location = useLocation();
  const isMobile = useBreakpoint("md");
  const [_pending, startTransition] = useTransition();
  const [active, setActive] = useState(null);
  const [userOpened, setUserOpened] = useState(null);
  const [userClosedActive, setUserClosedActive] = useState(false);

  const menu = useUserMenu();

  useEffect(() => {
    const active = menu
      .flatMap((m) => m.children || [m])
      .find((m) => isUrlMatch(m, location.pathname));
    setActive(active);
    setUserOpened(null);
    setUserClosedActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="sidebar" tw="pt-4 flex flex-col justify-start">
      <Link to="/" className="logo__container">
        <img
          src={logoSelectWhiteColorUrl}
          className="logo logo--full"
          alt="Logo"
        />
        <img
          src={logoIconWhiteUrl}
          className="logo logo--icon"
          tw="pt-1 pb-4"
          alt="Logo icon"
        />
      </Link>
      <List
        tw="p-0 pb-24 space-y-2 overflow-x-hidden overflow-y-auto w-full"
        className="sidebar__list"
      >
        {menu.map((menuItem, i) => {
          const Icon = menuItem.icon;
          const totalBadgeValue = menuItem.children?.reduce(
            (a, b) => a + (b.badgeValue || 0),
            0
          );
          const isActive = active?.to && active.to === menuItem.to;
          const hasActiveChild = menuItem.children?.find(
            ({ to }) => active?.to === to
          );
          const isOpen =
            (isEqual(menuItem, userOpened) && !hasActiveChild) ||
            (!userClosedActive && hasActiveChild);

          const handleClick = () => {
            if (hasActiveChild) {
              setUserClosedActive(!userClosedActive);
            } else if (menuItem.children) {
              setUserOpened(isOpen ? null : menuItem);
            } else {
              setActive(menuItem);
              if (isMobile) setOpen(false);
            }
          };
          return (
            <div key={`menu-${i}`}>
              <ListItem tw="block p-0">
                <ListItemButton
                  component={menuItem.children ? Box : Link}
                  css={[
                    tw`flex items-center justify-between p-2 mx-2 font-medium tracking-wide`,
                    tw`bg-transparent rounded hover:bg-neutral-700`,
                    (isActive || hasActiveChild) &&
                      tw`bg-neutral-700 text-primary-300!`,
                  ]}
                  title={menuItem.label}
                  to={menuItem.to}
                  onClick={() => startTransition(handleClick)}
                >
                  <div tw="flex items-center">
                    <div className="nav__icon">
                      {/* {menuItem.badgeContent && (
                        <StyledBadge
                          badgeContent={menuItem.badgeContent}
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <Icon />
                        </StyledBadge>
                      )} */}
                      <Icon />
                    </div>
                    <div className="nav__label">{menuItem.label}</div>
                  </div>
                  <div tw="flex gap-2 items-center">
                    {!!totalBadgeValue && (
                      <Badge
                        css={[
                          tw`transition delay-200`,
                          isOpen && tw`opacity-0 delay-0`,
                        ]}
                      >
                        {totalBadgeValue}
                      </Badge>
                    )}
                    {menuItem.children && (
                      <KeyboardArrowDownRoundedIcon
                        css={isOpen && tw`rotate-180`}
                      />
                    )}
                  </div>
                </ListItemButton>
              </ListItem>
              {menuItem.children && (
                <div
                  tw="overflow-hidden"
                  className="nav__submenu"
                  style={{
                    height: isOpen
                      ? `${menuItem.children.length * 3}em`
                      : "0em",
                  }}
                >
                  {menuItem.children.map((subItem) => {
                    const isActive = subItem.to === active?.to;
                    return (
                      <ListItem
                        key={`children-${subItem.to}`}
                        tw="block p-0 text-base"
                      >
                        <ListItemButton
                          component={Link}
                          tw="h-12 p-0 px-2 flex items-center mx-2 bg-transparent hover:bg-neutral-800 rounded"
                          title={subItem.label}
                          onClick={() =>
                            startTransition(() => {
                              setActive(subItem);
                              setUserOpened(menuItem);
                              isMobile && setOpen(false);
                            })
                          }
                          to={subItem.to}
                        >
                          <Dot isActive={isActive} className="nav__dot" />
                          <div
                            className="nav__label"
                            css={isActive && tw`text-primary-300`}
                          >
                            {subItem.label}
                          </div>
                          {!!subItem.badgeValue && (
                            <Badge tw="ml-auto mr-4">
                              {subItem.badgeValue}
                            </Badge>
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </List>
      {/* <ListItem tw="block p-0 mt-auto mb-3">
        <ListItemButton
          component={Link}
          css={[
            tw`flex items-center justify-between p-2 mx-2 `,
            tw`bg-transparent rounded hover:bg-neutral-700`,
            location.pathname.startsWith("/settings") &&
              tw`bg-neutral-700 text-primary-600!`,
          ]}
          title="Settings"
          to="/settings"
        >
          <div tw="flex items-center">
            <div className="nav__icon">
              <SettingsRoundedIcon />
            </div>
            <div className="nav__label">Settings</div>
          </div>
        </ListItemButton>
      </ListItem> */}
    </div>
  );
};

export default Sidebar;
