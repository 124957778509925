import React, { memo } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { upCase } from "@utility/utilityFunctions";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { id: "id", label: "Id" },
  { id: "name", label: "Category Name" },
  { id: "type", label: "Type" },
  { id: "isRequired", label: "Is Required" },
  { id: "limitsAvailability", label: "Limits Availability" },
  { id: "priorityLevel", label: "Priority Level" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const GroupCategoryTable = ({ handleCategoryClick, categories, isLoading }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {!isLoading && categories.length === 0 && (
            <TableRow>
              <TableCell align="left" colSpan={6}>
                <Typography className={classes.headerText}>
                  There are currently no categories created for your
                  organization.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            categories.length > 0 &&
            categories.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => handleCategoryClick(row.id, "edit", "group")}
              >
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{upCase(row.type)}</TableCell>
                <TableCell align="left">
                  {row.isRequired ? "Yes" : "No"}
                </TableCell>
                <TableCell align="left">
                  {row.limitsAvailability ? "Yes" : "No"}
                </TableCell>
                <TableCell align="left">{row.priorityLevel}</TableCell>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={6}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

GroupCategoryTable.propTypes = {
  handleCategoryClick: PropTypes.func.isRequired,
  categories: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(GroupCategoryTable);
