/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useRef, useState } from "react";

import { LinearProgress, Popover } from "@mui/material";

import "../../styles/tutorial.css";
import { StyledButton } from "../StyledComponents";

export const positions = {
  top: {
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
  },
  bottom: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
  right: {
    anchorOrigin: {
      vertical: "center",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left",
    },
  },
  left: {
    anchorOrigin: {
      vertical: "center",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "right",
    },
  },
  center: {
    anchorOrigin: {
      vertical: "center",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "center",
    },
  },
};

const Tutorial = ({ steps, handleDismiss }) => {
  const [step, setStep] = useState(-1);
  const backdropRef = useRef(null);
  const blankRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stepToRender, setStepToRender] = useState({});
  const [loading, setLoading] = useState(false);

  let content = stepToRender.content;
  if (typeof stepToRender.content === "function") {
    const Component = stepToRender.content;
    content = (
      <Component
        next={() => setStep(step + 1)}
        back={() => setStep(step - 1)}
      />
    );
  }

  const handleClose = () => {
    setLoading(true);
    handleDismiss();
  };

  useEffect(() => {
    if (step < 0) return;
    const el = document.querySelector(steps[step].anchor) ?? blankRef.current;

    el.scrollIntoView();
    const boundingRect = el.getBoundingClientRect();
    const copy = el.cloneNode(true);

    ["top", "left", "width", "height"].forEach((pos) => {
      copy.style.setProperty(pos, `${boundingRect[pos]}px`);
    });

    copy.style.setProperty("position", "fixed");

    backdropRef.current.appendChild(copy);

    setTimeout(() => {
      setAnchorEl(copy);
      setStepToRender({ ...steps[step], index: step });
    }, 300);

    return () => {
      setTimeout(() => copy.remove(), 10);
      setAnchorEl(null);
    };
  }, [step, steps]);

  useEffect(() => {
    let timeout = setTimeout(() => setStep(0), 1000);
    return () => clearTimeout(timeout);
  }, []);
  if (step < 0) return null;
  return (
    <div
      id="tutorial-backdrop"
      tw="fixed inset-0 bg-neutral-900 bg-opacity-75 z-[4999]"
      ref={backdropRef}
    >
      <div tw="fixed inset-0" ref={blankRef} />
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        css={{
          "&": tw`z-[5000]`,
          ".MuiPopover-paper": tw`relative rounded-lg shadow-lg max-w-prose`,
        }}
        {...(positions[stepToRender.position] || positions.bottom)}
        {...stepToRender.popoverProps}
      >
        <div tw="p-4 rounded-xl text-lg">
          {content}

          {!stepToRender.hideActions && (
            <div tw="flex gap-2 mt-4">
              {stepToRender.index > 0 && (
                <StyledButton outlined onClick={() => setStep(step - 1)}>
                  Back
                </StyledButton>
              )}
              {stepToRender.index < steps.length - 1 && (
                <StyledButton cta onClick={() => setStep(step + 1)}>
                  Next
                </StyledButton>
              )}
              {stepToRender.index === steps.length - 1 && (
                <StyledButton cta onClick={handleClose} loading={loading}>
                  Complete
                </StyledButton>
              )}
            </div>
          )}
        </div>
        <LinearProgress
          variant="determinate"
          color="secondary"
          tw="absolute inset-0 top-auto"
          value={((step + 1) / steps.length) * 100}
        />
      </Popover>
    </div>
  );
};

export default Tutorial;
