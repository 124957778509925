import { WarehouseID } from "./Address";
import { Channel } from "./Channel";
import { Group } from "./Group";
import { Image } from "./Image";
import { OrderType } from "./Order";
import { Territory } from "./Territory";
import { VariablePricing } from "./VariablePricing";
import { Variant } from "./Variant";

type DateString = string;

export type Item = {
  id: string;
  status: "draft" | "complete";
  cost: number;
  customerIdentifier?: string;
  description: string;
  previewText: string;
  isActive: boolean;
  leadTimeInDays: number;
  minimumOrderQuantity: number;
  name: string;
  orderType: OrderType;
  orderableStartDate?: DateString;
  orderableEndDate?: DateString;
  packSize: number;
  price: number;
  reorderThreshold: number;
  sku: string;
  specification: Record<string, string>;
  unitOfMeasure: "ea" | "pack";
  visibleStartDate?: DateString;
  visibleEndDate?: DateString;
  warehouse?: WarehouseID;
  weight: number;
  isCustomizable: boolean;
  customizationDescription: string;
  isBundle: boolean;
  onHand: number;

  countryOfOriginId?: string;
  htsCode?: string;
  images: Image[];
  variants: Variant[];
  variablePricing: VariablePricing[];
  // Many to Many

  groups: Group[];
  territories: Territory[];
  channels: Channel[];
  promotions: any[];
};

const itemTransformer = {
  // We can optionally define the plural like this
  type: ["item", "items"],
  deserialize: (attr) => ({
    ...attr,
    cost: Number(attr.cost),
    price: Number(attr.price),
  }),
  serialize: (item) => ({
    ...item,
    cost: item.cost?.toString(),
    price: item.price?.toString(),
  }),
};

export default itemTransformer;
