import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isLoading: false,
  error: false,
};

const patchOrderSlice = createSlice({
  name: "patchOrder",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    patchSuccess(state) {
      state.isLoading = false;
      state.error = false;
    },
    setFailure(state) {
      state.isLoading = false;
      state.error = true;
    },
  },
});

export const { setIsLoading, patchSuccess, setFailure } =
  patchOrderSlice.actions;

export default patchOrderSlice.reducer;
