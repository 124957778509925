import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";

import { PurchaseOrder } from "@models/PurchaseOrder";
import client from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const purchaseOrdersKeyFactory = createQueryKeys("purchase-orders", {
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<PurchaseOrder[]>("purchase-orders", {
        params,
      }),
  }),
});

export const usePaginatePurchaseOrdersQuery = buildPaginatedQuery(
  purchaseOrdersKeyFactory.paginated,
  {
    staleTime: 60_000,
    placeholderData: keepPreviousData,
  }
);
