/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  EditTwoTone,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { formatMoney } from "@utility/utilityFunctions";

import usePreferExternalId from "../../../hooks/usePreferExternalId";
import StandardTableHead from "../../Utility/StandardTableHead";
import CustomizationModal from "../CustomizationModal";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
}));

const CollapseRow = ({
  classes,
  rowData,
  orders,
  type,
  usesItemCustomization,
  handleCustomizationModalOpen,
}) => {
  const [open, setOpen] = useState(false);
  const preferExternalId = usePreferExternalId();
  const { status } = useSelector((state) => state.currentOrderSet);

  const itemAllocationsHeadCells = [
    "Order #",
    "Address Name",
    "City / State",
    "Total Allocated",
  ];
  const alignCells = ["left", "left", "left", "right"];

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {preferExternalId ? rowData.warehouseId : rowData.sku}
        </TableCell>
        <TableCell align="left">{rowData.item.name}</TableCell>
        <TableCell align="left">
          {rowData.variantNames ? rowData.variantNames : "---"}
        </TableCell>
        <TableCell align="left">{formatMoney(rowData.price, false)}</TableCell>
        <TableCell align="left">{rowData.count}</TableCell>
        <TableCell align="left">
          {formatMoney(rowData.totalPrice, false)}
        </TableCell>
        {usesItemCustomization && type !== "inventory" && (
          <>
            <TableCell align="left">
              {rowData.isCustomizable
                ? rowData.customization && rowData.customization.length > 25
                  ? rowData.customization.slice(0, 21) + "..."
                  : rowData.customization ?? "---"
                : "Not Customizable"}
            </TableCell>
            <TableCell align="center" padding="checkbox">
              <IconButton
                size="small"
                aria-label="edit customization"
                style={{
                  justifySelf: "flex-end",
                  marginLeft: "5px",
                }}
                disabled={
                  !rowData.isCustomizable ||
                  ["submitted", "approved"].includes(status)
                }
                onClick={() =>
                  handleCustomizationModalOpen(
                    rowData.item.sku,
                    rowData.customization,
                    rowData.customizationDescription
                  )
                }
              >
                <EditTwoTone
                  size="small"
                  color={
                    rowData.isCustomizable &&
                    !["submitted", "approved"].includes(status)
                      ? "secondary"
                      : "inherit"
                  }
                />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell tw="p-0" colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div tw="rounded-t-lg bg-neutral-100 pt-4">
              <Typography
                className={classes.headerText}
                gutterBottom
                component="div"
                tw="pl-4"
              >
                Item Allocations
              </Typography>
              <TableContainer>
                <Table size="small" aria-label="item-allocations">
                  <StandardTableHead
                    headCells={itemAllocationsHeadCells}
                    alignCells={alignCells}
                  />
                  <TableBody>
                    {orders.map((order) => {
                      let currentVariant = order.variants.find(
                        (v) => v.sku === rowData.sku
                      );
                      if (currentVariant && currentVariant.count > 0) {
                        return (
                          <TableRow key={`${rowData.id}-${order.id}`}>
                            <TableCell>
                              <Link
                                tw="underline"
                                to={`/orders/history/${order.id}`}
                                target="_blank"
                              >
                                {order.id}
                              </Link>
                            </TableCell>
                            <TableCell>{order.address.name}</TableCell>
                            <TableCell>
                              {`${order.address.city} / ${order.address.stateOrRegion}`}
                            </TableCell>
                            <TableCell align="right">
                              {
                                order.variants.find(
                                  (v) => v.sku === rowData.sku
                                ).count
                              }
                            </TableCell>
                          </TableRow>
                        );
                      } else return null;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SetOverviewItemTable = ({ orders, variants, type }) => {
  const classes = useStyles();
  const preferExternalId = usePreferExternalId();

  const [customizationModal, setCustomizationModal] = useState({
    open: false,
    sku: null,
    setVariantIds: null,
    customization: null,
    description: null,
  });

  const {
    organization: { usesItemCustomization },
  } = useSelector((state) => state.currentUser);

  const headCells = [
    "",
    preferExternalId ? "Product Identifier" : "Sku",
    "Name",
    "Variant",
    "price",
    "Qty",
    "Total Price",
    ...(usesItemCustomization && type !== "inventory"
      ? ["Customization", "Edit"]
      : []),
  ];

  const handleCustomizationModalOpen = (sku, customization, description) => {
    let validIds = variants
      .filter((osv) => osv.item.sku === sku)
      .map((v) => v.id);
    setCustomizationModal({
      open: true,
      sku: sku,
      setVariantIds: validIds,
      customization: customization,
      description: description,
    });
  };

  const handleCustomizationModalClose = () =>
    setCustomizationModal({
      open: false,
      sku: null,
      setVariantIds: null,
      customization: null,
      description: null,
    });

  return (
    <>
      {customizationModal.open && (
        <CustomizationModal
          {...customizationModal}
          handleClose={handleCustomizationModalClose}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <StandardTableHead headCells={headCells} />
          <TableBody>
            {variants.map((v) => {
              if (v.count > 0) {
                return (
                  <CollapseRow
                    key={v.id}
                    classes={classes}
                    rowData={v}
                    orders={orders}
                    type={type}
                    usesItemCustomization={usesItemCustomization}
                    handleCustomizationModalOpen={handleCustomizationModalOpen}
                  />
                );
              } else return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SetOverviewItemTable.propTypes = {
  orders: PropTypes.array,
  variants: PropTypes.array,
  type: PropTypes.string,
};

export default SetOverviewItemTable;
