import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { queueReport } from "./globalReportSlice";
import reportDefinitions from "./reportDefinitions";
import { ReportSource } from "./types";

const useReport = (
  reportName: keyof ReturnType<typeof reportDefinitions>,
  [resource, params]: [resource: string, params: any]
) => {
  const dispatch = useDispatch();
  const requestReport = useSelector(
    (state: any) => state.globalReports.requestReport
  );

  useEffect(() => {
    if (requestReport === reportName) {
      dispatch(
        queueReport({
          reportName,
          resource,
          params,
          source: ReportSource.JSON_API,
        })
      );
    }
  }, [requestReport, reportName, resource, params, dispatch]);
};

export default useReport;
