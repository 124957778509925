/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { Contained, StyledButton } from "@components/StyledComponents";
import DocTitle from "@utility/DocTitle";

const FourOhFour = () => {
  return (
    <>
      <DocTitle title={"Not Found"} />
      <Contained>
        <h1 tw="text-6xl text-neutral-300 font-bold">404</h1>
        <h2 tw="text-4xl text-neutral-600 font-bold">Not found</h2>
        <p tw="text-lg max-w-prose mt-2 mb-6">
          Either this page doesn't exist, or you don't have the right permissions
          to access it. If you think this is a mistake, please contact your
          account administrator.
        </p>
        <StyledButton cta component={Link} to="/">
          Take me back
        </StyledButton>
      </Contained>
    </>
    
  );
};

export default FourOhFour;
