import React, { memo } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { id: "id", label: "Id" },
  { id: "name", label: "Name" },
  { id: "isActive", label: "Status" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ChannelTable = ({ handleChannelClick, channels, isLoading }) => {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {!isLoading && channels.length === 0 && (
            <TableRow>
              <TableCell align="left" colSpan={3}>
                <Typography className={classes.headerText}>
                  There are currently no channels created for your organization.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            channels.length > 0 &&
            channels.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => handleChannelClick(row.id, "edit", "channel")}
              >
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">
                  {row.isActive ? "Active" : "Inactive"}
                </TableCell>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={4}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ChannelTable.propTypes = {
  handleChannelClick: PropTypes.func.isRequired,
  channels: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(ChannelTable);
