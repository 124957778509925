import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import {
  createVariant,
  createVariantCategory,
  setHasUpdated,
  updateVariantCategory,
} from "@redux/slices/variantCategories/variantCategorySlice";
import { updateVariant } from "@redux/slices/variantCategories/variantCategorySlice";

import { StyledButton } from "../../StyledComponents";
import VariantNameAbbrTable from "./VariantNameAbbrTable";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  controlledTextField: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
}));

const VariantCategoryModal = ({ open, handleClose, type, setType, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      variant: "",
      variantAbbr: "",
    },
  });

  const variant = watch("variant");
  const variantAbbr = watch("variantAbbr");

  const [currentCategory, setCurrentCategory] = useState(null);
  const [newVariants, setNewVariants] = useState([]);
  const [localError, setLocalError] = useState(null);

  const { isUpdateLoading, hasUpdated, error, categories } = useSelector(
    (state) => state.variantCategories
  );

  const handleReset = () => {
    reset();
    setCurrentCategory(null);
    setLocalError(null);
    setNewVariants([]);
  };

  const onSubmit = async (data) => {
    if (data.name?.length === 0) {
      return setLocalError("You must input a name for the Category");
    }

    let variantsWithUpdatedNames = [];
    if (currentCategory) {
      variantsWithUpdatedNames = currentCategory.variants
        ? currentCategory.variants.map((v, index) => ({
            ...v,
            name: data.variants[index].name,
          }))
        : [];
    }

    const modifiedVariants = variantsWithUpdatedNames.filter((g, index) => {
      return g.name !== currentCategory.variants[index]?.name;
    });

    const attributes = {
      name: data.name,
      variants: type === "new" ? newVariants : modifiedVariants,
    };

    setLocalError(null);

    dispatch(
      type === "edit"
        ? updateVariantCategory(currentCategory.id, attributes)
        : createVariantCategory(attributes)
    );

    if (type === "edit") {
      const promiseArray = attributes.variants.map(
        (v) =>
          new Promise((resolve) =>
            dispatch(updateVariant(v.id, { name: v.name }, resolve))
          )
      );
      await Promise.all(promiseArray);
    }
  };

  const isVariantUnique = (value, type) => {
    return !categories
      .flatMap((c) => c.variants ?? [])
      .some((v) => v[type]?.toLowerCase() === value.toLowerCase());
  };

  const handleVariantChange = (event) => {
    const value = event.target.value;
    setValue("variant", value.charAt(0).toUpperCase() + value.slice(1));
  };

  const handleVariantAbbrChange = (event) => {
    const value = event.target.value.toUpperCase();
    setValue("variantAbbr", value);
  };

  const handleAddVariant = async (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    clearErrors("variantAbbr");

    if (variant.length === 0 || variantAbbr.length === 0) {
      return null;
    }

    let notUnique = false;
    if (!isVariantUnique(variant, "name")) {
      setError("variant", {
        type: "manual",
        message: "Name already exists",
      });
      notUnique = true;
    }

    if (!isVariantUnique(variantAbbr, "abbreviation")) {
      setError("variantAbbr", {
        type: "manual",
        message: "Abbreviation already exists",
      });
      notUnique = true;
    }

    if (notUnique) {
      return;
    }

    if (type === "new") {
      setNewVariants([
        ...newVariants,
        {
          name: variant,
          abbreviation: variantAbbr,
        },
      ]);
    } else {
      dispatch(
        createVariant({
          name: variant,
          abbreviation: variantAbbr,
          variantCategoryId: currentCategory.id,
        })
      );
    }
    resetField("variant");
    resetField("variantAbbr");
  };

  useEffect(() => {
    dispatch(setHasUpdated({ value: false }));
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setLocalError(error);
    }
  }, [error]);

  useEffect(() => {
    if (type === "edit" || id) {
      let categoryToEdit = categories.find((c) => c.id === id);
      if (categoryToEdit) {
        setValue("name", categoryToEdit.name);
        setValue("variants", categoryToEdit.variants);
        setCurrentCategory(categoryToEdit);
      }
    }
  }, [
    categories,
    currentCategory,
    hasUpdated,
    id,
    isUpdateLoading,
    type,
    setValue,
  ]);

  useEffect(() => {
    if (hasUpdated && type === "new") {
      setType("edit");
    }
    const categoryToEdit = categories.find((c) => c.name === getValues("name"));
    setCurrentCategory(categoryToEdit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdated, type, categories]);

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          handleReset();
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            {type === "edit" ? `Editing ${getValues("name")}` : "New Category"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              handleReset();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.fullWidthCenterColumn}>
              <TextField
                size="small"
                className={classes.settingsMargin}
                variant="outlined"
                color="secondary"
                name="name"
                type="text"
                label="Name"
                {...register("name")}
                fullWidth
              />
              <br />
              <Typography className={classes.headerText}>Variants</Typography>
              <div
                className={classes.controlledTextField}
                onSubmit={handleAddVariant}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  color="secondary"
                  name="variant"
                  type="text"
                  label="Add Variant"
                  {...register("variant")}
                  onChange={handleVariantChange}
                  error={!!errors.variant}
                  helperText={errors.variant ? "Name already exists" : ""}
                  fullWidth
                />
                <TextField
                  size="small"
                  variant="outlined"
                  color="secondary"
                  name="abbreviation"
                  type="text"
                  label="Abbreviation"
                  inputProps={{ maxLength: 3 }}
                  {...register("variantAbbr")}
                  onChange={handleVariantAbbrChange}
                  error={!!errors.variantAbbr}
                  helperText={errors.variantAbbr ? "Already exists" : ""}
                  style={{ width: "200px", marginLeft: "10px" }}
                />
                <IconButton
                  onClick={handleAddVariant}
                  disabled={variant.length === 0 || variantAbbr.length === 0}
                  size="large"
                >
                  <AddIcon
                    fontSize="large"
                    color={
                      variant.length === 0 || variantAbbr.length === 0
                        ? "disabled"
                        : "secondary"
                    }
                  />
                </IconButton>
              </div>
              <br />
              <VariantNameAbbrTable
                classes={classes}
                id={currentCategory?.id}
                register={register}
                newCategoryVariants={type === "new" ? newVariants : undefined}
              />
              <br />
              <StyledButton
                cta
                onClick={handleSubmit(onSubmit)}
                style={{
                  float: "right",
                  marginBottom: "20px",
                  minWidth: "62px",
                }}
              >
                {isUpdateLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "SUBMIT"
                )}
              </StyledButton>
              {!isUpdateLoading && localError && (
                <Typography
                  className={classes.bodyText}
                  style={{ color: "#920000" }}
                >
                  {localError}
                </Typography>
              )}
              {!isUpdateLoading && hasUpdated && (
                <Typography className={classes.bodyText}>
                  {type === "edit"
                    ? "Update Successful!"
                    : "New Category Added Successfully!"}
                </Typography>
              )}
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

VariantCategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  categories: PropTypes.array,
};

export default VariantCategoryModal;
