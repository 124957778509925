import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isOrdering: false,
};

const draftOrderSetsSlice = createSlice({
  name: "draftOrderSets",
  initialState,
  reducers: {
    setIsOrdering(state, action) {
      const { value } = action.payload;
      state.isOrdering = value;
    },
  },
});

export const { setIsOrdering } = draftOrderSetsSlice.actions;

export default draftOrderSetsSlice.reducer;
