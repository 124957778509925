import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { rfqsKeyFactory } from "@features/rfqs";
import { Quote } from "@models/Quote";
import client from "@services/api";
import { BodyWithId } from "@utils/reactQuery";

import { quotesKeyFactory } from "./quoteQueries";

type UpdateQuoteStatusPayload = {
  id: string;
  action: "accept" | "decline" | "complete" | "award" | "unaward";
  attributes?: Record<string, any>;
};

export const useQuoteActionMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const timeoutRef = useRef<number | null>(null);

  useEffect(
    () => () => {
      // clear on unmount
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );
  return useMutation({
    mutationFn: ({ id, action, attributes }: UpdateQuoteStatusPayload) =>
      client
        .post<Quote>(`quotes/${id}/${action}`, { ...attributes })
        .then((res) => res.data),
    onSuccess: (quote, { action }) => {
      queryClient.invalidateQueries({ queryKey: rfqsKeyFactory._def });
      queryClient.invalidateQueries({
        queryKey: quotesKeyFactory.paginated._def,
      });
      queryClient.setQueryData<Quote>(
        quotesKeyFactory.detail(quote.id).queryKey,
        quote
      );
      if (action === "decline") {
        setTimeout(() => navigate("/quotes"), 3000);
      }
    },
  });
};

export const useUpdateQuoteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, ...data }: BodyWithId) =>
      client.put<Quote>(`quotes/${id}`, data).then((res) => res.data),
    onSuccess: (quote) => {
      queryClient.invalidateQueries({
        queryKey: quotesKeyFactory.paginated._def,
      });
      queryClient.setQueryData<Quote>(
        quotesKeyFactory.detail(quote.id).queryKey,
        quote
      );
    },
  });
};
