import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { updateOrderSetDetails } from "@redux/slices/orders/currentOrderSetSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const OrderDetailModal = ({ orderId, handleClose, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentOrder = useSelector((state) =>
    state.currentOrderSet.orderSetOrders.find((ord) => ord.id === orderId)
  );

  const [attn, setAttn] = useState(
    currentOrder && currentOrder.attn ? currentOrder.attn : ""
  );
  const [notes, setNotes] = useState(
    currentOrder && currentOrder.notes ? currentOrder.notes : ""
  );

  const handleChanges = (notes, attn) => {
    dispatch(updateOrderSetDetails(orderId, notes, attn));
    handleClose();
  };

  if (!currentOrder) {
    return null;
  }

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography className={classes.headerText}>
              {`${orderId} - ${currentOrder.address.name} - ${currentOrder.address.city}, ${currentOrder.address.stateOrRegion}`}
            </Typography>
            <br />
            <TextField
              fullWidth
              style={{ marginBottom: "15px" }}
              variant="outlined"
              color="secondary"
              name="attn"
              type="text"
              label="Attention"
              value={attn}
              onChange={(evt) => setAttn(evt.target.value)}
            />
            <TextField
              fullWidth
              multiline
              rows="4"
              style={{ marginBottom: "15px" }}
              variant="outlined"
              color="secondary"
              name="notes"
              type="text"
              label="Order Notes"
              value={notes}
              onChange={(evt) => setNotes(evt.target.value)}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => handleChanges(notes, attn)}
              >
                SAVE DETAILS
              </Button>
            </div>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

OrderDetailModal.propTypes = {
  orderId: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
};

export default React.memo(OrderDetailModal);
