import { BudgetWithCalcs } from "@models/Budget";
import { stringToCents } from "@utility/utilityFunctions";

export const calculateBudgetTotal = (
  budget: BudgetWithCalcs,
  ogBudgetId: any,
  includeShippingInBudgets: boolean,
  includeTaxesInBudgets: boolean,
  totalEstFreight: number,
  totalEstTax: number,
  totalVariantPrice: number
) => {
  if (+budget.id === +ogBudgetId) {
    return (
      (stringToCents(+budget.pendingBalance) +
        totalVariantPrice +
        (includeShippingInBudgets ? totalEstFreight : 0) +
        (includeTaxesInBudgets ? totalEstTax : 0)) /
      100
    ); // convert back to dollars;
  } else {
    return budget.pendingBalance;
  }
};
