/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import _ from "lodash";

import { SectionTitle } from "@components/StyledComponents";
import { Column } from "@components/Table/SmartTable";
import { Quote } from "@models/Quote";
import { formatMoneyString, formatNumber } from "@utility/utilityFunctions";

const Cell = tw.div`px-4 py-2`;
const HeaderRow = tw(Cell)`pt-6 pb-0`;

type DividerRow = { label: string };
const dividerRow = (label: string): DividerRow => ({ label });
const isDividerRow = (row: Column | DividerRow): row is DividerRow =>
  (row as Column).id === undefined;

const insureValue = (v: any) => v || "---";

const otherTableRows: (Column<Quote> | DividerRow)[] = [
  dividerRow("Item Details"),
  {
    id: "itemDimensions",
    label: "Item Dimensions",
    render: insureValue,
  },
  {
    id: "pricingBasedOn",
    label: "Pricing Based On",
    render: insureValue,
  },
  {
    id: "brandingArea",
    label: "Branding Area",
    render: insureValue,
  },
  {
    id: "colorOptions",
    label: "Color Options",
    render: insureValue,
  },
  {
    id: "decorationOptions",
    label: "Decoration Options",
    render: insureValue,
  },
  dividerRow("Pre-Production"),
  {
    id: "preProCost",
    label: "Pre-Pro Cost",
    render: formatMoneyString,
  },
  {
    id: "preProLeadTimeInDays",
    label: "Pre-Pro Lead Time",
    render: formatNumber,
  },
  dividerRow("Other"),
  {
    id: "description",
    label: "Notes",
    render: insureValue,
  },
  {
    id: "ideas",
    label: "Ideas",
    render: insureValue,
  },
];

const CompareQuotesModal = ({
  quotes,
  onClose,
}: {
  quotes: Quote[];
  onClose: () => void;
}) => {
  // Keep track of hovered-over quote;
  const [hoveredQuote, setHoveredQuote] = useState<string | null>(null);
  // keep track of row
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const pricingTiers = _(quotes)
    .flatMap((quote) =>
      quote.pricingTierQuotes.map((ptq) => ({ ...ptq, quoteId: quote.id }))
    )
    .groupBy((ptq) => ptq.pricingTierQty)
    .mapValues((ptsq) => _.keyBy(ptsq, "quoteId"))
    .value();

  const allPricingTiers = _(pricingTiers)
    .keys()
    .map(Number)
    .sort((a, b) => a - b)
    .value();

  const sortedQuotes = _.sortBy(
    quotes,
    "supplierName",
    "requestForQuote.round"
  );

  const cellHoverProps = (quote: Quote | null, rowId: string, styles?) => ({
    onMouseEnter: () => {
      setHoveredQuote(quote?.id || null);
      setHoveredRow(rowId);
    },
    onMouseLeave: () => {
      setHoveredQuote(null);
      setHoveredRow(null);
    },
    css: [
      (quote?.id === hoveredQuote || (rowId && rowId === hoveredRow)) &&
        tw`bg-neutral-50`,
      rowId &&
        quote?.id === hoveredQuote &&
        rowId === hoveredRow &&
        tw`bg-neutral-100`,
      styles,
    ],
  });

  const Padding = () => (
    <>
      <Cell />
      {sortedQuotes.map((quote) => (
        <Cell key={quote.id} {...cellHoverProps(quote, "")} />
      ))}
    </>
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle tw="flex items-center justify-between -mb-4">
        Comparing {quotes.length} Quotes
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent tw="pl-8 pr-4">
        <div
          tw="relative grid"
          css={{
            gridTemplateColumns: `repeat(${
              sortedQuotes.length + 1
            }, minmax(300px, 1fr))`,
          }}
        >
          {/* Headers */}
          <Cell />
          {sortedQuotes.map((quote) => (
            <Cell
              key={`head-${quote.id}`}
              tw="rounded-t-xl"
              {...cellHoverProps(
                quote,
                `head-${quote.id}`,
                tw`py-4 rounded-t-xl`
              )}
            >
              <SectionTitle>{quote.supplierQuoteNumber}</SectionTitle>
              <div tw="text-lg font-medium">
                {quote.supplierName} - R{quote.requestForQuote.round}
              </div>
            </Cell>
          ))}
          <div tw="bg-white rounded-l-xl drop-shadow-2xl col-span-full grid grid-cols-[subgrid] px-4 -mx-4">
            <Padding />

            {/* Pricing Tiers */}
            {allPricingTiers.map((qty) => (
              <React.Fragment key={`row-${qty}`}>
                <Cell {...cellHoverProps(null, `row-${qty}`)}>
                  Tier {formatNumber(qty)}
                </Cell>
                {sortedQuotes.map((quote) => {
                  const tier = pricingTiers[qty][quote.id];
                  return (
                    <Cell
                      key={`cell-${quote.id}-${qty}`}
                      {...cellHoverProps(quote, `row-${qty}`)}
                    >
                      {tier ? (
                        <>
                          <span tw="p-1 rounded bg-primary-50 text-primary-900">
                            {formatMoneyString(tier.cost)}
                          </span>{" "}
                          <span tw="text-primary-800 pl-3">
                            {tier.leadTimeInDays} days
                          </span>
                        </>
                      ) : (
                        "---"
                      )}
                    </Cell>
                  );
                })}
              </React.Fragment>
            ))}

            <Padding />
          </div>

          {otherTableRows.map((row, i) =>
            isDividerRow(row) ? (
              <React.Fragment key={`row-${i}`}>
                <HeaderRow>
                  <SectionTitle>{row.label}</SectionTitle>
                </HeaderRow>
                {sortedQuotes.map((quote) => (
                  <HeaderRow
                    key={`cell-${quote.id}`}
                    {...cellHoverProps(quote, "")}
                  />
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment key={`row-${row.id}`}>
                <Cell {...cellHoverProps(null, `row-${row.id}`)}>
                  {row.label}
                </Cell>
                {sortedQuotes.map((quote, i) => (
                  <Cell
                    key={`cell-${quote.id}-${row.id}`}
                    {...cellHoverProps(quote, `row-${row.id}`)}
                  >
                    {row.render
                      ? row.render(_.get(quote, row.id), quote, i)
                      : _.get(quote, row.id)}
                  </Cell>
                ))}
              </React.Fragment>
            )
          )}
          <Padding />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CompareQuotesModal;
