import { useState } from "react";

export const useDetailedInput = (
  initialValue,
  secondaryFunc,
  type,
  filterType
) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(initialValue),
    bind: {
      value,
      onChange: (event) => {
        let newValue = event.target.value;
        if (event.target.type === "checkbox") {
          if (typeof newValue === "boolean") {
            newValue = !newValue;
          } else {
            // Filters stringify booleans
            newValue = newValue === "false" ? "true" : "false";
          }
        }
        setValue(newValue);
        secondaryFunc(newValue, type, filterType);
      },
    },
  };
};
