/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PromotionTable from "@components/BrandHQ/Promotions/PromotionTable";
import FilterPanel from "@components/Filtering/FilterPanel";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
  TableLoading,
} from "@components/StyledComponents";
import OrderPatchLoading from "@components/Utility/OrderPatchLoading";
import {
  fetchNextPromotions,
  resetCurrentPromotion,
} from "@redux/slices/promotions/promotionSlice";

import { useInitialFetch } from "../hooks/useInitialFetch";
import useUrlQuery from "../hooks/useUrlQuery";
import DocTitle from "@utility/DocTitle";

/*
A filtered view that handles viewing, creating, and editing promotions for the organization.
Promotions can be used in many ways, and there are many different options when creating promotions.
Depending on the organization, promotions may or may not be used.
*/

const defaultFilters = {
  userIds: [],
  promotionRedemptionType: null,
  promotionStatus: "active",
  promotionType: null,
  ignoreUserTerritory: true,
  ignoreUserChannel: true,
  filterType: "promotion",
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PromotionAdmin = () => {
  const params = useUrlQuery();

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { promotionList, isLoading, isNextLoading, nextLink } = useSelector(
    (state) => state.promotions
  );

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current.scrollTop !== 0) {
        dispatch(fetchNextPromotions(nextLink));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 300,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const handlePromotionClick = (id, type) => {
    dispatch(resetCurrentPromotion());
    navigate(
      id ? `/admin/promotions/${type}/${id}` : `/admin/promotions/${type}`
    );
  };

  useInitialFetch(dispatch, "promotion", defaultFilters, params, true, true);

  return (
    <>
      <DocTitle title={"Promotion Admin"} />
      <OrderPatchLoading />
      <FixedHeightScrollLastChild>
        <header className={classes.titleBar}>
          <Typography className={classes.titleText}>Promotion Admin</Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton cta onClick={() => handlePromotionClick(null, "new")}>
              NEW PROMOTION
            </StyledButton>
          </div>
        </header>
        <FilterPanel />
        <OpaqueCard tw="p-0 relative overflow-hidden">
          <PromotionTable
            handlePromotionClick={handlePromotionClick}
            promotions={promotionList}
            isLoading={isLoading}
            scrollRef={scrollRef}
          />
          <TableLoading isNextLoading={isNextLoading} />
        </OpaqueCard>
      </FixedHeightScrollLastChild>
    </>
  );
};

PromotionAdmin.propTypes = {};

export default PromotionAdmin;
