import { useState } from "react";
import { useSelector } from "react-redux";

import format from "date-fns/format";
import subDays from "date-fns/subDays";
import PropTypes from "prop-types";

import { DatePicker } from "@utils/forms";

import { FilterRow } from "../Filtering/FilterComponents";
import { StyledButton } from "../StyledComponents";
import GroupAutoComplete from "../Utility/AutoCompleteFields/GroupAutoComplete";
import TerritoryAutoComplete from "../Utility/AutoCompleteFields/TerritoryAutoComplete";
import UserAutoComplete from "../Utility/AutoCompleteFields/UserAutoComplete";
import OrderTypeSelector from "../Utility/Selectors/OrderTypeSelector";

const FiltersOrderReports = ({
  reset,
  setReset,
  handleFilters,
  handleSearch,
  filterType,
}) => {
  const isLoading = useSelector((state) => state.globalState.isLoading);
  const categories = useSelector((state) => state.groupCategories.categories);
  const {
    groups,
    organization: { usesOnDemand, usesInventory },
  } = useSelector((state) => state.currentUser);
  const { dateRangeStart, dateRangeEnd, orderType } = useSelector(
    (state) => state.filters
  );

  const [type, setType] = useState(orderType || "");

  let userCategories =
    groups.length === 0
      ? categories
      : categories.map((cat) => {
          let currentGroups = groups.filter(
            (g) => g.groupCategoryId === cat.id
          );
          if (currentGroups.length > 0) {
            return {
              ...cat,
              groups: currentGroups,
            };
          } else return { ...cat, groups: [...cat.groups] };
        });

  return (
    <div>
      <FilterRow>
        <DatePicker
          label="Submitted At Start"
          value={
            dateRangeStart || format(subDays(new Date(), 30), "MM/dd/yyyy")
          }
          onChange={(value) =>
            handleFilters(
              format(value, "MM/dd/yyyy"),
              "dateRangeStart",
              filterType
            )
          }
          disabled={isLoading}
        />

        <DatePicker
          label="Submitted At End"
          value={dateRangeEnd || format(new Date(), "MM/dd/yyyy")}
          onChange={(value) =>
            handleFilters(
              format(value, "MM/dd/yyyy"),
              "dateRangeEnd",
              filterType
            )
          }
          disabled={isLoading}
        />

        {(usesOnDemand || usesInventory) && (
          <OrderTypeSelector
            handleOrderType={handleFilters}
            orderType={type}
            setType={setType}
            filterType={filterType}
          />
        )}
        {userCategories &&
          userCategories.length > 0 &&
          userCategories.map((cat) => (
            <GroupAutoComplete
              key={cat.id}
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={filterType}
              categoryLabel={cat.name}
              options={cat.groups}
            />
          ))}

        <UserAutoComplete
          handleChange={handleFilters}
          reset={reset}
          setReset={setReset}
          filterType={filterType}
        />

        <TerritoryAutoComplete
          handleChange={handleFilters}
          reset={reset}
          setReset={setReset}
          filterType={filterType}
        />

        <StyledButton cta onClick={handleSearch} disabled={isLoading}>
          GENERATE REPORT
        </StyledButton>
      </FilterRow>
    </div>
  );
};

FiltersOrderReports.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  filterType: PropTypes.string,
};

export default FiltersOrderReports;
