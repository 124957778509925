import _ from "lodash";

import client from "@services/api";
import asyncPool from "@utility/asyncPool";

export default async function saveQuoteCosts(quoteId, formCosts, savedCosts) {
  const savedCostsById = _(savedCosts)
    .map((c) => _.omit(c, "quote"))
    .keyBy("id")
    .value();

  const costsToDelete = savedCosts.filter(
    (cost) => !formCosts.find((c) => c.id === cost.id)
  );
  const costsToCreate = formCosts.filter((cost) => !cost.id);
  const costsToUpdate = formCosts.filter(
    (cost) => cost.id && !_.isEqual(cost, savedCostsById[cost.id])
  );

  const actions = [
    ...costsToDelete.map((c) => ["delete", c]),
    ...costsToCreate.map((c) => ["post", { ...c, quoteId }]),
    ...costsToUpdate.map((c) => ["patch", c]),
  ];
  return await asyncPool(5, actions, async ([method, body]) => {
    switch (method) {
      case "post":
        await client.post("costs", body);
        break;
      case "delete":
        await client.delete(`costs/${body.id}`);
        break;
      case "patch":
        await client.update(`costs/${body.id}`, body);
        break;
    }
  });
}
