/** @jsxImportSource @emotion/react */
import "twin.macro";

import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  PageTitle,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import {
  QuotesByStatusFilter,
  SupplierQuotesTable,
  usePaginatedQuoteQuery,
} from "@features/quotes";
import DocTitle from "@utility/DocTitle";

const Quotes = () => {
  const [filterParams] = useFilterParams();
  const { status } = filterParams;
  const { data, ...tableProps } = usePaginatedQuoteQuery({
    filter: {
      status: status && status !== "any" ? status.split(",") : undefined,
      searchTerm: filterParams.q,
      orderType: filterParams.orderType,
    },
    sort: filterParams.sort,
  });

  return (
    <FixedHeightScrollLastChild>
      <DocTitle title={"Quote Requests"} />
      <div tw="flex justify-between items-center">
        <PageTitle>Quotes</PageTitle>
      </div>
      <div tw="flex flex-wrap sm:flex-nowrap gap-3">
        <QuotesByStatusFilter status="sent" title="Pending" />
        <QuotesByStatusFilter status="accepted" title="Accepted" />
        <QuotesByStatusFilter status="complete,awarded" title="Completed" />
      </div>
      <Filters
        searchTitle="Search item name"
        slots={["quoteStatus", "orderType"]}
      />
      <OpaqueCard tw="p-0 overflow-hidden relative">
        <SupplierQuotesTable
          {...tableProps}
          query={filterParams.q}
          rows={data ?? []}
        />
      </OpaqueCard>
    </FixedHeightScrollLastChild>
  );
};

export default Quotes;
