import { useQuery } from "@tanstack/react-query";

import { GroupCategory } from "@models/GroupCategory";
import client from "@services/api";

export const useGroupCategoriesListQuery = () => {
  return useQuery({
    queryKey: ["group-categories"],
    queryFn: () =>
      client.get<GroupCategory[]>("group-categories").then((res) => res.data),
    staleTime: Infinity,
  });
};
