import { useEffect, useState } from "react";

const tailwindBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 153,
};

type BreakpointKey = keyof typeof tailwindBreakpoints;

export const useBreakpoint = <K extends BreakpointKey>(breakpointKey: K) => {
  const [breakpointActive, setBreakpointActive] = useState(false);

  useEffect(() => {
    const breakpointValue = tailwindBreakpoints[breakpointKey];
    const checkBreakpoint = () => {
      setBreakpointActive(window.innerWidth <= breakpointValue);
    };
    window.addEventListener("resize", checkBreakpoint);
    checkBreakpoint();
    return () => window.removeEventListener("resize", checkBreakpoint);
  }, [breakpointKey]);

  return breakpointActive;
};
