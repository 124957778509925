const imageExtensions = [
  "ai",
  "gif",
  "png",
  "webp",
  "avif",
  "bmp",
  "djvu",
  "ps",
  "ept",
  "eps",
  "eps3",
  "fbx",
  "flif",
  "gif",
  "glb",
  "gltf",
  "heif",
  "heic",
  "ico",
  "indd",
  "jpg",
  "jpe",
  "jpeg",
  "jp2",
  "wdp",
  "jxr",
  "hdp",
  "pdf",
  "png",
  "psd",
  "arw",
  "cr2",
  "svg",
  "tga",
  "tif",
  "tiff",
  "usdz",
  "webp",
];
export default imageExtensions;
