/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { Radio } from "@mui/material";

import { OrderStatus } from "@models/Order";
import { ControlledRadioGroup } from "@utils/forms";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";

const useOrderSetStatuses = (): { [key in OrderStatus | "all"]?: string } => {
  const { approvalFlows } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const oneRoundApproval = approvalFlows.length >= 1;
  const twoRoundApproval = approvalFlows.length >= 2;

  return {
    all: "Any submitted",
    draft: "Draft",
    ...(oneRoundApproval && { submitted: "Submitted" }),
    ...(twoRoundApproval && { "approval-review": "Approval Review" }),
    approved: "Approved",
    canceled: "Canceled",
    error: "Error",
  };
};

const OrderStatusPopper = () => {
  const { control } = useFilterContext();
  const orderStatuses = useOrderSetStatuses();

  const orderStatusOptions = Object.keys(orderStatuses);

  return (
    <div tw="min-w-[200px]">
      <h4 tw="text-neutral-600 my-4 px-3">Order Status is</h4>

      <ControlledRadioGroup tw="w-full" name="orderStatus" control={control}>
        {orderStatusOptions.map((key) => (
          <label
            key={key}
            tw="flex items-center px-3 border-t border-neutral-200 text-sm hover:bg-neutral-100 cursor-pointer"
          >
            <Radio size="small" value={key} edge="start" />
            {orderStatuses[key]}
          </label>
        ))}
      </ControlledRadioGroup>
    </div>
  );
};

const OrderStatusFilterTile = (props) => {
  const orderStatuses = useOrderSetStatuses();
  const { filterValues } = useFilterContext();
  const OrderStatus = filterValues["orderStatus"];
  const OrderStatusText = OrderStatus && orderStatuses[OrderStatus];

  return (
    <Chip {...props}>
      <span>Status is&nbsp;</span>
      <b>{OrderStatusText}</b>
    </Chip>
  );
};

const OrderStatusFilter = {
  Chip: OrderStatusFilterTile,
  Popper: OrderStatusPopper,
};

export default OrderStatusFilter;
