import { memo } from "react";

import { CircularProgress } from "@mui/material";

import clsx from "clsx";
import format from "date-fns/format";
import PropTypes from "prop-types";

import { DatePicker } from "@utils/forms";

import MemoTextField from "../../Utility/MemoTextField";

const GeneralPanel = ({
  classes,
  type,
  formData,
  handleFormUpdate,
  activeWindow,
}) => {
  if (type === "edit" && !activeWindow) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      <MemoTextField
        value={formData.name}
        setValue={handleFormUpdate}
        formKey="name"
        label="Name"
        fullWidth={true}
        componentClass={classes.settingsMargin}
      />
      <div className={classes.twoWideContainer}>
        <DatePicker
          className={clsx(classes.twoWide, classes.settingsMargin)}
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="open-date"
          label="Open Date"
          value={formData.openDate}
          onChange={(value) => {
            handleFormUpdate("openDate", format(value, "MM/dd/yyyy"));
          }}
        />

        <DatePicker
          className={clsx(classes.twoWide, classes.settingsMargin)}
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="close-date"
          label="Close Date"
          value={formData.closeDate}
          onChange={(value) => {
            handleFormUpdate("closeDate", format(value, "MM/dd/yyyy"));
          }}
        />
      </div>
      <div className={classes.twoWideContainer}>
        <DatePicker
          className={clsx(classes.twoWide, classes.settingsMargin)}
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="review-close-date"
          label="Review Close Date"
          value={formData.reviewCloseDate}
          onChange={(value) => {
            handleFormUpdate("reviewCloseDate", format(value, "MM/dd/yyyy"));
          }}
        />

        <DatePicker
          className={clsx(classes.twoWide, classes.settingsMargin)}
          color="secondary"
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="in-market-date"
          label="In Market Date"
          value={formData.inMarketDate}
          onChange={(value) => {
            handleFormUpdate("inMarketDate", format(value, "MM/dd/yyyy"));
          }}
        />
      </div>
    </>
  );
};

GeneralPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  handleFormUpdate: PropTypes.func.isRequired,
  activeWindow: PropTypes.object,
};

export default memo(GeneralPanel);
