/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Container } from "@mui/material";

import WithPermission, { permissions } from "../../Utility/WithPermission";
import Threshold from "./Threshold";

const EmailPreferences = () => {
  return (
    <Container>
      <WithPermission allow={permissions.admin}>
        <Threshold />
      </WithPermission>
    </Container>
  );
};

export default EmailPreferences;
