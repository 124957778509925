/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { Control, Controller } from "react-hook-form";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  InputBase,
  LinearProgress,
  autocompleteClasses,
  styled,
} from "@mui/material";

import { debounce, get, sortBy } from "lodash";

import { useFilterContext } from "../filterContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledAutocompletePopper = styled("div")(() => ({
  [`&.${autocompleteClasses.popperDisablePortal}`]: tw`relative`,
  [`& .${autocompleteClasses.paper}`]: tw`m-0 text-sm shadow-none`,
  [`& .${autocompleteClasses.listbox}`]: {
    ...tw`p-0 bg-white`,
    [`& .${autocompleteClasses.option}`]: {
      ...tw`items-center min-h-0 p-0 border-t border-neutral-200`,
      '&[aria-selected="true"]': tw`bg-transparent!`,
      '&:hover, [aria-selected="true"]:hover, &.Mui-focused': tw`bg-neutral-100`,
    },
  },
}));

function PopperComponent(props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} style={{ width: "auto" }} />;
}

const Loading = () => <CircularProgress tw="-mt-2" size={20} />;

type Option = {
  id: string;
  name: string;
  [x: string]: any;
};

type ControlledFilterAutocompleteProps = {
  control: Control;
  name: string;
  options: Option[];
  inputPlaceholder?: string;
  isLoading: boolean;
  isUpdating?: boolean;
  onQueryChange?: (val) => void;
};

const ControlledFilterAutocomplete = ({
  control,
  name,
  options,
  inputPlaceholder,
  isLoading = true,
  isUpdating = false,
  onQueryChange,
}: ControlledFilterAutocompleteProps) => {
  const { filterValues } = useFilterContext();
  const existingValues = get(filterValues, name) ?? ([] as string[]);
  const [searchValue, setSearchValue] = useState("");

  const getOpObj = (option: any) =>
    option.id ? option : options.find((o) => o.id === option);

  const sortedOptions = sortBy(
    [...options],
    (o) => !existingValues.includes(o.id)
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div tw="flex-col">
          <Autocomplete
            {...field}
            open
            multiple
            value={field.value || []}
            inputValue={searchValue}
            onInputChange={(_, val, reason) => {
              if (reason !== "input") return;
              setSearchValue(val);
              if (onQueryChange) {
                debounce(onQueryChange, 200)(val);
              }
            }}
            filterOptions={(options) =>
              options.filter((option) =>
                option.name.toLowerCase().includes(searchValue.toLowerCase())
              )
            }
            onChange={(event: any, newValue: any[], reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }

              field.onChange(newValue.map((v) => v.id ?? v));
            }}
            disableCloseOnSelect
            renderTags={() => null}
            noOptionsText={isLoading ? <Loading /> : "No options"}
            options={sortedOptions}
            isOptionEqualToValue={(option, value) =>
              option.id === getOpObj(value)?.id
            }
            getOptionLabel={(opt) => opt.name}
            renderOption={(props: any, option, { selected }) => (
              <li {...props} tw="py-0! px-0!" key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                <span tw="whitespace-nowrap pr-3">{option.name}</span>
              </li>
            )}
            renderInput={(params) => {
              return (
                <>
                  <InputBase
                    tw="p-3 z-[1450]"
                    autoFocus
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    placeholder={inputPlaceholder}
                  />
                  <div tw="h-0.5 overflow-hidden">
                    {isUpdating && <LinearProgress />}
                  </div>
                </>
              );
            }}
            PopperComponent={PopperComponent}
          />
        </div>
      )}
    />
  );
};

export default ControlledFilterAutocomplete;
