export const mapVariantCategories = (categories) => {
  const mappedCategories = categories.map((cat) => ({
    id: cat.id,
    name: cat.name,
  }));
  return mappedCategories;
};

export const mapVariants = (variants) => {
  const mappedVariants = variants.map((variant) => ({
    id: variant.id,
    name: variant.name,
    abbreviation: variant.abbreviation,
    variantCategoryId: variant["variant-category"].id,
    variantCategoryName: variant["variant-category"].name,
  }));
  return mappedVariants;
};
