import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;

const toUTC = (date) => {
  const utcFromLocal = new Date(date.getTime() + getTimezoneOffset(date));
  return utcFromLocal;
};

class AdapterUTCDateFns extends AdapterDateFns {
  constructor(...args) {
    super(...args);
    // https://github.com/mui/material-ui-pickers/issues/329
    // https://github.com/dmtrKovalenko/date-io/blob/master/packages/date-fns/src/date-fns-utils.ts#L280
    this.date = function (value) {
      if (typeof value === "undefined") {
        return toUTC(new Date());
      }

      if (value === null) {
        return null;
      }
      if (value.length === 10 || value.length === 8) {
        return toUTC(new Date(value));
      } else {
        return new Date(value);
      }
    };
  }
}
export default AdapterUTCDateFns;
