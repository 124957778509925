import React from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { id: "id", label: "Id" },
  { id: "name", label: "Name" },
  { id: "states", label: "States" },
  { id: "checkoutSettings", label: "Checkout Settings" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  cellRoot: {
    minWidth: "500px",
  },
}));

const TerritoryTable = ({ handleTerritoryClick, territories, isLoading }) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: "100%" }}>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {!isLoading && territories.length === 0 && (
            <TableRow>
              <TableCell align="left" colSpan={3}>
                <Typography className={classes.headerText}>
                  {`There are currently no visible territories..`}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            territories.length > 0 &&
            territories.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => {
                  handleTerritoryClick(row.id, "edit");
                }}
              >
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left" classes={{ root: classes.cellRoot }}>
                  {row.states
                    .map((s) => s.code)
                    .sort()
                    .join(", ")}
                </TableCell>

                <TableCell align="left">
                  {row.allowBudgetCheckout && <div>Budgets</div>}
                  {row.allowStandardCheckout && <div>Standard</div>}
                  {row.allowStripeCheckout && <div>Credit Card</div>}
                </TableCell>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={3}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TerritoryTable.propTypes = {
  handleTerritoryClick: PropTypes.func,
  territories: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default TerritoryTable;
