import { Controller, ControllerProps } from "react-hook-form";

import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import { routeResourceTypes } from "@services/api";

import { StyledTextInput } from "./ControlledInputs";
import {
  ResourceAutocomplete,
  ResourceAutocompleteProps,
} from "./ResourceAutocomplete";

export const ControlledResourceAutocomplete = <
  TResourceName extends keyof routeResourceTypes,
  Multiple extends boolean = false,
  DisableClearble extends boolean = false,
>({
  name,
  control,
  rules,
  ...props
}: Pick<ControllerProps<any>, "control" | "rules" | "name"> &
  ResourceAutocompleteProps<
    TResourceName,
    routeResourceTypes[TResourceName],
    Multiple,
    DisableClearble,
    false
  >) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
      <ResourceAutocomplete
        {...field}
        {...(props as any)}
        value={field.value ?? (props.multiple ? [] : null)}
        onChange={(e, value) => {
          if (props.multiple && Array.isArray(value)) {
            field.onChange(value?.map((v) => (v.id ? v.id : v)));
          } else {
            field.onChange(value?.id ?? value);
          }
        }}
        popupIcon={<KeyboardArrowDownRounded />}
        renderInput={({ isLoading, ...params }) => (
          <StyledTextInput
            {...params}
            label={props.label}
            autoComplete="off"
            error={!!error}
            helperText={error?.message}
            endAdornment={
              <>
                {isLoading ? <CircularProgress size={15} /> : null}
                {params.InputProps.endAdornment}
              </>
            }
          />
        )}
      />
    )}
  />
);
