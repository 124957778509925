import React from "react";
import { useSelector } from "react-redux";

import { PaginatedResourceProps } from "@components/Table/ReactQueryTable";
import OrderPatchLoading from "@components/Utility/OrderPatchLoading";
import { Item } from "@models";

import ItemCatalogGrid from "./ItemCatalogGrid";
import ItemCatalogTable from "./ItemCatalogTable";

export type ItemCatalogProps = PaginatedResourceProps & {
  rows: Item[];
  query?: string;
  showCheckbox?: boolean;
  isRowDisabled?: (row: Item) => boolean;
  getCheckboxText?: (row: Item) => string;
  rowActions?: (row: Item) => React.ReactNode | null;
  nextPage?: never;
};

const ItemCatalogView = (props: ItemCatalogProps) => {
  const productView: "table" | "grid" = useSelector(
    (state: any) => state.globalState.productView
  );

  return (
    <>
      <OrderPatchLoading />
      {productView === "table" ? (
        <ItemCatalogTable {...props} />
      ) : (
        <ItemCatalogGrid {...props} />
      )}
    </>
  );
};

export default ItemCatalogView;
