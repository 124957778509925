import { formatDateString } from "@utility/utilityFunctions";

import { mapChannels } from "../channelSlice";
import { mapItems } from "../items/maps";
import { mapTerritories } from "../territories/maps";

const handleCloudinaryId = (id, type, resourceType) => {
  if (id) {
    if (type === "img") {
      return `https://res.cloudinary.com/brandhub/image/upload/${id}`;
    } else
      return `https://res.cloudinary.com/brandhub/${resourceType}/upload/fl_attachment/${id}`;
  } else {
    if (type === "img") {
      return "https://res.cloudinary.com/brandhub/image/upload/v1626199716/dev/Brandhub/NoImageFound_axxgh5.png";
    } else return null;
  }
};

const handleOrderWindows = (windows) => {
  if (!windows || windows.length === 0) {
    return [];
  } else {
    return windows.map((w) => ({
      id: w.id,
      name: w.name,
      openDate: formatDateString(w["open-date"]),
      closeDate: formatDateString(w["close-date"]),
      reviewCloseDate: formatDateString(w["review-close-date"]),
      inMarketDate: formatDateString(w["in-market-date"]),
    }));
  }
};

export const mapProgram = (program, items) => {
  return {
    id: program.id,
    name: program.name,
    description: program.description,
    orderDescription: program["order-description"] ?? null,
    goals: program.goals,
    strategies: program.strategies,
    imgUrl: handleCloudinaryId(program["cloudinary-id"], "img", null),
    cloudinaryId: program["cloudinary-id"],
    additionalFileCloudinaryId: program["additional-file-cloudinary-id"],
    additionalFileName: program["additional-file-name"],
    additionalFileResourceType: program["additional-file-resource-type"],
    additionalFile: handleCloudinaryId(
      program["additional-file-cloudinary-id"],
      "download",
      program["additional-file-resource-type"]
    ),
    startDate: formatDateString(program["start-date"]),
    endDate: formatDateString(program["end-date"]),
    items: items ? mapItems(items) : [],
    isActive: program["is-active"],
    isTerritoryExclusive: program["is-territory-exclusive"] ? true : false,
    territories: mapTerritories(program.territories),
    channel: program.channel ? mapChannels([program.channel])[0] : null,
    orderWindows: handleOrderWindows(program["order-windows"]),
  };
};

export const mapPrograms = (programs) => {
  const mappedPrograms = programs.map((program) => mapProgram(program));
  return mappedPrograms;
};
