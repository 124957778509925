import { Checkbox } from "@mui/material";

import { xor } from "lodash";

import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import { Program } from "@models/Program";
import { CldImage } from "@services/cloudinary";

export type OrderWindowProgramTableProps = PaginatedResourceProps & {
  programData: Program[];
  selectedPrograms: string[];
  setSelectedPrograms: (programs: string[]) => void;
};

const OrderWindowProgramTable = ({
  programData,
  selectedPrograms,
  setSelectedPrograms,
  ...tableProps
}: OrderWindowProgramTableProps) => {
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = programData.map((p) => p.id);
      setSelectedPrograms(newSelecteds);
      return;
    }
    setSelectedPrograms([]);
  };

  const handleProgramSelect = (id: string) => {
    setSelectedPrograms(xor(selectedPrograms, [id]));
  };

  const isSelected = (id: string) => selectedPrograms.indexOf(id) !== -1;

  const numSelected = selectedPrograms.length;
  const rowCount = programData.length;

  const programColumns: Column<Program>[] = [
    {
      id: "select",
      label: (
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={handleSelectAll}
          disabled={rowCount === 0}
        />
      ),
      render: (i, row) => (
        <div tw="align-center">
          <Checkbox
            checked={isSelected(row.id)}
            inputProps={{ "aria-labelledby": `item-checkbox-${i}` }}
            onClick={(event) => event.stopPropagation()}
            onChange={(event) => {
              handleProgramSelect(row.id);
              event.stopPropagation();
            }}
          />
        </div>
      ),
    },
    {
      id: "preview",
      label: "Preview",
      render: (_, row) => (
        <CldImage
          tw="w-full aspect-square object-contain 
        border-2 border-neutral-200
      bg-white rounded-md"
          publicId={row.cloudinaryId}
          size="thumbnail"
        />
      ),
    },
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    {
      id: "isTerritoryExclusive",
      label: "Is Territory Exclusive",
      render: (_, row) => (row.isTerritoryExclusive ? "Yes" : "No"),
    },
    {
      id: "territories",
      label: "Territories",
      render: (_, row) =>
        row.isTerritoryExclusive
          ? row.territories.map((t) => t.name).join(", ")
          : "Available Nationally",
    },
    {
      id: "channel",
      label: "Channel",
      render: (_, row) => (row.channel ? row.channel.name : "---"),
    },
  ];

  return (
    <ReactQueryTable
      rows={programData}
      columns={programColumns}
      onRowClick={(prog: Program) => handleProgramSelect(prog.id)}
      {...tableProps}
    />
  );
};

export default OrderWindowProgramTable;
