// A version of stableHash that makes assumptions about how the JSONApi Response gets built
// in order to generate a smaller hash, and prevent an "Invalid string length" error on large responses

// adapted from https://github.com/vercel/swr/blob/main/src/_internal/utils/hash.ts

const isUndefined = (value: any): value is undefined =>
  typeof value === "undefined";

// use WeakMap to store the object->key mapping
// so the objects can be garbage collected.
// WeakMap uses a hashtable under the hood, so the lookup
// complexity is almost O(1).
const table = new WeakMap<object, number | string>();

// counter of the key
let counter = 0;

// A stable hash implementation that supports:
// - Fast and ensures unique hash properties
// - Handles unserializable values
// - Handles object key ordering
// - Generates short results
//
// This is not a serialization function, and the result is not guaranteed to be
// parsable.
export const betterHash = (arg: any): string => {
  // Keep track of seen objects to handle circular references, and minimize the hash string
  const localTable = new WeakMap<object, number | string>();
  let localCounter = 0;

  const hash = (arg: any): string => {
    const type = typeof arg;
    const constructor = arg && arg.constructor;
    const isDate = constructor === Date;
    
    let result: any;
    let index: any;

    if (Object(arg) === arg && !isDate && constructor !== RegExp) {
      // Object/function, not null/date/regexp. Use WeakMap to store the id first.
      // If it's already hashed, directly return the result.
      if (localTable.has(arg)) return localTable.get(arg) as string;
      result = table.get(arg);
      if (result) {
        localTable.set(arg, ++localCounter + "&");
        return result;
      }

      // Store the hash first for circular reference detection before entering the
      // recursive `hash` calls.
      // For other objects like set and map, we use this id directly as the hash.
      result = ++counter + "~";
      table.set(arg, result);
      localTable.set(arg, ++localCounter + "&");

      if (constructor === Array) {
        // Array.
        result = "@";
        for (index = 0; index < arg.length; index++) {
          result += hash(arg[index]) + ",";
        }
        table.set(arg, result);
      }
      if (constructor === Object) {
        // Object, sort keys.
        result = "#";
        const keys = Object.keys(arg).sort();
        while (!isUndefined((index = keys.pop() as string))) {
          if (!isUndefined(arg[index])) {
            result += index + ":" + hash(arg[index]) + ",";
          }
        }
        table.set(arg, result);
      }
    } else {
      result = isDate
        ? arg.toJSON()
        : type === "symbol"
        ? arg.toString()
        : type === "string"
        ? JSON.stringify(arg)
        : "" + arg;
    }

    return result;
  };
  return hash(arg);
};