import { useCallback } from "react";
import { useSelector } from "react-redux";

import {
  CloudinaryEvent,
  Event,
  UploadInfo,
  UploadInterface,
  Util,
} from "cloudinary-core";

const getUploadConfig = (orgName: string) => {
  const sanitizedOrgName = orgName
    .replace(/\s+/g, "-")
    .replace(/[^\w-]/g, "")
    .toLowerCase();
  return {
    cloudName: "brandhub",
    uploadPreset: `brandhub_default`,
    folder: `prod/Brandhub/${sanitizedOrgName}`,
  };
};

export function useOpenCloudinaryWidget() {
  const orgName = useSelector(
    (state: any) => state.currentUser.organization.name
  );
  return useCallback(
    ({
      onError,
      onSuccess,
      config,
    }: {
      onSuccess: (file: Event<"success">) => void;
      onError?: (error: string, file: Event<CloudinaryEvent>) => void;
      config?: Partial<UploadInterface>;
    }) => {
      window.cloudinary.openUploadWidget(
        { ...getUploadConfig(orgName), ...config },
        (error, file) => {
          if (error) onError?.(error, file);
          if (file.event === "success") onSuccess(file);
        }
      );
    },
    [orgName]
  );
}
// https://cloudinary.com/documentation/javascript_image_and_video_upload#upload_endpoint
export function useUploadFileToCloudinary() {
  const orgName = useSelector(
    (state: any) => state.currentUser.organization.name
  );
  return useCallback(
    (file: File, conf?: Partial<UploadInterface>) => {
      const { cloud_name, ...config } = Util.withSnakeCaseKeys({
        ...getUploadConfig(orgName),
        ...conf,
      }) as Record<string, any>;

      const formData = new FormData();
      for (const [key, value] of Object.entries(config)) {
        formData.append(key, value);
      }
      formData.append("file", file);

      return fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/upload`, {
        method: "POST",
        body: formData,
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Failed to upload file to Cloudinary");
        }
        return res.json() as Promise<UploadInfo>;
      });
    },
    [orgName]
  );
}
