export const buildOrgPatch = (attrs, countries, id) => {
  const patchData = {
    data: {
      type: "organization",
      attributes: {
        id: id,
        countries_ids: countries.map(({ id }) => id),
        ...attrs,
      },
    },
  };

  return patchData;
};

export const buildFavoriteItemPatch = (itemIds) => {
  const patchData = {
    data: {
      type: "current-user",
      attributes: {
        "favorite-item-ids": itemIds,
      },
    },
  };

  return patchData;
};

export const buildDashboardOptionPatch = (option) => {
  const patchData = {
    data: {
      type: "dashboard-option",
      attributes: {
        position: option.isActive ? option.position : 0,
        "display-name": option.displayName,
        "is-active": option.isActive,
      },
    },
  };

  return patchData;
};
