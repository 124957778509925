/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { StyledButton } from "@components/StyledComponents";
import { SaveFormBanner } from "@features/ui";
import { User } from "@models/User";
import useRoleIs from "@utils/useRoleIs";

import { useCreateUserMutation, useUpdateUserMutation } from "../data";
import formValuesFromUser, { UserFormValues } from "../formValuesFromUser";
import useNavigateToUser from "../useNavigateToUser";
import ReadOnlySupplierUserForm from "./ReadOnlySupplierUserForm";
import UserFormGeneral from "./UserFormGeneral";
import UserFormVisibility from "./UserFormVisibility";

const UserForm = ({
  user,
  id,
  fullPage,
}: {
  user?: User;
  id: string | null;
  fullPage?: boolean;
}) => {
  const navigateToUser = useNavigateToUser();
  const createUserMutation = useCreateUserMutation();
  const updateUserMutation = useUpdateUserMutation();
  const editorRoleIs = useRoleIs();

  const isReadOnlySupplier =
    !editorRoleIs("super") && user?.role === "supplier";

  const isLoading =
    createUserMutation.isPending || updateUserMutation.isPending;
  const formMethods = useForm({
    defaultValues: formValuesFromUser(user),
    mode: "onBlur",
  });
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = formMethods;

  const role = watch("role");
  const status = watch("status");
  const statusToggleText = status === "inactive" ? "Activate" : "Deactivate";

  const toggleStatus = () =>
    setValue("status", status === "active" ? "inactive" : "active", {
      shouldDirty: true,
    });
  const handleSave = async (data: UserFormValues) => {
    if (id) {
      updateUserMutation.mutate(data);
    } else {
      createUserMutation.mutate(data, {
        onSuccess: (res) => navigateToUser(res.id),
      });
    }
  };

  const handleReset = useCallback(() => {
    reset(formValuesFromUser(user));
  }, [user, reset]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  if (isReadOnlySupplier) {
    return <ReadOnlySupplierUserForm user={user} />;
  }

  return (
    <FormProvider {...formMethods}>
      {id && isDirty && (
        <SaveFormBanner
          handleReset={handleReset}
          handleSave={handleSubmit(handleSave)}
          isLoading={isLoading}
          {...(!fullPage && {
            css: tw`top-0 h-16 font-normal border-0`,
          })}
        />
      )}
      <form tw="space-y-4">
        <div tw="space-y-4">
          {/* if the saved user is inactive, show banner at the top */}
          {user?.status === "inactive" && (
            <div
              css={[
                tw`flex items-center justify-between px-6 py-3 mx-6 mt-4 -mb-4 rounded-lg bg-neutral-100 text-neutral-700`,
                fullPage && tw`mx-0 mb-0 bg-white border-2 border-neutral-200`,
              ]}
            >
              This user is {status}.
              <StyledButton outlined onClick={toggleStatus} size="small">
                {statusToggleText}
              </StyledButton>
            </div>
          )}

          <UserFormGeneral />
          {!["supplier", "select-account-manager", "purchasing-agent"].includes(
            role
          ) && <UserFormVisibility />}
        </div>
        {/* If the saved user is active, show small button at bottom */}
        <div tw="mx-6 mb-6">
          {id && user?.status === "active" && (
            <div>
              <button
                tw=" text-neutral-600 underline-offset-2 hover:(underline text-red-700)"
                onClick={toggleStatus}
                type="button"
              >
                {statusToggleText} User
              </button>
            </div>
          )}
          {!id && (
            <StyledButton
              cta
              onClick={handleSubmit(handleSave)}
              disabled={isLoading}
            >
              Create User
            </StyledButton>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default UserForm;
