import { useSelector } from "react-redux";

import { useApiResourcePaginated } from "@services/api";

export function useDraftOrderSets() {
  const { id } = useSelector((state: any) => state.currentUser);
  return useApiResourcePaginated("order-sets", {
    filter: {
      status: ["draft"],
      userIds: [id],
    },
    focusThrottleInterval: 30_000,
    initialSize: 10,
    revalidateAll: true,
  });
}
