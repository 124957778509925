import { usePaginatedItemsQuery } from "@features/items";

export default function useProgramItems(programId: string | undefined) {
  return usePaginatedItemsQuery(
    {
      filter: { programIds: [programId as string] },
    },
    {
      enabled: !!programId,
      initialPagesToLoad: 10,
    }
  );
}
