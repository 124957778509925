/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { upCase } from "@utility/utilityFunctions";
import { InputOption } from "@utils/forms/ControlledInputs";
import useRoleIs from "@utils/useRoleIs";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";
import ControlledFilterAutocomplete from "./ControlledFilterAutocomplete";

const usePurchaseOrderStatuses = (): InputOption[] => {
  const roleIs = useRoleIs();
  const { hasPurchasingAgents } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const isSupplier = roleIs("supplier", "select-account-manager");

  const options: InputOption[] = [
    ...(!isSupplier && !roleIs("purchasing-agent")
      ? [{ id: "draft", name: "Draft" }]
      : []),
    ...(hasPurchasingAgents && !isSupplier
      ? [{ id: "ready-to-purchase", name: "Ready to Purchase" }]
      : []),
    { id: "submitted", name: "Submitted" },
    { id: "in-progress", name: "In Progress" },
    ...(hasPurchasingAgents ? [{ id: "received", name: "Received" }] : []),
    { id: "complete", name: "Complete" },
    { id: "canceled", name: "Canceled" },
  ];

  return options;
};

const PurchaseOrderStatusFilterPopper = () => {
  const { control } = useFilterContext();
  const purchaseOrderStatuses = usePurchaseOrderStatuses();

  return (
    <ControlledFilterAutocomplete
      control={control}
      name="purchaseOrderStatus"
      options={purchaseOrderStatuses}
      inputPlaceholder="Filter PO Statuses"
      isLoading={false}
      isUpdating={false}
    />
  );
};

const PurchaseOrderStatusFilterTile = (props) => {
  const { filterValues } = useFilterContext();
  const poStatuses = filterValues["purchaseOrderStatus"] ?? [];

  return (
    <Chip {...props}>
      <span tw="text-neutral-600">
        {poStatuses.length === 0 ? "Select PO Status" : <>PO Status is&nbsp;</>}
      </span>
      <span tw="text-neutral-800 font-medium">
        {upCase(poStatuses.slice(0, 1).join(", ").replace("-", " "))}
        {poStatuses.length > 1 && <span>, + {poStatuses.length - 1}</span>}
      </span>
    </Chip>
  );
};

const PurchaseOrderStatusFilter = {
  Chip: PurchaseOrderStatusFilterTile,
  Popper: PurchaseOrderStatusFilterPopper,
};

export default PurchaseOrderStatusFilter;
