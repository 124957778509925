import { Item } from "@models/Item";
import _ from "lodash";
import { useSelector } from "react-redux";


const useGetOnHandForItem = (item: Item | undefined) => {
  const {
    id,
    currentTerritoryId, 
    organization: { allocationLocation },
  } = useSelector((state: any) => state.currentUser);

  const usesAllocations = useSelector(
    (state: any) => !!state.currentUser.organization.allocationLocation
  );
  
  if (!item) {
    return 0;
  }

  if (!usesAllocations) {
    return _(item.variants).filter("isActive").sumBy("globalAvailableToOrderQty");
  }

  return _(item.variants)
    .filter((variant): variant is NonNullable<typeof variant> => variant.isActive)
    .sumBy((variant) => {
      const userAllocation = variant.variantAllocations?.find(
        (allocation) => 
          allocationLocation === "user" ? allocation.user?.id === id : 
          allocation.territory?.id === currentTerritoryId
      );
      return userAllocation?.availableToOrderQty ?? 0;
    });

};

export default useGetOnHandForItem;