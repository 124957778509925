/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";

import { filter } from "lodash";

import { StyledButton } from "@components/StyledComponents";
import { Item, OrderSetVariant } from "@models";
import { wait } from "@utility/utilityFunctions";

const getLabelText = (item: Item, orderSetVariants: OrderSetVariant[]) => {
  const orderSetVariantIds = orderSetVariants.map((osv) => osv.variant.id);

  if (filter(item.variants, "isActive").length === 0) return "Inactive";

  const defaultVariant = item.variants.find((v) => v.isDefault);

  const allVariantsInCart =
    defaultVariant && defaultVariant.isActive
      ? orderSetVariantIds.includes(defaultVariant.id)
      : item.variants.every(
          (v) => v.isDefault || !v.isActive || orderSetVariantIds.includes(v.id)
        );

  if (allVariantsInCart) {
    return "In Cart"; // non-breaking space
  }
  if (item.warehouse !== "shipstation" && item.onHand === 0) {
    return "Out of Stock";
  }
  return "";
};

const AddToCartButton = ({
  item,
  orderSetVariants,
  addToOrderSet,
}: {
  item: Item;
  orderSetVariants: OrderSetVariant[];
  addToOrderSet: (item: Item) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(getLabelText(item, orderSetVariants));

  const handleAddToCart = async () => {
    setLoading(true);
    // insures loading animation is shown for at least .8 seconds
    await Promise.all([addToOrderSet(item), wait(800)]);
    setLoading(false);
  };

  useEffect(() => {
    if (loading) return;
    setMessage(getLabelText(item, orderSetVariants));
  }, [loading, item, orderSetVariants]);

  return (
    <StyledButton
      outlined
      loading={loading}
      disabled={!!message}
      onClick={handleAddToCart}
      tw="whitespace-nowrap"
    >
      {message || "Add to cart"}
    </StyledButton>
  );
};

export default AddToCartButton;
