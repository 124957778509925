import { useEffect, useState } from "react";

import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";

import asyncPool from "@utility/asyncPool";
import { BodyWithId } from "@utils/reactQuery";
import useDeepCompareEffect from "@utils/useDeepCompareEffect";

import client, { jsonApiPaginate } from ".";
import { splitOptions } from "./helperFunctions";
import removeFromSwrCacheList from "./removeFromSwrCacheList";
import Routes from "./routeResourceTypes";
import { Falsy, HookOptions } from "./types";
import updateSwrCacheList from "./updateSwrCacheList";

const useApiResourcePaginated = <R extends keyof Routes>(
  resource: R | Falsy,
  options: Omit<HookOptions, "id"> & SWRInfiniteConfiguration = {}
) => {
  const { requestOptions, swrOptions } = splitOptions(options);

  const { data, mutate, ...swr } = useSWRInfinite(
    jsonApiPaginate(resource ? [resource, requestOptions] : null),
    (key) => client.get(...key),
    swrOptions
  );

  const [isLoading, setIsLoading] = useState(false);

  const totalEntries = data?.[0]?.meta?.total_entries || 0;
  const flattenedData = data?.flatMap((res) => res.data) || [];
  const percentLoaded = flattenedData.length / totalEntries || 0;
  const isLastPage = data ? flattenedData.length === totalEntries : false;

  const update = ({ id, ...body }: BodyWithId) => {
    return updateSwrCacheList(
      mutate,
      client.update(`${resource}/${id}`, {
        relationshipNames: data?.[0]?.relationshipNames,
        ...body,
      })
    );
  };

  const create = (body: Record<string, any>) => {
    return updateSwrCacheList(
      mutate,
      client.post(resource as string, {
        relationshipNames: data?.[0]?.relationshipNames,
        ...body,
      })
    );
  };

  const createMany = async (
    bodies: Record<string, any>[],
    poolLimit: number = 5
  ) => {
    const iteratorFn = async (body: any) => {
      return await client.post(resource as string, body);
    };
    const { results, errors } = await asyncPool(poolLimit, bodies, iteratorFn);

    return updateSwrCacheList(mutate, {
      data: results.map((res: any) => res.data),
      error: (errors as any[]).find((res: any) => res.error)?.error,
    });
  };

  const remove = ({ id }) =>
    removeFromSwrCacheList(mutate, client.delete(resource + `/${id}`));

  useEffect(() => {
    if (!swr.isValidating && isLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swr.isValidating]);

  useDeepCompareEffect(() => {
    if (swr.isValidating) setIsLoading(true);
  }, [resource, options]);

  return {
    ...swr,
    mutate,
    data: flattenedData as Routes[R][],
    nextPage: () => swr.setSize((s) => s + 1),
    isLoading,
    isLastPage,
    percentLoaded,
    remove,
    update,
    create,
    createMany,
  };
};

export default useApiResourcePaginated;
