/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Add } from "@mui/icons-material";
import { Alert, MenuItem, TextField } from "@mui/material";

import { useApiResource } from "@services/api";
import { buildFromPath } from "@utility/utilityFunctions";

import { OpaqueCard, StyledButton } from "../StyledComponents";
import OptionsMenu from "../Utility/OptionsMenu";

const TextInput = (props) => (
  <TextField tw="w-32 bg-white" size="small" {...props} />
);

const Range = ({
  range,
  handleUpdate,
  handleRemove,
  purchaseOrderVariantId,
  setError,
  error,
}) => {
  const [editing, setEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    type: "beacon-range",
    minBeaconUidHex: "",
    maxBeaconUidHex: "",
    purchaseOrderVariantId,
  });

  const handleSubmit = () => {
    const { minBeaconUidHex, maxBeaconUidHex } = data;
    if (!minBeaconUidHex || !maxBeaconUidHex) return;

    if (parseInt(minBeaconUidHex, 16) > parseInt(maxBeaconUidHex, 16)) {
      setError("Max UID must be larger than or equal to min UID");
      return;
    }

    setIsLoading(true);
    handleUpdate(data)
      .then(() => {
        setError(null);
        setEditing(false);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handleDelete = () => handleRemove(range);

  const control = (name) => ({
    name,
    value: data[name],
    onChange: (e) =>
      setData({ ...data, [name]: e.target.value.replace(/[^0-9a-f]/gi, "") }),
  });

  useEffect(() => {
    range && setData({ ...range });
  }, [range]);

  if (editing || !range?.id)
    return (
      <div
        css={[
          tw`flex items-center w-full gap-2 p-2 rounded bg-neutral-100`,
          !range?.id && tw`bg-blue-50`,
          error && tw`bg-red-50`,
        ]}
      >
        <TextInput label="Min UID" {...control("minBeaconUidHex")} />
        <TextInput label="Max UID" {...control("maxBeaconUidHex")} />
        <StyledButton cta onClick={handleSubmit} loading={isLoading}>
          Save
        </StyledButton>
      </div>
    );

  return (
    <div tw="flex items-center rounded bg-neutral-50 pl-3 py-1 border border-neutral-50 hover:border-neutral-200 cursor-pointer">
      <div>
        {range.minBeaconUidHex}
        {" — "}
        {range.maxBeaconUidHex}
      </div>
      <OptionsMenu tw="mx-1">
        <MenuItem onClick={() => setEditing(true)}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </OptionsMenu>
    </div>
  );
};

const VariantRanges = ({ variant }) => {
  const { data, update, create, remove } = useApiResource(
    "beacon-ranges",
    buildFromPath("filter.purchase-order-variant-id", variant.id)
  );

  const ranges = data || [];
  const [error, setError] = useState(null);
  const [hasDraft, setHasDraft] = useState(false);

  const handleUpdate = (data) =>
    new Promise((res, rej) =>
      (data.id ? update : create)(data)
        .then(() => {
          setHasDraft(false);
          res();
        })
        .catch((err) => {
          console.log(err);
          setError(err.message);
          rej(err);
        })
    );

  return (
    <div>
      <div tw="flex items-start gap-4">
        <div tw="w-40 flex-none">{variant.sku}</div>
        <div tw="flex grow flex-wrap items-start gap-3">
          {ranges.map((range, i) => (
            <Range
              key={range.id || `draft${i}`}
              range={range}
              handleUpdate={handleUpdate}
              handleRemove={remove}
              setError={setError}
              error={error}
            />
          ))}
          {hasDraft && (
            <Range
              onSubmit={() => setHasDraft(false)}
              handleUpdate={handleUpdate}
              purchaseOrderVariantId={variant.id}
              setError={setError}
              error={error}
            />
          )}
          {!hasDraft && (
            <StyledButton
              outlined
              startIcon={<Add />}
              onClick={() => setHasDraft(true)}
            >
              New Range
            </StyledButton>
          )}
        </div>
      </div>
      {error && (
        <Alert severity="error" tw="mt-2 whitespace-pre-wrap">
          {error}
        </Alert>
      )}
    </div>
  );
};

const BeaconRangesTable = (props) => {
  const {
    currentPurchaseOrder: { purchaseOrderVariants },
  } = useSelector((state) => state.purchaseOrder);
  return (
    <OpaqueCard tw="space-y-4" {...props}>
      {purchaseOrderVariants.map((variant) => (
        <VariantRanges key={variant.id} variant={variant} />
      ))}
    </OpaqueCard>
  );
};

export default BeaconRangesTable;
