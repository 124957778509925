import { CheckoutType } from "@models";

export default function checkoutTypeLabel(checkoutType: CheckoutType) {
  if (!checkoutType) return "";
  const checkoutTypes = {
    stripe: "Card",
    budget: "Budget",
    default: "Standard",
  };
  return checkoutTypes[checkoutType];
}
