/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";

import _ from "lodash";

import { RecessCard, StyledButton } from "@components/StyledComponents";
import { calculateOrderTotal } from "@features/orderHistory";
import { useApiResource } from "@services/api";
import { formatMoneyString } from "@utility/utilityFunctions";
import { TextInput } from "@utils/forms";

type DenyOrderSetsModalProps = {
  handleClose: () => void;
  handleDeny: (reason: string) => Promise<void>;
  singleId?: string;
};

const OrderSetOverview = ({ orderSetId }) => {
  const { organization } = useSelector((state: any) => state.currentUser);
  const { data: orders = [], isLoading } = useApiResource("orders", {
    filter: { orderSetId },
  });
  const orderSetTotal = orders.reduce(
    (acc, order) => acc + calculateOrderTotal(order, organization),
    0
  );

  return (
    <div tw="text-sm space-y-1">
      <div tw="px-4 py-1 bg-neutral-100 text-neutral-700 flex justify-between font-medium text-base">
        <div>Order-Set #{orderSetId}</div>
        <div>{isLoading ? <Skeleton /> : formatMoneyString(orderSetTotal)}</div>
      </div>
      {orders.map((order) => (
        <div key={order.id} tw="px-4 flex justify-between">
          <div>{order.id}</div>
          <div>
            {formatMoneyString(calculateOrderTotal(order, organization))}
          </div>
        </div>
      ))}
    </div>
  );
};

const DenyOrderSetsModal: React.FC<DenyOrderSetsModalProps> = ({
  handleClose,
  handleDeny,
  singleId,
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");

  const { selectedOrderSetIds } = useSelector(
    (state: any) => state.orderReview
  );

  const displayIds = singleId
    ? [singleId]
    : _.sortBy(selectedOrderSetIds, (id) => id);

  const handleSubmit = async () => {
    if (!reason) return;
    setLoading(true);
    await handleDeny(reason);
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
    >
      <DialogTitle tw="flex justify-between items-start">
        Deny Orders
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h3 tw="text-lg font-medium">
          {singleId
            ? `Please provide a reason for denying order-set #${singleId}`
            : "Please provide a reason for denying the following order-sets:"}
        </h3>

        <RecessCard tw="space-y-3 px-0 max-h-[300px] overflow-y-auto">
          {displayIds.map((id) => (
            <OrderSetOverview key={id} orderSetId={id} />
          ))}
        </RecessCard>

        <TextInput
          tw="mt-3"
          autoFocus
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton
          role="button"
          outlined
          disabled={loading}
          onClick={handleClose}
        >
          Cancel
        </StyledButton>
        <StyledButton
          cta
          role="submit"
          disabled={reason.length === 0}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DenyOrderSetsModal;
