import { useCallback } from "react";
import {
  NavigateOptions,
  Path,
  useLocation,
  useNavigate,
} from "react-router-dom";

const useSetLocation = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  return useCallback(
    (
      location: {
        pathname?: string;
        search?: `?${string}` | "";
        hash?: `#${string}` | "";
      },
      navigateOptions?: NavigateOptions
    ) => {
      const newLocation: Path = {
        ...currentLocation,
        ...location,
      };
      navigate(
        newLocation.pathname + newLocation.search + newLocation.hash,
        navigateOptions
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentLocation.pathname,
      currentLocation.search,
      currentLocation.hash,
      navigate,
    ]
  );
};

export default useSetLocation;
