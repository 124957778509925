import { mapOrgs, mapUsers } from "../user/maps";

export const mapSuppliers = (suppliers) => {
  if (!suppliers || suppliers.length === 0) {
    return [];
  }

  return suppliers
    .map((s) => {
      const users = s.users ? mapUsers(s.users) : [];
      return {
        id: s.id,
        isActive: s["is-active"],
        name: s.name,
        users,
        organizations: s.organizations
          ? mapOrgs(s.organizations).availableOrgs
          : [],
        userNames:
          users.length > 0 ? users.map((s) => s.name).join(", ") : "---",
      };
    })
    .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
};
