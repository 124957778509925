import { useApiResource } from "@services/api";

const useActiveOrderWindows = () => {
  return useApiResource("order-windows", {
    filter: { isActive: true },
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });
};

export default useActiveOrderWindows;
