/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import { OpaqueCard } from "@components/StyledComponents";
import { isOrderWindowActive } from "@features/orderWindows";
import { Program } from "@models";
import { CldImage } from "@services/cloudinary";
import { formatUtcDate } from "@utility/utilityFunctions";

import useCurrentDraftOrderSet from "../ordering/hooks/useCurrentDraftOrderSet";

type ProgramCardProps = {
  program: Program;
  orderWindowId?: string;
};

const ProgramCard = ({ program, orderWindowId }: ProgramCardProps) => {
  const navigate = useNavigate();
  const { role } = useSelector((state: any) => state.currentUser);

  const orderWindow = program.orderWindows.find(
    (ow) => ow.id === orderWindowId
  );
  const isProgramOrderable = orderWindow && isOrderWindowActive(orderWindow);

  const { orderSet, createOrderSetVariants, isLoading } =
    useCurrentDraftOrderSet("pre-order", {
      programId: program.id,
      orderWindowId,
    });

  const link = orderWindowId
    ? `/programs/${program.id}/order/${orderWindowId}`
    : `/programs/${program.id}`;

  const description =
    program.description?.length > 200
      ? program.description.substr(0, 200) + "..."
      : program.description;

  return (
    <OpaqueCard tw="flex flex-col justify-between p-0 relative">
      <CldImage
        tw="absolute inset-0 object-cover object-center w-full h-full"
        publicId={program.cloudinaryId}
        size="medium"
        alt=""
      />
      <div tw="flex gap-6 p-4 bg-white/90 backdrop-blur-md h-full">
        <Link tw="flex-none" to={link}>
          <CldImage
            tw="rounded-lg w-40 h-40 object-contain object-center"
            publicId={program.cloudinaryId}
            alt={program.name}
            size="medium"
          />
        </Link>
        <div>
          <Link to={link}>
            <h3 tw="mt-2 text-xl font-bold text-neutral-700">{program.name}</h3>
          </Link>
          <div tw="flex flex-wrap mt-1 gap-x-6 text-neutral-600 tracking-wide whitespace-nowrap text-sm">
            <div>
              {orderWindow && (
                <>
                  <div>
                    Closes:
                    <b> {formatUtcDate(orderWindow.closeDate, "PP")}</b>
                  </div>
                  <div>
                    In Market:
                    <b> {formatUtcDate(orderWindow.inMarketDate, "PP")}</b>
                  </div>
                </>
              )}
            </div>
          </div>
          <p tw="text-neutral-500 mt-2">{description}</p>
          {role !== "read-only" && isProgramOrderable && (
            <Button
              tw="text-primary-600 normal-case"
              disabled={isLoading}
              onClick={(evt) => {
                evt.stopPropagation();
                if (orderSet) {
                  navigate(`/orders/open/draft/${orderSet.id}`);
                } else {
                  createOrderSetVariants([]);
                }
              }}
            >
              {orderSet ? "View Order" : "Order"}
            </Button>
          )}
        </div>
      </div>
    </OpaqueCard>
  );
};

export default ProgramCard;
