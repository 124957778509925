/** @jsxImportSource @emotion/react */
import "twin.macro";

import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  PageTitle,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import {
  NewRfqButton,
  QuotedItemsTable,
  usePaginatedQuotedItemsQuery,
} from "@features/rfqs";
import DocTitle from "@utility/DocTitle";

const QuotedItems = () => {
  const [filterParams] = useFilterParams();
  const { data, ...tableProps } = usePaginatedQuotedItemsQuery({
    filter: {
      programIds: filterParams.programs,
      searchTerm: filterParams.q,
    },
  });

  return (
    <FixedHeightScrollLastChild>
      <DocTitle title={"Quote Requests"} />
      <div tw="flex justify-between items-center">
        <PageTitle>Quote Requests</PageTitle>
        <NewRfqButton />
      </div>
      <Filters searchTitle="Search Item" slots={["programs"]} />
      <OpaqueCard tw="p-0 overflow-hidden relative">
        <QuotedItemsTable
          {...tableProps}
          query={filterParams.q}
          rows={data ?? []}
        />
      </OpaqueCard>
    </FixedHeightScrollLastChild>
  );
};

export default QuotedItems;
