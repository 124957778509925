/*
 * Defines separate groups of permissions for the site, pending decisions
 * on how we will handle that, but the general idea is that for a given permission
 * there will be pre defined options, or you can manually set roles in an
 * array
 */
import _ from "lodash";

const internalRoles = [
  "admin",
  "approver",
  "orderer",
  "purchaser",
  "read-only",
  "super",
] as const;

const externalRoles = [
  "supplier",
  "purchasing-agent",
  "select-account-manager",
] as const;

const allRoles = _.union(internalRoles, externalRoles);

const ordererAccess = _.without(internalRoles, "read-only");

const internalHigherUps = _.without(
  internalRoles,
  "orderer",
  "read-only",
  "approver"
);
const orderReviewAccess = _.without(internalRoles, "orderer", "read-only");

const purchaserOnlyAccess = ["super", "admin", "purchaser"] as const;

const generalPurchasingAccess = _.union(internalHigherUps, externalRoles);

const adminAccess = ["super", "admin"] as const;
const supplierAccess = ["supplier", "select-account-manager"] as const;

const permissions = {
  internalRoles,
  externalRoles,
  admin: adminAccess,
  beaconRanges: adminAccess,
  beacons: [...adminAccess, "approver"],
  budgets: internalRoles,
  dashboard: allRoles,
  deactivated: internalRoles,
  emailNotifications: [...adminAccess, "purchaser"] as const,
  help: allRoles,
  items: [...internalRoles, "select-account-manager"] as const,
  newOrderWindow: adminAccess,
  noSupplierAccess: internalRoles,
  orderReview: orderReviewAccess,
  orders: ordererAccess,
  profile: allRoles,
  programs: internalRoles,
  purchaseOrderRollup: purchaserOnlyAccess,
  rfqs: purchaserOnlyAccess,
  quotes: _.union(purchaserOnlyAccess, supplierAccess),
  purchaseOrders: generalPurchasingAccess,
  createPurchaseOrder: purchaserOnlyAccess,
  reporting: [...internalHigherUps, "approver"] as const,
  reportingOrderer: "orderer",
  super: ["super"] as const,
  userSettingsOrdering: ordererAccess,
  reviewApprovals: adminAccess,
  userRoles: allRoles,
  supplier: supplierAccess,
  bulkOrderUpload: adminAccess,
};

export default permissions;
