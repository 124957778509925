/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { setAvailableWindows } from "@redux/slices/orderWindows/orderWindowSlice";
import { updateCurrentChannel } from "@redux/slices/user/currentUserSlice";

const ChannelSelector = () => {
  const dispatch = useDispatch();

  const [channel, updateChannel] = useState("");

  const { channels, currentTerritoryId, currentChannelId, isChannelLocked } =
    useSelector((state) => state.currentUser);
  const { isOrdering } = useSelector((state) => state.draftOrderSets);
  const isStepperLoad = useSelector(
    (state) => state.globalState.isProgStepperLoad
  );

  const handleChangeSelect = (evt) => {
    let activeChannel = channels.find((c) => c.name === evt.target.value);
    updateChannel(evt.target.value);
    dispatch(updateCurrentChannel({ channel: activeChannel.id }));
    dispatch(
      setAvailableWindows({
        channelId: activeChannel.id,
        territoryId: currentTerritoryId,
      })
    );
  };

  useEffect(() => {
    if (channels.length > 0) {
      const currentChannel = channels.find((c) => c.id === currentChannelId);
      if (currentChannel) {
        updateChannel(currentChannel.name);
      } else {
        // Handle the case where currentChannel is not found
        updateChannel(channels[0].id);
      }
    }
  }, [channels, currentChannelId]);

  return (
    <FormControl
      size="small"
      css={{
        ".MuiOutlinedInput-root fieldset": tw`border-none`,
      }}
    >
      <InputLabel id="channel-select" color="secondary" tw="max-w-none">
        Channel
      </InputLabel>
      <Select
        name="channels"
        labelId="channel-select"
        id="channels"
        size="small"
        value={channel}
        onChange={handleChangeSelect}
        disabled={
          (isOrdering && window.location.href.includes("/orders/open")) ||
          (isOrdering && window.location.href.includes("/programs")) ||
          isStepperLoad ||
          isChannelLocked
        }
      >
        {channels.map((channel, i) => (
          <MenuItem value={channel.name} key={i}>
            {channel.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default memo(ChannelSelector);
