/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { ZodError, z } from "zod";

import { SaveFormBanner } from "@features/ui";
import { Item } from "@models/Item";
import { useMutateError } from "@services/api";

import { usePersistFormChanges } from "../../../utils/forms";
import { useUpdateItemMutation } from "../data";
import saveItemVariants from "../data/saveItemVariants";
import saveVariablePricing from "../data/saveVariablePricing";
import { ItemFormData, formDataFromItem } from "../maps";
import { FormVariant } from "../types";
import { ItemImagesProvider } from "./ItemImageContext";
import CategoriesBlock from "./blocks/Categories";
import ChampionWarehouse from "./blocks/ChampionWarehouse";
import LogisticBlock from "./blocks/Logistic";
import MainBlock from "./blocks/Main";
import MediaBlock from "./blocks/Media";
import PricingBlock from "./blocks/Pricing";
import Programs from "./blocks/Programs";
import Quotes from "./blocks/Quotes";
import SpecificationsBlock from "./blocks/Specifications";
import Status from "./blocks/Status";
import VariantsBlock from "./blocks/Variants/VariantsBlock";
import VisibilityBlock from "./blocks/Visibility";

const draftSchema = z.object({
  name: z.string().min(1, "Name can't be empty"),
});
const TwoColLayout = tw.div`
  grid gap-4 pb-6
  lg:[grid-template-columns:2fr 1fr]
`;

const ItemAdminForm = ({ item }: { item: Item }) => {
  const setMutateError = useMutateError();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const updateItemMutation = useUpdateItemMutation();

  const { usesRfqs } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const formMethods = useForm({
    defaultValues: formDataFromItem(item),
    reValidateMode: "onBlur",
  });

  const {
    formState: { isDirty, dirtyFields },
    reset,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
  } = formMethods;

  const hasInitialized = Boolean(watch("id"));

  usePersistFormChanges(hasInitialized && `item-admin-${item.id}`, formMethods);

  const onInvalid = () => {
    if (!item) return;
    // Reset  to original saved value
    setValue("status", item.status ?? "draft");
  };

  const calculateVariantWarehouseQty = (formVariant: FormVariant) => {
    const savedVariant =
      !!formVariant.id && item.variants.find((v) => v.id === formVariant.id);
    if (!savedVariant) return formVariant;
    // quantityOnHand is not an editable field, it's calculated from the cachedWarehouseQty
    // calculate the change in quantity and apply it to the cachedWarehouseQty in order for quantityOnHand to be accurate
    const diff =
      formVariant.quantityOnHand - (savedVariant.quantityOnHand ?? 0);
    return {
      ...formVariant,
      cachedWarehouseQty: (savedVariant.cachedWarehouseQty ?? 0) + diff,
    };
  };

  const onValid = async ({
    variants,
    variablePricing,
    usesVariablePricing,
    groups,
    ...data
  }: ItemFormData) => {
    try {
      if (!item) return;
      setIsSubmitLoading(true);
      // New variants aren't always marked as dirty unless its fields have been updated
      // so also check if the variant has an id
      const dirtyVariants = Object.fromEntries(
        Object.entries(variants)
          .filter(
            ([key, variant]: any[]) =>
              dirtyFields.variants?.[key] || !variant.id
          )
          .map(([key, variant]) => [key, calculateVariantWarehouseQty(variant)])
      );

      // If variable pricing has been turned off, all vps need to be deleted
      // so mark them all as dirty
      const dirtyVariablePricing = !usesVariablePricing
        ? variablePricing
        : variablePricing.filter((_, i) => dirtyFields.variablePricing?.[i]);

      await Promise.all([
        saveItemVariants(item.id, dirtyVariants),
        saveVariablePricing(item.id, dirtyVariablePricing, usesVariablePricing),
      ]);

      const formattedGroups = Object.values(groups)
        .filter(Boolean)
        .map((id) => ({ id: id as string }));

      await updateItemMutation
        .mutateAsync({
          ...data,
          groups: formattedGroups,
          specification: Object.fromEntries(
            data.specification.map(({ name, value }) => [name, value])
          ),
        })
        .catch(setMutateError);
    } catch (error) {
      setMutateError(error as any);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleSave = () => {
    const formValues = getValues();
    if (formValues.status === "draft") {
      // Skip field-level validation, only validate the following
      try {
        draftSchema.parse(formValues);
        onValid(formValues);
      } catch (error) {
        if (error instanceof ZodError) {
          error.issues.forEach((error) =>
            setError(error.path.join(".") as any, {
              type: error.code ?? "validation",
              message: error.message,
            })
          );
        }
        onInvalid();
      }
    } else {
      handleSubmit(onValid, onInvalid)();
    }
  };

  const handleReset = useCallback(() => {
    reset(formDataFromItem(item));
  }, [item, reset]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  return (
    <FormProvider {...formMethods}>
      <ItemImagesProvider item={item}>
        {isDirty && (
          <SaveFormBanner
            handleSave={handleSave}
            handleReset={handleReset}
            isLoading={isSubmitLoading}
          />
        )}
        <TwoColLayout tw="mb-8">
          <div tw="space-y-4 overflow-hidden min-w-0">
            <MainBlock />

            <MediaBlock />

            <PricingBlock item={item} />
            <LogisticBlock />
            <SpecificationsBlock />

            <VariantsBlock item={item} />
          </div>
          <div tw="space-y-4 min-w-0">
            <Status item={item} handleSave={handleSave} />
            <Programs />
            <VisibilityBlock />

            <CategoriesBlock />
            <ChampionWarehouse />
            {usesRfqs && <Quotes />}
          </div>
        </TwoColLayout>
      </ItemImagesProvider>
    </FormProvider>
  );
};

export default ItemAdminForm;
