import React, { memo } from "react";
import { useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";

import { formatMoney } from "@utility/utilityFunctions";

import StripeCheckout from "./StripeCheckout";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputCell: {
    boxSizing: "border-box",
    borderWidth: "2px",
    borderStyle: "inset",
    borderImage: "initial",
    borderColor: theme.palette.secondary.light,
    borderRadius: "5px",
    padding: "18.5px 14px",
    fontFamily: "Roboto",
    fontWeight: "400",
    width: "100%",
  },
}));

const StripeModal = ({
  open,
  id,
  userName,
  amount,
  orderType,
  handleClose,
}) => {
  const classes = useStyles();

  const {
    organization: { stripeAccount },
  } = useSelector((state) => state.currentUser);

  const stripePromise = Boolean(stripeAccount)
    ? loadStripe(process.env.REACT_APP_STRIPE_KEY, {
        stripeAccount: stripeAccount,
      })
    : loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className={classes.relativeContainer}>
      <Elements stripe={stripePromise}>
        <Dialog
          open={open}
          disableScrollLock
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <IconButton
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CancelIcon fontSize="large" color="secondary" />
            </IconButton>
            <br />
            <div className={classes.centeredModal}>
              <Typography
                className={classes.headerText}
              >{`Authorizing Payment of ${formatMoney(
                amount,
                false
              )}`}</Typography>
              <br />
              <StripeCheckout
                classes={classes}
                id={id}
                userName={userName}
                amount={amount}
                orderType={orderType}
                handleClose={handleClose}
              />
            </div>
          </DialogContent>
        </Dialog>
      </Elements>
    </div>
  );
};

StripeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  orderType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(StripeModal);
