import { jsonToCSV } from "react-papaparse";

import { CsvSchema, csvDataFromSchema, formatCsvBlob } from "@utils/csv";

import { Report } from "../types";

export default function schemaToCsvReport(
  csvSchema: CsvSchema,
  thru?: (data: any[]) => any[]
): Report {
  const onDownload = (data: any[][]) => {
    // parse json data to csv text
    const csvData = jsonToCSV(data);
    return formatCsvBlob(csvData);
  };

  return {
    fileExtension: "csv",
    onComplete: (data) =>
      csvDataFromSchema(csvSchema, thru ? thru(data) : data),
    onDownload,
  };
}
