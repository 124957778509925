/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Collapse } from "@mui/material";

import {
  Contained,
  FixedHeightScrollLastChild,
  PageTitle,
  StyledButton,
} from "@components/StyledComponents";
import { Filters, useFilterParams } from "@features/filters";
import { RequestReportButton, useReport } from "@features/reports";
import {
  UserSidebarContent,
  UserTable,
  usePaginatedUsersQuery,
} from "@features/users";
import { useSetLocation } from "@services/reactRouterDom";
import DocTitle from "@utility/DocTitle";

const defaultValues = {
  status: "active",
  sort: "name",
};

const AdminUsers = () => {
  const [filterParams] = useFilterParams();
  const { hash } = useLocation();
  const setLocation = useSetLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const params = {
    filter: {
      "name-or-email": filterParams.q,
      roles: filterParams.userRoles,
      status: filterParams.userActiveStatus ?? "active",
    },
    sort: filterParams.sort,
  };

  useReport("users", ["users", params]);

  const handleUserRowClick = (user) => setLocation({ hash: `#${user.id}` });

  const { data, ...tableProps } = usePaginatedUsersQuery(params);

  useEffect(() => {
    setSidebarOpen(!!hash);
  }, [hash]);

  return (
    <>
      <DocTitle title={"Users Admin"} />
      <div tw="flex max-w-full">
        <FixedHeightScrollLastChild tw="flex-1 min-w-0">
          <div tw="flex items-center justify-between flex-wrap gap-2 w-full">
            <PageTitle>Edit Users</PageTitle>
            {/* Absolute so it goes behind the user sidebar */}
            <Contained tw="flex justify-end gap-2 absolute right-0">
              <RequestReportButton reportName="users" />
              <StyledButton cta onClick={() => setLocation({ hash: "#new" })}>
                NEW USER
              </StyledButton>
            </Contained>
          </div>

          <Filters
            searchTitle="Search Users"
            slots={["userRoles", "userActiveStatus"]}
            defaultValues={defaultValues}
          />
          <UserTable
            userData={data}
            handleClick={handleUserRowClick}
            {...tableProps}
          />
        </FixedHeightScrollLastChild>
        <Collapse
          in={sidebarOpen}
          // Prevent jank when closing sidebar by only resetting hash after the close animation
          onExited={() => setLocation({ hash: "" })}
          timeout={300}
          unmountOnExit
          orientation="horizontal"
          tw="shrink-0 -mt-4 [--space-after-header: -16px]"
          className="body-height-after-header"
        >
          <div tw="w-[480px] h-full bg-white shadow-xl relative rounded-t-lg overflow-y-auto max-h-full">
            <UserSidebarContent
              id={hash.substring(1)}
              handleClose={() => setSidebarOpen(false)}
            />
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default AdminUsers;
