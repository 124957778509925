import {
  axiosDelete,
  axiosGet,
  axiosGetWithNext,
  axiosPatch,
  axiosPost,
} from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";
import asyncPool from "@utility/asyncPool";
import { kebabCaseKeys, stringToCents } from "@utility/utilityFunctions";

import { mapCostCenter } from "../costCenterSlice";
import { setError } from "../errorSlice";
import { setRedirect } from "../globalStateSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { updateRedeemedPromotions } from "../promotions/promotionSlice";
import {
  buildOrder,
  buildOrderCancelRequest,
  buildOrderPatch,
  buildOrderVariantPatch,
  buildPromotionRequest,
  buildSetVariantPatch,
  sortOrderVariants,
} from "./helpers";
import { mapOrderSet, mapOrders, mapSetVariants } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  id: null,
  type: null,
  territoryId: null,
  orderWindowId: null,
  orderWindowName: null,
  status: null,
  totalVariantPrice: 0,
  totalEstFreight: 0,
  totalEstTax: 0,
  totalPrice: 0,
  programId: null,
  programName: null,
  programOrderDescription: null,
  channelId: null,
  budgetId: null,
  costCenter: null,
  promotionIds: null,
  userName: null,
  checkoutType: null,
  totalDiscount: 0,
  orderSetVariants: [],
  orderSetOrders: [],
  loadingCells: [],
  errorCells: [],
  rebuildRef: false,
  error: null,
};

const currentOrderSetSlice = createSlice({
  name: "currentOrderSet",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getOrderSetSuccess(state, action) {
      const { promotionIds, totalDiscount, orders, setVariants, ...rest } =
        action.payload;
      let total = 0;
      let estFreight = 0;
      let estTax = 0;
      let currentSetVariants = setVariants.length > 0 ? [...setVariants] : [];

      if (orders.length > 0) {
        total = orders.reduce((acc, order) => {
          estFreight += order.estFreight;
          estTax += order.estTax;
          return (
            acc +
            order.variants.reduce((a, v) => {
              currentSetVariants = currentSetVariants.map((variant) => {
                if (v.sku === variant.sku) {
                  return {
                    ...variant,
                    price: v.price,
                    count: variant.count + v.count,
                    originalCount: variant.originalCount + v.count,
                    totalPrice: variant.totalPrice + v.totalPrice,
                  };
                } else return variant;
              });
              return a + v.totalPrice;
            }, 0)
          );
        }, 0);

        sortOrderVariants(orders);

        orders.sort((a, b) =>
          a.address.name < b.address.name
            ? -1
            : a.address.name > b.address.name
            ? 1
            : 0
        );
      }

      let setSkuGroup = currentSetVariants
        .sort((a, b) => (a.sku < b.sku ? -1 : a.sku > b.sku ? 1 : 0))
        .reduce((group, v) => {
          group[v.item.sku] = group[v.item.sku] || [];
          group[v.item.sku].push(v);
          return group;
        }, {});
      let setSkus = Object.keys(setSkuGroup);

      let updatedSetVariants = setSkus.reduce((updatedVariants, sku) => {
        setSkuGroup[sku]
          .sort((a, b) => (a.sku < b.sku ? -1 : a.sku > b.sku ? 1 : 0))
          .sort((a, b) =>
            a.orderPosition < b.orderPosition
              ? -1
              : a.orderPosition > b.orderPosition
              ? 1
              : 0
          );

        return (updatedVariants = updatedVariants.concat([
          ...setSkuGroup[sku],
        ]));
      }, []);

      state.promotionIds = promotionIds;
      state.totalDiscount = totalDiscount;
      state.totalVariantPrice = total;
      state.totalEstFreight = estFreight;
      state.totalEstTax = estTax;
      state.totalPrice = total + estFreight + estTax;
      state.orderSetVariants = updatedSetVariants;
      state.orderSetOrders = orders;

      Object.assign(state, rest);

      state.rebuildRef = true;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = null;
    },
    addSetOrdersSuccess(state, action) {
      const { orders } = action.payload;
      sortOrderVariants(orders);
      state.orderSetOrders = state.orderSetOrders
        .concat(orders)
        .sort((a, b) =>
          a.address.name < b.address.name
            ? -1
            : a.address.name > b.address.name
            ? 1
            : 0
        );
      state.rebuildRef = true;
      state.isUpdateLoading = false;
      state.error = null;
    },
    setLoadingCell(state, action) {
      const { cell } = action.payload;
      state.loadingCells = state.loadingCells.concat(cell);
    },
    clearCellState(state) {
      state.loadingCells = [];
      state.errorCells = [];
    },
    updateVariantQtySuccess(state, action) {
      const { id, sku, orderId, value } = action.payload;
      let orders = [...state.orderSetOrders];
      let variants = [...state.orderSetVariants];
      let currentOrder = state.orderSetOrders.find((o) => o.id === orderId);
      let updatedPrice = 0;
      let updatedCount = 0;
      currentOrder.variants = currentOrder.variants.map((v) => {
        if (v.sku === sku) {
          let newCount = value === "" ? 0 : parseInt(value);
          updatedCount += newCount;
          updatedPrice += newCount * v.price;
          return {
            ...v,
            count: newCount,
            totalPrice: newCount * v.price,
          };
        } else {
          updatedCount += v.count;
          updatedPrice += v.totalPrice;
          return v;
        }
      });
      currentOrder.totalPrice = updatedPrice;
      currentOrder.count = updatedCount;
      orders.splice(orders.indexOf(currentOrder), 1, currentOrder);

      let currentVariant = variants.find((v) => v.sku === sku);
      let updatedVariantPrice = 0;
      let updatedVariantCount = 0;
      let updatedSetTotal = 0;

      orders.forEach((o) => {
        let ov = o.variants.find((v) => v.sku === sku);
        updatedVariantCount += ov.count;
        updatedVariantPrice += ov.totalPrice;
        o.variants.forEach(
          (variant) => (updatedSetTotal += variant.totalPrice)
        );
      });
      currentVariant.count = updatedVariantCount;
      currentVariant.totalPrice = updatedVariantPrice;
      variants.splice(variants.indexOf(currentVariant), 1, currentVariant);

      state.loadingCells = state.loadingCells.filter((cell) => cell.id !== id);
      state.errorCells = state.errorCells.filter((cell) => cell.id !== id);
      state.orders = orders;
      state.totalVariantPrice = updatedSetTotal;
      state.orderSetVariants = variants;
      state.isUpdateLoading = false;
      state.error = null;
    },
    deleteSetVariantSuccess(state, action) {
      const { sku } = action.payload;
      state.totalVariantPrice -= state.orderSetVariants.find(
        (v) => v.sku === sku
      ).totalPrice;
      state.orderSetVariants = state.orderSetVariants.filter(
        (v) => v.sku !== sku
      );
      state.orderSetOrders = state.orderSetOrders.map((o) => {
        let orderVariants = o.variants.filter((v) => v.sku !== sku);
        let updatedPrice = 0;
        let updatedCount = 0;
        orderVariants.forEach((v) => {
          updatedPrice += v.totalPrice;
          updatedCount += v.count;
        });
        return {
          ...o,
          variants: orderVariants,
          totalPrice: updatedPrice,
          count: updatedCount,
        };
      });
      state.rebuildRef = true;
      state.isUpdateLoading = false;
      state.error = null;
    },
    deleteSetOrderSuccess(state, action) {
      const { id } = action.payload;
      let order = state.orderSetOrders.find((o) => o.id === id);
      state.totalVariantPrice -= order.totalPrice;
      state.totalEstFreight -= order.estFreight;
      state.totalEstTax -= order.estTax;

      state.totalPrice =
        state.totalVariantPrice + state.totalEstFreight + state.totalEstTax;

      state.orderSetOrders = state.orderSetOrders.filter((o) => o.id !== id);
      state.orderSetVariants = state.orderSetVariants.map((v) => {
        let variant = order.variants.find((ov) => ov.sku === v.sku);
        return {
          ...v,
          totalPrice: v.totalPrice - variant.totalPrice,
          count: v.count - variant.count,
        };
      });
      state.rebuildRef = true;
      state.isUpdateLoading = false;
      state.error = null;
    },
    denyOrderSuccess(state, action) {
      const { order } = action.payload;

      const prevOrder = state.orderSetOrders.find((o) => o.id === order.id);
      // detuct order total
      state.totalVariantPrice -= prevOrder.totalPrice;
      state.totalEstFreight -= prevOrder.estFreight;
      state.totalEstTax -= prevOrder.estTax;

      state.totalPrice =
        state.totalVariantPrice + state.totalEstFreight + state.totalEstTax;

      state.orderSetVariants = state.orderSetVariants.map((v) => {
        let variant = order.variants.find((ov) => ov.sku === v.sku);
        return {
          ...v,
          totalPrice: v.totalPrice - variant.totalPrice,
          count: v.count - variant.count,
        };
      });
      state.orderSetOrders = state.orderSetOrders.map((o) => {
        if (o.id === order.id) {
          return order;
        } else return o;
      });
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateSetOrderDetailsSuccess(state, action) {
      const { id, notes, attn } = action.payload;
      state.orderSetOrders = state.orderSetOrders.map((o) => {
        if (o.id === id) {
          return {
            ...o,
            notes: notes ? notes : "",
            attn: attn ? attn : "",
          };
        } else return o;
      });
      state.isUpdateLoading = false;
    },
    updateSetOrderSuccess(state, action) {
      const {
        id,
        expeditedDate,
        isExpedited,
        estFreight: newEstFreight,
        isForEvent,
      } = action.payload;
      const order = state.orderSetOrders.find((o) => o.id === id);
      const differenceInShippingCost = newEstFreight - order.estFreight;
      order.expeditedDate = expeditedDate;
      order.isExpedited = isExpedited;
      order.estFreight = newEstFreight;
      order.isForEvent = isForEvent;

      state.totalEstFreight += differenceInShippingCost;
      state.isUpdateLoading = false;
    },
    updateSetVariantCustomizationSuccess(state, action) {
      const { setVariants } = action.payload;
      state.orderSetVariants = state.orderSetVariants.map((v) => {
        let currentVariant = setVariants.find((sv) => sv.id === v.id);
        if (currentVariant) {
          return {
            ...v,
            customization: currentVariant.customization,
          };
        } else return v;
      });
      state.isUpdateLoading = false;
    },
    updateStatus(state, action) {
      const { status } = action.payload;
      state.status = status;
      state.isUpdateLoading = false;
      state.error = null;
    },
    updateBudgetSuccess(state, action) {
      const { id } = action.payload;
      state.budgetId = id;
      state.isUpdateLoading = false;
    },
    updateCostCenterSuccess(state, action) {
      const { costCenter } = action.payload;
      state.costCenter = costCenter;
      state.isUpdateLoading = false;
    },
    updatePromotionsSuccess(state, action) {
      const { ids, totalDiscount } = action.payload;
      state.promotionIds = ids;
      state.totalDiscount = totalDiscount;
      state.isUpdateLoading = false;
    },
    setRebuildRef(state, action) {
      const { value } = action.payload;
      state.rebuildRef = value;
    },
    resetOrderSet(state) {
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.id = null;
      state.type = null;
      state.territoryId = null;
      state.budgetId = null;
      state.costCenter = null;
      state.programId = null;
      state.programOrderDescription = null;
      state.promotionIds = null;
      state.totalDiscount = 0;
      state.orderWindowId = null;
      state.status = null;
      state.totalVariantPrice = 0;
      state.totalEstFreight = 0;
      state.totalEstTax = 0;
      state.totalPrice = 0;
      state.orderSetVariants = [];
      state.orderSetOrders = [];
      state.loadingCells = [];
      state.errorCells = [];
      state.rebuildRef = false;
      state.error = null;
    },
    setErrorCell(state, action) {
      const { cell } = action.payload;
      state.loadingCells = state.loadingCells.filter((c) => c.id !== cell.id);
      state.errorCells = state.errorCells.concat(cell);
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getOrderSetSuccess,
  addSetOrdersSuccess,
  setLoadingCell,
  clearCellState,
  updateVariantQtySuccess,
  deleteSetVariantSuccess,
  deleteSetOrderSuccess,
  denyOrderSuccess,
  updateSetOrderDetailsSuccess,
  updateSetOrderSuccess,
  updateSetVariantCustomizationSuccess,
  updateStatus,
  updateBudgetSuccess,
  updateCostCenterSuccess,
  updatePromotionsSuccess,
  setRebuildRef,
  resetOrderSet,
  setErrorCell,
  setFailure,
} = currentOrderSetSlice.actions;

export default currentOrderSetSlice.reducer;

export const fetchOrderSet =
  (id, isUpdate = false) =>
  async (dispatch) => {
    try {
      if (isUpdate) {
        dispatch(setIsUpdateLoading());
      } else {
        dispatch(setIsLoading());
      }
      const osResponse = await axiosGet(`/api/order-sets/${id}`);
      if (osResponse.error) {
        throw osResponse.error;
      }
      let nextLink = "start";
      let orders = [];
      while (nextLink) {
        let orderResponse = await axiosGetWithNext(
          nextLink === "start"
            ? `/api/orders?filter[order-set-id]=${osResponse.data.id}`
            : nextLink
        );
        if (orderResponse.error) {
          nextLink = null;
          throw orderResponse.error;
        }
        nextLink = orderResponse.data.nextLink;
        orders = orders.concat(orderResponse.data.data);
      }
      const mappedData = mapOrderSet(osResponse.data, orders);
      dispatch(getOrderSetSuccess(mappedData));
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Current Order Set" })
      );
    }
  };

export const addOrderSetOrders = (id, addIds, type) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const { errors, results } = await asyncPool(5, addIds, async (addId) => {
      let postData = buildOrder(id, addId, type);
      let response = await axiosPost("/api/orders", postData);
      if (response.error) {
        throw new Error(response.error);
      }
      return response.data;
    });

    const mappedData = mapOrders(results);
    dispatch(addSetOrdersSuccess({ orders: mappedData }));
    dispatch(patchSuccess());
    if (errors) {
      dispatch(
        setError({
          error: `Some addresses weren't able to be added. ${errors[0].message}`,
          source: "Current Order Set",
        })
      );
    }
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const addAddressIdToOrder =
  (orderSetId, addressId, orderType) => async (dispatch) => {
    try {
      const orderData = buildOrder(orderSetId, addressId, orderType);
      const orderResponse = await axiosPost("/api/orders", orderData);
      if (orderResponse.error) throw orderResponse.error;
      const mappedData = mapOrders([orderResponse.data]);
      dispatch(addSetOrdersSuccess({ orders: mappedData }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(
        setError({ error: err.toString(), source: "Current Order Set" })
      );
    }
  };

export const deleteOrderSetOrder = (id) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const response = await axiosDelete(`/api/orders/${id}`, {});
    if (response.error) {
      throw response.error;
    }
    dispatch(deleteSetOrderSuccess({ id: id }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const denyOrderSetOrder = (id, note) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const postData = buildOrderCancelRequest(id, note, "denial");
    const response = await axiosPost(`/api/orders/${id}/cancel`, postData);
    if (response.error) {
      throw response.error;
    }
    const order = mapOrders([response.data])[0];
    dispatch(denyOrderSuccess({ order }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const deleteOrderSetVariant = (args) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const response = await axiosDelete(
      `/api/order-set-variants/${args.id}`,
      {}
    );
    if (response.error) {
      throw response.error;
    }
    dispatch(deleteSetVariantSuccess({ sku: args.sku }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const updateVariantQty = (id, sku, qty, ordId) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    dispatch(setLoadingCell({ cell: { id: id, orderId: ordId } }));
    const patchData = buildOrderVariantPatch(id, "qty", qty);
    const response = await axiosPatch(`/api/order-variants/${id}`, patchData);
    if (response.error) {
      dispatch(setErrorCell({ cell: { id: id, orderId: ordId } }));
      dispatch(setFailure({ error: response.error.toString() }));
      dispatch(patchFailure());
      dispatch(
        setError({
          error: response.error.toString(),
          source: "Current Order Set",
        })
      );
    } else {
      dispatch(
        updateVariantQtySuccess({
          id: id,
          sku: sku,
          orderId: ordId,
          value: qty,
        })
      );
      dispatch(patchSuccess());
    }
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const updateOrderSetDetails = (id, notes, attn) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const patchData = buildOrderPatch(id, notes, attn);
    const response = await axiosPatch(`/api/orders/${id}`, patchData);
    if (response.error) {
      throw response.error;
    }
    dispatch(
      updateSetOrderDetailsSuccess({ id: id, notes: notes, attn: attn })
    );
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const updateOrderSetVariantCustomization =
  (ids, value) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      dispatch(patchLoading());

      let updatedSetVariants = [];

      for (let i = 0; i < ids.length; i++) {
        let patchData = buildSetVariantPatch(ids[i], "customization", value);
        let response = await axiosPatch(
          `/api/order-set-variants/${ids[i]}`,
          patchData
        );
        if (response.error) throw response.error;
        updatedSetVariants = updatedSetVariants.concat(response.data);
      }

      const mappedData = mapSetVariants(updatedSetVariants);
      dispatch(
        updateSetVariantCustomizationSuccess({ setVariants: mappedData })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(
        setError({ error: err.toString(), source: "Current Order Set" })
      );
    }
  };

export const updateOrderSetBudget = (id, budgetId) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const response = await axiosPost(`/api/order-sets/${id}/update-budget`, {
      "budget-id": budgetId ? +budgetId : null,
    });
    if (response.error) throw response.error;
    dispatch(updateBudgetSuccess({ id: budgetId }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};
export const updateOrderSetCostCenter =
  (id, costCenterId) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      dispatch(patchLoading());
      const response = await axiosPatch(`/api/order-sets/${id}`, {
        data: { attributes: { "cost-center-id": costCenterId } },
      });
      if (response.error) throw response.error;
      dispatch(
        updateCostCenterSuccess({
          costCenter: mapCostCenter(response.data["cost-center"]),
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(
        setError({ error: err.toString(), source: "Current Order Set" })
      );
    }
  };

export const updateOrderSetPromotions =
  (id, promotionIds, codes, currentIds) => async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      dispatch(patchLoading());
      const postData = buildPromotionRequest(promotionIds, codes);
      const response = await axiosPost(
        `/api/order-sets/${id}/update-promotions`,
        postData
      );
      if (response.error) throw response.error;
      dispatch(
        updatePromotionsSuccess({
          ids: response.data["promotion-ids"],
          totalDiscount: stringToCents(
            response.data["total-promotion-discount"]
          ),
        })
      );
      let falseIds = currentIds.filter((id) => !promotionIds.includes(id));
      dispatch(
        updateRedeemedPromotions({
          ids: response.data["promotion-ids"],
          falseIds: falseIds,
        })
      );
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(
        setError({ error: err.toString(), source: "Current Order Set" })
      );
    }
  };

export const deleteOrderSet = (args) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const response = await axiosDelete(`/api/order-sets/${args.id}`, {});
    if (response.error) {
      throw response.error;
    }
    dispatch(patchSuccess());
    dispatch(
      setRedirect({
        redirectBool: true,
        url:
          args.type === "pre-order"
            ? `/programs/${args.programId}/order/${args.orderWindowId}`
            : `/orders/draft/${args.type}`,
      })
    );
    dispatch(resetOrderSet());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const submitOrderSet =
  (type, id, submitForId = null) =>
  async (dispatch) => {
    try {
      dispatch(setIsUpdateLoading());
      dispatch(patchLoading());
      const postData = submitForId ? { "submitted-for-id": +submitForId } : {};

      const response = await axiosPost(
        `/api/order-sets/${id}/submit`,
        postData
      );
      if (response.error) {
        throw response.error;
      }
      dispatch(updateStatus({ status: "submitted" }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(
        setError({ error: err.toString(), source: "Current Order Set" })
      );
    }
  };

export const submitStripePayment = (id, data) => async (dispatch) => {
  try {
    const response = await axiosPost("/api/stripe", data);
    if (response.error) {
      throw response.error;
    }
    dispatch(updateStatus({ status: "approved" }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(
      setError({ error: err.toString(), source: "Credit Card Payment" })
    );
  }
};

export const approveOrderSet = (id) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());
    const response = await axiosPost(`/api/order-sets/${id}/approve`, {});
    if (response.error) {
      throw response.error;
    }
    dispatch(updateStatus({ status: "approved" }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};

export const updateOrder = (orderId, attributes) => async (dispatch) => {
  try {
    dispatch(setIsUpdateLoading());
    dispatch(patchLoading());

    const body = {
      data: {
        attributes: kebabCaseKeys(attributes),
      },
    };

    const res = await axiosPatch(`/api/orders/${orderId}`, body);
    if (res.error) throw res.error;

    dispatch(updateSetOrderSuccess(mapOrders([res.data])[0]));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Current Order Set" }));
  }
};
