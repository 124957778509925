import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import InputBase from "@mui/material/InputBase";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { updateVariantQty } from "@redux/slices/orders/currentOrderSetSlice";
import { roundUp } from "@utility/utilityFunctions";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  borderRight: {
    borderRight: "1px solid #cbcbcb",
  },
  root: {
    width: "200px !important",
    maxWidth: "200px !important",
    minWidth: "200px !important",
  },
}));

const MemoInputCell = React.memo(
  React.forwardRef(
    (
      { sku, id, index, orderId, cellRef, handleKeyDown, packSize, disabled },
      ref
    ) => {
      const classes = useStyles();
      const debounce = useRef(null);

      const numArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
      const dispatch = useDispatch();
      const [change, setChange] = useState(false);
      const [isDebouncing, setDebouncing] = useState(false);
      const value = useSelector((state) =>
        state.currentOrderSet.orderSetOrders
          .find((ord) => ord.id === orderId)
          .variants.find((v) => v.sku === sku)
      );
      const loading = useSelector((state) =>
        state.currentOrderSet.loadingCells.find(
          (cell) => cell.id === id && cell.orderId === orderId
        )
      );
      const error = useSelector((state) =>
        state.currentOrderSet.errorCells.find(
          (cell) => cell.id === id && cell.orderId === orderId
        )
      );

      const [updatedValue, setUpdatedValue] = useState(value.count);

      const handleChange = (value) => {
        if (numArray.includes(value[value.length - 1]) || value === "") {
          setUpdatedValue(value);
          setChange(true);
          setDebouncing(true);

          clearTimeout(debounce.current);
          debounce.current = null;
        }
      };

      const handleDispatch = (isBlur) => {
        if (isBlur) {
          window.removeEventListener("keydown", handleKeyEvent);
        }

        clearTimeout(debounce.current);

        if (change) {
          if (updatedValue === "") {
            setUpdatedValue(0);
            dispatch(updateVariantQty(id, sku, 0, orderId));
          } else {
            if (parseInt(updatedValue) % packSize === 0) {
              dispatch(updateVariantQty(id, sku, updatedValue, orderId));
            } else {
              let rounded = roundUp(parseInt(updatedValue), packSize);
              setUpdatedValue(rounded);
              dispatch(updateVariantQty(id, sku, rounded, orderId));
            }
          }
          setChange(false);
          setDebouncing(false);
          debounce.current = null;
        }
      };

      const handleScrollLeft = () => {
        ref.current.scrollLeft = 0;
      };

      const handleKeyEvent = (evt) => {
        if (
          evt.key === "Enter" ||
          evt.key === "ArrowUp" ||
          evt.key === "ArrowDown" ||
          evt.key === "ArrowLeft" ||
          evt.key === "ArrowRight"
        ) {
          evt.preventDefault();
          handleKeyDown(`${orderId}-${sku}`, evt.key);
          window.removeEventListener("keydown", handleKeyEvent);
        }
        if (evt.key === "Tab") {
          window.removeEventListener("keydown", handleKeyEvent);
        }
      };

      useEffect(() => {
        if (isDebouncing && !debounce.current) {
          debounce.current = setTimeout(() => {
            handleDispatch();
          }, 10_000);
        }
      });

      return (
        <TableCell
          classes={{ root: classes.root }}
          className={classes.borderRight}
          onFocus={() => {
            return index === 0 ? handleScrollLeft() : null;
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <InputBase
              ref={cellRef}
              style={{ textAlign: "center" }}
              fullWidth
              size="small"
              id={`${orderId}-${sku}`}
              value={updatedValue}
              onFocus={() => {
                cellRef.current.firstChild.select();
                window.addEventListener("keydown", handleKeyEvent);
              }}
              onBlur={() => handleDispatch(true)}
              onChange={(evt) => {
                handleChange(evt.target.value);
              }}
              disabled={disabled}
            />
            {loading && <CircularProgress size={20} />}
            {error && (
              <Tooltip
                title={error.error ? error.error : "Something went wrong"}
              >
                <WarningIcon color="error" />
              </Tooltip>
            )}
          </div>
        </TableCell>
      );
    }
  )
);

MemoInputCell.propTypes = {
  sku: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  packSize: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  orderId: PropTypes.string,
  orderStatus: PropTypes.string,
  program: PropTypes.string,
  cellRef: PropTypes.any,
  handleKeyDown: PropTypes.func.isRequired,
};

export default MemoInputCell;
