/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import { Link } from "react-router-dom";

import { ResourceError } from "@features/errors";
import { QuoteStatus, usePaginatedQuoteQuery } from "@features/quotes";
import { NewRfqButton } from "@features/rfqs";
import { DateFromNow } from "@features/ui";
import { utcToLocalDate } from "@utility/utilityFunctions";

import BlockCard from "../../../ui/BlockCard";

const Quotes = () => {
  const id = useWatch({ name: "id" });
  const [showAll, setShowAll] = useState(false);
  const { data, error } = usePaginatedQuoteQuery({ filter: { itemId: id } });
  const quotes = data.reverse();

  const quotesToShow = showAll ? quotes : quotes.slice(0, 3);

  return (
    <BlockCard
      title="Quotes"
      titleElement={
        <NewRfqButton
          tw="normal-case py-1 shadow-none hover:(shadow-none bg-neutral-100!)"
          itemId={id}
          label="New"
          cta={false}
        />
      }
    >
      {error && <ResourceError error={error} />}
      {data && data.length === 0 && (
        <p tw="text-neutral-600 font-light">
          This item doesn't have any quotes.
        </p>
      )}
      <div tw="-mt-2 -mx-6 max-h-48 overflow-y-auto">
        {quotesToShow.map((quote) => (
          <Link
            to={
              quote.isComplete
                ? `/quotes/${quote.id}`
                : `/rfqs/${quote.requestForQuote.id}`
            }
            key={quote.id}
            tw="block mx-3 px-3 py-1 rounded transition-colors text-neutral-700 hover:(text-neutral-900 bg-neutral-100)"
          >
            <div tw="flex gap-3 justify-between text-sm">
              <div>
                <QuoteStatus status={quote.status} />
              </div>
              <div tw="max-w-[10em] text-right whitespace-nowrap overflow-hidden text-ellipsis">
                {quote.supplierName}
              </div>
            </div>
            <div tw="text-neutral-500 text-xs">
              <DateFromNow date={utcToLocalDate(quote.updatedAt)} /> • round{" "}
              {quote.requestForQuote.round}
            </div>
          </Link>
        ))}
      </div>
      {quotes.length > 3 && (
        <div tw="text-sm mt-2">
          <button
            tw="text-neutral-500 hover:text-neutral-700"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Show less" : "Show all"}
          </button>
        </div>
      )}
    </BlockCard>
  );
};

export default Quotes;
