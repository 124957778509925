import React from "react";

import { ProgramAdmin } from "@features/programs";

const AdminProgramCreate = () => {
  return (
    <>
      <ProgramAdmin />
    </>
  );
};

export default AdminProgramCreate;
