import { format } from "date-fns";

import { createSlice } from "@reduxjs/toolkit";

import { axiosGet } from "../../api/axiosCalls";

let initialState = {
  isLoading: false,
  trackingData: null,
  error: null,
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getTrackingSuccess(state, action) {
      const { trackingData } = action.payload;
      state.trackingData = trackingData;
      state.isLoading = false;
      state.error = null;
    },
    resetTracking(state) {
      state.isLoading = false;
      state.trackingData = null;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.error = error;
    },
  },
});

export const { setIsLoading, getTrackingSuccess, resetTracking, setFailure } =
  trackingSlice.actions;

export default trackingSlice.reducer;

const handleData = (data) => {
  const events =
    data.api_response.events && data.api_response.events.length > 0
      ? data.api_response.events.map((evt) => {
          return {
            date: evt.occurred_at
              ? format(new Date(evt["occurred_at"]), "MM/dd/yyyy")
              : "Not Provided",
            time: evt.occurred_at
              ? new Date(evt["occurred_at"]).toLocaleTimeString()
              : "Not Provided",
            city: evt["city_locality"] ?? null,
            state: evt["state_province"] ?? null,
            zip: evt["postal_code"] ?? null,
            status: evt.description ?? "Not Provided",
          };
        })
      : [];

  return {
    trackingNumber: data.api_response.tracking_number,
    shipDate: data.api_response.ship_date
      ? format(new Date(data.api_response.ship_date), "MM/dd/yyyy")
      : "Has not left original location yet.",
    estimatedDeliveryDate: data.api_response.estimated_delivery_date
      ? format(
          new Date(data.api_response.estimated_delivery_date),
          "MM/dd/yyyy"
        )
      : "Not Provided.",
    deliveryDate: data.api_response.actual_delivery_date
      ? format(new Date(data.api_response.actual_delivery_date), "MM/dd/yyyy")
      : "Has not yet arrived at delivery location.",
    status: data.api_response.status_description ?? "Not Provided",
    events: events,
  };
};

export const getTracking = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/order-variants/${id}/track`, false);
    if (response.error) throw response.error;
    const trackingData = handleData(response.data);
    dispatch(getTrackingSuccess({ trackingData: trackingData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
  }
};
