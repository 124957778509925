import { memo } from "react";

import { CircularProgress, Divider, Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import OptionTable from "../../Utility/OptionTable";

const ReviewPanel = ({
  classes,
  type,
  formData,
  activeWindow,
  channel,
  territories,
  programs,
}) => {
  if (type === "edit" && !activeWindow) {
    return (
      <>
        <br />
        <br />
        <CircularProgress color="secondary" />
      </>
    );
  }

  return (
    <>
      <Typography className={clsx(classes.headerText, classes.settingsMargin)}>
        General:
      </Typography>
      <Typography className={classes.bodyText}>
        {`Name: ${formData.name}`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Open Date: ${formData.openDate}`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Close Date: ${formData.closeDate}`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`Review Close Date: ${formData.reviewCloseDate}`}
      </Typography>
      <Typography className={classes.bodyText}>
        {`In Market Date: ${formData.inMarketDate}`}
      </Typography>
      <br />
      <Divider className={classes.fullWidth} />
      <br />
      <Typography className={clsx(classes.headerText, classes.settingsMargin)}>
        Visibility:
      </Typography>
      {channel && (
        <>
          <Typography className={classes.bodyText}>
            {`Channel: ${channel.name}`}
          </Typography>
        </>
      )}
      <Typography className={classes.bodyText}>
        {`Territories: ${territories.map((t) => t.name).join(", ")}`}
      </Typography>
      <br />
      <Divider className={classes.fullWidth} />
      <br />
      <Typography className={clsx(classes.headerText, classes.settingsMargin)}>
        Program Assignment:
      </Typography>
      {programs.length > 0 ? (
        <OptionTable
          headCells={[
            { id: "name", label: "Name" },
            { id: "description", label: "Description" },
          ]}
          options={programs}
          handleRemove={null}
          disabled={true}
          isRemoveable={false}
        />
      ) : (
        <Typography className={classes.bodyText}>
          There are no programs set for this order window.
        </Typography>
      )}
      <br />
      <Typography className={classes.bodyText} color="textSecondary">
        <em>
          * Creating this order window will automatically update all associated
          items orderability if they would not normally be orderable during the
          duration of the window.
        </em>
      </Typography>
    </>
  );
};

ReviewPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  activeWindow: PropTypes.object,
  channel: PropTypes.object,
  territories: PropTypes.array,
  programs: PropTypes.array,
};

export default memo(ReviewPanel);
