import { formatDateString, stringToCents } from "@utility/utilityFunctions";

import { mapItems } from "../items/maps";
import { mapUsers } from "../user/maps";

export const mapPromotion = (promotion, isForUser) => {
  return {
    id: promotion.id,
    startDate: formatDateString(promotion["start-date"]),
    expirationDate: formatDateString(promotion["expiration-date"]),
    percentOff: promotion["percent-off"]
      ? parseFloat(promotion["percent-off"])
      : null,
    dollarsOff: promotion["dollars-off"]
      ? stringToCents(promotion["dollars-off"])
      : null,
    type: promotion.type,
    redemptionType: promotion["redemption-type"],
    discountType: promotion["discount-type"],
    isOneTimeUse: promotion["is-one-time-use"],
    maxQty: promotion["max-qty"],
    description: promotion.description,
    promotionCode: promotion["promotion-code"] ?? null,
    hasRedeemed: promotion["has-redeemed"],
    items: mapItems(promotion.items),
    users: isForUser ? [] : mapUsers(promotion.users),
    orderType: promotion["order-type"],
  };
};

export const mapPromotions = (promotions, isForUser) => {
  return promotions.map((p) => mapPromotion(p, isForUser));
};
