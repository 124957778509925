import { useEffect } from "react";

import { setFilterType } from "@redux/slices/filterSlice";

//ensures that no fetch will happen in views that don't need to make a fetch

export const useNoFetch = (dispatch) => {
  useEffect(() => {
    dispatch(setFilterType({ type: null }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
