import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { fetchFilteredUsers } from "@redux/slices/user/usersSlice";

const SingleUserAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  disabled,
  roles,
  territoryId,
  channelId,
  noMargin,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("");
  const [currentUser, setCurrentUser] = useState("");

  const isLoading = useSelector((state) => state.users.isLoading);
  const options = useSelector((state) => state.users.userList);

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handleUsers = (value) => {
    setCurrentUser(value);
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(
        fetchFilteredUsers(
          user,
          "active",
          roles && roles.length > 0 ? roles : [],
          territoryId ?? null,
          channelId ?? null
        )
      );
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, roles, territoryId, channelId]);

  useEffect(() => {
    if (user.length >= 1) {
      handleQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (reset) {
      setUser("");
      setCurrentUser("");
      if (setReset) {
        setReset(false);
      }
    }
  }, [reset, setUser, setReset]);

  return (
    <>
      <Autocomplete
        autoHighlight
        fullWidth
        freeSolo
        className={!noMargin ? classes.settingsMargin : null}
        id="user-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={user}
        onInputChange={(_evt, value) => setUser(value)}
        onChange={(_evt, value) => {
          handleChange(value);
          handleUsers(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name ?? ""}
        options={options}
        loading={loading}
        value={currentUser}
        disabled={disabled}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="User"
            id="user-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

SingleUserAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  territoryId: PropTypes.string,
  channelId: PropTypes.string,
  roles: PropTypes.array,
};

export default SingleUserAutoComplete;
