import { stringToCents } from "@utility/utilityFunctions";

import { mapBudgets } from "../budgets/maps";
import { mapChannels, mapchannelUsers } from "../channelSlice";
import { mapSuppliers } from "../suppliers/maps";
import { mapTerritories } from "../territories/maps";

const handleGroups = (groups, orgId) => {
  const mappedGroups = groups
    .map((group) => ({
      id: group.id,
      groupCategoryId: group["group-category"].id,
      groupCategoryName: group["group-category"].name,
      name: group.name,
      orgId: group["group-category"].organization.id,
    }))
    .filter((group) => (orgId ? group.orgId === orgId : true));

  return mappedGroups;
};

export const mapUser = (user) => {
  const mappedUser = {
    id: user.id,
    name: user.name.includes(",")
      ? user.name.split(", ")[1] + " " + user.name.split(", ")[0]
      : user.name,
    firstName: user.name.includes(",")
      ? user.name.split(", ")[1]
      : user.name.split(" ")[0],
    lastName: user.name.includes(",")
      ? user.name.split(", ")[0]
      : user.name.split(" ")[1],
    email: user.email,
    vernonBillingId: user["vernon-billing-id"],
    role: user.role,
    territories:
      user.role !== "supplier"
        ? mapTerritories(user.territories, user.organization?.id)
        : [],
    channels:
      user["channel-users"] && user.role !== "supplier"
        ? mapChannels(
            user["channel-users"].map((c) => c.channel),
            user.organization?.id
          )
        : [],
    channelUsers:
      user["channel-users"] && user.role !== "supplier"
        ? mapchannelUsers(user["channel-users"], user.organization?.id)
        : [],
    groups: user.groups ? handleGroups(user.groups, user.organization?.id) : [],
    budgets:
      user.budgets && user.budgets.length > 0
        ? mapBudgets(user.budgets, "overview").filter((b) => b.isActive)
        : [],
    accountManagerOrganizationIds:
      user["account-manager-organization-ids"]?.map(String) ?? [],
    favoriteItemIds: user["favorite-item-ids"],
    status: user.status ? user.status : "active",
    supplier: user.supplier ? mapSuppliers([user.supplier])[0] : null,

    hasViewedDashboard: user["has-viewed-dashboard"] ?? null,
    hasViewedAddressBook: user["has-viewed-address-book"] ?? null,
    hasViewedPreOrder: user["has-viewed-pre-order"] ?? null,
    hasViewedOrderSet: user["has-viewed-order-set"] ?? null,

    defaultTerritoryId: user["default-territory-id"]?.toString(),
    defaultChannelId: user["default-channel-id"]?.toString(),

    receivesThresholdEmails: user["receives-threshold-emails"],
  };
  mappedUser.initials = mappedUser.name
    .split(" ")
    .map((n) => n[0])
    .join("");
  return mappedUser;
};

export const mapUsers = (users) => {
  const mappedUsers = users.map((user) => mapUser(user));
  return mappedUsers;
};

export const mapStates = (states) => {
  if (!states || states.length === 0) return null;
  const mappedStates = states
    .map((s) => ({ id: s.id, code: s.code }))
    .sort((a, b) => {
      return a.code < b.code ? -1 : a.code > b.code ? 1 : 0;
    });

  return mappedStates;
};

export const mapCountries = (countries) => {
  const mappedCountries = countries.map((c) => ({
    id: c.id,
    name: c.name,
    code: c.code,
    states: mapStates(c.states),
  }));

  return mappedCountries;
};

export const mapDashboardOptions = (options) => {
  const mappedOptions = options.map((op) => ({
    id: op.id,
    displayId: op["display-type"],
    displayName: op["display-name"],
    position: op.position,
    isActive: op["is-active"],
  }));

  return mappedOptions;
};

export const mapDashboards = (dashboards) => {
  const mappedDashboards = dashboards.map((dash) => {
    let options = mapDashboardOptions(dash["dashboard-options"]);

    return {
      id: dash.id,
      role: dash.role,
      options: options,
    };
  });

  return mappedDashboards;
};

export const mapOrgs = (orgs, id) => {
  const mappedOrgs = orgs.map((org) => ({
    id: org.id,
    name: org.name,
    logoUrl: org["logo-cloudinary-id"]
      ? `https://res.cloudinary.com/brandhub/image/upload/${org["logo-cloudinary-id"]}`
      : "https://res.cloudinary.com/brandhub/image/upload/v1626199716/dev/Brandhub/NoImageFound_axxgh5.png",
    usesApprovalFlow: org["uses-approval-flow"],
    usesApprovalThreshold: org["uses-approval-threshold"],
    usesChannels: org["uses-channels"],
    approvalThreshold: stringToCents(org["approval-threshold"]),
    usesOnDemand: org["uses-on-demand"] ?? false,
    usesInventory: org["uses-inventory"] ?? false,
    usesCostCenters: org["uses-cost-centers"] ?? false,
    preferExternalId: org["prefer-warehouse-id"] ?? false,
    preferProductGridView: org["prefer-product-grid-view"] ?? false,
    usesStandardCheckout: org["uses-standard-checkout"],
    usesStripe: org["uses-stripe"] ?? false,
    stripeAccount: org["stripe-account"] ?? null,
    usesItemCustomization: org["uses-item-customization"] ?? false,
    includeShippingInStripeCharge:
      org["include-shipping-in-stripe-charge"] ?? false,
    includeTaxesInStripeCharge: org["include-taxes-in-stripe-charge"] ?? false,
    budgetLocation: org["budget-location"] ?? null,
    budgetCategoryId: org["budget-group-category-id"] ?? null,
    includeShippingInBudgets: org["include-shipping-in-budgets"] ?? false,
    includeTaxesInBudgets: org["include-taxes-in-budgets"] ?? false,
    includesTaxes: org["includes-taxes"] ?? false,
    includeTaxesInInventoryOrders:
      org["include-taxes-in-inventory-orders"] ?? false,
    allocationLocation: org["allocation-location"] ?? null,
    orderLimitType: org["order-limit-type"],
    estimatedShippingPercentage: parseFloat(
      org["estimated-shipping-percentage"]
    ),
    minimumShippingCost: +org["minimum-shipping-cost"],
    addressBookType: org["address-book-type"],
    countries: mapCountries(org.countries),
    dashboards: mapDashboards(org.dashboards),
    warehouses: org.warehouses ?? [],
    approvalFlows: org["approval-flows"] ?? [],
    hasPurchasingAgents: org["has-purchasing-agents"],
    allowShippingCostsAtOrderLevel: org["allow-shipping-costs-at-order-level"],

    // Org properties that don't have a site-setting yet
    usesRfqs: ["Tito's", "Demo"].includes(org.name),
    restrictItemArchiveToAdmins: org.name === "Nokian",
    usesOrderEventDesignation: org.name === "Tito's",
    usesHistoricalOrders: org.name === "Tito's",
    hasBeacons: org["has-beacons"],
  }));
  const currentOrg = id ? mappedOrgs.find((org) => org.id === id) : null;

  return {
    ...currentOrg,
    availableOrgs: mappedOrgs,
  };
};
