/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { setError } from "@redux/slices/errorSlice";
import FilterSyncResourceAutocomplete from "@utils/forms/FilterSyncResourceAutocomplete";

import { FilterRow } from "../Filtering/FilterComponents";
import { StyledButton } from "../StyledComponents";
import GroupAutoComplete from "../Utility/AutoCompleteFields/GroupAutoComplete";
import StatusSelector from "../Utility/Selectors/StatusSelector";

const FiltersOrderWindowReports = ({
  reset,
  setReset,
  handleFilters,
  handleSearch,
  filterType,
}) => {
  const dispatch = useDispatch();

  const {
    status: filterStatus,
    orderWindowIds,
    programId,
  } = useSelector((state) => state.filters);
  const isLoading = useSelector((state) => state.globalState.isLoading);
  const categories = useSelector((state) => state.groupCategories.categories);

  const [status, setStatus] = useState(filterStatus || "");

  const orderWindowParams = {
    filter: {
      ...(programId && { programId: programId.id }),
    },
  };

  const programIdParams = {
    filter: {
      ...(orderWindowIds &&
        orderWindowIds.length > 0 && {
          orderWindowIds: orderWindowIds.map((owi) => owi.id),
        }),
    },
  };

  return (
    <div>
      <FilterRow>
        <FilterSyncResourceAutocomplete
          handleChange={handleFilters}
          reset={reset}
          setReset={setReset}
          filterType={filterType}
          resource={"order-windows"}
          filterName={"orderWindowIds"}
          label={"Order Window"}
          requestParams={orderWindowParams}
        />
        {filterType === "reports-pre-order-user-report" && (
          <>
            <FilterSyncResourceAutocomplete
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={filterType}
              resource={"programs"}
              filterName={"programId"}
              label={"Program"}
              requestParams={programIdParams}
              single
            />
          </>
        )}
        {filterType !== "reports-pre-order-user-report" && (
          <>
            <FilterSyncResourceAutocomplete
              handleChange={handleFilters}
              reset={reset}
              setReset={setReset}
              filterType={filterType}
              resource={"programs"}
              filterName={"programIds"}
              label={"Programs"}
            />
            {categories &&
              categories.length > 0 &&
              categories.map((cat) => (
                <GroupAutoComplete
                  key={cat.id}
                  handleChange={handleFilters}
                  reset={reset}
                  setReset={setReset}
                  filterType={filterType}
                  categoryLabel={cat.name}
                  options={cat.groups}
                />
              ))}
            <StatusSelector
              handleStatus={handleFilters}
              status={status}
              setStatus={setStatus}
              filterType={filterType}
            />
          </>
        )}

        <StyledButton
          cta
          onClick={() => {
            if (
              filterType === "reports-pre-order-user-report" &&
              (orderWindowIds.length === 0 || orderWindowIds.length > 1)
            ) {
              return dispatch(
                setError({
                  error:
                    "One and only one order window is required for this report",
                  source: "Pre Order User Report",
                })
              );
            }
            handleSearch();
          }}
          disabled={isLoading}
        >
          GENERATE REPORT
        </StyledButton>
      </FilterRow>
    </div>
  );
};

FiltersOrderWindowReports.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default FiltersOrderWindowReports;
