import useSWR from "swr";

import { camelCaseKeys } from "@utility/utilityFunctions";

import client from ".";
import { splitOptions } from "./helperFunctions";
import removeFromSwrCache from "./removeFromSwrCache";
import Routes from "./routeResourceTypes";
import { EntityType, Falsy, HookOptions } from "./types";
import updateSwrCache from "./updateSwrCache";

type MetaType = { totalEntries: number };

const useApiResource = <T extends HookOptions, R extends keyof Routes>(
  resource: R | Falsy,
  opts?: T
) => {
  const options = opts ?? ({} as HookOptions);
  const { requestOptions, swrOptions } = splitOptions(options);

  const url = options.id ? `${resource}/${options.id}` : resource;
  const { data, mutate, ...swr } = useSWR(
    resource && [url, requestOptions],
    (key: [string, typeof requestOptions]) => client.get(...key), //
    swrOptions
  );

  const relationshipNames = data?.relationshipNames ?? undefined;

  const update = ({ id, ...body }: Record<string, any>) =>
    updateSwrCache(
      mutate,
      client.update(`${resource}/${id ?? options.id}`, {
        relationshipNames,
        ...body,
      })
    );
  const create = (body: Record<string, any>) =>
    updateSwrCache(
      mutate,
      client.post(resource as string, {
        relationshipNames,
        ...body,
      })
    );
  const createMany = async (bodies: Record<string, any>[]) => {
    const results = await Promise.all(
      bodies.map((body) =>
        client.post(resource as string, {
          relationshipNames,
          ...body,
        })
      )
    );
    return updateSwrCache(mutate, {
      data: results.flatMap((res: any) => res.data),
      error: results.find((res: any) => res.error),
    });
  };

  const remove = ({ id }) =>
    removeFromSwrCache(mutate, client.delete(resource + `/${id}`));

  return {
    ...swr,
    mutate,
    data: data?.data as EntityType<Routes[R], T> | undefined,
    update,
    create,
    createMany,
    remove,
    meta: data?.meta ? (camelCaseKeys(data.meta) as MetaType) : undefined,
  };
};

export default useApiResource;
