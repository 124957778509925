import React from "react";
import { useDispatch } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import PropTypes from "prop-types";

import { updateOrderSetVariantCustomization } from "@redux/slices/orders/currentOrderSetSlice";

import { useInput } from "../../hooks/inputs/useInput";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const CustomizationModal = ({
  open,
  sku,
  setVariantIds,
  customization,
  description,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { value, bind, reset } = useInput(customization || "");

  const handleSubmit = () => {
    dispatch(updateOrderSetVariantCustomization(setVariantIds, value));
    reset();
    handleClose();
  };

  const handleRemove = () => {
    dispatch(updateOrderSetVariantCustomization(setVariantIds, null));
    reset();
    handleClose();
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              reset();
              handleClose();
            }}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.centeredModal}>
            <Typography
              className={clsx(classes.headerText, classes.settingsMargin)}
            >
              {`Customizing Sku: ${sku}`}
            </Typography>
            <Typography classname={classes.bodyText} color="textSecondary">
              <em>
                * Customizations apply to all variants (sizes / colors) of the
                same item
              </em>
            </Typography>
            <br />
            <Typography className={classes.bodyText}>{description}</Typography>
            <br />
            <TextField
              fullWidth
              multiline
              rows={2}
              size="small"
              variant="outlined"
              color="secondary"
              name="customization"
              type="text"
              label="Customization"
              {...bind}
            />
            <br />
            <div className={clsx(classes.fullWidth, classes.center)}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
                disabled={value.length === 0}
              >
                SUBMIT
              </Button>
              <Button
                className={classes.button}
                style={{ marginLeft: "20px" }}
                variant="contained"
                color="secondary"
                onClick={handleRemove}
              >
                REMOVE CUSTOMIZATION
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CustomizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  sku: PropTypes.string.isRequired,
  setVariantIds: PropTypes.array.isRequired,
  customization: PropTypes.any,
  description: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CustomizationModal;
