import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

const SingleCountryAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [currentCountry, setCurrentCountry] = useState("");

  const { countries: options } = useSelector((state) => state.territories);

  const handleCountries = (value) => {
    setCurrentCountry(value);
  };

  useEffect(() => {
    if (reset) {
      setCountry("");
      setCurrentCountry("");
      setReset(false);
    }
  }, [reset, setCountry, setReset]);

  return (
    <>
      <Autocomplete
        autoHighlight
        fullWidth
        freeSolo
        className={classes.settingsMargin}
        id="country-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={country}
        onInputChange={(_evt, value) => setCountry(value)}
        onChange={(_evt, value) => {
          handleChange(value);
          handleCountries(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name ?? ""}
        options={options}
        value={currentCountry}
        disabled={disabled}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            id="territory-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

SingleCountryAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SingleCountryAutoComplete;
