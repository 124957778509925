/** @jsxImportSource @emotion/react */
import "twin.macro";

import { EmojiEvents } from "@mui/icons-material";

import { StyledButton } from "@components/StyledComponents";
import useConfirm from "@features/confirm";
import { Quote } from "@models/Quote";

import { useQuoteActionMutation } from "./data/quoteMutations";

type AwardQuoteButtonProps = {
  quote: Quote;
};

const AwardQuoteButton = ({ quote }: AwardQuoteButtonProps) => {
  const confirm = useConfirm();
  const doQuoteAction = useQuoteActionMutation();
  const handleAwardQuote = () => {
    doQuoteAction.mutate({ id: quote.id, action: "award" });
  };
  const handleUnawardQuote = async () => {
    if (!(await confirm("Are you sure you want to unaward this quote?")))
      return;
    doQuoteAction.mutate({ id: quote.id, action: "unaward" });
  };

  return quote.status === "awarded" ? (
    <StyledButton
      startIcon={<EmojiEvents />}
      outlined
      loading={doQuoteAction.isPending}
      onClick={handleUnawardQuote}
    >
      Awarded
    </StyledButton>
  ) : (
    <StyledButton
      startIcon={<EmojiEvents />}
      cta
      loading={doQuoteAction.isPending}
      onClick={handleAwardQuote}
    >
      Award Quote
    </StyledButton>
  );
};

export default AwardQuoteButton;
