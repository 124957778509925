const budgetTypeMap = {
  user: "users",
  territory: "territories",
  group: "groups",
};
export const buildBudget = (attrs, budgetType) => {
  const relationshipArray = attrs.relationships.map((r) => ({
    type: budgetType,
    id: r.id,
  }));

  return {
    data: {
      type: "budget",
      attributes: {
        name: attrs.name,
        "start-date": new Date(attrs.startDate),
        "expiration-date": new Date(attrs.expirationDate),
        "is-active": attrs.isActive,
        "total-amount": attrs.totalAmount,
        note: attrs.note,
      },
      relationships: {
        [budgetTypeMap[budgetType]]: {
          data: relationshipArray,
        },
      },
    },
  };
};

export const buildBulkBudgets = (bulkBudgets) => {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  let budgetDataArr = [];
  bulkBudgets.forEach((budget) => {
    const newBudget = {
      name: budget.name,
      "start-date": new Date(budget.start_date).toLocaleDateString(
        "en-US",
        options
      ),
      "expiration-date": new Date(budget.expiration_date).toLocaleDateString(
        "en-US",
        options
      ),
      "total-amount": budget.total_budget,
      users: budget.users,
    };
    budgetDataArr.push(newBudget);
  });

  return {
    data: {
      rows: budgetDataArr,
    },
  };
};
