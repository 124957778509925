/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Radio } from "@mui/material";

import { ControlledRadioGroup } from "@utils/forms";

import { useFilterContext } from "../filterContext";
import Chip from "./Chip";

const options = {
  any: "All",
  sent: "Pending",
  accepted: "Accepted",
  "complete,awarded": "Completed",
  awarded: "Awarded",
};

const QuoteStatusPopper = () => {
  const { control } = useFilterContext();

  return (
    <div tw="min-w-[200px]">
      <h4 tw="text-neutral-600 my-4 px-3">Quote Status is</h4>

      <ControlledRadioGroup tw="w-full" name="status" control={control}>
        {Object.keys(options).map((key) => (
          <label
            key={key}
            tw="flex items-center px-3 border-t border-neutral-200 text-sm hover:bg-neutral-100 cursor-pointer"
          >
            <Radio size="small" value={key} edge="start" />
            {options[key]}
          </label>
        ))}
      </ControlledRadioGroup>
    </div>
  );
};

const QuoteStatusFilterTile = (props) => {
  const { filterValues } = useFilterContext();
  const quoteStatus = filterValues["status"];
  const quoteStatusText = quoteStatus && options[quoteStatus];

  return (
    <Chip {...props}>
      <span>Status is&nbsp;</span>
      <b>{quoteStatusText}</b>
    </Chip>
  );
};

const QuoteStatusFilter = {
  Chip: QuoteStatusFilterTile,
  Popper: QuoteStatusPopper,
};

export default QuoteStatusFilter;
