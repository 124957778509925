import fp from "lodash/fp";
import { v4 as uuidv4 } from "uuid";

import { Program } from "../../models";
import { defaultFormValues } from "../../utils/forms";

type ValueIdPair = { id: string; value: string };
type OptionField = {
  id: string;
  name?: string;
  [key: string]: any;
};

const optionFields = fp.pick(["id", "name"]);
const valueWithId = (value: string): ValueIdPair => ({ id: uuidv4(), value });

interface ProgramFormValues {
  id: string;
  status: "draft" | "complete";
  additionalFileCloudinaryId: string | null;
  additionalFileName: string;
  additionalFileResourceType: string;
  cloudinaryId: string;
  description: string;
  goals: ValueIdPair[];
  isActive: boolean;
  isTerritoryExclusive: boolean;
  name: string;
  orderDescription: string;
  channelId: string | null;
  strategies: ValueIdPair[];
  territories: OptionField[];
}

export const defaultProgramValues: ProgramFormValues = {
  id: "",
  status: "draft",
  additionalFileCloudinaryId: null,
  additionalFileName: "",
  additionalFileResourceType: "",
  cloudinaryId: "",
  description: "",
  goals: [],
  isActive: false,
  isTerritoryExclusive: false,
  name: "",
  orderDescription: "",
  channelId: "",
  strategies: [],
  territories: [],
};

export const formDataFromProgram: (program: Program) => ProgramFormValues =
  fp.flow(
    fp.pick([...Object.keys(defaultProgramValues), "itemIds", "channel"]),
    fp.defaultTo(defaultProgramValues),
    (data: Program) => ({
      ...data,
      channelId: data.channel?.id,
      goals: data.goals?.map(valueWithId) ?? [],
      strategies: data.strategies?.map(valueWithId) ?? [],
      territories: data.territories.map(optionFields),
    }),
    fp.omit(["channel", "itemIds"]),
    // (data) => (console.log(data) as any) || data,
    defaultFormValues
  );
