import React, { memo } from "react";

import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const BulkUploadModal = ({ open, handleClose, successCount, errors }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableScrollLock
      fullWidth
      maxWidth="sm"
      keepMounted
    >
      <DialogContent>
        <br />
        <div className={classes.centeredModal}>
          <Typography className={classes.headerText}>
            Bulk Address Upload Results
          </Typography>
          <br />
          {successCount > 0 && (
            <Typography className={classes.bodyText}>
              {`Successfully uploaded ${successCount}
              ${successCount === 1 ? "Address" : "Addresses"}`}
            </Typography>
          )}
          {!!errors && errors.length > 0 && (
            <>
              {successCount === 0 && (
                <Typography className={classes.bodyText}>
                  Please resolve the errors below and try again.
                </Typography>
              )}
              <br />
              <Typography className={classes.headerText}>Errors:</Typography>
              <br />
              {errors.map((e, i) => (
                <Typography
                  key={i}
                  className={classes.bodyText}
                  style={{ marginBottom: "10px" }}
                >
                  {e}
                </Typography>
              ))}
            </>
          )}
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleClose}
          >
            CONTINUE
          </Button>
        </div>
        <br />
      </DialogContent>
    </Dialog>
  );
};

BulkUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  successCount: PropTypes.number.isRequired,
  errors: PropTypes.any,
};

export default memo(BulkUploadModal);
