import { useSelector } from "react-redux";

import { endOfDay } from "date-fns";

import { OrderWindow } from "../../models";
import { utcDate } from "../../utility/utilityFunctions";

export function useIsOrderWindowOrderable() {
  const { role } = useSelector((state: any) => state.currentUser);
  return (orderWindow: OrderWindow) => {
    const now = new Date();
    const compareDate =
      role === "orderer" ? orderWindow.closeDate : orderWindow.reviewCloseDate;
    return (
      utcDate(orderWindow.openDate) < now &&
      endOfDay(utcDate(compareDate)) > now
    );
  };
}
