/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useNavigate } from "react-router-dom";

import { Filters } from "@features/filters";
import {
  ProgramTable,
  useCreateProgramMutation,
  usePaginatedProgramsQuery,
} from "@features/programs";
import DocTitle from "@utility/DocTitle";

import {
  FixedHeightScrollLastChild,
  PageTitle,
  StyledButton,
} from "../components/StyledComponents";
import { useFilterParams } from "../features/filters";
import { useMutateError } from "../services/api";

const AdminProgram = () => {
  const navigate = useNavigate();

  const setMutateError = useMutateError();
  const createProgramMutation = useCreateProgramMutation();

  const [filterParams] = useFilterParams();
  const { data, ...tableProps } = usePaginatedProgramsQuery({
    filter: {
      name: filterParams.q,
      territoryIds: filterParams.territories,
      orderWindowIds: filterParams.orderWindows,
      groupIds:
        filterParams.groups && Object.values(filterParams.groups).flat(),
      isActive:
        filterParams.activeStatus && filterParams.activeStatus !== "inactive",
      isDraft: !filterParams.activeStatus
        ? null
        : filterParams.activeStatus === "draft",
    },
    sort: filterParams.sort ?? "-id",
  });

  const handleCreateNewProgram = async () => {
    createProgramMutation.mutate(
      { name: "New Program" },
      {
        onSuccess: (program) => {
          navigate(`/admin/programs/${program.id}`);
        },
        onError: (err) => setMutateError(err, "Program Create"),
      }
    );
  };

  return (
    <FixedHeightScrollLastChild>
      <DocTitle title="Program Admin" />
      <div tw="flex justify-between items-center">
        <PageTitle>Program Admin</PageTitle>
        <StyledButton
          cta
          onClick={handleCreateNewProgram}
          loading={createProgramMutation.isPending}
        >
          New Program
        </StyledButton>
      </div>
      <Filters
        searchTitle="Search Programs"
        slots={["activeStatus", "orderWindows", "groups", "territories"]}
      />
      <ProgramTable {...tableProps} query={filterParams.q} rows={data ?? []} />
    </FixedHeightScrollLastChild>
  );
};

export default AdminProgram;
