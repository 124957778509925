import { CloudinaryFile } from "@models/CloudinaryFile";
import cloudinary from "@services/cloudinary";

type options = {
  forDownload?: boolean;
};

export default function getCloudinaryFileUrl(
  file: CloudinaryFile,
  options?: options
) {
  const forDownload = options?.forDownload ?? true;
  return cloudinary.url(file.cloudinaryId, {
    resource_type: file.resourceType,
    ...(forDownload && { flags: `attachment:${file.fileName}` }),
  });
}
