import { OrderType } from "@models";

export default function orderTypeLabel(orderType: OrderType) {
  const orderTypes = {
    inventory: "Inventory",
    "pre-order": "Pre-Order",
    "on-demand": "On Demand",
  };
  return orderTypes[orderType];
}
