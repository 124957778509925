import format from "date-fns/format";
import _ from "lodash";

import { formatDateString, stringToCents } from "@utility/utilityFunctions";

import { mapItems } from "../items/maps";

const handleVariantNames = (options) => {
  let name = options
    .filter((v) => v.name.length)
    .map((vs) => `${vs["variant-category"].name}: ${vs.name}`)
    .join(", ");

  return name.length > 0 ? name : "---";
};

export const mapShippingParameterVariants = (variants) => {
  const mappedVariants = variants.map((spv) => {
    let item = mapItems([spv.variant.item])[0];

    return {
      id: spv.id,
      sku: spv.variant["variant-sku"],
      externalWarehouseId: spv.variant["external-warehouse-id"],
      qty: spv.qty,
      shippingStatus: spv["shipping-status"],
      tax: spv.tax ? stringToCents(spv.tax) : 0,
      method: spv.method ?? null,
      actualShipDate: spv["actual-ship-date"]
        ? formatDateString(spv["actual-ship-date"])
        : null,
      packageCount: spv["package-count"] ?? null,
      shippedQty: spv["shipped-qty"] ?? null,
      status: spv.status,
      cancelationType: spv["cancelation-type"] ?? null,
      trackingData: {
        number: spv["tracking-data"]?.number ?? null,
        carrier: spv["tracking-data"]?.carrier ?? null,
      },
      item: item,
      customization: spv.customization ?? null,
      variantNames:
        spv.variant["selected-variant-options"].length > 0
          ? handleVariantNames(spv.variant["selected-variant-options"])
          : null,
    };
  });

  return mappedVariants;
};

const handleFullAddress = (sp) => {
  let addArray = [
    sp["street-address-1"],
    sp["street-address-2"] ?? null,
    sp.city,
    sp?.state?.code || sp.region || null,
    sp.zip,
    sp?.country?.name ?? null,
  ];

  return addArray.filter((a) => a).join(", ");
};

export const mapShippingParameters = (params) => {
  const mappedParams = params.map((sp) => {
    let shippingParameterVariants = mapShippingParameterVariants(
      sp["shipping-parameter-variants"]
    );

    return {
      id: sp.id,
      addressName: sp.name,
      city: sp.city,
      addressOne: sp["street-address-1"],
      addressTwo: sp["street-address-2"] ?? null,
      state: sp?.state?.code,
      region: sp.region ?? null,
      stateOrRegion: sp?.state?.code || sp.region || "---",
      zip: sp.zip,
      country: sp["country"]?.name ?? null,
      phoneNumber: sp.phoneNumber ?? null,
      fullAddress: handleFullAddress(sp),
      attn: sp.attn ?? null,
      notes: sp.notes ?? null,
      isDirectShip: sp["is-direct-ship"],
      purchaseOrderId: sp["purchase-order"].id,
      shippingParameterVariants: shippingParameterVariants,
    };
  });

  return mappedParams;
};

export const mapPurchaseOrderVariants = (variants) => {
  const mappedVariants = variants.map((pov) => {
    if (pov["is-setup-fee"]) {
      return {
        id: pov.id,
        qty: pov.qty,
        initialCost: null,
        actualCost: stringToCents(pov["actual-cost"]),
        packSize: null,
        actualPackSize: null,
        isSetupFee: pov["is-setup-fee"],
        setupFeeDesc: pov["setup-fee-desc"] ?? null,
        purchaseOrderId: pov["purchase-order"].id,
        item: null,
        customization: null,
        sku: null,
        variantNames: null,
        variantOptionIds: null,
      };
    } else {
      let item = mapItems([pov.variant.item])[0];
      return {
        id: pov.id,
        qty: pov.qty,
        initialCost: item.cost,
        actualCost: stringToCents(pov["actual-cost"]),
        packSize: item.packSize,
        actualPackSize: pov["actual-qty-per-pack"],
        isSetupFee: pov["is-setup-fee"],
        setupFeeDesc: pov["setup-fee-desc"] ?? null,
        purchaseOrderId: pov["purchase-order"].id,
        item: item,
        imageId: pov.variant.image?.id ?? null,
        customization: pov.customization ?? null,
        sku: pov.variant["variant-sku"],
        warehouseId: pov.variant["external-warehouse-id"],
        variantNames:
          pov.variant["selected-variant-options"].length > 0
            ? handleVariantNames(pov.variant["selected-variant-options"])
            : null,
        variantOptionIds:
          pov.variant["selected-variant-options"].length > 0
            ? pov.variant["selected-variant-options"]
                .filter((v) => v.name?.length)
                .map((v) => v.id)
            : null,
        isToInventory: pov["is-to-inventory"],
      };
    }
  });

  return mappedVariants.sort((a, b) => {
    if (a.isSetupFee && b.isSetupFee) return 0;
    if (a.isSetupFee) return 1;
    if (b.isSetupFee) return -1;
    return 0;
  });
};

export const mapPurchaseOrder = (po) => {
  const purchaseOrderVariants = mapPurchaseOrderVariants(
    po["purchase-order-variants"]
  );
  const shippingParameters = mapShippingParameters(po["shipping-parameters"]);

  const totalPurchaseOrderVariantCost = _.sumBy(
    purchaseOrderVariants,
    (pov) => pov.actualCost * pov.qty
  );

  const totalTax = _.sumBy(shippingParameters, (sp) =>
    _.sumBy(sp.shippingParameterVariants, (spv) => spv.tax)
  );

  const totalFreight = po["total-freight-cost"]
    ? stringToCents(po["total-freight-cost"])
    : 0;

  return {
    id: po.id,
    orgId: po?.organization?.id ?? null,
    additionalFileCloudinaryId: po["additional-file-cloudinary-id"] ?? null,
    additionalFileName: po["additional-file-name"] ?? null,
    additionalFileResourceType: po["additional-file-resource-type"] ?? null,
    inMarketDate: formatDateString(po["in-market-date"]),
    invoiceNumber: po["invoice-number"] ?? null,
    isDirectShip: po["is-direct-ship"],
    method: po["method"] ?? null,
    note: po["note"] ?? null,
    labelingInstructions: po["labeling-instructions"] ?? null,
    terms: po["terms"] ?? null,
    changeNote: po["change-note"] ?? null,
    status: po.status,
    submittedAt: po["submitted-at"]
      ? format(new Date(po["submitted-at"]), "MM/dd/yyyy")
      : null,
    warehouse: po.warehouse ?? null,
    totalFreight: totalFreight,
    totalTax: totalTax,
    totalTaxCost: po["total-tax-cost"],
    total: totalTax + totalFreight + totalPurchaseOrderVariantCost,
    type: po.type,
    supplierReference: po["supplier-reference"] ?? null,
    warehouseTrackingNumber: po["warehouse-tracking-number"] ?? null,
    purchaser: po.purchaser.name,
    purchaserId: po.purchaser.id,
    supplier: po?.supplier?.name ?? null,
    supplierId: po?.supplier?.id ?? null,
    purchaseOrderVariants: purchaseOrderVariants,
    shippingParameters: shippingParameters,
    purchasingAgentReferenceNumber:
      po["purchasing-agent-reference-number"] ?? null,
    purchasingAgentInvoiceNumber: po["purchasing-agent-invoice-number"] ?? null,
    requiresPurchasingAgent: po["requires-purchasing-agent"],
    inboundTrackingNumber: po["inbound-tracking-number"] ?? null,
    inboundCarrier: po["inbound-carrier"] ?? null,
  };
};

export const mapPurchaseOrders = (pos) => {
  const mappedPOs = pos.map((po) => mapPurchaseOrder(po));

  return mappedPOs;
};
