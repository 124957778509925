/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { ReactNode, useEffect, useState } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Checkbox,
  CircularProgress,
  LinearProgress,
  Paper,
  PaperProps,
  Typography,
} from "@mui/material";

import _ from "lodash";

import { useSelectedResources } from "@features/filters";
import { routeResourceTypes, useApiResource } from "@services/api";
import useDebouncedValue from "@utils/useDebouncedValue";

import { TextInput } from "./DefaultInputs";

const MIN_PAGE_SIZE = 20;

const Loading = () => <CircularProgress tw="-mt-2" size={20} />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const getIdArrayFromUnkownValue = (value): any[] => {
  if (Array.isArray(value)) {
    return value.map((v) => v?.id ?? v);
  }
  return value ? [value?.id ?? value] : [];
};

type PaperComponentProps = {
  isLoading: boolean;
  optionCount: number;
  children?: ReactNode;
} & PaperProps;

const PaperComponent = React.forwardRef<any, PaperComponentProps>(
  ({ isLoading, children, optionCount, ...props }, ref) => (
    <Paper
      {...props}
      ref={ref}
      tw="relative border border-neutral-300 min-w-max"
    >
      {children}
      {optionCount === 20 && !isLoading && (
        <div tw="text-sm text-primary-600 px-3 py-1">
          Showing top 20 results
        </div>
      )}
      {isLoading && <LinearProgress tw="absolute bottom-0 left-0 right-0" />}
    </Paper>
  )
);

export type ResourceAutocompleteProps<
  TResourceName extends keyof routeResourceTypes,
  T,
  Multiple extends boolean,
  DisableClearble extends boolean,
  FreeSolo extends boolean,
> = Partial<AutocompleteProps<T, Multiple, DisableClearble, FreeSolo>> & {
  resource: TResourceName;
  resourceFilterName?: string | false;
  label?: string;
  getOptionText?: (option: T) => string;
  getOptionSecondaryText?: string | ((option: T) => string);
  requestParams?: Record<string, any>;
  fetchOnMount?: boolean;
  clearSearchOnSelect?: boolean;
  renderInput?: (
    params: AutocompleteRenderInputParams & { isLoading?: boolean }
  ) => JSX.Element;
  /* Tap options lets us modify the resource data before it's given as options */
  tapOptions?: (options: T[]) => T[];
};

export const ResourceAutocomplete = <
  TResourceName extends keyof routeResourceTypes,
  Multiple extends boolean = false,
  DisableClearble extends boolean = false,
>({
  label,
  resource,
  resourceFilterName = "name",
  requestParams,
  value,
  onChange,
  fetchOnMount = false,
  getOptionText,
  getOptionSecondaryText,
  tapOptions = (opts) => opts,
  ...props
}: ResourceAutocompleteProps<
  TResourceName,
  routeResourceTypes[TResourceName],
  Multiple,
  DisableClearble,
  false
>) => {
  const [searchString, setSearchString] = useState<string>("");
  const [fetchData, setFetchData] = useState(fetchOnMount);
  const [areAllResourcesOnFirstPage, setAreAllResourcesOnFirstPage] =
    useState(false);

  const { multiple } = props;

  // no need to query the api if all resources are on the first page
  const shouldFilter = !!resourceFilterName && !areAllResourcesOnFirstPage;

  const debouncedSearchString = useDebouncedValue(searchString, 200);

  const { data, isLoading } = useApiResource(fetchData && resource, {
    params: {
      ...requestParams,
      filter: {
        ...(shouldFilter && { [resourceFilterName]: debouncedSearchString }),
        ...requestParams?.filter,
      },
    },
    keepPreviousData: true,
    focusThrottleInterval: 10_000,
  });

  const { data: selectedValues } = useSelectedResources(
    resource,
    getIdArrayFromUnkownValue(value),
    areAllResourcesOnFirstPage
  );

  let options: routeResourceTypes[TResourceName][] = _.uniqBy(
    [...(data ?? []), ...selectedValues],
    "id"
  );
  options = tapOptions(options);
  options = getOptionText
    ? options.map((opt) => ({ ...opt, name: getOptionText(opt) }))
    : options;

  const getOpObj = (option) =>
    option?.id ? option : options.find((o) => o.id === option);

  useEffect(() => {
    if (multiple && Array.isArray(value) ? value.length : value) {
      setFetchData(true);
    }
  }, [multiple, value]);

  useEffect(() => {
    if (
      !areAllResourcesOnFirstPage &&
      debouncedSearchString === "" &&
      data &&
      data.length < MIN_PAGE_SIZE
    ) {
      setAreAllResourcesOnFirstPage(true);
    }
  }, [data, isLoading, debouncedSearchString, areAllResourcesOnFirstPage]);

  return (
    <Autocomplete
      size="small"
      value={value}
      options={options}
      inputValue={searchString}
      onInputChange={(_, value, reason) => {
        if (reason !== "reset" || !props.disableCloseOnSelect) {
          setSearchString(value); // don't reset search string if popper is staying open
        }
      }}
      onChange={(event, newValue, reason) => {
        if (props.clearSearchOnSelect) {
          setSearchString("");
        }
        onChange?.(event, newValue, reason);
      }}
      onOpen={() => setFetchData(true)}
      isOptionEqualToValue={(option, value) =>
        option.id === getOpObj(value)?.id
      }
      getOptionLabel={(option) => getOpObj(option)?.name ?? ""}
      {...props}
      noOptionsText={isLoading ? <Loading /> : "No options"}
      filterOptions={(options, { inputValue }) => {
        if (!inputValue) return options;
        return options.filter(
          (option) =>
            getOpObj(option)
              ?.name.toLowerCase()
              .includes(inputValue.toLowerCase()) ||
            (getOptionSecondaryText &&
              (typeof getOptionSecondaryText === "string"
                ? _.get(getOpObj(option), getOptionSecondaryText)
                : getOptionSecondaryText(getOpObj(option))
              )
                .toLowerCase()
                .includes(inputValue.toLowerCase()))
        );
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id} className={props.className + " group"}>
          {multiple && (
            <Checkbox
              edge="start"
              tw="-my-2"
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
          )}
          <Typography noWrap>{option.name}</Typography>
          {getOptionSecondaryText && (
            <Typography tw="text-neutral-400 text-sm ml-auto group-hover:text-neutral-700">
              {typeof getOptionSecondaryText === "string"
                ? _.get(option, getOptionSecondaryText)
                : getOptionSecondaryText(option)}
            </Typography>
          )}
        </li>
      )}
      PaperComponent={(props) => (
        <PaperComponent
          isLoading={isLoading}
          optionCount={data ? data.length : 0}
          {...props}
        />
      )}
      renderInput={
        props.renderInput
          ? (params) => props.renderInput!({ ...params, isLoading })
          : (params) => (
              <TextInput
                {...params}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: "off",
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress size={15} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )
      }
    />
  );
};
