import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { Event } from "cloudinary-core";

import { Image } from "@models/Image";
import client from "@services/api";

export function saveItemImage(
  event: Event<"success">,
  itemId: string,
  positionRef: MutableRefObject<number>,
  setSavedImages: Dispatch<SetStateAction<Image[]>>
) {
  client
    .post<Image>("images", {
      type: "large",
      cloudinaryId: event.info.public_id,
      originalUrl: event.info.url,
      position: positionRef.current++,
      itemId,
    })
    .then((data) => setSavedImages((imgs) => [...imgs, data.data]))
    .catch(console.error);
}
