import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function ChannelAutoComplete({ handleChange, filterType, reset, setReset }) {
  const [channel, setChannel] = useState("");
  const [selectedChannels, setSelectedChannels] = useState([]);

  const currentFilters = useSelector((state) => state.filters.channelIds);
  const { channels } = useSelector((state) => state.channels);

  const options = [{ id: "all", name: "Select All" }, ...channels];

  // Set selected channels based on autocomplete selection
  const handleChangeChannels = (_, newValue) => {
    let selection = [];
    if (newValue.some((option) => option.id === "all")) {
      selection = options.slice(1);
    } else {
      selection = newValue;
    }

    setSelectedChannels(selection);
    handleChange(selection, "channelIds", filterType);
  };

  // Make sure the same channels are selected in the autocomplete as in the filters
  useEffect(() => {
    if (currentFilters.length !== selectedChannels.length) {
      setSelectedChannels(currentFilters);
    }
  }, [currentFilters, selectedChannels]);

  // handle reset
  useEffect(() => {
    if (reset) {
      setChannel("");
      setSelectedChannels([]);
      setReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  // Clears the global channel filter which interferes with the use of this component
  useEffect(() => {
    handleChange("", "channelId", filterType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType]);

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      value={selectedChannels}
      onChange={handleChangeChannels}
      inputValue={channel}
      onInputChange={(_evt, value) => setChannel(value)}
      renderTags={() => null}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          placeholder="Channel"
        />
      )}
    />
  );
}

export default ChannelAutoComplete;
