/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

type DeclinePurchaseOrderModalProps = {
  handleClose: () => void;
  handleDecline: (reason: string) => Promise<void>;
};

const DeclinePurchaseOrderModal: React.FC<DeclinePurchaseOrderModalProps> = ({
  handleClose,
  handleDecline,
}) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");

  const canSubmit = reason.trim().length > 0;

  const handleSubmit = async () => {
    if (!canSubmit) return;
    setLoading(true);
    await handleDecline(reason);
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
    >
      <DialogTitle tw="flex justify-between items-start">
        Decline Purchase Order
        <IconButton onClick={handleClose} edge="end">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h3 tw="text-lg font-medium mb-4">
          Please provide a reason for denying this Purchase Order.
        </h3>
        <StyledTextInput
          label="Decline Reason"
          autoFocus
          multiline
          minRows={3}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton
          role="button"
          outlined
          disabled={loading}
          onClick={handleClose}
        >
          Cancel
        </StyledButton>
        <StyledButton
          cta
          role="submit"
          disabled={!canSubmit}
          onClick={handleSubmit}
          loading={loading}
        >
          Submit
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeclinePurchaseOrderModal;
