/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";

import BeaconList from "@components/Beacons/BeaconList";
import BeaconMap from "@components/Beacons/BeaconMap";
import BeaconNav from "@components/Beacons/BeaconNav";
import ItemList from "@components/Beacons/ItemList";
import ProgramHeader from "@components/Beacons/ProgramHeader";
import StateList from "@components/Beacons/StateList";
import { isStateCode } from "@components/Beacons/helpers";
import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import DocTitle from "@utility/DocTitle";

import { useBeaconParams } from "../hooks/beaconHooks";

export const VIEWS = {
  state: "state",
  item: "item",
  beacon: "beacon",
};

const NATIONAL = "national";
const UNSPECIFIED = "Unspecified";

const Beacon = () => {
  const [params, setParams] = useBeaconParams();
  const { view, state, variantSku } = params;
  const isState = isStateCode(state);

  const handleChangeState = (state) => {
    setParams({
      state: state || "Unspecified",
      view: state === "Unspecified" || variantSku ? VIEWS.beacon : VIEWS.state,
    });
  };

  const handleSetVariantSku = (variantSku) =>
    setParams({ variantSku, view: isState ? VIEWS.beacon : VIEWS.state });

  useEffect(() => {
    // redirect on some impossible views
    try {
      const validParams = { view, state };

      // View isn't valid or isn't set
      if (!VIEWS[view]) validParams.view = VIEWS.state;

      if (state === UNSPECIFIED) return;

      // view is state, but we're not at the national level
      if (isState && view === VIEWS.state) validParams.view = VIEWS.item;

      // view is beacon, but we're at the national level
      if (!isState && view === VIEWS.beacon) validParams.view = VIEWS.state;

      // validate state param => valid state code || "national"
      if (!isState && state !== NATIONAL) validParams.state = NATIONAL;

      setParams(validParams);
    } catch (error) {
      console.log(error);
    }
  }, [view, state, isState, setParams]);

  return (
    <>
      <DocTitle title={"Beacon"} />
      <FixedHeightScrollLastChild>
        <ProgramHeader />

        <BeaconNav />

        <div tw="grid grid-cols-2 gap-3 grow! overflow-hidden items-start">
          {view === VIEWS.state && (
            <StateList
              handleChangeState={handleChangeState}
              selectedState={state}
            />
          )}
          {view === VIEWS.item && (
            <ItemList setVariantSku={handleSetVariantSku} />
          )}
          {view === VIEWS.beacon && <BeaconList />}

          {isState && <BeaconMap />}
          {/* National */}
          {state && !isState && <BeaconMap />}
          {/** Different Map view for national map to keep things organized **/}
          {/** {!state && <NationalMap />} **/}
        </div>
      </FixedHeightScrollLastChild>
    </>
  );
};

export default Beacon;
