import _ from "lodash";

import { variantName } from "@features/items";
import { schemaToCsvReport, displayDate } from "@features/reports";
import { GroupCategory } from "@models/GroupCategory";
import { PurchaseOrder } from "@models/PurchaseOrder";
import { formatMoneyString } from "@utility/utilityFunctions";
import { CsvSchema } from "@utils/csv";

import { calcTotalCostPurchaseOrder } from "./purchasingHelpers";

export function purchaseOrderHistoryReport(
  organization,
  categories: GroupCategory[]
) {
  const { hasPurchasingAgents } = organization;
  const flattenPOVariants = (purchaseOrders: PurchaseOrder[]) =>
    purchaseOrders.flatMap((po) =>
      po.purchaseOrderVariants.map((v) => ({
        ...v,
        purchaseOrder: po,
      }))
    );

  type ColumnType = CsvSchema<ReturnType<typeof flattenPOVariants>[0]>;

  const columns: ColumnType = [
    { id: "purchaseOrder.id", label: "Purchase Order #" },
    { id: "purchaseOrder.status", label: "PO Status" },
    { id: "purchaseOrder.supplier.name", label: "Supplier" },
    ...(hasPurchasingAgents ? [{ id: "purchaseOrder.purchasingAgentReferenceNumber", label: "External PO #" }] : []),
    { id: "purchaseOrder.inMarketDate", label: "In Market Date", f: displayDate},
    { id: "purchaseOrder.inboundCarrier", label: "Inbound Carrier"},
    { id: "purchaseOrder.inboundTrackingNumber", label: "Inbound Tracking Number"},
    { id: "variant.variantSku", label: "SKU" },
    { id: "variant.externalWarehouseId", label: "External Warehouse ID" },
    { id: "variant.item.name", label: "Name" },
    { id: "variant", label: "Variant", f: (v) => (v ? variantName(v) : "") },
    { id: "variant.item.packSize", label: "Pack Size"},
    ...categories.map<ColumnType[0]>((c) => ({
      id: c.id,
      label: c.name,
      f: (_, row) =>
        row.variant
          ? row.variant.item.groups.find(
              (group) => group.groupCategory.id === c.id
            )?.name ?? ""
          : "",
    })),
    { id: "qty", label: "Qty" },
    { id: "variant.actualCost", label: "Cost/Unit", f: formatMoneyString },
    { id: "variant.isSetUpFee", label: "Setup Fee", f: (isSetUpFee) => (isSetUpFee ? "Yes" : "No") },
    { id: "totalFreightCost", label: "Total Freight Cost", f: formatMoneyString },
    {
      id: "_totalPOCost",
      label: "Total PO Cost",
      f: (_, row) =>
        formatMoneyString(calcTotalCostPurchaseOrder(row.purchaseOrder)),
    },
    { id: "purchaseOrder.purchaser.name", label: "Purchased By" },
    { id: "purchaseOrder.supplierReference", label: "Supplier Reference" },
    { id: "purchaseOrder.invoiceNumber", label: "Invoice" },
    { id: "purchaseOrder.purchaser.name", label: "Purchased By" },
    ...(hasPurchasingAgents ? [{ id: "purchaseOrder.purchasingAgentInvoiceNumber", label: "Purchasing Agent Invoice Number"}] : []),
  ];

  return schemaToCsvReport(columns, flattenPOVariants);
}
