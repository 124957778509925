import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildVariantAllocation } from "./helpers";
import { mapAllocationHistory, mapItems } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  itemPreviewId: null,
  itemPreviewFocusImageId: null,
  selectedItems: [],
  allocationHistory: null,
  currentItem: null,
  error: null,
};

const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    setItemPreview(state, action) {
      const { id, focusImageId } = action.payload;
      state.itemPreviewId = id;
      state.itemPreviewFocusImageId = focusImageId
        ? String(focusImageId)
        : null;
    },
    clearItemPreview(state) {
      state.itemPreviewId = null;
      state.itemPreviewFocusImageId = null;
    },
    getSingleItemSuccess(state, action) {
      const { item } = action.payload;
      state.currentItem = { ...item };
      state.isLoading = false;
    },
    createOrUpdateItemSuccess(state, action) {
      const { item } = action.payload;
      state.hasUpdated = true;
      state.currentItem = { ...item };
      state.isUpdateLoading = false;
      state.error = null;
    },
    getAllocationHistorySuccess(state, action) {
      const { history } = action.payload;
      state.allocationHistory = history;
      state.error = null;
    },
    resetAllocationHistory(state) {
      state.allocationHistory = null;
    },
    setItemSelection(state, action) {
      const { items } = action.payload;
      state.selectedItems = items;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetCurrentItem(state) {
      state.currentItem = null;
    },
    resetItems(state) {
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.nextLink = null;
      state.selectedItems = [];
      state.currentItem = null;
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsNextLoading,
  setIsUpdateLoading,
  setItemPreview,
  clearItemPreview,
  getItemsSuccess,
  getNextItemsSuccess,
  getSingleItemSuccess,
  createOrUpdateItemSuccess,
  getAllocationHistorySuccess,
  resetAllocationHistory,
  setItemSelection,
  setHasUpdated,
  resetCurrentItem,
  resetItems,
  setFailure,
} = itemSlice.actions;

export default itemSlice.reducer;

export const fetchItem = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/items/${id}`);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapItems([response.data])[0];
    dispatch(getSingleItemSuccess({ item: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Items" }));
  }
};

export const createOrUpdateVariantAllocation =
  (id, variantId, qty, userId, territoryId, itemId) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const data = buildVariantAllocation(variantId, qty, userId, territoryId);
      let response;
      if (id) {
        response = await axiosPatch(`/api/variant-allocations/${id}`, data);
      } else {
        response = await axiosPost(`/api/variant-allocations`, data);
      }
      if (response.error) throw response.error;
      const itemResponse = await axiosGet(`/api/items/${itemId}`);
      if (itemResponse.error) throw itemResponse.error;
      const mappedData = mapItems([itemResponse.data])[0];
      dispatch(createOrUpdateItemSuccess({ item: mappedData }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(patchFailure());
      dispatch(setError({ error: err.toString(), source: "Items" }));
    }
  };

export const deleteVariantAllocation = (id, itemId) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const response = await axiosDelete(`/api/variant-allocations/${id}`, {});
    if (response.error) throw response.error;
    const itemResponse = await axiosGet(`/api/items/${itemId}`);
    if (itemResponse.error) throw itemResponse.error;
    const mappedData = mapItems([itemResponse.data])[0];
    dispatch(createOrUpdateItemSuccess({ item: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Items" }));
  }
};

export const getAllocationHistory = (id) => async (dispatch) => {
  try {
    const response = await axiosGet(
      `/api/variant-allocation-history?filter[variant-allocation-id]=${id}`
    );
    if (response.error) throw response.error;
    const mappedData = mapAllocationHistory(response.data);
    dispatch(getAllocationHistorySuccess({ history: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Items" }));
  }
};
