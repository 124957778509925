/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { SelectInputProps } from "@mui/material/Select/SelectInput";

import { SelectInput } from "@utils/forms";

const useOrgOptions = () => {
  const { availableOrgs } = useSelector(
    (state: any) => state.currentUser.organization
  );

  return availableOrgs.map((org) => ({
    name: org.name,
    id: org.id,
  }));
};

const OrganizationSelector = (props: SelectInputProps) => {
  const options = useOrgOptions();

  return (
    <SelectInput
      tw="min-w-[200px]"
      name="organization"
      label="Organization"
      options={options}
      {...props}
    />
  );
};

export default OrganizationSelector;
