import { createSlice } from "@reduxjs/toolkit";

import { axiosGet, axiosPatch, axiosPost } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildBudget, buildBulkBudgets } from "./helpers";
import { mapBudget, mapBudgets } from "./maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isUpdateLoading: false,
  nextLink: null,
  budgets: [],
  budgetsHistory: [],
  budgetReport: [],
  currentBudget: null,
  triggerCSVDownload: false,
  hasUpdated: false,
  error: null,
};

const budgetSlice = createSlice({
  name: "budgets",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    setIsNextLoading(state) {
      state.isNextLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getBudgetSuccess(state, action) {
      const { budget } = action.payload;
      state.currentBudget = budget;
      state.isLoading = false;
      state.error = null;
    },
    createOrUpdateBudgetSuccess(state, action) {
      const { budget } = action.payload;
      let currentBudget = state.budgets.find((b) => b.id === budget.id);
      state.budgets = !currentBudget
        ? state.budgets.concat(budget)
        : state.budgets.map((b) => {
            if (b.id === budget.id) return budget;
            else return b;
          });
      state.currentBudget = budget;
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    uploadBulkBudgetsSuccess(state) {
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    setTriggerCSVFalse(state) {
      state.triggerCSVDownload = false;
    },
    resetCurrentBudget(state) {
      state.currentBudget = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsNextLoading,
  setIsUpdateLoading,
  getBudgetSuccess,
  createOrUpdateBudgetSuccess,
  uploadBulkBudgetsSuccess,
  setHasUpdated,
  setTriggerCSVFalse,
  resetCurrentBudget,
  setFailure,
} = budgetSlice.actions;

export default budgetSlice.reducer;

export const fetchBudget = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(
      `/api/budgets/${id}?include=budget_calculations`
    );
    if (response.error) throw response.error;
    const mappedData = mapBudgets([response.data], "overview")[0];
    dispatch(getBudgetSuccess({ budget: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Fetch Budget" }));
  }
};

export const createOrUpdateBudget =
  (attrs, type, id = null) =>
  async (dispatch) => {
    try {
      dispatch(patchLoading());
      dispatch(setIsUpdateLoading());
      const data = buildBudget(attrs, type);
      const response = id
        ? await axiosPatch(`/api/budgets/${id}`, data)
        : await axiosPost("/api/budgets", data);
      if (response.error) throw response.error;
      const mappedData = mapBudget(response.data);
      dispatch(createOrUpdateBudgetSuccess({ budget: mappedData }));
      dispatch(patchSuccess());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Create or Update Budgets" })
      );
      dispatch(patchFailure());
    }
  };

export const uploadBulkBudgets =
  (bulkBudgets, callback) => async (dispatch) => {
    try {
      dispatch(patchLoading());
      const body = buildBulkBudgets(bulkBudgets);
      const res = await axiosPost(`/api/budgets/import`, body);
      if (res.error) throw res.error;
      dispatch(uploadBulkBudgetsSuccess());
      dispatch(patchSuccess());
    } catch (err) {
      console.error(err);
      dispatch(setFailure({ error: err.toString() }));
      dispatch(
        setError({ error: err.toString(), source: "Upload Bulk Budgets" })
      );
      dispatch(patchFailure());
    } finally {
      callback?.();
    }
  };
