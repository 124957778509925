import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

const PromotionTypeSelector = ({ handleType, type, setType, filterType }) => {
  const currentType = useSelector((state) => state.filters.promotionType);
  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const types = [
    { id: "order", label: "Order" },
    { id: "item", label: "Item" },
  ].map((type, index) => (
    <MenuItem value={type.id} key={index}>
      <Typography variant="body2">{type.label}</Typography>
    </MenuItem>
  ));

  const handleChangeSelect = (evt) => {
    setType(evt.target.value);
    handleType(evt.target.value, "promotionType", filterType);
  };

  useEffect(() => {
    if (type !== currentType) {
      setType(currentType);
    }
  });

  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        size="small"
        disabled={isGlobalLoading}
      >
        <InputLabel id="promotion-type-select">Promotion Type</InputLabel>
        <Select
          label={"Promotion Type"}
          name="promotion-type"
          labelId="promotion-type-select"
          id="promotion-type"
          value={type || ""}
          onChange={handleChangeSelect}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {[...types]}
        </Select>
      </FormControl>
    </>
  );
};

PromotionTypeSelector.propTypes = {
  handleType: PropTypes.func.isRequired,
  type: PropTypes.string,
  setType: PropTypes.func.isRequired,
  filterType: PropTypes.string,
};

export default PromotionTypeSelector;
