import client from "@services/api";
import asyncPool from "@utility/asyncPool";

import { variantOptionIdsFromHash } from "../helpers";
import { FormVariant } from "../types";

const saveItemVariants = async (
  itemId: string,
  variantFormData: Record<string, FormVariant>
) => {
  const res = await asyncPool(
    5,
    Object.entries(variantFormData),
    ([hash, { id, ...variant }]) => {
      const requestBody = {
        ...variant,
        itemId,
        variantOptions: variantOptionIdsFromHash(hash).map((id) => ({
          id,
        })),
        image: variant.imageId && {
          id: variant.imageId,
        },
        relationshipNames: ["variantOptions", "image"],
      };

      if (id) {
        return client.update(`variants/${id}`, requestBody);
      } else {
        return client.post(`variants`, requestBody);
      }
    }
  );
  if (res.errors) throw res.errors[0];
  return res.results;
};
export default saveItemVariants;
