import { useApiResource } from "@services/api";

const useOrderWindow = (id: string) => {
  return useApiResource("order-windows", {
    id,
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });
};

export default useOrderWindow;
