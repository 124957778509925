import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { Channel } from "@models/Channel";
import client from "@services/api";

export const channelsKeyFactory = createQueryKeys("channels", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<Channel[]>("channels", { params }).then((res) => res.data),
  }),
});

export const useChannelsListQuery = (params?: { id: string }) => {
  return useQuery({
    ...channelsKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: Infinity,
  });
};
