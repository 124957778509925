import React, { memo } from "react";

import { Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { useInput } from "../../hooks/inputs/useInput";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const CancelModal = ({
  open,
  cancelObject,
  handleClose,
  handleCancel,
  type,
}) => {
  const classes = useStyles();

  const { value, bind, reset } = useInput("");

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => {
          reset();
          handleClose("cancel");
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => {
              reset();
              handleClose("cancel");
            }}
            size="large"
          >
            <Cancel fontSize="large" color="secondary" />
          </IconButton>
          <div className={classes.centeredModal}>
            <Typography className={classes.headerText}>
              {type === "order"
                ? `Please provide a reason for canceling Order #${cancelObject.id}`
                : `Please provide a reason for canceling #${cancelObject.sku}${
                    cancelObject.variantNames
                      ? ` - ${cancelObject.variantNames}`
                      : ""
                  }`}
            </Typography>
            <br />
            <TextField
              fullWidth
              multiline
              rows="4"
              variant="outlined"
              color="secondary"
              name="note"
              type="text"
              label="Cancelation Reason"
              {...bind}
            />
            <br />
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => handleCancel(cancelObject.id, value, type)}
            >
              {type === "order" ? "CANCEL ORDER" : "CANCEL ITEM"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CancelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  cancelObject: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default memo(CancelModal);
