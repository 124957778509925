/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import _ from "lodash";

import SmartTable from "@components/Table/SmartTable";
import { useSelectedResources } from "@features/filters";
import { ControlledResourceAutocomplete } from "@utils/forms";

const splitSort = (str: string): ["asc" | "desc", string] =>
  str.startsWith("-") ? ["desc", str.slice(1)] : ["asc", str];

const BudgetAssignment = ({
  control,
  resource,
  budgetAssignment,
  setValue,
}: {
  control: Control<any>;
  resource: "users" | "territories" | "groups";
  budgetAssignment: any[];
  setValue: UseFormSetValue<any>;
}) => {
  const resourceIds = useWatch({ control, name: resource });
  const { data: resources, isLoading } = useSelectedResources(
    resource,
    resourceIds
  );
  const [filterState, setFilterState] = useState<Record<string, any>>({
    sort: "role",
  });

  const [dir, sort] = splitSort(filterState.sort);

  // Merge the entities coming from the budget with the ones requested by their ID.
  // Users that can change orgs may not always be returned by the user's endpoint on this current org,
  // To insure we're displaying all user relationships, we need to get them directly from the budget relationship
  const rows = _(resources)
    .unionBy(budgetAssignment, "id")
    .filter((entities) => resourceIds.includes(entities.id))
    .sortBy(sort)
    .value();

  if (dir === "desc") {
    rows.reverse();
  }

  const handleRemove = (id: string) => {
    setValue(
      resource,
      resourceIds.filter((resourceId: string) => resourceId !== id),
      { shouldDirty: true }
    );
  };

  return (
    <>
      <ControlledResourceAutocomplete
        tw="mb-3"
        label="Search"
        resource={resource}
        renderTags={() => null}
        control={control}
        name={resource}
        multiple
        disableClearable
        disableCloseOnSelect
        getOptionSecondaryText={resource === "users" ? "email" : undefined}
        resourceFilterName={resource === "users" ? "name-or-email" : "name"}
        requestParams={{ sort: "name" }}
      />
      {rows.length > 0 && (
        <SmartTable
          maxHeight={500}
          rows={rows}
          isLoading={isLoading}
          filterState={filterState}
          setFilterState={setFilterState}
          columns={[
            {
              id: "name",
              label: "Name",
              sort: "name",
            },
            ...(resource === "users"
              ? [
                  { id: "email", sort: "email", label: "Email" },
                  {
                    id: "role",
                    label: "Role",
                    sort: "role",
                    render: _.startCase,
                  },
                ]
              : []),
            {
              id: "_remove",
              label: "",
              align: "right",
              render: (_, ent) => (
                <IconButton
                  size="small"
                  tw="-m-3"
                  onClick={() => handleRemove(ent.id)}
                >
                  <Close tw="text-neutral-400 hover:text-neutral-700" />
                </IconButton>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default BudgetAssignment;
