import { axiosGet, axiosPatch, axiosPost } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "./errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "./patchOrderSlice";

const mapChannel = (channel) => ({
  id: channel.id,
  name: channel.name,
  isActive: channel["is-active"],
  orgId: channel.organization.id,
});

export const mapChannels = (channels, orgId) => {
  return channels
    .map(mapChannel)
    .filter((c) => (orgId ? +c.orgId === +orgId : true));
};

export const mapchannelUsers = (channelUsers, orgId) => {
  return channelUsers
    .filter(({ channel }) =>
      orgId ? +channel.organization.id === +orgId : true
    )
    .map(({ channel, ...uc }) => ({
      id: uc.id,
      channelId: channel.id,
      channelName: channel.name,
      receivesThresholdEmails: uc["receives-threshold-emails"],
    }));
};

const buildChannel = (attrs) => {
  return {
    data: {
      type: "channel",
      attributes: {
        name: attrs.name,
        "is-active": attrs.isActive,
      },
    },
  };
};

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  channels: [],
  hasUpdated: false,
  error: null,
};

const channelSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getChannelsSuccess(state, action) {
      const { channels } = action.payload;
      state.channels = channels;
      state.isLoading = false;
      state.error = null;
    },
    createChannelSuccess(state, action) {
      const { channel } = action.payload;
      state.channels = state.channels
        .concat(channel)
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    updateChannelSuccess(state, action) {
      const { channel } = action.payload;
      state.channels = state.channels.map((c) => {
        if (c.id === channel.id) {
          return channel;
        } else return c;
      });
      state.isUpdateLoading = false;
      state.hasUpdated = true;
      state.error = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    clearErrors(state) {
      state.error = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getChannelsSuccess,
  createChannelSuccess,
  updateChannelSuccess,
  setHasUpdated,
  clearErrors,
  setFailure,
} = channelSlice.actions;

export default channelSlice.reducer;

export const fetchChannels = () => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet("/api/channels");
    if (response.error) {
      throw response.error;
    }
    let mappedData = mapChannels(response.data);
    dispatch(getChannelsSuccess({ channels: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Channels" }));
  }
};

export const createChannel = (attrs) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const postData = buildChannel(attrs);
    const response = await axiosPost("/api/channels", postData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapChannels([response.data])[0];
    dispatch(createChannelSuccess({ channel: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(patchFailure());
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Channels" }));
  }
};

export const updateChannel = (id, attrs) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const patchData = buildChannel(attrs);
    const response = await axiosPatch(`/api/channels/${id}`, patchData);
    if (response.error) {
      throw response.error;
    }
    const mappedData = mapChannels([response.data])[0];
    dispatch(updateChannelSuccess({ channel: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(patchFailure());
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Channels" }));
  }
};
