import React, { memo } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { id: "id", label: "Id" },
  { id: "name", label: "Name" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const VariantCategoryTable = ({
  handleCategoryClick,
  categories,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: "100%", overflowY: "auto" }}>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {!isLoading && categories.length === 0 && (
            <TableRow>
              <TableCell align="left" colSpan={4}>
                <Typography className={classes.headerText}>
                  There are currently no variants created for your organization.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            categories.length > 0 &&
            categories.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => handleCategoryClick(row.id, "edit", "variant")}
              >
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={4}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

VariantCategoryTable.propTypes = {
  handleCategoryClick: PropTypes.func.isRequired,
  categories: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
};

export default memo(VariantCategoryTable);
