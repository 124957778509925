import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { setExpires } from "@redux/slices/user/currentUserSlice";

const QueryClientProviderWrapper = (props: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  // make client persistent
  const [client] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        onError: (error: any) => {
          // Catch 401, invalid_token errors and set to expire
          if (error.status === 401) {
            console.error(error);
            dispatch(setExpires({ expires: 1 }));
          }
        },
      }),
      defaultOptions: {
        queries: {
          // because most of our data contains circular data, we can't use this optimization
          structuralSharing: false,
          retry: false,
        },
        mutations: {},
      },
    })
  );
  return <QueryClientProvider client={client} {...props} />;
};

export default QueryClientProviderWrapper;
