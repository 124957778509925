import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const writeWorkbookToFile = async (workbook) => {
  const buffer = await workbook.xlsx.writeBuffer();
  return new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
};

const downloadFile = (blob: Blob, filename: string) => {
  saveAs(blob, `${filename}.xlsx`);
};

const createWorkbook = async (data: any[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // Define headers
  const headers = [
    "SKU",
    "Item Name",
    "Packout",
    "Category",
    "Current On Hand",
    "Current Allocated Inventory",
    "Current Unallocated Inventory",
  ];

  // Track column indices for coloring
  const territoryColumnRanges: { start: number; end: number }[] = [];
  let currentColumn = headers.length + 1; // Start after the initial headers

  // Add territory headers and track their column ranges
  data[0]?.territories.forEach(territory => {
    const startColumn = currentColumn;
    
    territory.territory_users.forEach(user => {
      headers.push(user.user_name);
      currentColumn++;
    });
    headers.push(`${territory.territory_name} Total`);
    
    territoryColumnRanges.push({
      start: startColumn,
      end: currentColumn,
    });
    currentColumn++;
  });

  // Add headers to the worksheet
  const headerRow = worksheet.addRow(headers);
  
  // Set alignment for each header cell
  headerRow.eachCell((cell, colNumber) => {
    cell.alignment = {
      textRotation: 90,
      vertical: 'middle',
      horizontal: 'center'
    };
    // Make columns A-G bold
    if (colNumber <= 7) {
      cell.font = { bold: true };
    }
  });
  headerRow.height = 150;


  // Set specific column widths
  worksheet.getColumn('A').width = 17.85;  // 125px / 7
  worksheet.getColumn('B').width = 60.71;  // 425px / 7
  worksheet.getColumn('C').width = 6.28;   // 44px / 7
  worksheet.getColumn('D').width = 22.14;  // 155px / 7
  worksheet.getColumn('E').width = 12.14;  // 85px / 7
  worksheet.getColumn('F').width = 19.57;  // 137px / 7
  worksheet.getColumn('G').width = 21.28;  // 149px / 7

  // Color the allocation header cells and add borders to columns
  ['E', 'F', 'G'].forEach(col => {
    // Color only the header cell
    const headerCell = headerRow.getCell(col);
    headerCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF92D050' }, // Moss Green
    };

    // Add borders to the entire column
    worksheet.eachRow((row) => {
      const cell = row.getCell(col);
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Freeze header row and first two columns
  worksheet.views = [{ 
    state: 'frozen', 
    xSplit: 2,  // Freeze columns A and B
    ySplit: 1   // Freeze top row
  }];
  worksheet.properties.defaultRowHeight = 20;

  // Predefined contrasting colors (can add more if needed)
  const colors = [
    'FFD9D9D9', // light grey
    'FFD9D9D9', // light grey
    'FF3DC5F5', // blue
    'FFF56B89', // red
    'FFFFE699', // yellow
    'FF943BC5', // purple
    'FF27F7A8', // mint green
    'FFED83E3', // pink
    'FFFFC000', // orange
    'FFB7DEE8', // light blue
    'FFBFBFBF', // dark grey
    'FF00b050', // dark green
  ];

  // Apply colors to territory headers and make territory name columns bold
  territoryColumnRanges.forEach((range, index) => {
    const color = colors[index % colors.length];
    
    for (let col = range.start; col <= range.end; col++) {
      // Color only the header cell
      const headerCell = headerRow.getCell(col);
      headerCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color },
      };
      
      // Make the territory total header cell bold (last column in each range)
      if (col === range.end) {
        headerCell.font = { bold: true };
      }

      // Add borders to the entire column
      worksheet.eachRow((row) => {
        const cell = row.getCell(col);
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    }
  });

  // First add all the data
  data.forEach((item) => {
    const rowData = [
      item.variantSku || "BrandHub SKU",
      item.itemName,
      item.packSize,
      item.groupName,
      item.currOnHandInventory || null,
      item.currAllocatedInventory === 0 ? null : item.currAllocatedInventory,
      item.currUnallocatedInventory === 0 ? null : item.currUnallocatedInventory,
    ];

    // Add territory data
    item.territories.forEach(territory => {
      territory.territory_users.forEach(user => {
        rowData.push(user.user_allocation === 0 ? null : user.user_allocation);
      });
      rowData.push(territory.territory_total_allocation === 0 ? null : territory.territory_total_allocation);
    });

    worksheet.addRow(rowData);
  });

  // Then apply alternating row colors (starting after header row)
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1 && rowNumber % 2 === 0) { // Skip header row (1) and do even rows
      for (let i = 1; i <= headers.length; i++) {
        const cell = row.getCell(i);
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFF2F2F2' }  // Light grey color
        };
      }
    }
  });

  // Add borders to all cells in all columns
  for (let col = 1; col <= headers.length; col++) {
    worksheet.eachRow((row) => {
      const cell = row.getCell(col);
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  }

  return workbook;
}

export default async function downloadAllocationSnapshotReportExcel(data) {
  const workbook = await createWorkbook(data);
  const blob = await writeWorkbookToFile(workbook);
  downloadFile(blob, 'AllocationDetailReport');
}