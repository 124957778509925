import { isEqual } from "lodash";
import { useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const useSetParams = (paramString) => {
  const params = useParams();
  const navigate = useNavigate();

  const setParams = useCallback(
    (obj) => {
      const newParams = { ...params, ...obj };
      const newUrl = paramString
        .replace(/:([\w-_]+)/gi, (_, m1) => newParams[m1] || "")
        .split("/")
        .join("/");
      navigate(newUrl);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramString, useDeepCompareMemoize(params)]
  );
  return [params, setParams];
};

export default useSetParams;
