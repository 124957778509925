/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { updateHasViewed } from "@redux/slices/user/currentUserSlice";

import Tutorial from "./Tutorial";

const DashboardTutorial = () => {
  const dispatch = useDispatch();
  const { channels, territories } = useSelector((state) => state.currentUser);

  const channelOrTerritoryString = [
    channels.length > 1 && "Channels",
    territories.length > 1 && "Territories",
  ]
    .filter(Boolean)
    .join(" and ");

  const handleDismiss = () => dispatch(updateHasViewed("has-viewed-dashboard"));

  return (
    <Tutorial
      handleDismiss={handleDismiss}
      steps={[
        {
          anchor: "#org-card",
          content: (
            <p tw="text-2xl">
              Welcome to <b>brandhub</b>!
            </p>
          ),
        },

        {
          anchor: ".sidebar",
          position: "right",
          content: `Over here is your side navigation for all brandhub functions.`,
        },

        ...(channelOrTerritoryString
          ? [
              {
                anchor: "#tutorial-channel-territory",
                content: (
                  <p>
                    It looks like you have been assigned to multiple{" "}
                    {channelOrTerritoryString}, use these selectors to select
                    which one you are currently working in.
                  </p>
                ),
              },
            ]
          : []),
        {
          anchor: "#tutorial-profile-button",
          content: (
            <>
              These are your User Options. Click here to find your{" "}
              <b>Address Book</b>, look at your <b>account settings</b>, and
              find helpful guides and tutorials for using Brandhub.
            </>
          ),
        },
        {
          position: "center",
          content: (
            <>
              <p tw="text-2xl">What would you like to do next:</p>
              <ol>
                <li>
                  1. See the{" "}
                  <Link
                    tw="text-primary-600 underline"
                    to="/profile/addressBook"
                    onClick={handleDismiss}
                  >
                    Address Book Tutorial
                  </Link>
                </li>
                <li>
                  2. See the{" "}
                  <Link
                    tw="text-primary-600 underline"
                    onClick={handleDismiss}
                    to="/help"
                  >
                    Help page
                  </Link>
                </li>
              </ol>
            </>
          ),
        },
      ]}
    />
  );
};

export default DashboardTutorial;
