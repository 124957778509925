/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useFormContext, useWatch } from "react-hook-form";

import { StyledButton } from "@components/StyledComponents";
import { ControlledTextInput } from "@utils/forms";
import useDebouncedValue from "@utils/useDebouncedValue";

import { useAddSupplierUserMutation, useSupplierUserListQuery } from "../data";
import useNavigateToUser from "../useNavigateToUser";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const UserEmailInput = () => {
  const navigateToUser = useNavigateToUser();
  const {
    control,
    formState: { dirtyFields },
  } = useFormContext();

  const addSupplierUserMutation = useAddSupplierUserMutation();
  const email = useWatch({ name: "email" }).trim();

  const debouncedEmail = useDebouncedValue(email, 300);
  const emailSearch =
    dirtyFields.email && // only search if the email field has been changed
    debouncedEmail === email &&
    emailRegex.test(debouncedEmail)
      ? debouncedEmail
      : "";

  const { data } = useSupplierUserListQuery(emailSearch);

  const foundSupplierUser = data?.[0] ?? null;

  const handleAddSupplierUser = (id: string) => {
    addSupplierUserMutation.mutate(id, {
      onSuccess: () => navigateToUser(id),
    });
  };

  return (
    <>
      <ControlledTextInput
        label="Email"
        name="email"
        control={control}
        rules={{
          required: "Email is required",
          pattern: { value: emailRegex, message: "Invalid email address" },
        }}
      />
      {foundSupplierUser && (
        <div tw="rounded-md bg-primary-50 p-4 text-primary-800 border border-primary-200">
          <h3 tw="text-lg">
            {foundSupplierUser.name} is already a member of brandhub
          </h3>
          <p tw="text-sm">Would you like to add them to this organization?</p>
          <div tw="mt-4 flex gap-4">
            <StyledButton
              cta
              size="small"
              onClick={() => handleAddSupplierUser(foundSupplierUser.id)}
            >
              Add user
            </StyledButton>
          </div>
        </div>
      )}
    </>
  );
};

export default UserEmailInput;
