import { globalReportReducer } from "@features/reports";
import { combineReducers } from "@reduxjs/toolkit";

import budgetReducer from "./slices/budgets/budgetSlice";
import channelReducer from "./slices/channelSlice";
import costCenterReducer from "./slices/costCenterSlice";
import errorReducer from "./slices/errorSlice";
import filterReducer from "./slices/filterSlice";
import globalStateReducer from "./slices/globalStateSlice";
import groupCategoryReducer from "./slices/groupCategories/groupCategorySlice";
import itemsReducer from "./slices/items/itemSlice";
import orderWindowReducer from "./slices/orderWindows/orderWindowSlice";
import currentOrderSetReducer from "./slices/orders/currentOrderSetSlice";
import draftOrderSetReducer from "./slices/orders/draftOrderSetsSlice";
import orderHistoryReducer from "./slices/orders/orderHistorySlice";
import orderReviewReducer from "./slices/orders/orderReviewSlice";
import patchOrderReducer from "./slices/patchOrderSlice";
import programsReducer from "./slices/programs/programsSlice";
import promotionReducer from "./slices/promotions/promotionSlice";
import purchaseOrderRollupReducer from "./slices/purchaseOrders/purchaseOrderRollupSlice";
import purchaseOrderReducer from "./slices/purchaseOrders/purchaseOrderSlice";
import reportsReducer from "./slices/reports/reportSlice";
import supplierReducer from "./slices/suppliers/supplierSlice";
import territoryReducer from "./slices/territories/territorySlice";
import trackingReducer from "./slices/trackingSlice";
import currentUserReducer from "./slices/user/currentUserSlice";
import usersReducer from "./slices/user/usersSlice";
import variantCategoryReducer from "./slices/variantCategories/variantCategorySlice";

const rootReducer = combineReducers({
  budgets: budgetReducer,
  costCenters: costCenterReducer,
  channels: channelReducer,
  currentOrderSet: currentOrderSetReducer,
  currentUser: currentUserReducer,
  draftOrderSets: draftOrderSetReducer,
  error: errorReducer,
  filters: filterReducer,
  globalState: globalStateReducer,
  groupCategories: groupCategoryReducer,
  items: itemsReducer,
  orderHistory: orderHistoryReducer,
  orderReview: orderReviewReducer,
  orderWindows: orderWindowReducer,
  patchOrder: patchOrderReducer,
  programs: programsReducer,
  promotions: promotionReducer,
  purchaseOrder: purchaseOrderReducer,
  purchaseOrderRollup: purchaseOrderRollupReducer,
  reports: reportsReducer,
  suppliers: supplierReducer,
  territories: territoryReducer,
  tracking: trackingReducer,
  users: usersReducer,
  variantCategories: variantCategoryReducer,
  globalReports: globalReportReducer,
});

export default rootReducer;
