export const buildVariantCategory = (attributes) => {
  return {
    data: {
      type: "variant-category",
      attributes: {
        name: attributes.name,
      },
    },
  };
};

export const buildVariant = (attributes) => {
  const data = {
    data: {
      type: "variant",
      attributes: {
        name: attributes.name,
        ...(attributes.abbreviation && {
          abbreviation: attributes.abbreviation,
        }),
        ...(attributes.variantCategoryId && {
          "variant-category-id": attributes.variantCategoryId,
        }),
      },
    },
  };
  return data;
};
