/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import SupplierModal from "@components/Settings/Suppliers/SupplierModal";
import SupplierTable from "@components/Settings/Suppliers/SupplierTable";
import {
  FixedHeightScrollLastChild,
  OpaqueCard,
  StyledButton,
} from "@components/StyledComponents";
import { UserTable, usePaginatedUsersQuery } from "@features/users";
import {
  fetchSuppliers,
  resetCurrentSupplier,
} from "@redux/slices/suppliers/supplierSlice";
import DocTitle from "@utility/DocTitle";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AdminSuppliers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [supplierModal, setSupplierModal] = useState({
    open: false,
    type: null,
    id: null,
  });
  const { suppliers, isLoading } = useSelector((state) => state.suppliers);

  const { data: users, ...userTableProps } = usePaginatedUsersQuery({
    filter: { status: "active", roles: ["supplier"] },
  });

  const handleModalOpen = (id, type) => {
    dispatch(resetCurrentSupplier());
    setSupplierModal({ open: true, type: type, id: id });
  };

  const handleModalClose = () => {
    setSupplierModal({ open: false, type: null, id: null });
    dispatch(fetchSuppliers("", true));
  };

  useEffect(() => {
    dispatch(fetchSuppliers("", true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocTitle title={"Supplier Admin"} />
      <FixedHeightScrollLastChild>
        {supplierModal.open && (
          <SupplierModal {...supplierModal} handleClose={handleModalClose} />
        )}

        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Edit Suppliers</Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton cta onClick={() => handleModalOpen(null, "new")}>
              NEW SUPPLIER
            </StyledButton>
          </div>
        </div>
        <OpaqueCard tw="p-0 overflow-hidden">
          <SupplierTable
            handleSupplierClick={handleModalOpen}
            suppliers={suppliers}
            isLoading={isLoading}
            isModalOpen={supplierModal.open}
          />
        </OpaqueCard>
        <div className={classes.titleBar}>
          <Typography className={classes.titleText}>Supplier Users</Typography>
          <div className={classes.innerConfigDiv}>
            <StyledButton cta component={Link} to="/admin/users/new">
              NEW SUPPLIER USER
            </StyledButton>
          </div>
        </div>
        <UserTable
          userData={users}
          {...userTableProps}
          handleClick={(user) => navigate(`/admin/users/${user.id}`)}
        />
      </FixedHeightScrollLastChild>
    </>
  );
};

export default AdminSuppliers;
