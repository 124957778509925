import React, { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import PropTypes from "prop-types";

import SingleUserAutoComplete from "../Utility/AutoCompleteFields/SingleUserAutoComplete";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const SubmitOnBehalfModal = ({
  open,
  id,
  handleSubmit,
  territoryId,
  channelId,
  handleClose,
}) => {
  const classes = useStyles();

  const [selectedUser, setSelectedUser] = useState(null);
  const [reset, setReset] = useState(false);

  const handleUserSelect = (value) => {
    setSelectedUser(value);
    setReset(true);
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => handleClose()}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.centeredModal}>
            <Typography
              className={clsx(classes.headerText, classes.settingsMargin)}
            >
              {`Submitting Order Set #${id} On Behalf of Another User`}
            </Typography>
            <Typography className={classes.bodyText} color="textSecondary">
              <em>
                * User selection is limited to the territory and if applicable,
                the channel you are currently working in.
              </em>
            </Typography>
            <br />
            <SingleUserAutoComplete
              classes={classes}
              handleChange={handleUserSelect}
              reset={reset}
              setReset={setReset}
              disabled={false}
              roles={null}
              territoryId={territoryId}
              channelId={channelId ?? null}
              noMargin
            />
            <br />
            <Typography
              className={clsx(classes.headerText, classes.settingsMargin)}
            >
              Selected User:
            </Typography>
            {selectedUser && (
              <>
                <Typography className={classes.bodyText}>
                  <b>Name: </b>
                  {selectedUser.name}
                </Typography>
                <Typography className={classes.bodyText}>
                  <b>Email: </b>
                  {selectedUser.email}
                </Typography>
                <Typography className={classes.bodyText}>
                  <b>Role: </b>
                  {selectedUser.role}
                </Typography>
              </>
            )}
            {!selectedUser && (
              <Typography className={classes.bodyText}>
                No user selected
              </Typography>
            )}
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => {
                handleSubmit(selectedUser.id);
                handleClose();
              }}
              disabled={!selectedUser}
            >
              SUBMIT
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SubmitOnBehalfModal.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  territoryId: PropTypes.string.isRequired,
  channelId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default SubmitOnBehalfModal;
