export const buildPurchaseOrder = (attrs) => {
  if (attrs.type && attrs.type === "inventory") {
    return {
      data: {
        type: "purchase-order",
        attributes: {
          type: "inventory",
          "variant-qtys": attrs.variantQtys,
          warehouse: attrs.warehouse,
        },
      },
    };
  } else if (attrs.type && attrs.type === "fulfillment") {
    return {
      data: {
        type: "purchase-order",
        attributes: {
          "order-variant-ids": attrs.orderVariantIds.map((id) => +id),
          "in-market-date": attrs.inMarketDate,
        },
      },
    };
  } else {
    return {
      data: {
        type: "purchase-order",
        attributes: {
          "order-variant-ids": attrs.orderVariantIds,
          "order-type": attrs.orderType,
        },
      },
    };
  }
};

export const buildSetupFee = (attrs) => {
  return {
    data: {
      type: "purchase-order-variant",
      attributes: {
        ...attrs,
      },
    },
  };
};

export const buildPurchaseOrderPatch = (id, { supplierId, ...attrs }) => {
  return {
    data: {
      type: "purchase-order",
      id: id,
      attributes: {
        ...attrs,
      },
      ...(supplierId && {
        relationships: {
          supplier: {
            data: {
              type: "supplier",
              id: supplierId,
            },
          },
        },
      }),
    },
  };
};

export const buildPurchaseOrderVariantPatch = (id, attrs) => {
  return {
    data: {
      type: "purchase-order-variant",
      id: id,
      attributes: {
        ...attrs,
      },
    },
  };
};

export const buildShippingParameterPatch = (id, attrs) => {
  return {
    data: {
      type: "shipping-parameter",
      id: id,
      attributes: {
        ...attrs,
      },
    },
  };
};

export const buildShippingParameterExtras = (spvArray) => {
  return {
    "shipping-parameter-variants": spvArray,
  };
};
