/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Suspense } from "react";
import { useImage } from "react-image";

import { CircularProgress } from "@mui/material";

import { Image } from "@models/Image";

import { cloudinary } from "./cloudinary";
import imageSizeOptions, { ImageSize } from "./imageSizeOptions";

const LazyImage = ({ src, ...props }) => {
  const { src: renderSrc } = useImage({
    srcList: [
      src,
      "https://res.cloudinary.com/brandhub/image/upload/v1685722984/prod/Brandhub/no-image-square_iefxuz.png",
    ],
  });
  return <img src={renderSrc} alt="" {...props} />;
};

const CldImage = ({
  src: providedSrc,
  image,
  publicId,
  size,
  ...props
}: Omit<React.HTMLProps<HTMLImageElement>, "size"> & {
  src?: string;
  publicId?: string;
  image?: Image | null;
  size?: ImageSize;
}) => {
  const urlOptions = size ? imageSizeOptions[size] : {};
  const src =
    providedSrc ??
    cloudinary.url(publicId ?? image?.cloudinaryId ?? "", urlOptions);

  return (
    <Suspense
      fallback={
        <div
          tw="flex items-center justify-center w-8 h-8"
          className={props.className}
        >
          <CircularProgress tw="text-neutral-300" size={16} />
        </div>
      }
    >
      <LazyImage src={src} {...props} />
    </Suspense>
  );
};

export default CldImage;
