/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { Item } from "@models/Item";
import {
  ControlledSwitchInput,
  ControlledTextInput as TextInput,
  moneyAdornment,
  moneyValidation,
} from "@utils/forms";

import BlockCard from "../../../../ui/BlockCard";
import AwardedQuoteButton from "./AwardedQuoteButton";
import TieredPricingTable from "./TieredPricingTable";

const PricingBlock = ({ item }: { item: Item }) => {
  const { usesRfqs } = useSelector(
    (state: any) => state.currentUser.organization
  );
  const { watch, setValue, control } = useFormContext();

  const controlled = (name: string, rules?: any) => ({ name, control, rules });

  const canTurnOnVariablePricing =
    (watch("orderType") || item.orderType) === "on-demand";

  const usesVariablePricing = watch("usesVariablePricing");

  useEffect(() => {
    if (!canTurnOnVariablePricing && usesVariablePricing) {
      setValue("usesVariablePricing", false, { shouldDirty: true });
    }
  }, [canTurnOnVariablePricing, usesVariablePricing, setValue]);

  return (
    <BlockCard
      title="Pricing"
      tw="space-y-2"
      titleElement={usesRfqs ? <AwardedQuoteButton /> : undefined}
    >
      <div tw="flex flex-col sm:flex-row gap-4">
        <TextInput
          label="Price"
          {...moneyAdornment}
          {...controlled("price", moneyValidation)}
          onFocus={(e) => e.target.select()}
        />
        <TextInput
          label="Cost"
          {...moneyAdornment}
          {...controlled("cost", moneyValidation)}
          onFocus={(e) => e.target.select()}
        />
      </div>

      {canTurnOnVariablePricing && (
        <ControlledSwitchInput
          label="Use tiered pricing?"
          {...controlled("usesVariablePricing")}
        />
      )}
      {usesVariablePricing && <TieredPricingTable item={item} />}
    </BlockCard>
  );
};

export default PricingBlock;
