/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { updateOrder } from "@redux/slices/orders/currentOrderSetSlice";
import { formatMoney } from "@utility/utilityFunctions";

import usePreferExternalId from "../../../hooks/usePreferExternalId";
import StandardTableHead from "../../Utility/StandardTableHead";
import DeliverySelectionDropdown from "./DeliverySelectionDropdown";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
}));

const RightStickyCell = tw(TableCell)`
  sticky right-0 z-10 shadow-lg border-l border-neutral-200 bg-white`;

const CollapseRow = ({ classes, rowData, hasTax, preferExternalId }) => {
  const dispatch = useDispatch();
  const { usesOrderEventDesignation } = useSelector(
    (state) => state.currentUser.organization
  );
  const [open, setOpen] = useState(false);
  const itemDetailHeadCells = [
    preferExternalId ? "Product Identifier" : "Sku",
    "Name",
    "Price",
    "Qty",
    "Total",
  ];
  const alignCells = ["left", "left", "right", "right", "right"];
  const canSetDelivery =
    rowData.type === "inventory" && rowData.address.country === "United States";
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <Link
            tw="underline"
            to={`/orders/history/${rowData.id}`}
            target="_blank"
          >
            {rowData.id}
          </Link>
        </TableCell>
        <TableCell align="left">{rowData.address.name}</TableCell>
        <TableCell align="left">
          {`${rowData.address.city} / ${rowData.address.stateOrRegion}`}
        </TableCell>
        <TableCell align="right">
          {formatMoney(rowData.totalPrice, false)}
        </TableCell>
        <TableCell align="right">
          {formatMoney(rowData.estFreight, false)}
        </TableCell>
        {hasTax && (
          <TableCell align="right">
            {formatMoney(rowData.estTax, false)}
          </TableCell>
        )}
        <TableCell align="right">
          {formatMoney(
            rowData.totalPrice + rowData.estFreight + rowData.estTax,
            false
          )}
        </TableCell>

        <RightStickyCell>
          <div tw="flex items-center gap-3">
            {canSetDelivery ? (
              <DeliverySelectionDropdown order={rowData} />
            ) : (
              <ListItemText primary="Standard" secondary="5 - 7 days" />
            )}
            {usesOrderEventDesignation && (
              <FormControlLabel
                control={
                  <Checkbox
                    tw="-my-1"
                    checked={rowData.isForEvent}
                    onChange={(e) =>
                      dispatch(
                        updateOrder(rowData.id, {
                          isForEvent: e.target.checked,
                        })
                      )
                    }
                  />
                }
                tw="m-0"
                label="For Event"
                labelPlacement="bottom"
                slotProps={{
                  typography: {
                    style: tw`text-xs text-center text-neutral-500`,
                  },
                }}
              />
            )}
          </div>
        </RightStickyCell>
      </TableRow>
      <TableRow>
        <TableCell tw="p-0 relative z-20" colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div tw="rounded-t-lg bg-neutral-100 pt-4">
              <Typography
                className={classes.headerText}
                gutterBottom
                component="div"
                tw="pl-4"
              >
                Item Detail
              </Typography>
              <TableContainer>
                <Table size="small" aria-label="item-detail">
                  <StandardTableHead
                    headCells={itemDetailHeadCells}
                    alignCells={alignCells}
                  />
                  <TableBody>
                    {rowData.variants.map((v, i) => (
                      <TableRow key={`${v.id}-${rowData.id}-${i}`}>
                        <TableCell>
                          {preferExternalId ? v.warehouseId : v.sku}
                        </TableCell>
                        <TableCell>{v.name}</TableCell>
                        <TableCell align="right">
                          {formatMoney(v.price, false)}
                        </TableCell>
                        <TableCell align="right">{v.count}</TableCell>
                        <TableCell align="right">
                          {formatMoney(v.totalPrice, false)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SetOverviewOrderTable = ({ orders, hasTax }) => {
  const classes = useStyles();
  const preferExternalId = usePreferExternalId();

  const headCells = [
    "",
    "Order #",
    "Address",
    "City / State",
    "Total",
    "Est. Freight",
    ...(hasTax ? ["Est. Tax"] : []),
    "Extended Total",
  ];
  const alignCells = [
    "left",
    "left",
    "left",
    "left",
    "right",
    "right",
    "right",
    "right",
  ];

  return (
    <TableContainer className={classes.tableContainer}>
      <Table tw="border-separate">
        <TableHead
          css={{
            th: tw`py-3 text-xs tracking-wider uppercase whitespace-nowrap text-neutral-500`,
          }}
        >
          <TableRow>
            {headCells.map((headCell, i) => (
              <TableCell key={i} align={alignCells[i]}>
                {headCell}
              </TableCell>
            ))}
            <RightStickyCell>Delivery</RightStickyCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((o) => (
            <CollapseRow
              key={o.id}
              classes={classes}
              rowData={o}
              hasTax={hasTax}
              preferExternalId={preferExternalId}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SetOverviewOrderTable.propTypes = {
  orders: PropTypes.array.isRequired,
  hasTax: PropTypes.bool.isRequired,
};

export default SetOverviewOrderTable;
