import React, { Suspense } from "react";
import { useImage } from "react-image";

import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";

import ImgErrorBoundary from "./ImgErrorBoundary";

const ImgComp = ({ imgUrl, alt, imgClass, id, handleClick, ...props }) => {
  const { src } = useImage({ srcList: [imgUrl, "/images/NotFound.png"] });
  return (
    <img
      src={src}
      alt={alt}
      className={imgClass}
      id={id}
      onClick={handleClick}
      {...props}
    />
  );
};

const ImageWrapper = ({
  imgUrl,
  alt,
  imgClass,
  id,
  handleClick = () => {},
  ...props
}) => {
  return (
    <Suspense
      fallback={<CircularProgress size={16} style={{ padding: "1em" }} />}
    >
      <ImgErrorBoundary alt={alt} imgClass={imgClass} id={id}>
        <ImgComp
          imgUrl={imgUrl}
          alt={alt}
          imgClass={imgClass}
          id={id}
          handleClick={handleClick}
          {...props}
        />
      </ImgErrorBoundary>
    </Suspense>
  );
};

ImageWrapper.propTypes = {
  imgUrl: PropTypes.string,
  alt: PropTypes.string,
  imgClass: PropTypes.string,
  id: PropTypes.string,
};

export default React.memo(ImageWrapper);
