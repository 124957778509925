/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useFilterParams } from "@features/filters";
import { useApiResource } from "@services/api";

import useDefaultApprovalStatus from "../useDefaultApprovalStatus";

const SuggestionCard = tw.button`
rounded-lg py-2 px-3 shadow-sm text-sm
bg-primary-50 text-primary-800 border border-primary-300 `;

const InventoryFilterSuggestions = () => {
  const defaultApprovalStatus = useDefaultApprovalStatus();
  const [filters, applyFilters] = useFilterParams();
  const handleSetFilter = () => {
    applyFilters({
      ...filters,
      orderWindows: [],
      orderType: "inventory",
      orderSetStatus: defaultApprovalStatus,
    });
  };

  const { data = [] } = useApiResource("order-sets", {
    params: {
      filter: {
        status: [defaultApprovalStatus],
        types: ["inventory"],
      },
      page: { size: 1 },
    },
  });
  if (filters.orderType === "inventory") {
    return null;
  }
  if (data.length > 0) {
    return (
      <SuggestionCard
        tw="bg-orange-50 text-orange-800 border border-orange-400"
        onClick={handleSetFilter}
      >
        You have Inventory Orders to Review
      </SuggestionCard>
    );
  }
  return null;
};

const PreOrderFilterSuggestions = () => {
  const defaultApprovalStatus = useDefaultApprovalStatus();
  const [filters, applyFilters] = useFilterParams();
  const handleSetFilter = () => {
    applyFilters({
      ...filters,
      orderWindows: [],
      orderType: "pre-order",
      orderSetStatus: defaultApprovalStatus,
    });
  };
  const { data = [] } = useApiResource("order-sets", {
    params: {
      filter: {
        status: [defaultApprovalStatus],
        types: ["pre-order"],
      },
      page: { size: 1 },
    },
  });
  if (filters.orderType === "pre-order") {
    return null;
  }
  if (data.length > 0) {
    return (
      <SuggestionCard onClick={handleSetFilter}>
        You have Pre-Order Orders to Review
      </SuggestionCard>
    );
  }
  return null;
};

const OnDemandFilterSuggestions = () => {
  const defaultApprovalStatus = useDefaultApprovalStatus();
  const [filters, applyFilters] = useFilterParams();
  const handleSetFilter = () => {
    applyFilters({
      ...filters,
      orderWindows: [],
      orderType: "on-demand",
      orderSetStatus: defaultApprovalStatus,
    });
  };
  const { data = [] } = useApiResource("order-sets", {
    params: {
      filter: {
        status: [defaultApprovalStatus],
        types: ["on-demand"],
      },
      page: { size: 1 },
    },
  });
  if (filters.orderType === "on-demand") {
    return null;
  }
  if (data.length > 0) {
    return (
      <SuggestionCard
        tw="bg-purple-50 text-purple-800 border border-purple-400"
        onClick={handleSetFilter}
      >
        You have on Demand Orders to Review
      </SuggestionCard>
    );
  }
  return null;
};

// const OrderWindowFilterSuggestion = ({
//   orderWindow,
// }: {
//   orderWindow: OrderWindow;
// }) => {
//   const defaultApprovalStatus = useDefaultApprovalStatus();
//   const [filters, applyFilters] = useFilterParams();
//   const handleSetFilter = () => {
//     applyFilters({
//       ...filters,
//       orderWindows: [orderWindow.id],
//       orderSetStatus: defaultApprovalStatus,
//       orderType: undefined,
//     });
//   };
//   const { data = [] } = useApiResource("order-sets", {
//     params: {
//       filter: {
//         orderWindowIds: [orderWindow.id],
//         states: [defaultApprovalStatus],
//       },
//       page: { size: 1 },
//     },
//   });
//   if (filters.orderWindows?.includes(orderWindow.id)) {
//     return null;
//   }
//   if (data.length > 0) {
//     return (
//       <SuggestionCard onClick={handleSetFilter}>
//         You have Orders to Review for{" "}
//         <span tw="underline">{orderWindow.name}</span>
//       </SuggestionCard>
//     );
//   }
//   return null;
// };

const ApprovalsFilterSuggestions = () => {
  return (
    <div tw="empty:hidden flex flex-wrap gap-2 -mb-2! mt-4!">
      <InventoryFilterSuggestions />
      <PreOrderFilterSuggestions />
      <OnDemandFilterSuggestions />
    </div>
  );
};

export default ApprovalsFilterSuggestions;
