import { memo, useState } from "react";
import { useDispatch } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { AddressSearch } from "@features/addresses";
import { updateOrderAddress } from "@redux/slices/orders/orderHistorySlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const EditOrderAddressModal = ({ open, editObject, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newAddress, setNewAddress] = useState(null);

  const handleChangeAddress = () => {
    dispatch(updateOrderAddress(editObject.id, newAddress.id));
    handleClose("edit");
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={() => handleClose("edit")}
        fullWidth
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            Updating Shipping Parameter Address
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={() => handleClose("edit")}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.fullWidthCenterColumn}>
            <Typography className={classes.bodyText}>
              Current Address:
            </Typography>
            <br />
            <Typography className={classes.bodyText}>
              {editObject.address.name}
            </Typography>
            <Typography className={classes.bodyText}>
              {editObject.address.streetOne}
            </Typography>
            {editObject.address.streetTwo && (
              <Typography className={classes.bodyText}>
                {editObject.address.streetTwo}
              </Typography>
            )}
            <Typography className={classes.bodyText}>
              {`${editObject.address.city}, ${
                editObject.address.stateOrRegion !== "---"
                  ? `${editObject.address.stateOrRegion}, `
                  : ""
              }${editObject.address.zip}`}
            </Typography>
            {editObject.address.country && (
              <Typography className={classes.bodyText}>
                {editObject.address.country.name}
              </Typography>
            )}
            <br />
            <AddressSearch
              onChange={setNewAddress}
              validateTerritory={editObject.territoryId}
            />
            <br />
            <Typography className={classes.bodyText}>New Address:</Typography>
            {newAddress && (
              <>
                <br />
                <Typography className={classes.bodyText}>
                  {newAddress.name}
                </Typography>
                <Typography className={classes.bodyText}>
                  {newAddress.streetAddress1}
                </Typography>
                {newAddress.streetAddress2 && (
                  <Typography className={classes.bodyText}>
                    {newAddress.streetAddress2}
                  </Typography>
                )}
                <Typography className={classes.bodyText}>
                  {`${newAddress.city}, ${
                    newAddress.state?.code ?? newAddress.region
                  }, ${newAddress.zip}`}
                </Typography>
                {newAddress.country && (
                  <Typography className={classes.bodyText}>
                    {newAddress.country.name}
                  </Typography>
                )}
              </>
            )}
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleChangeAddress}
              disabled={!newAddress}
            >
              UPDATE ADDRESS
            </Button>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditOrderAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  editObject: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(EditOrderAddressModal);
