/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Edit } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

import _, { kebabCase } from "lodash";

import { updatePurchaseOrder } from "@redux/slices/purchaseOrders/purchaseOrderSlice";
import { upCase } from "@utility/utilityFunctions";
import { DatePicker, ResourceAutocomplete } from "@utils/forms";
import { StyledTextInput } from "@utils/forms/ControlledInputs";
import permissions from "@utils/permissions";
import useRoleIs from "@utils/useRoleIs";

import { OpaqueCard, SectionTitle, StyledButton } from "../StyledComponents";
import DebounceTextField from "../Utility/DebounceTextField";

const TextOnlyField = ({ label, value }) => (
  // Display newlines in the value
  <p tw="whitespace-pre-line">
    <b>{label}: </b>
    {value ?? "---"}
  </p>
);

const Field = ({ canEdit, ...props }) => {
  if (!canEdit)
    return <TextOnlyField label={props.label} value={props.value} />;

  return <DebounceTextField {...props} />;
};

const SupplierEditForm = ({ purchaseOrder, onCancel, onSubmit }) => {
  const [supplier, setSupplier] = useState(purchaseOrder.supplierId);
  return (
    <div>
      <ResourceAutocomplete
        label="Supplier *"
        resource="suppliers"
        value={supplier}
        disableClearable
        onChange={(_, val) => setSupplier(val.id)}
      />
      <div tw="flex justify-end gap-2 mt-2">
        <StyledButton outlined onClick={onCancel}>
          Cancel
        </StyledButton>
        <StyledButton cta onClick={() => onSubmit(supplier)}>
          Save
        </StyledButton>
      </div>
    </div>
  );
};

const PurchaseOrderDetail = () => {
  const dispatch = useDispatch();
  const [editingSupplier, setEditingSupplier] = useState(false);
  const { currentPurchaseOrder: purchaseOrder } = useSelector(
    (state) => state.purchaseOrder
  );

  const { status } = purchaseOrder;

  const roleIs = useRoleIs();

  const poCompleted = ["complete", "canceled"].includes(status);

  const editSupplierReference =
    !roleIs("purchaser", "read-only") && !poCompleted;

  const editInvoiceNumber = roleIs("super") || !poCompleted;
  const canEditSupplierFields =
    !roleIs("read-only", permissions.supplier) && !poCompleted;

  const canEditPurchasingAgentFields =
    !roleIs(permissions.supplier, "read-only") && !poCompleted;

  const register = (name) => ({
    value: purchaseOrder[name],
    id: purchaseOrder.id,
    setter: updatePurchaseOrder,
    attr: kebabCase(name),
    fullWidth: true,
  });

  const handleSaveSupplier = (supplierId) => {
    dispatch(updatePurchaseOrder(purchaseOrder.id, { supplierId }));
    setEditingSupplier(false);
  };

  if (purchaseOrder.type === "fulfillment") {
    return (
      <div tw="flex flex-col lg:flex-row gap-6">
        <OpaqueCard tw="p-6 space-y-3 flex-1">
          <SectionTitle>Inventory Fulfillment</SectionTitle>
          <TextOnlyField label="Purchased By" value={purchaseOrder.purchaser} />

          <TextOnlyField label="Status" value={upCase(status, "-")} />
        </OpaqueCard>

        <OpaqueCard tw="p-6 space-y-3 flex-1">
          <SectionTitle>Fulfillment Notes</SectionTitle>
          <TextOnlyField
            label="In Market Date"
            value={purchaseOrder.inMarketDate}
          />
          <TextOnlyField
            label="Warehouse(s)"
            value={_(purchaseOrder.purchaseOrderVariants)
              .map((pov) => upCase(pov.item.warehouse))
              .uniq()
              .join(", ")}
          />

          <p>
            This fulfillment is complete, but will continue to update with
            shipping information as the warehouse updates ship dates and
            tracking information for the orders.
          </p>
        </OpaqueCard>
      </div>
    );
  } else
    return (
      <div tw="flex flex-col lg:flex-row gap-6">
        <OpaqueCard tw="p-6 space-y-3 flex-1">
          <TextOnlyField label="Status" value={upCase(status, "-")} />
          {!editingSupplier && (
            <div tw="flex items-center justify-between gap-2">
              <TextOnlyField label="Supplier" value={purchaseOrder.supplier} />
              {roleIs("super") &&
                ["draft", "ready-to-purchase"].includes(status) && (
                  <IconButton
                    onClick={() => setEditingSupplier(true)}
                    tw="-my-3"
                  >
                    <Edit tw="text-primary-600" />
                  </IconButton>
                )}
            </div>
          )}
          {editingSupplier && (
            <SupplierEditForm
              purchaseOrder={purchaseOrder}
              onCancel={() => setEditingSupplier(false)}
              onSubmit={handleSaveSupplier}
            />
          )}

          <TextOnlyField label="Purchased By" value={purchaseOrder.purchaser} />

          <Field
            {...register("supplierReference")}
            canEdit={editSupplierReference}
            label="Supplier Reference"
          />

          <Field
            {...register("invoiceNumber")}
            canEdit={editInvoiceNumber}
            label="Invoice Number"
          />

          <Field
            canEdit={!roleIs(permissions.supplier, "read-only") && !poCompleted}
            {...register("terms")}
            label="Terms"
          />

          {purchaseOrder.requiresPurchasingAgent && (
            <div
              css={[
                tw`space-y-3`,
                // highlight the area for the purchasing-agent
                roleIs("purchasing-agent") &&
                  status === "ready-to-purchase" &&
                  tw`p-3 -mx-3 rounded-lg bg-primary-50`,
              ]}
            >
              <Field
                canEdit={canEditPurchasingAgentFields}
                {...register("purchasingAgentReferenceNumber")}
                label="External PO #"
              />

              <Field
                canEdit={canEditPurchasingAgentFields}
                {...register("purchasingAgentInvoiceNumber")}
                label="Purchasing Agent Invoice Number"
              />
            </div>
          )}
        </OpaqueCard>
        <OpaqueCard tw="p-6 space-y-3 flex-1">
          <SectionTitle>Notes for supplier</SectionTitle>
          {!roleIs(permissions.supplier, "read-only") && !poCompleted && (
            <DatePicker
              format="MM/dd/yyyy"
              label="In Market Date"
              value={purchaseOrder.inMarketDate}
              slots={{ textField: StyledTextInput }}
              onChange={(value) =>
                dispatch(
                  updatePurchaseOrder(purchaseOrder.id, {
                    "in-market-date": new Date(value),
                  })
                )
              }
            />
          )}
          {(roleIs(permissions.supplier, "read-only") || poCompleted) && (
            <Typography>
              <b>In Market Date: </b>
              {`${purchaseOrder.inMarketDate}`}
            </Typography>
          )}

          <Field
            {...register("method")}
            canEdit={canEditSupplierFields}
            label="Shipping Method"
          />

          <Field
            {...register("labelingInstructions")}
            canEdit={canEditSupplierFields}
            label="Labeling Instructions"
            multiline
            minRows={2}
          />

          <Field
            {...register("note")}
            canEdit={canEditSupplierFields}
            label="Note"
            multiline
            minRows={3}
          />
        </OpaqueCard>
      </div>
    );
};

export default PurchaseOrderDetail;
