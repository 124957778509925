import { capitalize } from "lodash";

import { formatDateString, formatMoneyString } from "@utility/utilityFunctions";
import { CsvSchema } from "@utils/csv";

const historicalOrderReport: CsvSchema = [
  { id: "id", label: "ID" },
  { id: "orderId", label: "Order #" },
  { id: "accountCode", label: "Acc. Code" },
  { id: "externalWarehouseId", label: "Warehouse ID" },
  { id: "itemName", label: "Item Name" },
  { id: "variant1", label: "Variant 1" },
  { id: "variant2", label: "Variant 2" },
  { id: "orderedBy", label: "Ordered By" },
  { id: "email", label: "Email" },
  { id: "program", label: "Program" },
  { id: "state", label: "State" },
  { id: "orderType", label: "Order Type", f: capitalize },
  {
    id: "orderDate",
    label: "Order Date",
    f: formatDateString,
  },
  { id: "totalQty", label: "Total Qty" },
  { id: "price", label: "Price", f: (v) => v && formatMoneyString(v) },
  {
    id: "actualFreight",
    label: "Freight",
    f: (v) => v && formatMoneyString(v),
  },
  { id: "actualTax", label: "Tax", f: (v) => v && formatMoneyString(v) },
  { id: "budget", label: "Budget" },
  { id: "billingInfo", label: "Billing Info" },
  { id: "supplier", label: "supplier" },
  { id: "poNumber", label: "PO #" },
  { id: "status", label: "Status", f: capitalize },
];

export default historicalOrderReport;
