/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import { Publish } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import axios, { AxiosError as _AxiosError } from "axios";
import PropTypes from "prop-types";

import { StyledButton } from "@components/StyledComponents";
import { useItemListQuery } from "@features/items";
import { Territory } from "@models/Territory";
import { User } from "@models/User";
import { clearError, setError } from "@redux/slices/errorSlice";
import { downloadAsCsv } from "@utils/csv";
import { ResourceAutocomplete } from "@utils/forms";

export const handleBulkAllocationCSV = (data) => {
  const allocations = data.slice(3).map((row) => {
    const {
      "User Email": userEmail,
      User: user,
      "Territory Name": territory,
      ...rest
    } = row.data;
    const userAllocations = Object.entries(rest).reduce(
      (acc, [sku, quantity]) => {
        if (quantity !== "") {
          acc[sku] = isNaN(Number(quantity)) ? quantity : Number(quantity);
        }
        return acc;
      },
      {}
    );

    return {
      ...(userEmail && { "User Email": userEmail }),
      Name: user ?? territory,
      ...userAllocations,
    };
  });

  return allocations;
};

const BulkAllocationModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const csvRef = useRef<CSVReader<any>>(null);
  const [selected, setSelected] = useState<Territory[] | User[]>([]);
  const [isUploadLoading, setUploadLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [allocations, setAllocations] = useState<any[]>([]);
  const [successMessages, setSuccessMessages] = useState<string[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const { allocationLocation } = useSelector(
    (state: any) => state.currentUser.organization
  );
  const allocationLocationIsTerritory = allocationLocation === "territory";

  const getCommonTerritoryIds = (users: User[]): string[] => {
    if (users.length === 0) return [];
    const [firstUser, ...restUsers] = users;

    const commonTerritories = firstUser.territories
      .map((territory) => territory.id)
      .filter((id) =>
        restUsers.every((user) =>
          user.territories.some((territory) => territory.id === id)
        )
      );
    return commonTerritories;
  };

  const { data, isLoading } = useItemListQuery({
    skipPagination: true,
    filter: {
      isActive: true,
      ...(allocationLocationIsTerritory && {
        territoryIds: (selected as Territory[]).map(
          (territory: Territory) => territory.id
        ),
      }),
      ...(!allocationLocationIsTerritory && {
        territoryIds: getCommonTerritoryIds(selected as User[]),
      }),
    },
  });
  const items = data ?? [];

  const handleChangeSelection = (_, newSelected) => {
    const sanitizedSelection = allocationLocationIsTerritory
      ? newSelected
      : newSelected.map(({ _supplier, ...rest }) => rest);
    setSelected(sanitizedSelection);
  };

  const handleDownloadTemplate = () => {
    const headers = allocationLocationIsTerritory
      ? ["Territory Name"]
      : ["User", "User Email"];

    const variants = items.flatMap((item) =>
      item.variants.filter(
        (variant) =>
          variant.availableToOrderQty &&
          variant.availableToOrderQty > 0 &&
          variant.isActive
      )
    );
    const skus = variants.map((variant, _) =>
      variant.externalWarehouseId
        ? variant.externalWarehouseId
        : variant.variantSku
    );

    const csvHeaders = [...headers, ...skus];

    let csvData = [csvHeaders];

    const itemName = [
      "",
      ...(allocationLocationIsTerritory ? [] : [""]),
      ...variants.map((variant) => `Item Name: ${variant.item.name}`),
    ];

    const packSizes = [
      "",
      ...(allocationLocationIsTerritory ? [] : [""]),
      ...variants.map((variant) => `Pack Size: ${variant.item.packSize}`),
    ];

    const onHands = [
      "",
      ...(allocationLocationIsTerritory ? [] : [""]),
      ...variants.map((variant) => `On Hand: ${variant.availableToOrderQty}`),
    ];

    csvData.push(itemName);
    csvData.push(packSizes);
    csvData.push(onHands);

    selected.forEach((selection) => {
      const row = allocationLocationIsTerritory
        ? [selection.name, ...Array(skus.length).fill("")]
        : [selection.name, selection.email, ...Array(skus.length).fill("")];
      csvData.push(row);
    });

    // Use the downloadAsCsv function
    downloadAsCsv(csvData, "allocation_template.csv");
  };

  const handleValidateFile = async (data) => {
    dispatch(clearError());
    setErrors([]);
    setWarnings([]);
    setAllocations([]);
    setSuccessMessages([]);

    try {
      const allocations = handleBulkAllocationCSV(data);

      const res = await axios.post("/api/bulk-variant-allocations/validate", {
        data: { rows: allocations },
      });

      if (res.data.warnings && res.data.warnings.length > 0) {
        setWarnings(res.data.warnings);
      }
      setAllocations(allocations);
    } catch (err: any) {
      if (err.response && err.response.data) {
        const { errors, warnings } = err.response.data;
        if (errors && errors.length > 0) {
          setErrors(errors);
        }
        if (warnings && warnings.length > 0) {
          setWarnings(warnings);
        }
      } else {
        dispatch(setError({ error: err, source: "Bulk Address Upload" }));
      }
    }
    setUploadLoading(false);
  };

  const handleOpenDialog = (evt) => {
    if (csvRef.current) {
      csvRef.current.open(evt);
    }
  };

  const handleFileUploadError = (err) => {
    // If there are errors uploading
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order File Upload" })
    );
  };

  const handleSubmitAllocations = async () => {
    setUploadLoading(true);
    setShowConfirmation(false); // Close dialog immediately when starting upload
    try {
      const res = await axios.post(
        "/api/bulk-variant-allocations/validate-and-save",
        {
          data: { rows: allocations },
        }
      );

      if (res.status === 200) {
        setSuccessMessages(["Allocations successfully uploaded!"]);
        setAllocations([]); // Clear allocations to hide the submit button
      }
    } catch (err: _AxiosError | any) {
      if (err.response && err.response.data) {
        const { errors, warnings } = err.response.data;
        if (errors && errors.length > 0) {
          setErrors(errors);
        }
        if (warnings && warnings.length > 0) {
          setWarnings(warnings);
        }
      } else {
        dispatch(setError({ error: err, source: "Bulk Allocation Upload" }));
      }
    }
    setUploadLoading(false);
  };

  const ConfirmationDialog = () => (
    <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
      <DialogTitle>Bulk Allocations Ready to Upload</DialogTitle>
      <DialogContent>
        <Typography>
          Once this allocation file is uploaded, any items that are included in
          the file will have their allocations replaced by the allocations
          indicated in this file. Please double-check the allocations listed
          before submitting the file.
        </Typography>
        <Typography tw="mt-4 font-bold">
          PRO TIP: Download an allocation report BEFORE submitting this bulk
          Allocation for reference to what allocations previously existed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton outlined onClick={() => setShowConfirmation(false)}>
          Cancel
        </StyledButton>
        <StyledButton
          cta
          onClick={handleSubmitAllocations}
          loading={isUploadLoading}
        >
          Upload
        </StyledButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle tw="flex justify-between items-center">
          Bulk Allocation Upload
          <IconButton onClick={handleClose} edge="end">
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent tw="p-6">
          <div tw="text-lg font-semibold mb-4">Create Template</div>
          <div tw="text-base mb-4">
            Select {allocationLocationIsTerritory ? "territories" : "users"} for
            your allocations, then click “Download Template”. After filling in
            the template with your new allocations, upload your template by
            clicking “Upload Allocations” below and selecting your filled-in
            template file.
          </div>
          {allocationLocationIsTerritory ? (
            <ResourceAutocomplete
              label="Select Territories"
              resource="territories"
              onChange={handleChangeSelection}
              multiple
              disableCloseOnSelect
              tw="mb-4"
              clearSearchOnSelect
            />
          ) : (
            <ResourceAutocomplete
              label="Select Users"
              resource="users"
              resourceFilterName="nameOrEmail"
              onChange={handleChangeSelection}
              multiple
              disableCloseOnSelect
              tw="mb-4"
              clearSearchOnSelect
              requestParams={{
                filter: {
                  status: "active",
                },
              }}
            />
          )}
          {errors.length > 0 && (
            <div tw="text-red-500 mb-4">
              <Typography variant="h6">Errors:</Typography>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>
                    <Box tw="bg-red-100 p-2 rounded-md mb-2 border border-red-500 text-red-700">
                      {error}
                    </Box>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {warnings.length > 0 && (
            <div tw="text-yellow-500 mb-4">
              <Typography variant="h6">Warnings:</Typography>
              <ul>
                {warnings.map((warning, index) => (
                  <li key={index}>
                    <Box tw="bg-yellow-100 p-2 rounded-md mb-2 border border-yellow-500 text-yellow-700">
                      {warning}
                    </Box>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {successMessages.length > 0 && (
            <div tw="text-green-500 mb-4">
              <Typography variant="h6">Success:</Typography>
              <ul>
                {successMessages.map((message, index) => (
                  <li key={index}>
                    <Box tw="bg-green-100 p-2 rounded-md mb-2 border border-green-500 text-green-700">
                      {message}
                    </Box>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </DialogContent>
        <DialogActions tw="p-4">
          <StyledButton
            outlined
            onClick={handleDownloadTemplate}
            tw="mr-2"
            disabled={isLoading || selected.length === 0}
            loading={isLoading}
          >
            Download Template
          </StyledButton>
          <CSVReader
            ref={csvRef}
            onFileLoad={handleValidateFile}
            onError={handleFileUploadError}
            noClick
            noDrag
            config={{
              header: true,
              beforeFirstChunk: () => setUploadLoading(true),
            }}
            noProgressBar
          >
            {({ error }) => (
              <div style={{ position: "relative" }}>
                <StyledButton
                  outlined
                  startIcon={<Publish />}
                  onClick={handleOpenDialog}
                  loading={isUploadLoading}
                  id="tutorial-bulk-upload"
                >
                  Upload Allocations
                </StyledButton>
                {!isUploadLoading && error && (
                  <Typography color="textSecondary">
                    {`Error uploading file: ${error}`}
                  </Typography>
                )}
              </div>
            )}
          </CSVReader>
          {allocations.length > 0 && errors.length === 0 && (
            <StyledButton
              cta
              onClick={() => setShowConfirmation(true)}
              loading={isUploadLoading}
            >
              Submit Allocations
            </StyledButton>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmationDialog />
    </>
  );
};

BulkAllocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BulkAllocationModal;
