/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { HistoryRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";
import { sortBy } from "lodash";

import { OpaqueCard } from "@components/StyledComponents";
import ReactQueryTable, {
  Column,
  PaginatedResourceProps,
} from "@components/Table/ReactQueryTable";
import { usersKeyFactory } from "@features/users";
import { User } from "@models/User";
import { upCase } from "@utility/utilityFunctions";
import permissions from "@utils/permissions";

export type UserTableProps = PaginatedResourceProps & {
  userData: User[];
  handleClick: (user: User) => void;
};

const UserTable = ({
  userData,
  handleClick,
  ...tableProps
}: UserTableProps) => {
  const queryClient = useQueryClient();

  const { warehouses } = useSelector(
    (state: any) => state.currentUser.organization
  );

  const orgHasVernonWarehouse = warehouses.some(
    (warehouse) => warehouse === "vernon"
  );

  const handleUserClick = (user: User) => {
    queryClient.setQueryData(usersKeyFactory.detail(user.id).queryKey, user);
    handleClick(user);
  };

  const userColumns: Column<User>[] = [
    {
      id: "id",
      label: "ID",
      sort: "id",
    },
    {
      id: "name",
      label: "Name",
      sort: "name",
      render: (name) => <span tw="whitespace-nowrap">{name}</span>,
    },
    {
      id: "email",
      label: "Email",
      sort: "email",
    },
    ...(orgHasVernonWarehouse
      ? [
          {
            id: "vernonBillingId",
            label: "Account Code",
          },
        ]
      : []),
    {
      id: "role",
      label: "Role",
      render: (role) => upCase(role, "-"),
    },
    {
      id: "territories",
      label: "Territories",
      render: (territories, user) => {
        // these roles don't have territories
        if (
          ["supplier", "select-account-manager", "purchasing-agent"].includes(
            user.role
          )
        )
          return null;
        // Show only territories that belong to the user's organization
        const territoriesForUserOrg = territories.filter(
          (terr) => terr.organizationId === user.organizationId
        );
        const terrString = sortBy(territoriesForUserOrg, ["name"])
          .map((terr) => terr.name)
          .join(", ");
        return (
          <div tw="flex items-baseline">
            <div tw="bg-neutral-100 rounded py-1 px-2 mr-2 text-neutral-600 text-xs">
              {territoriesForUserOrg.length}
            </div>{" "}
            <span tw="line-clamp-1">{terrString}</span>
          </div>
        );
      },
    },
    {
      id: "actions",
      label: "",
      render: (_, row) => {
        if (!permissions.orders.includes(row.role as any)) return null;
        return (
          <Tooltip title="View Order History">
            <IconButton
              component={Link}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              to={`/orders/history?orderGroupBy=order&users[]=${row.id}&fromDate=&toDate=`}
              size="small"
            >
              <HistoryRounded tw="text-primary-600" />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <ReactQueryTable
        columns={userColumns}
        rows={userData}
        onRowClick={handleUserClick}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

export default UserTable;
