import React from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { upCase } from "@utility/utilityFunctions";

import StandardTableHead from "../../Utility/StandardTableHead";

const headCells = [
  { id: "id", label: "Id" },
  { id: "description", label: "Description" },
  { id: "discountType", label: "Discount Type" },
  { id: "startDate", label: "Start Date" },
  { id: "expirationDate", label: "Expiration Date" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const PromotionTable = ({
  handlePromotionClick,
  promotions,
  isLoading,
  scrollRef,
}) => {
  const classes = useStyles();

  return (
    <TableContainer style={{ maxHeight: "100%" }} ref={scrollRef}>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {!isLoading && promotions.length === 0 && (
            <TableRow>
              <TableCell aligh="left" colSpan={5}>
                <Typography className={classes.headerText}>
                  There are currently no promotions that match your search...
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            promotions.length > 0 &&
            promotions.map((row) => (
              <TableRow
                key={row.id}
                hover
                className={classes.clickableRow}
                onClick={() => handlePromotionClick(row.id, "edit")}
              >
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">
                  {upCase(row.discountType, "-")}
                </TableCell>
                <TableCell align="left">{row.startDate}</TableCell>
                <TableCell align="left">{row.expirationDate}</TableCell>
              </TableRow>
            ))}
          {isLoading && (
            <TableRow>
              <TableCell align="left" colSpan={5}>
                <CircularProgress color="secondary" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PromotionTable.propTypes = {
  handlePromotionClick: PropTypes.func.isRequired,
  promotions: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.any,
};

export default PromotionTable;
