import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

const SingleChannelAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [channel, setChannel] = useState("");
  const [currentChannel, setCurrentChannel] = useState("");

  const { channels: options } = useSelector((state) => state.channels);

  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const handleChannels = (value) => {
    setCurrentChannel(value);
  };

  useEffect(() => {
    if (reset) {
      setChannel("");
      setCurrentChannel("");
      setReset(false);
    }
  }, [reset, setChannel, setReset]);

  return (
    <>
      <Autocomplete
        autoHighlight
        fullWidth
        freeSolo
        className={classes.settingsMargin}
        id="channel-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={channel}
        onInputChange={(_evt, value) => setChannel(value)}
        onChange={(_evt, value) => {
          handleChange(value);
          handleChannels(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name ?? ""}
        options={options}
        value={currentChannel}
        disabled={isGlobalLoading || disabled}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label="Channel"
            id="channel-auto-search"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

SingleChannelAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SingleChannelAutoComplete;
