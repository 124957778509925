export const buildVariantAllocation = (id, qty, userId, territoryId) => {
  let attributes = {
    "allocated-qty": +qty,
    "variant-id": +id,
  };
  if (userId) attributes["user-id"] = +userId;
  if (territoryId) attributes["territory-id"] = +territoryId;

  return {
    data: {
      attributes: attributes,
    },
  };
};
