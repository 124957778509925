import { Component } from "react";

import PropTypes from "prop-types";

import ErrorPage from "../../pages/ErrorPage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("ErrorBoundary caught an error", error, info);
    //add logging service like Sentry
  }

  render() {
    if (this.state.hasError) {
      const { errorElement: ErrorComponent } = this.props;
      console.log(this.props);
      if (ErrorComponent) {
        return <ErrorComponent {...this.state.error} />;
      }
      return <ErrorPage />;
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorElement: PropTypes.func,
};

export default ErrorBoundary;
