import React from "react";

import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { IconButton, Menu, Tooltip } from "@mui/material";

const DefaultButton = ({ open, ...props }) => (
  <IconButton
    size="small"
    aria-controls={open ? "basic-menu" : undefined}
    aria-haspopup="true"
    aria-expanded={open ? "true" : undefined}
    {...props}
  >
    <MoreVertRoundedIcon fontSize="inherit" />
  </IconButton>
);

const OptionsMenu = ({
  children,
  title,
  skipCloseOnClick = false,
  originRight = false,
  component: Component = DefaultButton,
  ...props
}) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title={title}>
        <div ref={anchorRef}>
          <Component onClick={handleClick} {...props} />
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        onClick={() => !skipCloseOnClick && handleClose()}
        {...(originRight && {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        })}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {children}
      </Menu>
    </>
  );
};

OptionsMenu.defaultProps = {
  skipCloseOnClick: false,
  title: "Options",
  originRight: false,
};

export default OptionsMenu;
