import React from "react";

import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { useNumberOnlyInput } from "../../hooks/inputs/useNumberOnlyInput";

const MemoNumberField = React.memo(
  ({
    value,
    setValue,
    formKey,
    label,
    fullWidth,
    componentClass,
    disabled,
  }) => {
    const { value: input, bind: bindInput } = useNumberOnlyInput(value || "");

    return (
      <TextField
        size="small"
        fullWidth={fullWidth}
        className={componentClass}
        variant="outlined"
        color="secondary"
        name={label}
        label={label}
        {...bindInput}
        onBlur={() => setValue(formKey, input)}
        disabled={disabled}
      />
    );
  }
);

MemoNumberField.propTypes = {
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired,
  formKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  componentClass: PropTypes.any.isRequired,
};

export default MemoNumberField;
