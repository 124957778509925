/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import { GetApp, HelpTwoTone, Publish } from "@mui/icons-material";
import { ClickAwayListener, IconButton, Typography } from "@mui/material";

import { uniqBy } from "lodash";

import AddressModal from "@components/Profile/Addresses/AddressModal";
import BulkUploadModal from "@components/Profile/Addresses/BulkUploadModal";
import {
  FixedHeightScrollLastChild,
  PageTitle,
  StyledButton,
} from "@components/StyledComponents";
import { InfoTooltip } from "@components/Utility/StyledComponents/InfoTooltip";
import AddressBookTutorial from "@components/tutorial/AddressBookTutorial";
import { Filters, useFilterParams } from "@features/filters";
import { clearError, setError } from "@redux/slices/errorSlice";
import asyncPool from "@utility/asyncPool";
import { downloadAsCsv } from "@utils/csv";

import AddressBookTable from "./AddressBookTable";
import {
  useCreateAddressImportMutation,
  usePaginatedAddressesQuery,
} from "./addressQueries";
import { handleAddressCSV } from "./helpers";

const csvHeaders = [
  "Name",
  "Street Address One",
  "Street Address Two",
  "Attention",
  "City",
  "State / Province",
  "Region",
  "Zip",
  "Country",
  "Phone Number",
  "Emails (separated by a semicolon)",
  "Territory (separated by a semicolon)",
];

const AddressPage = () => {
  const dispatch = useDispatch();
  const csvRef = useRef<CSVReader<any>>(null);

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState<any>("edit");
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [currentAddressId, setCurrentAddressId] = useState<string | null>(null);
  const [isUploadLoading, setUploadLoading] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  // const [csvErrors, setCSVErrors] = useState<any | null>([]);
  const [uploadErrors, setUploadErrors] = useState<any | null>([]);
  const [numSuccess, setNumSuccess] = useState<number>(0);

  const importAddress = useCreateAddressImportMutation();

  const {
    id: userId,
    currentTerritoryId,
    territories,
    role,
    hasViewedAddressBook,
    organization: { addressBookType },
  } = useSelector((state: any) => state.currentUser);
  const isAdmin = ["super", "admin"].includes(role);
  const [filterParams] = useFilterParams();

  const { data, ...tableProps } = usePaginatedAddressesQuery({
    filter: {
      searchTerm: filterParams.q,
      territoryId: currentTerritoryId,
      userIds: !isAdmin && addressBookType === "user" ? [userId] : null,
      stateIds: filterParams.states,
    },
    sort: filterParams.sort,
  });

  const addresses = data ?? [];

  const handleModalClose = () => {
    setModal(false);
    setCurrentAddressId(null);
  };

  const handleModalOpen = (id, type) => {
    setCurrentAddressId(id);
    setModalType(type);
    setModal(true);
  };

  const handleBulkModalClose = () => {
    setBulkUploadModal(false);
  };

  const handleTooltipClose = () => setTooltipOpen(false);

  const handleFileUpload = async (data) => {
    dispatch(clearError());
    setNumSuccess(0);
    setUploadErrors(0);

    const countries = uniqBy(
      territories.flatMap((t) => t.countries),
      "id"
    );

    const states = uniqBy(
      territories.flatMap((t) => t.states),
      "id"
    );

    try {
      const { validData, errors: csvValidationErrors } = handleAddressCSV(
        data,
        states,
        countries
      );

      if (csvValidationErrors.length > 0) {
        setUploadErrors(csvValidationErrors);
        setBulkUploadModal(true);
        setUploadLoading(false);
        return;
      }

      const res = await asyncPool(5, validData, async (address) =>
        importAddress.mutateAsync({ ...(address as Record<string, any>) })
      );

      if (res.errors) {
        setUploadErrors(res.errors.map((e: any) => e.data.error));
      }
      setNumSuccess(res.results.length);
      setBulkUploadModal(true);
    } catch (err) {
      dispatch(setError({ error: err, source: "Bulk Address Upload" }));
    }
    setUploadLoading(false);
  };

  const handleFileUploadError = (err) => {
    // If there are errors uploading
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order File Upload" })
    );
  };

  const handleOpenDialog = (evt) => {
    if (csvRef.current) {
      csvRef.current.open(evt);
    }
  };

  return (
    <FixedHeightScrollLastChild>
      {!tableProps.isLoading &&
        !hasViewedAddressBook &&
        role !== "read-only" && <AddressBookTutorial />}
      {modal && (
        <AddressModal
          open={modal}
          handleClose={handleModalClose}
          type={modalType}
          id={currentAddressId}
          forOrder={false}
          orderSetId={null}
          orderType={null}
        />
      )}
      {bulkUploadModal && (
        <BulkUploadModal
          open={bulkUploadModal}
          handleClose={handleBulkModalClose}
          successCount={numSuccess}
          errors={uploadErrors ?? []}
        />
      )}
      <div tw="flex flex-col gap-2 xl:(flex-row justify-between items-center)">
        <PageTitle>Edit Addresses</PageTitle>
        <div tw="flex gap-2 items-center">
          <StyledButton
            cta
            className="tutorial-highlight"
            id="tutorial-new-address"
            onClick={() => handleModalOpen(null, "new")}
          >
            NEW ADDRESS
          </StyledButton>
          <CSVReader
            ref={csvRef}
            onFileLoad={handleFileUpload}
            onError={handleFileUploadError}
            noClick
            noDrag
            config={{
              header: true,
              beforeFirstChunk: () => setUploadLoading(true),
            }}
            noProgressBar
          >
            {({ error }) => (
              <div style={{ position: "relative" }}>
                <StyledButton
                  outlined
                  startIcon={<Publish />}
                  onClick={handleOpenDialog}
                  loading={isUploadLoading}
                  id="tutorial-bulk-upload"
                >
                  UPLOAD BULK ADDRESSES
                </StyledButton>
                {!isUploadLoading && error && (
                  <Typography color="textSecondary">
                    {`Error uploading file: ${error}`}
                  </Typography>
                )}
              </div>
            )}
          </CSVReader>
          <StyledButton
            outlined
            startIcon={<GetApp />}
            onClick={() => downloadAsCsv([csvHeaders], "bulk-address-form.csv")}
          >
            BULK ADDRESS FORM
          </StyledButton>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <InfoTooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Upload Bulk Address allows you to upload a csv file of addresses.  You must first download the form, and make sure to leave the titles of the column heads the same. When adding states or provinces, you should enter them as the state code (CA, MN, etc ..) and when entering the Country, you must enter the full name of the country (United States, United Kingdom, etc ...).  State / Province is required if you are in the United States or Canada, otherwise Region is an optional field."
            >
              <IconButton
                tw="-my-4"
                onClick={() => setTooltipOpen(!tooltipOpen)}
              >
                <HelpTwoTone fontSize="large" color="secondary" />
              </IconButton>
            </InfoTooltip>
          </ClickAwayListener>
        </div>
      </div>
      <Filters
        searchTitle="Search Addresses"
        slots={["states"]}
        defaultValues={{ sort: "name" }}
      />
      <AddressBookTable
        rows={addresses}
        query={filterParams.q}
        onRowClick={(row) => handleModalOpen(row.id, "edit")}
        {...tableProps}
      />
    </FixedHeightScrollLastChild>
  );
};

export default AddressPage;
