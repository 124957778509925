import _ from "lodash";

import { Address } from "@models/Address";
import { Country } from "@models/Country";
import client from "@services/api";

export const addressColumns = [
  "Ship-to Attn",
  "Street Address 1",
  "Street Address 2",
  "City",
  "State",
  "Zip Code",
  "Country",
  "Phone Number",
] as const;
export const requiredAddressColumns = _.without(
  addressColumns,
  "Street Address 2"
);

export function validateOrders(
  data: Record<string, string>[],
  skus: string[],
  countries: Country[],
  territory
) {
  let errors: string[] = [];

  const expectedColumns = [...addressColumns, ...skus];
  const missingColumns = _.difference(expectedColumns, Object.keys(data[0]));
  if (missingColumns.length > 0) {
    errors.push(`Missing column headers: ${missingColumns.join(", ")}`);
  }
  const invalidColumns = _.difference(
    Object.keys(data[0]),
    expectedColumns
  ).map((header) => (header === "" ? "Empty Header" : header));
  if (invalidColumns.length > 0) {
    errors.push(`Invalid column headers: ${invalidColumns.join(", ")}`);
  }
  if (errors.length > 0) {
    return { data: null, errors };
  }

  const rows = data.map((rawRow, index) => {
    // remove whitespace from all values
    const row = _.mapValues(rawRow, (v) => v.trim());

    const addError = (msg: string) => errors.push(`Row ${index + 2}. ${msg}`);
    // require address columns to be filled
    const missingAddressColumns = requiredAddressColumns.filter(
      (col) => row[col].trim().length === 0
    );
    if (missingAddressColumns.length > 0) {
      addError(
        `Missing required address field: ${missingAddressColumns.join(", ")}`
      );
    }
    // validate country/state
    const country = countries.find(
      (c) => c.name === row["Country"] || c.code === row["Country"]
    );
    if (!country) {
      addError(`Invalid country "${row["Country"]}"`);
    } else if (!territory.countries.some((c) => c.id === country.id)) {
      addError(
        `Country "${country.name}" is invalid for territory ${territory.name}`
      );
    }

    let stateId: null | string = null;
    let region: null | string = null;
    if (country) {
      if (!country.states?.length) {
        region = row["State"];
      } else {
        const state = country.states.find((s) => s.code === row["State"]);
        if (!state) {
          addError(
            `Invalid state "${row["State"]}" for country ${country.name}`
          );
        } else if (!territory.states.some((s) => s.id === state.id)) {
          addError(
            `State ${state.code} is invalid for territory ${territory.name}`
          );
        } else {
          stateId = state.id;
        }
      }
    }

    // validate sku values are numbers
    const skuValues = _.pick(row, skus);
    const invalidValues = Object.entries(skuValues).filter(([_, qty]) => {
      const n = Number(qty);
      return isNaN(n) || n < 0;
    });

    if (invalidValues.length > 0) {
      addError(
        `Invalid quantity values: ${invalidValues
          .map(([sku, qty]) => `SKU ${sku} (${qty})`)
          .join(", ")}`
      );
    }
    const skuQtys = _.mapValues(skuValues, Number);

    return {
      name: row["Ship-to Attn"],
      streetAddress1: row["Street Address 1"],
      streetAddress2: row["Street Address 2"],
      city: row["City"],
      zip: row["Zip Code"],
      countryId: country?.id ?? null,
      phoneNumber: row["Phone Number"],
      region,
      stateId,
      ...skuQtys,
    };
  });
  if (errors.length > 0) {
    return { data: null, errors };
  }
  return { data: rows, errors: null };
}

export const createTempAddress = async (data, territoryId) =>
  client
    .post<Address>("/addresses", {
      __type: "address",
      ..._.pick(data, [
        "name",
        "streetAddress1",
        "streetAddress2",
        "city",
        "zip",
        "region",
        "phoneNumber",
      ]),
      type: "address",
      country: { __type: "country", id: data.countryId },
      state: { __type: "state", id: data.stateId },
      territory: { __type: "territory", id: territoryId },
      relationshipNames: ["country", "state", "territory"],
      // This is what makes it "temp" 👍
      isActive: false,
    })
    .then((res) => res.data);
