import { useSelector } from "react-redux";

import { Territory } from "@models/Territory";

type TBudgetLocation = "user" | "territory" | "group" | null;

export default function useCheckoutOptions(): {
  allowStandardCheckout: boolean;
  allowBudgetCheckout: boolean;
  allowStripeCheckout: boolean;
  budgetLocation: TBudgetLocation;
  requireBudget: boolean;
} {
  const {
    territories,
    currentTerritoryId,
    role,
    organization: { budgetLocation, usesStandardCheckout, usesStripe },
  } = useSelector((state: any) => state.currentUser);

  const currentTerritory: Territory = territories.find(
    (territory: any) => territory.id === currentTerritoryId
  );

  let checkouts = {
    allowStandardCheckout: false,
    allowBudgetCheckout: false,
    allowStripeCheckout: false,
    requireBudget: false,
    budgetLocation: budgetLocation as TBudgetLocation,
  };

  if (["admin", "super"].includes(role)) {
    checkouts = {
      ...checkouts,
      allowStandardCheckout: usesStandardCheckout,
      allowBudgetCheckout: !!budgetLocation,
      allowStripeCheckout: usesStripe,
    };
  } else if (currentTerritory) {
    checkouts = {
      ...checkouts,
      allowStandardCheckout: currentTerritory.allowStandardCheckout,
      allowBudgetCheckout: currentTerritory.allowBudgetCheckout,
      allowStripeCheckout: currentTerritory.allowStripeCheckout,
    };
  }

  checkouts.requireBudget =
    checkouts.allowBudgetCheckout &&
    !checkouts.allowStandardCheckout &&
    !checkouts.allowStripeCheckout;

  return checkouts;
}
