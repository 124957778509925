/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";

import { Tooltip, Typography } from "@mui/material";

import SearchableText from "../../../components/Table/SearchableText";
import { Item } from "../../../models";

const DottedInfoText = ({ title, children }) => {
  return (
    <Tooltip title={title}>
      <span tw="decoration-dashed underline underline-offset-4 decoration-neutral-300">
        {children}
      </span>
    </Tooltip>
  );
};

const ItemIdentifiers = ({
  item,
  query,
}: {
  item: Pick<Item, "sku" | "customerIdentifier">;
  query?: string;
}) => {
  return (
    <Typography
      tw="space-x-2 items-center text-sm text-primary-600 w-full"
      noWrap
    >
      <DottedInfoText title="brandhub generated SKU">
        <SearchableText text={item.sku} query={query} />
      </DottedInfoText>
      {item.customerIdentifier && <span tw="text-neutral-600">/</span>}
      {item.customerIdentifier && (
        <DottedInfoText title="Customer provided identifier">
          <SearchableText text={item.customerIdentifier} query={query} />
        </DottedInfoText>
      )}
    </Typography>
  );
};

export default ItemIdentifiers;
