/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { updateOrderSetCostCenter } from "@redux/slices/orders/currentOrderSetSlice";
import { SelectInput } from "@utils/forms";

const OrderSetCostCenters = () => {
  const dispatch = useDispatch();
  const { id, costCenter } = useSelector((state) => state.currentOrderSet);
  const costCenters = useSelector(
    (state) => state.costCenters.currentUserCostCenters
  );

  return costCenters.length > 1 ? (
    <SelectInput
      tw="w-full max-w-md"
      label="Cost center"
      options={costCenters}
      value={costCenter.id}
      onChange={(e) => dispatch(updateOrderSetCostCenter(id, e.target.value))}
    />
  ) : (
    <div>
      <Typography>Cost Center:</Typography>
      <Typography>{costCenter?.name || "No Cost Center assigned"}</Typography>
    </div>
  );
};

export default OrderSetCostCenters;
