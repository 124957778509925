/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { Controller } from "react-hook-form";

import { Close, SearchRounded } from "@mui/icons-material";
import { IconButton, InputBase } from "@mui/material";

import { useFilterContext } from "../filterContext";
import { Bordered } from "./StyledComponents";

const Search = ({ searchTitle = "Search", submit }) => {
  const { control } = useFilterContext();
  return (
    <Controller
      control={control}
      name={"q"}
      render={({ field }) => (
        <Bordered tw="relative h-10 focus-within:border-primary-800">
          <InputBase
            autoFocus
            tw="py-1 text-filter pl-3 pr-16 max-w-full w-56"
            placeholder={searchTitle}
            {...field}
            value={field.value ?? ""}
          />
          <IconButton
            type="submit"
            size="small"
            tw="absolute right-0"
            onClick={() => field.onChange(field.value)}
          >
            <SearchRounded />
          </IconButton>
          {field.value && (
            <IconButton
              type="submit"
              tw="absolute right-8 opacity-50 hover:opacity-100"
              onClick={() => {
                field.onChange("");
                submit();
              }}
            >
              <Close tw="text-base" />
            </IconButton>
          )}
        </Bordered>
      )}
    />
  );
};

export default Search;
