import client from "@services/api";

const saveImageOrder = (imageIds: string[]) =>
  Promise.all(
    imageIds.map((imgId, position) =>
      client.update(`images/${imgId}`, {
        position,
      })
    )
  );

export default saveImageOrder;
