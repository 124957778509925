/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "@mui/material";

import { format, subDays } from "date-fns";

import {
  FixedHeightScrollLastChild,
  OpaqueCard,
} from "@components/StyledComponents";
import Filters, { useFilterParams } from "@features/filters";
import {
  OrderHistoryItemTable,
  OrderHistoryOrderTable,
} from "@features/orderHistory";
import { usePaginatedOrderVariantsQuery } from "@features/orderVariants";
import { RequestReportButton, useReport } from "@features/reports";
import { useApiResourcePaginated } from "@services/api";
import DocTitle from "@utility/DocTitle";

const today = new Date();
const past7Days = subDays(today, 7);

const encodeDate = (date) => format(date, "yyyy-MM-dd");
const defaultFilters = {
  orderGroupBy: "item",
  orderStatus: "all",
  fromDate: encodeDate(past7Days),
  toDate: encodeDate(today),
  sort: "-order-submitted-at",
};

const buildParams = (filters, other) => ({
  filter: {
    searchTerm: filters.q,
    territoryIds: filters.territories,
    programIds: filters.programs,
    userIds: filters.users,
    groupIds: filters.groups && Object.values(filters.groups).flat(),
    budgetIds: filters.budgets,
    orderWindowIds: filters.orderWindows,
    ...other,
  },
  sort: filters.sort ?? "-order-submitted-at",
});

const OrderHistoryByItem = ({ filters }) => {
  const params = buildParams(filters, {
    orderType: filters.orderType,
    orderStatus:
      filters.orderStatus &&
      (filters.orderStatus === "all"
        ? ["submitted", "approval-review", "approved", "canceled", "error"]
        : [filters.orderStatus]),
    ...(filters.orderStatus !== "draft" && {
      orderSubmittedFromDate: filters.fromDate,
      orderSubmittedToDate: filters.toDate,
    }),
  });

  useReport("order-history-variant", ["order-variants", params]);

  const { data, ...tableProps } = usePaginatedOrderVariantsQuery(params);

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <OrderHistoryItemTable
        rows={data ?? []}
        query={filters.q}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

const OrderHistoryByOrder = ({ filters }) => {
  const params = buildParams(filters, {
    type: filters.orderType,
    status:
      filters.orderStatus &&
      (filters.orderStatus === "all"
        ? ["submitted", "approved", "canceled", "error"]
        : [filters.orderStatus]),
    ...(filters.orderStatus !== "draft" && {
      submittedFromDate: filters.fromDate,
      submittedToDate: filters.toDate,
    }),
  });
  params.sort = filters.sort?.replace("order-", "");
  useReport("order-history", ["orders", params]);
  const { data, ...tableProps } = useApiResourcePaginated("orders", {
    params,
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateFirstPage: false,
  });

  return (
    <OpaqueCard tw="p-0 overflow-hidden relative">
      <OrderHistoryOrderTable
        rows={data ?? []}
        query={filters.q}
        {...tableProps}
      />
    </OpaqueCard>
  );
};

const OrderHistory = () => {
  const [filters] = useFilterParams();
  const reportName =
    filters.orderGroupBy === "item"
      ? "order-history-variant"
      : filters.orderGroupBy === "order"
      ? "order-history"
      : null;

  const { usesHistoricalOrders } = useSelector(
    (state: any) => state.currentUser.organization
  );

  return (
    <>
      <DocTitle title={"Order History"} />
      <FixedHeightScrollLastChild>
        <div tw="flex flex-wrap justify-between items-center">
          <h2 tw="text-2xl text-neutral-600 font-bold mb-2">Order History</h2>
          <div tw="flex gap-2 items-center">
            {usesHistoricalOrders && (
              <Button variant="text" component={Link} to={"/orders/archive"}>
                View Order Archive
              </Button>
            )}
            {reportName && <RequestReportButton reportName={reportName} />}
          </div>
        </div>
        <Filters
          searchTitle="Search Orders"
          slots={[
            "orderGroupBy",
            "orderStatus",
            "dateRange",
            "orderType",
            "groups",
            "territories",
            "programs",
            "users",
            "budgets",
            "orderWindows",
          ]}
          slotProps={{
            dateRange: {
              title: "Order was submitted",
              chipTextPrefix: "Submitted ",
            },
          }}
          defaultValues={defaultFilters}
          alwaysShow={["dateRange"]}
        />
        {filters.orderGroupBy === "item" && (
          <OrderHistoryByItem filters={filters} />
        )}
        {filters.orderGroupBy === "order" && (
          <OrderHistoryByOrder filters={filters} />
        )}
      </FixedHeightScrollLastChild>
    </>
  );
};

export default OrderHistory;
