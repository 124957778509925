import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { List, ListItem, TextField } from "@mui/material";

type Group = {
  id: string;
  name: string;
  abbreviation?: string;
};

type CategoryGroupListProps = {
  id?: string;
  register: any;
  newCategoryGroups?: any;
};

const CategoryGroupList: React.FC<CategoryGroupListProps> = ({
  id,
  register,
  newCategoryGroups,
}) => {
  const [categoryList, setCategoryList] = useState<Group[]>([]);
  const { categories } = useSelector((state: any) => state.groupCategories);

  const { role } = useSelector((state: any) => state.currentUser);

  useEffect(() => {
    if (id) {
      const category = categories.find((c: any) => c.id === id);
      if (category) {
        setCategoryList(category.groups || []);
      }
    } else if (newCategoryGroups) {
      setCategoryList(newCategoryGroups);
    }
  }, [id, categories, newCategoryGroups]);

  return (
    <>
      {categoryList.length !== 0 && (
        <List
          style={{ width: "100%", maxHeight: "200px", overflowY: "auto" }}
          dense
        >
          {categoryList.map((group: Group, i: number) => {
            const groupNameDisplay = group.abbreviation
              ? `${group.name} - ${group.abbreviation}`
              : group.name;
            return (
              <ListItem
                style={{
                  width: "75%",
                  display: "flex",
                  flexDirection: "column",
                  margin: "auto",
                }}
                key={i}
              >
                {role === "super" || role === "admin" ? (
                  <TextField
                    size="small"
                    defaultValue={groupNameDisplay}
                    {...register(`groups.${i}.name`)}
                    disabled={!!newCategoryGroups}
                  />
                ) : (
                  groupNameDisplay
                )}
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};

export default CategoryGroupList;
