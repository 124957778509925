/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";

import { StyledButton } from "@components/StyledComponents";
import Filters from "@features/filters";
import { ItemSelectionTable, usePaginatedItemsQuery } from "@features/items";

type ItemSelectionModalProps = {
  onClose: () => void;
  onSelect: (itemId: string) => void;
  programId?: string;
};

const ItemSelectionModal: React.FC<ItemSelectionModalProps> = ({
  onClose,
  onSelect,
  programId,
}) => {
  const [itemId, setItemId] = useState<null | string>(null);
  const [filters, setFilters] = React.useState<Record<string, any>>({});
  const params = {
    filter: {
      isActive: true,
      query: filters.q,
      favorites: filters.favorites,
      orderType: filters.orderType,
      territoryIds: filters.territories,
      programIds: filters.programs,
      groupIds: filters.groups && Object.values(filters.groups).flat(),
    },
  };

  const { data, ...tableProps } = usePaginatedItemsQuery(params);
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle tw="flex items-center justify-between">
        Select Item For Quote Request
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        tw="overflow-visible grid grid-rows-[auto 1fr] gap-3 h-[70vh]"
        css={{ th: tw`bg-neutral-100 text-neutral-600` }}
      >
        <Filters
          searchTitle="Search Item"
          slots={[
            "favorites",
            "orderType",
            "groups",
            "territories",
            "programs",
          ]}
          alwaysShow={["programs"]}
          filterState={filters}
          setFilterState={setFilters}
          defaultValues={(programId as undefined) && { programs: [programId] }}
        />

        <ItemSelectionTable
          {...tableProps}
          query={filters.q}
          rows={data ?? []}
          selectedItemId={itemId}
          setSelectedItemId={setItemId}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton outlined onClick={onClose}>
          Cancel
        </StyledButton>
        <StyledButton
          cta
          disabled={!itemId}
          onClick={() => onSelect(itemId as string)}
        >
          Create Request
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ItemSelectionModal;
