import React, { memo } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import StandardTableHead from "../../Utility/StandardTableHead";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AllocationHistoryTable = ({ history }) => {
  const classes = useStyles();

  const headCells = [
    { id: "updatedBy", label: "Updated By" },
    { id: "updatedAt", label: "Update At" },
    { id: "allocatedQty", label: "Allocated Qty" },
    { id: "orderNumber", label: "Order Number" },
    { id: "orderStatus", label: "Order Status" },
    { id: "adjustmentAmount", label: "Adjustment" },
  ];

  return (
    <TableContainer>
      <Table stickyHeader className={classes.table}>
        <StandardTableHead classes={classes} headCells={headCells} />
        <TableBody>
          {history.map((h, i) => (
            <TableRow key={`row-${i}`}>
              {headCells.map((hc, index) => (
                <TableCell key={`cell-${i}-${index}`} align="left">
                  {h[hc.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AllocationHistoryTable.propTypes = { history: PropTypes.array.isRequired };

export default memo(AllocationHistoryTable);
