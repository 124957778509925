import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";

import StandardTableHead from "../../Utility/StandardTableHead";

type Variant = {
  id: string;
  name: string;
  abbreviation: string;
};

type VariantNameAbbrTableProps = {
  classes: any;
  id?: string;
  register: any;
  newCategoryVariants?: any;
};

const headCells: { id: string; label: string }[] = [
  { id: "name", label: "Variant Name" },
  { id: "abbreviation", label: "Abbreviation" },
];

const VariantNameAbbrTable: React.FC<VariantNameAbbrTableProps> = ({
  classes,
  id,
  register,
  newCategoryVariants,
}) => {
  const [categoryList, setCategoryList] = useState<Variant[]>([]);

  const { categories } = useSelector((state: any) => state.variantCategories);
  const { role } = useSelector((state: any) => state.currentUser);

  useEffect(() => {
    if (id) {
      const category = categories.find((c: any) => c.id === id);
      if (category) {
        setCategoryList(category.variants || []);
      }
    } else if (newCategoryVariants) {
      setCategoryList(newCategoryVariants);
    }
  }, [id, categories, newCategoryVariants]);

  return (
    <>
      {categoryList.length !== 0 && (
        <TableContainer
          style={{
            maxHeight: "100%",
            ...(!!newCategoryVariants && {
              opacity: "50%",
              pointerEvents: "none",
            }),
          }}
        >
          <Table stickyHeader size={"small"} className={classes.table}>
            <StandardTableHead headCells={headCells} />
            <TableBody>
              {categoryList.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>
                    {role === "super" || role === "admin" ? (
                      <TextField
                        size={"small"}
                        defaultValue={row.name}
                        {...register(`variants.${i}.name`)}
                        disabled={!!newCategoryVariants}
                      />
                    ) : (
                      row.name
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {row.abbreviation.toUpperCase()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default VariantNameAbbrTable;
