import _ from "lodash";

import { Item } from "@models/Item";

// imageId may come from the variant
export function getItemPreviewImage(
  item: Item,
  imageId?: string | number | null
) {
  if (item.images.length === 0) return null;
  if (imageId) {
    const image = item.images.find((img) => img.id === String(imageId));
    if (image) return image;
  }
  let image = item.images.find((img) => img.position === 0);
  if (!image) {
    image = _.sortBy(item.images, "position")[0];
  }

  return image;
}
