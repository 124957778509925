import React, { useCallback, useEffect, useRef, useState } from "react";

import { sortBy } from "lodash";

import { Image } from "@models/Image";
import { Item } from "@models/Item";
import { useOpenCloudinaryWidget } from "@services/cloudinary";

import { saveItemImage } from "../data/saveItemImage";

type ItemImagesContextProps = {
  handleUpload: () => void;
  images: Image[];
  setImages: React.Dispatch<React.SetStateAction<Image[]>>;
};

const ItemImagesContext = React.createContext<ItemImagesContextProps>(
  {} as any
);

export const ItemImagesProvider = ({
  item,
  children,
}: {
  item: Item;
  children: React.ReactNode;
}) => {
  const [savedImages, setSavedImages] = useState<Image[]>([]);
  const imageLengthRef = useRef(0);
  imageLengthRef.current = savedImages.length;
  const openCloudinaryWidget = useOpenCloudinaryWidget();
  const handleUpload = useCallback(
    () =>
      openCloudinaryWidget({
        onSuccess: (file) =>
          saveItemImage(
            file,
            item.id as string,
            imageLengthRef,
            setSavedImages
          ),
        onError: (err) => console.error(err),
      }),
    [item.id, imageLengthRef, setSavedImages, openCloudinaryWidget]
  );

  // Sync to item images
  useEffect(() => {
    if (!item) return;
    setSavedImages(sortBy(item.images ?? [], "position"));
  }, [item]);

  return (
    <ItemImagesContext.Provider
      value={{
        handleUpload,
        images: savedImages,
        setImages: setSavedImages,
      }}
    >
      {children}
    </ItemImagesContext.Provider>
  );
};

export const useItemImages = () => React.useContext(ItemImagesContext);

export default ItemImagesContext;
