import { cloudinary } from "./cloudinary";
import imageExtensions from "./imageExtensions";

export const getResourceUrl = (
  fileId: string,
  extension?: string | { resourceType: string }
) => {
  const providedResourceType =
    typeof extension === "object" && extension.resourceType;
  const inferedResourceType =
    typeof extension === "string" &&
    (imageExtensions.includes(extension) ? "image" : "raw");

  const url = cloudinary.url(fileId, {
    resource_type: providedResourceType || inferedResourceType || "raw",
    secure: true,
  });

  return url;
};
