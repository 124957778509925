import itemTransformer from "@models/Item";
import orderTransformer from "@models/Order";
import { orderVariantTransformer } from "@models/OrderVariant";
import quoteTransformer from "@models/Quote";
import variantTransformer from "@models/Variant";

import apiClient from "./apiClient";

export { query } from "./clientHelpers";
export { buildFilters, jsonApiPaginate } from "./helperFunctions";

export { default as removeFromSwrCache } from "./removeFromSwrCache";
export { default as updateSwrCache } from "./updateSwrCache";
export { default as useApiResource } from "./useApiResource";
export { default as useApiResourcePaginated } from "./useApiResourcePaginated";
export { default as useMutateError } from "./useMutateError";
export { default as useClearSwrCache } from "./useClearSwrCache";
export type { default as routeResourceTypes } from "./routeResourceTypes";

export * from "./types";

const client = apiClient();

client.registerTransformer(variantTransformer);
client.registerTransformer(itemTransformer);
client.registerTransformer(orderTransformer);
client.registerTransformer(orderVariantTransformer);
client.registerTransformer(quoteTransformer);

export default client;
