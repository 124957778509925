import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setIsOrdering } from "@redux/slices/orders/draftOrderSetsSlice";

// Locks channel/territory selection
// when component is mounted.
const useOrderLock = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsOrdering({ value: true }));
    return () => {
      dispatch(setIsOrdering({ value: false }));
    };
  }, [dispatch]);
};

export default useOrderLock;
