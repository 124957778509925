import { kebabCase } from "lodash";

import { HookOptions } from "./types";

export const buildFilters = (filterObj) =>
  Object.entries(filterObj).reduce((obj, [key, val]) => {
    if (val !== undefined && val !== null) {
      key = kebabCase(key);
      if (Array.isArray(val)) {
        key += "][";
        val = val.map((n) => (typeof n === "object" ? n?.id : n)).join(",");
      }
      obj[key] = val;
    }
    return obj;
  }, {});

export const jsonApiPaginate =
  (initialParams: [string, ...any[]] | null) =>
  (pageIndex, previousPageData) => {
    let params = null as [string, ...any[]] | null;
    if (pageIndex === 0) params = initialParams;
    else if (previousPageData.links?.next) {
      params = [previousPageData.links.next?.replace(/^\/api/, "")];
    }
    return params;
  };

export const splitOptions = (options: HookOptions = {}) => {
  return {
    requestOptions: {
      params: {
        filter: { ...options.filter },
        sort: options.sort,
        ...options.params,
      },
      deserializeOutput: true as true,
    },
    swrOptions: {
      ...(options.revalidate === false && {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateFirstPage: false,
      }),
      ...options,
    },
  };
};
