import { memo, useState } from "react";
import { useDispatch } from "react-redux";

import CancelIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { AddressSearch } from "@features/addresses";
import { updateShippingParameter } from "@redux/slices/purchaseOrders/purchaseOrderSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const EditShippingParameterAddressModal = ({
  open,
  shippingParameter,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newAddress, setNewAddress] = useState(null);

  const handleChangeAddress = () => {
    dispatch(
      updateShippingParameter(shippingParameter.id, {
        "address-id": newAddress.id,
      })
    );
    handleClose();
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        disableScrollLock
      >
        <DialogTitle>
          <Typography className={classes.headerText}>
            Updating Shipping Parameter Address
          </Typography>
        </DialogTitle>
        <DialogContent>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            size="large"
          >
            <CancelIcon fontSize="large" color="secondary" />
          </IconButton>
          <br />
          <div className={classes.fullWidthCenterColumn}>
            <Typography className={classes.bodyText}>
              Current Address:
            </Typography>
            <br />
            <Typography className={classes.bodyText}>
              {shippingParameter.addressName}
            </Typography>
            <Typography className={classes.bodyText}>
              {shippingParameter.addressOne}
            </Typography>
            {shippingParameter.addressTwo && (
              <Typography className={classes.bodyText}>
                {shippingParameter.addressTwo}
              </Typography>
            )}
            <Typography className={classes.bodyText}>
              {`${shippingParameter.city}, ${
                shippingParameter.stateOrRegion !== "---"
                  ? `${shippingParameter.stateOrRegion}, `
                  : ""
              }${shippingParameter.zip}`}
            </Typography>
            {shippingParameter.country && (
              <Typography className={classes.bodyText}>
                {shippingParameter.country}
              </Typography>
            )}
            <br />
            <AddressSearch onChange={setNewAddress} />
            <br />
            <Typography className={classes.bodyText}>New Address:</Typography>
            {newAddress && (
              <>
                <br />
                <Typography className={classes.bodyText}>
                  {newAddress.name}
                </Typography>
                <Typography className={classes.bodyText}>
                  {newAddress.streetAddress1}
                </Typography>
                {newAddress.streetAddress2 && (
                  <Typography className={classes.bodyText}>
                    {newAddress.streetAddress2}
                  </Typography>
                )}
                <Typography className={classes.bodyText}>
                  {`${newAddress.city}, ${
                    newAddress.state?.code ?? newAddress.region
                  }, ${newAddress.zip}`}
                </Typography>
                {newAddress.country && (
                  <Typography className={classes.bodyText}>
                    {newAddress.country.name}
                  </Typography>
                )}
              </>
            )}
            <br />
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleChangeAddress}
              disabled={!newAddress}
            >
              UPDATE ADDRESS
            </Button>
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditShippingParameterAddressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  shippingParameter: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default memo(EditShippingParameterAddressModal);
