import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { BudgetSearch } from "@features/budgets";
import { setError } from "@redux/slices/errorSlice";

import { FilterRow } from "../Filtering/FilterComponents";
import { StyledButton } from "../StyledComponents";

const FiltersBudgets = ({
  reset,
  setReset,
  handleFilters,
  handleSearch,
  filterType,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.reports.isLoading);

  const { budget } = useSelector((state) => state.filters);

  const handleBudget = (budget) => {
    handleFilters(budget, "budget", filterType);
  };

  return (
    <div>
      <FilterRow>
        <BudgetSearch
          handleChange={handleBudget}
          reset={reset}
          setReset={setReset}
        />
        <StyledButton
          cta
          onClick={() => {
            if (!budget) {
              return dispatch(
                setError({
                  error: "A budget selection is required for this report",
                  source: "Budget Reports",
                })
              );
            }
            handleSearch();
          }}
          disabled={isLoading}
        >
          GENERATE REPORT
        </StyledButton>
      </FilterRow>
    </div>
  );
};

FiltersBudgets.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default FiltersBudgets;
