export type Report = {
  fileExtension: string;
  /* If we're not using useApiResourcePaginated, provide a fetcher function */
  fetcher?: (...params: any[]) => never; // not implemented
  // fetcher?: (...params: any[]) => Promise<any>;
  onComplete?: (data: any[]) => any;
  onDownload: (data: any) => Blob | Promise<Blob>;
};

export enum ReportStatus {
  LOADING = "LOADING",
  COMPLETE = "COMPLETE",
  DOWNLOADED = "DOWNLOADED",
  ABORTED = "ABORTED",
  FAILED = "FAILED",
}

export enum ReportSource {
  CSV = "CSV",
  JSON_API = "JSON_API",
}

export type RStatus = keyof typeof ReportStatus;
export type RSource = keyof typeof ReportSource;

export type TReportState = {
  name: string;
  filename: string;
  id: string;
  url: string;
  status: RStatus;
  source: RSource;
};

export type TGlobalReports = {
  reportQueue: TReportState[];
  requestReport: string | null;
  filenames: string[];
  reportTrayOpen: boolean;
};
