import axios from "axios";

import client from "@services/api";

import { handleErrors } from "./apiFunctions";

export const loginWithAuthToken = async (token, exp) => {
  const response = { status: "", error: null };
  await axios
    .post(`/auth/token`, {
      auth0_token: token,
    })
    .then((res) => {
      setAuthToken({ ...res.data, expires_at: exp });
      response.status = "ok";
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

//Removes user information from local storage on logout, and removes authenticated headers from axios
export const logoutUser = async () => {
  localStorage.removeItem("brandhub-silver-user");
  localStorage.removeItem("brandhub-silver-role");
  delete axios.defaults.headers.common["Authorization"];
  client.headers["Authorization"] = "";
};

//Set's users info in local storage and updates axios headers on successful login
const setAuthToken = (token) => {
  const authToken = `Bearer ${token.access_token}`;
  localStorage.setItem("brandhub-silver-user", JSON.stringify(token));
  axios.defaults.headers.common["Authorization"] = authToken;
  client.headers["Authorization"] = authToken;
};
