import { StyleSheet } from "@react-pdf/renderer";

const generalStyles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 54,
  },
  titleText: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 800,
    color: "white",
  },
  headerText: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 800,
    marginBottom: 10,
  },
  bodyText: {
    fontFamily: "Roboto",
    fontSize: 10,
    marginBottom: 5,
  },
  smText: {
    fontFamily: "Roboto",
    fontSize: 8,
    marginBottom: 5,
  },
});

export default generalStyles;
