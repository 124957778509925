import { useSelector } from "react-redux";

import format from "date-fns/format";
import subDays from "date-fns/subDays";
import PropTypes from "prop-types";

import { DatePicker } from "@utils/forms";

import { FilterRow } from "../Filtering/FilterComponents";
import { StyledButton } from "../StyledComponents";
import PromotionAutoComplete from "../Utility/AutoCompleteFields/PromotionAutoComplete";

const FiltersPromotionReports = ({
  reset,
  setReset,
  handleFilters,
  handleSearch,
  filterType,
}) => {
  const isLoading = useSelector((state) => state.reports.isLoading);
  const { dateRangeStart, dateRangeEnd } = useSelector(
    (state) => state.filters
  );

  return (
    <FilterRow>
      <PromotionAutoComplete
        handleChange={handleFilters}
        reset={reset}
        setReset={setReset}
        filterType={filterType}
      />

      <DatePicker
        label="Order Approved at Start"
        value={dateRangeStart || format(subDays(new Date(), 30), "MM/dd/yyyy")}
        onChange={(value) =>
          handleFilters(
            format(value, "MM/dd/yyyy"),
            "dateRangeStart",
            filterType
          )
        }
        disabled={isLoading}
      />

      <DatePicker
        label="Order Approved at End"
        value={dateRangeEnd || format(new Date(), "MM/dd/yyyy")}
        onChange={(value) =>
          handleFilters(format(value, "MM/dd/yyyy"), "dateRangeEnd", filterType)
        }
        disabled={isLoading}
      />

      <StyledButton cta onClick={handleSearch} disabled={isLoading}>
        GENERATE REPORT
      </StyledButton>
    </FilterRow>
  );
};

FiltersPromotionReports.propTypes = {
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  handleFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default FiltersPromotionReports;
