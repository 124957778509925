import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { VariantOption } from "@models/VariantOption";
import client, { RequestParams } from "@services/api";

export const variantOptionsKeyFactory = createQueryKeys("variant-options", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<VariantOption[]>("variant-options", { params })
        .then((res) => res.data),
  }),
});

export const useVariantOptionsListQuery = (params?: RequestParams) => {
  return useQuery({
    ...variantOptionsKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: Infinity,
  });
};
