import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { moneyValidation } from "@utils/forms";
import { StyledTextInput } from "@utils/forms/ControlledInputs";

const DebounceTextField = React.memo(
  ({
    value: initialValue,
    id,
    setter,
    attr,
    isNumberOnly,
    disabled,
    ...props
  }: {
    value: any;
    id: any;
    setter: any;
    attr: any;
    isNumberOnly: boolean;
    disabled?: boolean;
  }) => {
    const dispatch = useDispatch();
    const debounce = useRef<ReturnType<typeof setTimeout> | undefined>(
      undefined
    );

    const [value, setValue] = useState(initialValue || "");
    const [change, setChange] = useState(false);
    const [isDebouncing, setIsDebouncing] = useState(false);

    const handleChange = (value) => {
      if (isNumberOnly) {
        value = moneyValidation.transform(value);
      }

      setValue(value);
      setChange(true);
      setIsDebouncing(true);

      clearTimeout(debounce.current);
      debounce.current = undefined;
    };

    const handleDispatch = () => {
      clearTimeout(debounce.current);

      if (change) {
        dispatch(setter(id, { [attr]: value }));
        setChange(false);
        setIsDebouncing(false);
        debounce.current = undefined;
      }
    };

    const handleBlur = () => {
      if (isNumberOnly) {
        setValue(moneyValidation.transformOnBlur);
      }
      handleDispatch();
    };

    useEffect(() => {
      if (isDebouncing && !debounce.current) {
        debounce.current = setTimeout(() => {
          handleDispatch();
        }, 750);
      }
    });

    return (
      <StyledTextInput
        value={value}
        onChange={(evt) => handleChange(evt.target.value)}
        onBlur={handleBlur}
        disabled={disabled}
        {...props}
      />
    );
  }
);

export default DebounceTextField;
