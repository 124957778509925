/** @jsxImportSource @emotion/react */
import "twin.macro";

export const HeaderSkeleton = () => {
  return (
    <div tw="flex">
      <div tw="w-full ml-4">
        <div tw="w-3/4 h-4 rounded bg-neutral-200 animate-pulse" />
        <div tw="w-1/2 h-4 rounded bg-neutral-200 mt-3 animate-pulse delay-500" />
      </div>
      <div tw="rounded-full w-16 h-16 border-[1em] border-neutral-200 flex-none animate-pulse"></div>
    </div>
  );
};
