import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSorted, updateSingleFilter } from "@redux/slices/filterSlice";
import { clearReports } from "@redux/slices/reports/reportSlice";

/*
The useChannel hook is present on most views, and ensures that the current active channel is
present correctly in all filters used.
*/

export const useChannel = () => {
  const dispatch = useDispatch();

  const { channelId, sorted, filterType } = useSelector(
    (state) => state.filters
  );
  const currentChannelId = useSelector(
    (state) => state.currentUser.currentChannelId
  );

  useEffect(() => {
    if (channelId && currentChannelId && channelId !== currentChannelId) {
      dispatch(
        updateSingleFilter({ filter: "channelId", value: currentChannelId })
      );
      if (!sorted && filterType && !filterType.includes("reports-")) {
        dispatch(setSorted({ sorted: true }));
      }
      if (filterType && filterType.includes("reports-")) {
        dispatch(clearReports());
      }
    }
  }, [channelId, sorted, currentChannelId, filterType, dispatch]);
};
