import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { OrderVariant } from "@models/OrderVariant";
import client, { RequestParams } from "@services/api";
import { buildPaginatedQuery } from "@utils/reactQuery";

export const orderVariantsKeyFactory = createQueryKeys("order-variants", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client
        .get<OrderVariant[]>("order-variants", { params })
        .then((res) => res.data),
  }),
  paginated: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<OrderVariant[]>("order-variants", {
        params,
      }),
  }),
});

export const useOrderVariantsListQuery = (params: RequestParams) => {
  return useQuery({
    ...orderVariantsKeyFactory.list({ skipPagination: true, ...params }),
    staleTime: 60_000,
    placeholderData: keepPreviousData,
  });
};

export const usePaginatedOrderVariantsQuery = buildPaginatedQuery(
  orderVariantsKeyFactory.paginated,
  {
    staleTime: 60_000,
    placeholderData: keepPreviousData,
  }
);
