import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ExitToApp } from "@mui/icons-material";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";

import UserAssignment from "@components/BrandHQ/CostCenters/UserAssignment";
import { Contained } from "@components/StyledComponents";
import Loading from "@components/Utility/Loading";
import SuccessModal from "@components/Utility/Modals/SuccessModal";
import StandardTableHead from "@components/Utility/StandardTableHead";
import {
  createOrUpdateCostCenter,
  fetchCostCenter,
  setHasUpdated,
} from "@redux/slices/costCenterSlice";
import { setError } from "@redux/slices/errorSlice";
import DocTitle from "@utility/DocTitle";
import { formatMoney } from "@utility/utilityFunctions";

import { useNoFetch } from "../hooks/useNoFetch";

/*
Handles creating and updating all budgets. Breaks the workflow into separate panels, panel components
can be found at ../components/BrandHQ/Budgets. The visible panel is controlled by tabs at the top of
the view.
*/

const headCells = [
  { label: "Id", id: "id" },
  { label: "Name", id: "name" },
  { label: "Total", id: "total" },
  // { label: "Status", id: "status" },
];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  twoWideContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  twoWide: {
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  justifyCenter: {
    justifyContent: "center",
  },
  formWrapper: {
    width: "70%",
    maxWidth: "1250px",
    padding: "20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      width: "85%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  tabs: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const CostCenterCreate = () => {
  const { action, id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [costCenter, setCostCenter] = useState(null);
  const [relationships, setRelationships] = useState([]);
  const [centerName, setCenterName] = useState("");
  const [successModal, setSuccessModal] = useState({
    open: false,
    message: null,
    options: [],
  });
  const [localError, setLocalError] = useState(null);

  const { isLoading, hasUpdated, currentCostCenter } = useSelector(
    (state) => state.costCenters
  );
  const reset = () => {
    setCostCenter(null);
    setRelationships([]);
    setCenterName("");
    setLocalError(null);
    dispatch(setHasUpdated({ value: false }));
  };

  const handleSubmit = () => {
    if (centerName.trim().length === 0) {
      setLocalError(`Please give the cost center a valid name.`);
    } else {
      setLocalError(null);
      const data = {
        id: costCenter?.id,
        name: centerName,
        users: relationships.map((u) => ({ id: u.id })),
      };
      dispatch(createOrUpdateCostCenter(data));
    }
  };

  const handleSuccessClose = (arg) => {
    if (arg === "create") {
      reset();
      setSuccessModal({
        open: false,
        message: null,
        options: [],
      });
    } else if (arg === "continue") {
      setSuccessModal({
        open: false,
        message: null,
        options: [],
      });
      dispatch(setHasUpdated({ value: false }));
    } else {
      reset();
      navigate("/admin/cost-centers");
    }
  };

  useEffect(() => {
    if (
      action === "edit" &&
      !costCenter &&
      currentCostCenter &&
      currentCostCenter.id &&
      currentCostCenter.id === id
    ) {
      setCostCenter(currentCostCenter);
      setCenterName(currentCostCenter.name);
      setRelationships(currentCostCenter.users);
    }
  }, [costCenter, currentCostCenter, id, action]);

  useNoFetch(dispatch);

  useEffect(() => {
    dispatch(setHasUpdated({ value: false }));
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localError) {
      dispatch(setError({ error: localError, source: "Cost Center Create" }));
      setLocalError(null);
    }
  }, [localError, dispatch]);

  useEffect(() => {
    if (hasUpdated && !successModal.open) {
      setSuccessModal({
        open: true,
        message:
          action === "edit"
            ? "Const Center Updated Successfully!"
            : "Const Center Created Successfully",
        options:
          action === "edit"
            ? [
                { label: "CONTINUE EDITING", arg: "continue" },
                { label: "BACK TO COST CENTERS", arg: "back" },
              ]
            : [
                { label: "CREATE NEW COST CENTER", arg: "create" },
                { label: "BACK TO COST CENTERS", arg: "back" },
              ],
      });
    }
  }, [hasUpdated, successModal, setSuccessModal, action]);

  useEffect(() => {
    if (action === "edit" && id) {
      dispatch(fetchCostCenter(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocTitle title={"Order Window Create"} />
      {successModal.open && (
        <SuccessModal {...successModal} handleClose={handleSuccessClose} />
      )}
      {((action === "edit" && (!costCenter || costCenter.id !== id)) ||
        isLoading) && <Loading opacity="75%" />}
      {((action === "edit" && costCenter && costCenter.id === id) ||
        action === "create") && (
        <Contained
          className={clsx(classes.center)}
          style={{ flexDirection: "column" }}
        >
          <div>
            <header className={classes.titleBar}>
              <Typography className={classes.headerText}>
                {action === "edit"
                  ? `Editing ${costCenter.name}`
                  : "New Cost Center"}
              </Typography>
              <Button
                className={classes.button}
                style={{ marginLeft: "20px" }}
                variant="contained"
                color="secondary"
                startIcon={
                  <ExitToApp style={{ transform: "rotate(180deg)" }} />
                }
                component={Link}
                to={"/admin/cost-centers"}
              >
                BACK TO COST CENTERS
              </Button>
            </header>
            <br />
            {action === "edit" && (
              <>
                <TableContainer>
                  <Table className={classes.table}>
                    <StandardTableHead
                      classes={classes}
                      headCells={headCells}
                    />
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">{costCenter.id}</TableCell>
                        <TableCell align="left">{costCenter.name}</TableCell>
                        <TableCell align="left">
                          {formatMoney(costCenter.total)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <br />
              </>
            )}
            <TextField
              fullWidth
              size="small"
              label="Cost Center Name"
              value={centerName}
              onChange={(e) => setCenterName(e.target.value)}
            />
            <UserAssignment
              classes={classes}
              type={action}
              currentCostCenter={costCenter}
              relationships={relationships}
              setRelationships={setRelationships}
              isUpdateLoading={isLoading}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </Contained>
      )}
    </>
  );
};

export default CostCenterCreate;
