import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Table, TableBody, TableContainer } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import StandardTableHead from "../Utility/StandardTableHead";
import EditShippingParameterAddressModal from "./EditShippingParameterAddressModal";
import EditShippingParameterVariantModal from "./EditShippingParameterVariantModal";
import ShippingParameterTableRow from "./ShippingParameterTableRow";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ShippingParameterTable = () => {
  const classes = useStyles();

  const { shippingParameters, status, id } = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const { role } = useSelector((state) => state.currentUser);

  const readOnly = ["supplier", "select-account-manager"].includes(role);

  const [editAddress, setEditAddress] = useState({
    open: false,
    shippingParameter: null,
  });
  const [editSPV, setEditSPV] = useState({
    open: false,
    shippingParameterVariantId: null,
  });

  const handleEditAddressOpen = (sp) => {
    setEditAddress({
      open: true,
      shippingParameter: sp,
    });
  };

  const handleEditSPVOpen = (id) => {
    setEditSPV({
      open: true,
      shippingParameterVariantId: id,
    });
  };

  const handleCloseModal = () => {
    setEditAddress({
      open: false,
      shippingParameter: null,
    });
    setEditSPV({
      open: false,
      shippingParameterVariantId: null,
    });
  };

  return (
    <>
      {editAddress.open && editAddress.shippingParameter && (
        <EditShippingParameterAddressModal
          {...editAddress}
          handleClose={handleCloseModal}
        />
      )}
      {editSPV.open && editSPV.shippingParameterVariantId && (
        <EditShippingParameterVariantModal
          {...editSPV}
          purchaseOrderId={id}
          handleClose={handleCloseModal}
        />
      )}
      <TableContainer className={classes.tableContainer}>
        <Table>
          <StandardTableHead
            classes={classes}
            headCells={[
              { id: "action", label: "" },
              { id: "name", label: "Name" },
              { id: "address", label: "Address" },
              { id: "attn", label: "Attention" },
              !readOnly &&
                status !== "complete" && { id: "edit", label: "Edit" },
            ].filter(Boolean)}
          />
          <TableBody>
            {shippingParameters.map((sp) => (
              <ShippingParameterTableRow
                key={sp.id}
                rowData={sp}
                handleEditAddressOpen={handleEditAddressOpen}
                handleEditSPVOpen={handleEditSPVOpen}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ShippingParameterTable;
