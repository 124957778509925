/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { HelpTwoTone } from "@mui/icons-material";
import {
  ClickAwayListener,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { AddressSearch } from "@features/addresses";
import { addOrderSetOrders } from "@redux/slices/orders/currentOrderSetSlice";
import { useApiResource } from "@services/api";

import AddressModal from "../../Profile/Addresses/AddressModal";
import { StyledButton } from "../../StyledComponents";
import { InfoTooltipWide } from "../../Utility/StyledComponents/InfoTooltip";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddressSelection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [queuedAddresses, setQueuedAddresses] = useState([]);
  const [addressModal, setAddressModal] = useState({
    open: false,
    type: "new",
    id: null,
    forOrder: true,
    orderSetId: null,
    orderType: null,
  });

  const {
    isLoading: isOrderSetLoading,
    id: osId,
    orderSetOrders,
    type: orderType,
  } = useSelector((state) => state.currentOrderSet);
  const { currentTerritoryId } = useSelector((state) => state.currentUser);

  const { data: favoriteAddressLists = [] } = useApiResource(
    "address-favorite-sets",
    {
      params: {
        filter: { territoryIds: [currentTerritoryId] },
        skipPagination: true,
      },
    }
  );

  const orderSetAddressIds = orderSetOrders.map((ord) => ord.address.id);

  const handleAddressModalOpen = () => {
    setAddressModal({
      open: true,
      type: "new",
      id: null,
      forOrder: true,
      orderSetId: osId,
      orderType: orderType,
    });
  };

  const handleAddressModalClose = () => {
    setAddressModal({
      open: false,
      type: "new",
      id: null,
      forOrder: true,
      orderSetId: null,
      orderType: null,
    });
  };

  const handleAddAddress = (addresses) => {
    const last = addresses.at(-1);
    setQueuedAddresses([...queuedAddresses, last.id]);
    dispatch(addOrderSetOrders(osId, [last.id], orderType));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTooltipClose = () => setTooltipOpen(false);

  const handleAddFavorites = (id) => {
    let newIds = favoriteAddressLists.find((list) => list.id === id);
    let currentIds = orderSetOrders.map((ord) => ord.address.id);
    let idArray = newIds.addresses
      .map((add) => add.id)
      .filter((id) => !currentIds.includes(id));

    dispatch(addOrderSetOrders(osId, idArray, orderType));
  };

  return (
    <>
      {addressModal.open && (
        <AddressModal {...addressModal} handleClose={handleAddressModalClose} />
      )}
      <div
        tw="flex flex-col gap-3 w-full items-start"
        className="tutorial-highlight"
        id="tutorial-address-selection"
      >
        <div tw="flex justify-between items-center w-full">
          <Typography tw="text-left mt-2 font-bold">Add Addresses</Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <InfoTooltipWide
              PopperProps={{
                disablePortal: true,
              }}
              style={{ marginLeft: "10px" }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement="right-end"
              title={
                <div tw="p-4">
                  <Typography className={classes.bodyText}>
                    Once you have added addresses to your address book, there
                    are three ways to add an address to the order grid.
                  </Typography>
                  <br />
                  <Typography className={classes.bodyText}>
                    1. Type an address name in the search field to find the
                    address you would like to order for, and select the address
                    from the found options.
                  </Typography>
                  <br />
                  <Typography className={classes.bodyText}>
                    2. Click the + ALL button to add all addresses in your
                    current Territory to the order grid.
                  </Typography>
                  <br />
                  <Typography className={classes.bodyText}>
                    3. Click the + FAVORITES button to add all addresses from
                    favorites lists you have created in the Profile section.
                  </Typography>
                  <br />
                  <Typography className={classes.bodyText}>
                    You can also click + NEW to create a new address and add it
                    directly to this order.
                  </Typography>
                  <br />
                  <Typography className={classes.bodyText}>
                    If you don't have any addresses added yet, please visit the
                    address book in your profile to add addresses.
                  </Typography>
                </div>
              }
            >
              <IconButton
                onClick={() => setTooltipOpen(!tooltipOpen)}
                style={{ padding: 0, marginLeft: "10px" }}
                size="large"
              >
                <HelpTwoTone fontSize="large" color="secondary" />
              </IconButton>
            </InfoTooltipWide>
          </ClickAwayListener>
        </div>
        <AddressSearch
          multiple
          autoFocus
          validateTerritory
          onChange={handleAddAddress}
          renderTags={() => null}
          getOptionDisabled={(option) =>
            orderSetAddressIds.includes(option.id) ||
            queuedAddresses.includes(option.id)
          }
        />
        <div tw="space-y-3 w-full">
          <div tw="flex gap-3">
            <StyledButton
              tw="flex-1"
              outlined
              disabled={favoriteAddressLists.length === 0 || isOrderSetLoading}
              aria-controls="favorite-address-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              + Favorite Addresses
            </StyledButton>
            {favoriteAddressLists.length > 0 && Boolean(anchorEl) && (
              <Menu
                id="favorite-address-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ marginTop: "10px", width: "100%" }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {favoriteAddressLists.map((fav, index) => {
                  if (index !== favoriteAddressLists.length - 1) {
                    return [
                      <MenuItem
                        key={fav.id}
                        onClick={() => {
                          handleClose();
                          handleAddFavorites(fav.id);
                        }}
                      >
                        <ListItemText primary={fav.name} />
                      </MenuItem>,
                      <Divider />,
                    ];
                  } else {
                    return (
                      <MenuItem
                        key={fav.id}
                        onClick={() => {
                          handleClose();
                          handleAddFavorites(fav.id);
                        }}
                      >
                        <ListItemText primary={fav.name} />
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            )}
            <StyledButton
              tw="flex-1 whitespace-nowrap"
              outlined
              aria-haspopup="true"
              onClick={handleAddressModalOpen}
            >
              + New Address
            </StyledButton>
          </div>
        </div>
        <Link tw="text-primary-800 underline" to="/profile/addressBook">
          Manage Address Book
        </Link>
      </div>
    </>
  );
};

export default React.memo(AddressSelection);
