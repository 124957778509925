import format from "date-fns/format";

import { formatDateString, stringToCents } from "@utility/utilityFunctions";

import { mapGroups } from "../groupCategories/maps";
import { mapTerritories } from "../territories/maps";
import { mapUsers } from "../user/maps";

export const mapBudget = (budget) => ({
  id: budget.id,
  name: budget.name,
  startDate: formatDateString(budget["start-date"]),
  expirationDate: formatDateString(budget["expiration-date"]),
  isActive: budget["is-active"],
  pendingSpend: stringToCents(budget["pending-spend"]),
  spend: stringToCents(budget.spend),
  pendingBalance: stringToCents(budget["pending-balance"]),
  balance: stringToCents(budget["current-level"]),
  totalAmount: stringToCents(budget["total-amount"]),
  territories: mapTerritories(budget.territories || []),
  users: mapUsers(budget.users || []),
  groups: mapGroups(budget.groups || []),
});

const mapBudgetHistory = (budgetHistory) => ({
  id: budgetHistory.id,
  total: budgetHistory.total ? stringToCents(budgetHistory.total) : "---",
  adjustment: budgetHistory.adjustment
    ? stringToCents(budgetHistory.adjustment)
    : "---",
  userName: budgetHistory["user-name"],
  orderNumber: budgetHistory["order-number"],
  note: budgetHistory.note ?? "---",
  updatedAt: format(new Date(budgetHistory["updated-at"]), "MM/dd/yyyy"),
});

export const mapBudgets = (budgets, type) => {
  if (type === "overview") return budgets.map((b) => mapBudget(b));
  if (type === "history") return budgets.map((b) => mapBudgetHistory(b));
};
