import { axiosGetWithNext, axiosPost } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";
import { separateByComma } from "@utility/utilityFunctions";

import { setError } from "../errorSlice";
import { mapOrderVariants } from "../orders/maps";

let initialState = {
  isLoading: false,
  isNextLoading: false,
  isOrderVariantsLoading: false,
  nextLink: null,
  rollup: [],
  selectedRollups: [],
  rollupReport: [],
  fulfillmentOrderVariants: null,
  triggerCSVDownload: false,
  error: null,
};

const purchaseOrderRollupSlice = createSlice({
  name: "purchaseOrderRollup",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsOrderVariantsLoading(state) {
      state.isOrderVariantsLoading = true;
    },
    getFulfillmentOrderVariantsSuccess(state, action) {
      const { orderVariants } = action.payload;
      let groupedVariants = orderVariants.reduce((group, ov) => {
        group[ov.sku] = group[ov.sku] || [];
        group[ov.sku].push(ov);
        return group;
      }, {});
      state.fulfillmentOrderVariants = groupedVariants;
      state.isOrderVariantsLoading = false;
      state.error = null;
    },
    resetFulfillmentOrderVariants(state) {
      state.fulfillmentOrderVariants = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isNextLoading = false;
      state.isOrderVariantsLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsOrderVariantsLoading,
  getFulfillmentOrderVariantsSuccess,
  resetFulfillmentOrderVariants,
  setFailure,
} = purchaseOrderRollupSlice.actions;

export default purchaseOrderRollupSlice.reducer;

export const fetchFulfillmentOrderVariants = (ids) => async (dispatch) => {
  try {
    dispatch(setIsOrderVariantsLoading());
    let nextLink = "start";
    let orderVariants = [];
    while (nextLink) {
      let response = await axiosGetWithNext(
        nextLink === "start"
          ? `/api/order-variants?${separateByComma("ids", ids)}`
          : nextLink
      );
      if (response.error) {
        nextLink = null;
        throw response.error;
      }
      let mappedData = mapOrderVariants(response.data.data);
      orderVariants = orderVariants.concat(mappedData);
      nextLink = response.data.nextLink ?? false;
    }
    dispatch(
      getFulfillmentOrderVariantsSuccess({ orderVariants: orderVariants })
    );
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(
      setError({ error: err.toString(), source: "Purchase Order Rollup" })
    );
  }
};

//Cancels all order items in provided array
export const cancelMultipleOrderVariants =
  (orderVariantArray, cancelNote, callback) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      await Promise.all(
        orderVariantArray.map(async (id) => {
          let postData = {
            "cancelation-type": "order",
            "cancelation-note": cancelNote,
          };
          let response = await axiosPost(
            `/api/order-variants/${id}/cancel`,
            postData
          );
          if (response.error) throw response.error;
        })
      );
      callback?.();
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "PO Rollup slice" }));
    }
  };
