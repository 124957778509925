import { useSelector } from "react-redux";

import { OrderType } from "@models/Order";

import orderTypeLabel from "../orderTypeLabel";

export function useOrderTypes() {
  const {
    organization: { usesOnDemand, usesInventory },
  } = useSelector((state: any) => state.currentUser);

  let orderTypes = ["pre-order"] as OrderType[];

  if (usesOnDemand) orderTypes.push("on-demand");
  if (usesInventory) orderTypes.push("inventory");

  const orderTypeOptions = Object.fromEntries(
    orderTypes.map((type) => [type, orderTypeLabel(type)])
  ) as Record<OrderType, string>;

  return orderTypeOptions;
}

export function useOrderTypeOptions() {
  const orderTypes = useOrderTypes();
  const orderTypeOptions = Object.entries(orderTypes).map(([key, value]) => ({
    id: key,
    name: value,
  }));

  return orderTypeOptions;
}
