/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { ControllerProps, useFormContext, useWatch } from "react-hook-form";

import { utcDate } from "@utility/utilityFunctions";
import { ControlledDateInput, ControlledTextInput } from "@utils/forms";

import BlockCard from "../../../ui/BlockCard";
import { useValidateUniqueName } from "../../helpers";

const TwoCol = tw.div`flex flex-col sm:flex-row gap-4`;

const MainBlock = () => {
  const { control } = useFormContext();
  const reg = (name: string, rules?: ControllerProps["rules"]) => ({
    control,
    name,
    rules,
  });

  const visibleStartDate = useWatch({ name: "visibleStartDate" });
  const orderableStartDate = useWatch({ name: "orderableStartDate" });

  useValidateUniqueName();

  return (
    <BlockCard tw="space-y-4">
      <ControlledTextInput label="Name" {...reg("name", { required: true })} />
      <ControlledTextInput
        multiline
        minRows={2}
        maxRows={8}
        label="Description"
        {...reg("description")}
      />
      <TwoCol>
        <ControlledTextInput
          label="Product Identifier"
          {...reg("customerIdentifier")}
        />
        <ControlledTextInput disabled label="SKU" {...reg("sku")} />
      </TwoCol>
      <TwoCol>
        <ControlledDateInput
          tw="w-full"
          label="Visible Start Date *"
          {...reg("visibleStartDate", { required: true })}
        />
        <ControlledDateInput
          tw="w-full"
          label="Visible End Date (optional)"
          minDate={utcDate(visibleStartDate)}
          {...reg("visibleEndDate")}
        />
      </TwoCol>
      <p tw="text-neutral-500 mt-1! text-sm">
        When should the item be visible from the Item Catalog?
      </p>
      <TwoCol>
        <ControlledDateInput
          tw="w-full"
          label="Orderable Start Date *"
          {...reg("orderableStartDate", { required: true })}
        />
        <ControlledDateInput
          tw="w-full"
          label="Orderable End Date (optional)"
          minDate={utcDate(orderableStartDate)}
          {...reg("orderableEndDate")}
        />
      </TwoCol>
      <p tw="text-neutral-500 mt-1! text-sm">
        When should the item be orderable?
      </p>
    </BlockCard>
  );
};

export default MainBlock;
