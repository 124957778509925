/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import { StyledButton } from "@components/StyledComponents";
import { Quote } from "@models/Quote";
import { TextInput } from "@utils/forms";

import { useQuoteActionMutation } from "../data/quoteMutations";

const SupplierQuoteActions = ({ quote }: { quote: Quote }) => {
  const doQuoteAction = useQuoteActionMutation();
  const loading = doQuoteAction.isPending;

  const [isDeclining, setIsDeclining] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  return (
    <div tw="flex flex-col gap-3 bg-primary-50 rounded p-4">
      {!isDeclining && (
        <div tw="flex justify-end gap-3">
          <StyledButton
            danger
            onClick={() => setIsDeclining(true)}
            disabled={loading}
          >
            Decline
          </StyledButton>
          <StyledButton
            cta
            onClick={() =>
              doQuoteAction.mutate({ id: quote.id, action: "accept" })
            }
            loading={loading}
          >
            Accept
          </StyledButton>
        </div>
      )}
      {isDeclining && (
        <>
          <TextInput
            autoFocus
            label="Reason for declining"
            multiline
            minRows={3}
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
          <div tw="flex justify-end gap-3">
            <StyledButton
              onClick={() => setIsDeclining(false)}
              disabled={loading}
            >
              Back
            </StyledButton>
            <StyledButton
              cta
              onClick={() =>
                doQuoteAction.mutate({
                  id: quote.id,
                  action: "decline",
                  attributes: {
                    declineReason: declineReason.trim(),
                  },
                })
              }
              disabled={declineReason.trim().length === 0 || loading}
              loading={loading}
            >
              Confirm Decline
            </StyledButton>
          </div>
        </>
      )}
    </div>
  );
};

export default SupplierQuoteActions;
