/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useFormContext } from "react-hook-form";

import { ControlledTextInput } from "../../../utils/forms";
import BlockCard from "../../ui/BlockCard";

const OrderingInstructions = () => {
  const { control } = useFormContext();

  return (
    <BlockCard title="Ordering Instructions">
      <ControlledTextInput
        control={control}
        placeholder="Tell your users what they should keep in mind while ordering from this program..."
        name="orderDescription"
        maxRows={4}
        multiline
      />
    </BlockCard>
  );
};

export default OrderingInstructions;
