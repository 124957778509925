import { useSelector } from "react-redux";

import { TextField } from "@mui/material";

import PropTypes from "prop-types";

import { StyledButton } from "../StyledComponents";
import { FilterRow } from "./FilterComponents";

const CostCentersBudgets = ({ name, bindName, handleSearch }) => {
  const isLoading = useSelector((state) => state.globalState.isLoading);

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <div>
      <FilterRow onKeyUp={handleKeyUp}>
        <TextField
          color="secondary"
          fullWidth
          name="orderId"
          type="text"
          label="Name"
          variant="outlined"
          size="small"
          value={name}
          {...bindName}
          disabled={isLoading}
        />

        <StyledButton cta onClick={handleSearch} disabled={isLoading}>
          SEARCH
        </StyledButton>
      </FilterRow>
    </div>
  );
};

CostCentersBudgets.propTypes = {
  name: PropTypes.string.isRequired,
  bindName: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default CostCentersBudgets;
