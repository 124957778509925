/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import clsx from "clsx";
import PropTypes from "prop-types";

import { useCheckoutOptions } from "@features/ordering";
import {
  updateOrderSetBudget,
  updateOrderSetPromotions,
} from "@redux/slices/orders/currentOrderSetSlice";
import { formatMoney } from "@utility/utilityFunctions";

import { OpaqueCard } from "../../StyledComponents";
import OrderControl from "./OrderControl";
import OrderSetBudgets from "./OrderSetBudgets";
import OrderSetCostCenters from "./OrderSetCostCenters";
import OrderSetPromotions from "./OrderSetPromotions";
import SetOverviewItemTable from "./SetOverviewItemTable";
import SetOverviewOrderTable from "./SetOverviewOrderTable";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  detailGrid: {
    width: "90%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  detailPaper: {
    boxSizing: "border-box",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    border: `2px solid ${theme.palette.secondary.light}`,
    height: "100%",
  },
  warningBlock: {
    boxSizing: "border-box",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `5px solid ${theme.palette.secondary.dark}`,
    width: "80%",
    margin: "0 auto",
  },
}));

const SetOverview = ({
  setView,
  origin,
  handleWarningOpen,
  handleSubmit,
  setStripeModal,
  determineTotal,
  handleApprove,
  handleOpenDenyModal,
  handleOpenSubmitOnBehalfModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    id,
    type,
    status,
    totalVariantPrice,
    totalEstFreight,
    totalEstTax,
    totalPrice,
    orderSetVariants,
    orderSetOrders,
    promotionIds,
    totalDiscount,
    budgetId,
  } = useSelector((state) => state.currentOrderSet);
  const {
    usesCostCenters,
    includeShippingInBudgets,
    includeTaxesInBudgets,
    includeShippingInStripeCharge,
    includeTaxesInStripeCharge,
  } = useSelector((state) => state.currentUser.organization);

  const { allowBudgetCheckout, allowStripeCheckout, allowStandardCheckout } =
    useCheckoutOptions();

  const stripeOnly =
    allowStripeCheckout && !allowBudgetCheckout && !allowStandardCheckout;

  const removePromotions = () => {
    dispatch(updateOrderSetPromotions(id, [], [], [...promotionIds]));
  };

  const getTotalTax = () => {
    let tax = totalEstTax;
    if (allowStripeCheckout && !budgetId) {
      tax = includeTaxesInStripeCharge ? totalEstTax : 0;
    }
    if (budgetId) {
      tax = includeTaxesInBudgets ? totalEstTax : 0;
    }
    return formatMoney(tax, false);
  };

  const getTotalFreight = () => {
    let shipping = totalEstFreight;
    if (allowStripeCheckout && !budgetId) {
      shipping = includeShippingInStripeCharge ? totalEstFreight : 0;
    }
    if (budgetId) {
      shipping = includeShippingInBudgets ? totalEstFreight : 0;
    }
    return formatMoney(shipping, false);
  };

  const getTotalPrice = () => {
    let tax;
    let shipping;
    if (allowStripeCheckout && !budgetId) {
      tax = includeTaxesInStripeCharge ? 0 : totalEstTax;
      shipping = includeShippingInStripeCharge ? 0 : totalEstFreight;
      return formatMoney(totalPrice - tax - shipping - totalDiscount, false);
    }
    if (budgetId) {
      tax = includeTaxesInBudgets ? 0 : totalEstTax;
      shipping = includeShippingInBudgets ? 0 : totalEstFreight;
      return formatMoney(totalPrice - tax - shipping - totalDiscount, false);
    }
    return formatMoney(totalPrice - totalDiscount, false);
  };

  useEffect(() => {
    if (!allowBudgetCheckout && budgetId) {
      dispatch(updateOrderSetBudget(id, null));
    }
  }, [id, allowBudgetCheckout, budgetId, dispatch]);

  return (
    <>
      <div tw="flex flex-col gap-6">
        <OpaqueCard tw="p-6 text-center">
          <Typography className={classes.titleText}>Order Summary</Typography>
          <br />
          <Typography className={classes.bodyText}>
            <b>Total Qty:</b>
            {` ${orderSetVariants
              .map((v) => v.count)
              .reduce((a, b) => a + b, 0)}`}
          </Typography>
          <Typography className={classes.bodyText}>
            <b>Total Price:</b>
            {` ${formatMoney(totalVariantPrice, false)}`}
          </Typography>
          <Typography className={classes.bodyText}>
            <b>Estimated Freight:</b>
            {` ${getTotalFreight()}`}
          </Typography>
          {totalEstTax > 0 && (
            <Typography className={classes.bodyText}>
              <b>Estimated Tax:</b>
              {` ${getTotalTax()}`}
            </Typography>
          )}
          {totalDiscount > 0 && (
            <Typography className={classes.bodyText}>
              <b>Total Savings:</b>
              {` - ${formatMoney(totalDiscount, false)}`}
            </Typography>
          )}
          <br />
          <Typography className={classes.titleText}>
            {`Total: ${getTotalPrice()}`}
          </Typography>
          <div tw="mt-6 space-y-6">
            {usesCostCenters && <OrderSetCostCenters classes={classes} />}
            {allowBudgetCheckout && <OrderSetBudgets classes={classes} />}
          </div>

          <br />
          <Divider className={classes.fullWidth} />
          <br />

          <OrderSetPromotions />

          <div className={classes.fullWidth}>
            {origin === "draft" &&
            ["submitted", "approved"].includes(status) ? (
              <>
                <br />
                <br />
                <Typography className={classes.headerText}>
                  Thank you for your Submission!
                </Typography>
                <br />
                <br />
              </>
            ) : (
              <>
                {type === "pre-order" && (
                  <>
                    <br />
                    {!stripeOnly && (
                      <Typography className={classes.bodyText}>
                        Thanks for your Order! It has been saved and will be
                        included in this pre order. If you would like, you can
                        still edit this order up until "Pre Order Close Date".
                      </Typography>
                    )}
                    <br />
                    {allowStripeCheckout && allowBudgetCheckout && (
                      <>
                        <Typography className={classes.bodyText}>
                          You may check out with a credit card or submit at any
                          time before the Pre Order closes, otherwise the order
                          will be automatically submitted, and applied to any
                          assigned budgets if applicable.
                        </Typography>
                        <br />
                      </>
                    )}
                    {stripeOnly && (
                      <>
                        <Typography className={classes.bodyText}>
                          The checkout process must be complete before the Pre
                          Order Closes, or the order will not be able to be
                          processed.
                        </Typography>
                        <br />
                      </>
                    )}
                  </>
                )}
                {promotionIds.length > 0 && (
                  <>
                    <br />
                    <div className={classes.warningBlock}>
                      <Typography className={classes.headerText}>
                        Promotions should only be applied right before checking
                        out, if you have applied a promotion and need to edit or
                        delete your order, please remove the promotion, and re
                        apply it after you have edited your order.
                      </Typography>
                      <br />
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={removePromotions}
                      >
                        REMOVE PROMOTIONS
                      </Button>
                    </div>
                  </>
                )}
                <br />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={() => setView("order")}
                  disabled={promotionIds.length > 0}
                >
                  EDIT ORDER
                </Button>
                <br />
                <br />
                <br />
                <OrderControl
                  classes={classes}
                  origin={origin}
                  handleWarningOpen={handleWarningOpen}
                  handleSubmit={handleSubmit}
                  setStripeModal={setStripeModal}
                  determineTotal={determineTotal}
                  handleApprove={handleApprove}
                  handleOpenDenyModal={handleOpenDenyModal}
                  handleOpenSubmitOnBehalfModal={handleOpenSubmitOnBehalfModal}
                />
              </>
            )}
          </div>
        </OpaqueCard>

        <OpaqueCard tw="p-6">
          <Typography
            className={
              promotionIds.length > 0
                ? clsx(classes.headerText, classes.settingsMargin)
                : classes.headerText
            }
          >
            Item Overview
          </Typography>

          {promotionIds.length > 0 && (
            <Typography className={classes.bodyText} color="textSecondary">
              <em>* Pricing does not reflect discounts</em>
            </Typography>
          )}
          <br />
          {orderSetVariants.map((v) => v.count).reduce((a, b) => a + b, 0) >
          0 ? (
            <SetOverviewItemTable
              orders={orderSetOrders}
              variants={orderSetVariants}
              type={type}
            />
          ) : (
            <>
              <br />
              <Typography className={classes.bodyText}>
                There are no items added to this order currently ...
              </Typography>
            </>
          )}
        </OpaqueCard>

        <OpaqueCard tw="p-6">
          <Typography
            className={
              promotionIds.length > 0
                ? clsx(classes.headerText, classes.settingsMargin)
                : classes.headerText
            }
          >
            Order Overview
          </Typography>
          {promotionIds.length > 0 && (
            <Typography className={classes.bodyText} color="textSecondary">
              <em>* Pricing does not reflect discounts</em>
            </Typography>
          )}
          <br />
          {orderSetOrders.length > 0 ? (
            <SetOverviewOrderTable
              orders={orderSetOrders}
              hasTax={totalEstTax > 0}
            />
          ) : (
            <>
              <br />
              <Typography className={classes.bodyText}>
                There are no orders in the current order set ...
              </Typography>
            </>
          )}
        </OpaqueCard>
      </div>
    </>
  );
};

SetOverview.propTypes = {
  setView: PropTypes.func.isRequired,
  origin: PropTypes.string,
  handleWarningOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setStripeModal: PropTypes.func.isRequired,
  determineTotal: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  handleOpenDenyModal: PropTypes.func.isRequired,
  handleOpenSubmitOnBehalfModal: PropTypes.func.isRequired,
};

export default SetOverview;
