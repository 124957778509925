import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

type LoadingProps = {
  partial?: boolean;
  hidden?: boolean;
  opacity?: number;
};
const Loading = ({ partial, hidden, opacity }: LoadingProps) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        opacity: opacity ? opacity : "100%",
        backgroundColor: partial ? "rgb(0,0,0,0)" : "white",
        zIndex: "2499",
      }}
    >
      <br />

      {!hidden && (
        <CircularProgress color="secondary" disableShrink size={100} />
      )}
    </div>
  );
};

export default Loading;
