import { PropsWithChildren, ReactNode, createContext, useState } from "react";

import ConfirmModal from "./ConfirmModal";

type PromiseResolve = { resolve: (x: boolean) => void };
export type ConfirmContentProps = {
  message: ReactNode;
  title: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

type ContextProps = {
  promise: PromiseResolve | null;
  setPromise: (x: PromiseResolve | null) => void;
  confirmContent: ConfirmContentProps | null;
  setConfirmContent: (x: ConfirmContentProps | null) => void;
};

export const GlobalConfirmContext = createContext<ContextProps>({
  promise: null,
  setPromise: () => {},
  confirmContent: null,
  setConfirmContent: () => {},
});

export const GlobalConfirmProvider = ({ children }: PropsWithChildren) => {
  const [promise, setPromise] = useState<PromiseResolve | null>(null);
  const [confirmContent, setConfirmContent] =
    useState<ConfirmContentProps | null>(null);

  return (
    <GlobalConfirmContext.Provider
      value={{
        promise,
        setPromise,
        confirmContent,
        setConfirmContent,
      }}
    >
      {children}
      <ConfirmModal />
    </GlobalConfirmContext.Provider>
  );
};
