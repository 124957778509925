import { Link } from "react-router-dom";

import AdminItem from "src/pages/AdminItem";
import Budget from "src/pages/Budget";
import User from "src/pages/User";

import permissions from "@utils/permissions";

import AddressAdmin from "../../../pages/AddressAdmin";
import AdminChannels from "../../../pages/AdminChannels";
import AdminDashboards from "../../../pages/AdminDashboards";
import AdminGeneral from "../../../pages/AdminGeneral";
import AdminItemSettings from "../../../pages/AdminItemSettings";
import AdminItems from "../../../pages/AdminItems";
import AdminProgram from "../../../pages/AdminProgram";
import AdminPrograms from "../../../pages/AdminPrograms";
import AdminSuppliers from "../../../pages/AdminSuppliers";
import AdminTerritories from "../../../pages/AdminTerritories";
import AdminUsers from "../../../pages/AdminUsers";
import BudgetAdmin from "../../../pages/BudgetAdmin";
import CostCenterAdmin from "../../../pages/CostCenterAdmin";
import CostCenterCreate from "../../../pages/CostCenterCreate";
import OrderWindowAdmin from "../../../pages/OrderWindowAdmin";
import OrderWindowCreate from "../../../pages/OrderWindowCreate";
import PromotionAdmin from "../../../pages/PromotionAdmin";
import PromotionCreate from "../../../pages/PromotionCreate";
import { protectedRoute } from "../types";

const makeCrumb = (url, label) => ({
  crumb: () => <Link to={url}>{label}</Link>,
});
const programHandle = makeCrumb("/admin/programs", "Program Admin");
const budgetHandle = makeCrumb("/admin/budgets", "Budget Admin");
const costCenterHandle = makeCrumb("/admin/cost-centers", "Cost Centers Admin");
const itemHandle = makeCrumb("/admin/items", "Items Admin");
const userHandle = makeCrumb("/admin/users", "Users Admin");
const orderWindowHandle = makeCrumb(
  "/admin/order-window",
  "Order Window Admin"
);
const promotionHandle = makeCrumb("/admin/promotions", "Promotion Admin");

const managersAndApprover = [...permissions.admin, "approver" as const];

const adminRoutes: protectedRoute = {
  path: "admin",
  allowedRoles: managersAndApprover,
  defaultChildrenAllowedRoles: permissions.admin,
  children: [
    {
      path: "settings",
      allowedRoles: permissions.super,
      element: <AdminGeneral />,
      handle: { crumb: () => "Site Settings" },
    },
    {
      path: "dashboards",
      element: <AdminDashboards />,
      handle: { crumb: () => "Dashboard Admin" },
    },
    {
      path: "addresses",
      element: <AddressAdmin />,
      handle: { crumb: () => "Address Admin" },
    },
    {
      path: "channels",
      element: <AdminChannels />,
      handle: { crumb: () => "Channels Admin" },
    },
    {
      path: "item-settings",
      element: <AdminItemSettings />,
      handle: { crumb: () => "Products Admin" },
    },
    {
      path: "suppliers",
      allowedRoles: permissions.super,
      element: <AdminSuppliers />,
      handle: { crumb: () => "Suppliers Admin" },
    },

    {
      path: "budgets",
      handle: budgetHandle,
      allowedRoles: managersAndApprover,
      children: [
        { path: "", element: <BudgetAdmin /> },
        { path: ":id", element: <Budget /> },
      ],
    },

    {
      path: "cost-centers",
      handle: costCenterHandle,
      allowedRoles: managersAndApprover,
      children: [
        { path: "", element: <CostCenterAdmin /> },
        { path: ":action", element: <CostCenterCreate /> },
        { path: ":action/:id", element: <CostCenterCreate /> },
      ],
    },
    {
      path: "items",
      handle: itemHandle,
      children: [
        { path: "", element: <AdminItems /> },
        { path: ":id", element: <AdminItem /> },
      ],
    },

    {
      path: "order-windows",
      handle: orderWindowHandle,
      children: [
        { path: "", element: <OrderWindowAdmin /> },
        { path: ":type", element: <OrderWindowCreate /> },
        { path: ":type/:id", element: <OrderWindowCreate /> },
      ],
    },

    {
      path: "promotions",
      handle: promotionHandle,
      children: [
        { path: "", element: <PromotionAdmin /> },
        { path: ":type", element: <PromotionCreate /> },
        { path: ":type/:id", element: <PromotionCreate /> },
      ],
    },
    {
      path: "territories",
      element: <AdminTerritories />,
      handle: { crumb: () => "Territory Admin" },
    },

    {
      path: "users",
      handle: userHandle,
      children: [
        { path: "", element: <AdminUsers /> },
        { path: ":id", element: <User /> },
      ],
    },
    {
      path: "programs",
      handle: programHandle,
      children: [
        { path: "", element: <AdminPrograms /> },
        { path: ":id", element: <AdminProgram /> },
        { path: ":id/:view", element: <AdminProgram /> },
      ],
    },
  ],
};

export default adminRoutes;
