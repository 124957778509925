import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import PropTypes from "prop-types";

import { fetchPrograms } from "@redux/slices/programs/programsSlice";

const ProgramAutoComplete = ({ handleChange, reset, setReset, filterType }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [program, setProgram] = useState("");
  const [currentPrograms, setCurrentPrograms] = useState([]);

  const isLoading = useSelector((state) => state.programs.isProgramListLoading);
  const options = useSelector((state) => state.programs.programList);
  const currentFilterPrograms = useSelector(
    (state) => state.filters.programIds
  );
  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const loading = open && isLoading;

  const debounce = useRef(null);

  const handlePrograms = (value) => {
    setCurrentPrograms(value);
  };

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchPrograms({ name: program, isAutoComplete: true }));
    }, 250);
  }, [program, dispatch]);

  useEffect(() => {
    if (program.length >= 1) {
      handleQuery();
    }
  }, [program, handleQuery, dispatch]);

  useEffect(() => {
    if (currentFilterPrograms.length !== currentPrograms.length) {
      setCurrentPrograms(currentFilterPrograms);
    }
  }, [currentFilterPrograms, currentPrograms.length]);

  useEffect(() => {
    if (reset) {
      setProgram("");
      setCurrentPrograms([]);
      setReset(false);
    }
  }, [reset, setProgram, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        freeSolo
        id="program-auto-complete"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={program}
        onInputChange={(_evt, value) => setProgram(value)}
        onChange={(evt, value) => {
          handleChange(value, "programIds", filterType);
          handlePrograms(value);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        value={currentPrograms}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Program"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

ProgramAutoComplete.propTypes = {
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
};

export default ProgramAutoComplete;
