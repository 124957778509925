const buildAddress = (address) => {
  return {
    __type: "address",
    name: address.name,
    "default-attn": address.attn,
    "street-address-1": address.addressOne,
    "street-address-2": address.addressTwo,
    city: address.city,
    zip: address.zip,
    "phone-number": address.phoneNumber,
    region: address.region ?? null,
    type: "address",
    "user-id": address.userId ?? null,
    "email-list": address.emailList ?? null,
    "territory-list": address.territoryList ?? null,
    country: {
      type: "country",
      id: address.countryId,
    },
    state: {
      type: "state",
      id: address.state,
    },
    relationshipNames: ["state", "country"],
  };
};

export const handleAddressCSV = (data, states, countries) => {
  const requiredFields = [
    "Name",
    "Street Address One",
    "City",
    "Zip",
    "Phone Number",
    "Country",
    "Emails (separated by a semicolon)",
    "Territory (separated by a semicolon)"
  ];

  // Check if all required fields are present in the data
  const fieldsFoundInData = Object.keys(data[0].data);
  for (const column of requiredFields) {
    if (!fieldsFoundInData.includes(column)) {
      throw new Error(`Missing or empty required column: ${column}`);
    }
  }

  let errors: any[] = [];
  let csvErrors = data.filter((dataPoint) => dataPoint.errors.length > 0);

  const cleanEmailTerrArr = (arr) => {
    let list = arr.trim().split(";");
    if (list[list.length - 1] === "") {
      list.pop();
    }
    list = list.map((item) => item.trim());
    return list;
  };

  errors =
    csvErrors.length > 0
      ? errors.concat(
          csvErrors.map((e) => ({ error: `CSV Upload Error: ${e.toString()}` }))
        )
      : errors;

  let updatedWithRequiredErrors = data.map((dataPoint) => {
    let emailList = cleanEmailTerrArr(
      dataPoint.data["Emails (separated by a semicolon)"]
    );
    let terrList = cleanEmailTerrArr(
      dataPoint.data["Territory (separated by a semicolon)"]
    );
    
    // Check if any of the required fields are empty
    const hasMissingData = requiredFields.some(
      field => dataPoint.data[field].trim().length === 0) || 
      emailList.length === 0 || 
      terrList.length === 0;
    if (hasMissingData) {
      return {
        ...dataPoint.data,
        error: "missing-required",
      };
    } else return { ...dataPoint.data, error: null };
  });

  let updatedWithInvalidStates = updatedWithRequiredErrors.map((dataPoint) => {
    if (
      dataPoint["State / Province"] &&
      dataPoint["State / Province"].trim().length > 0 &&
      !states.find(
        (s) =>
          s?.code.toLowerCase() ===
          dataPoint["State / Province"].trim().toLowerCase()
      )
    ) {
      return { ...dataPoint, error: "invalid-state" };
    } else if (
      dataPoint["State / Province"] &&
      dataPoint["State / Province"].trim().length > 0 &&
      states.find(
        (s) =>
          s?.code.toLowerCase() ===
          dataPoint["State / Province"].trim().toLowerCase()
      )
    ) {
      return {
        ...dataPoint,
        "State / Province": parseInt(
          states.find(
            (s) =>
              s?.code.toLowerCase() ===
              dataPoint["State / Province"].trim().toLowerCase()
          ).id
        ),
      };
    } else return { ...dataPoint };
  });

  let updatedWithInvalidZipCodes = updatedWithInvalidStates.map((dataPoint) => {
    const country = dataPoint.Country.trim().toLowerCase();
    const zip = dataPoint.Zip.trim();
    if (country === "united states" && zip.length !== 5) {
      return { ...dataPoint, error: "invalid-zip" };
    } else {
      return { ...dataPoint };
    }
  });

  let updatedWithInvalidCountry = updatedWithInvalidZipCodes.map(
    (dataPoint) => {
      if (
        dataPoint.Country &&
        dataPoint.Country.trim().length > 0 &&
        !countries.find(
          (c) => c.name.toLowerCase() === dataPoint.Country.trim().toLowerCase()
        )
      ) {
        return { ...dataPoint, error: "invalid-country" };
      } else if (
        dataPoint.Country &&
        dataPoint.Country.trim().length > 0 &&
        countries.find(
          (c) => c.name.toLowerCase() === dataPoint.Country.trim().toLowerCase()
        )
      ) {
        return {
          ...dataPoint,
          Country: parseInt(
            countries.find(
              (c) =>
                c.name.toLowerCase() === dataPoint.Country.trim().toLowerCase()
            ).id
          ),
        };
      } else return { ...dataPoint };
    }
  );

  let updatedWithInvalidEmails = updatedWithInvalidCountry.map((dataPoint) => {
    let emailList = cleanEmailTerrArr(
      dataPoint["Emails (separated by a semicolon)"]
    );
    const validRegExp =
      /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    var valid = true;
    // We will check to make sure each email is an actual email.
    for (var n = 0; n < emailList.length; n++) {
      const email = emailList[n];
      if (email.search(validRegExp) === -1) {
        valid = false;
        break;
      }
    }
    if (!valid) {
      return { ...dataPoint, error: "invalid-email" };
    } else {
      return { ...dataPoint };
    }
  });

  updatedWithInvalidEmails.forEach((dataPoint) => {
    if (dataPoint.error) {
      errors.push(dataPoint);
    }
  });

  const mappedData = updatedWithInvalidEmails
    .filter((dataPoint) => !dataPoint.error)
    .map((dataPoint) => {
      let emailList = cleanEmailTerrArr(
        dataPoint["Emails (separated by a semicolon)"]
      );
      let terrList = cleanEmailTerrArr(
        dataPoint["Territory (separated by a semicolon)"]
      );
      return {
        name: dataPoint.Name.trim(),
        addressOne: dataPoint["Street Address One"].trim(),
        addressTwo:
          dataPoint["Street Address Two"].trim().length > 0
            ? dataPoint["Street Address Two"].trim()
            : null,
        attn:
          dataPoint.Attention.trim().length > 0
            ? dataPoint.Attention.trim()
            : null,
        city: dataPoint.City.trim(),
        state: dataPoint["State / Province"] ?? null,
        zip: dataPoint.Zip.trim(),
        countryId: dataPoint.Country,
        phoneNumber: dataPoint["Phone Number"].trim(),
        region:
          dataPoint.Region.trim().length > 0 ? dataPoint.Region.trim() : null,
        emailList: emailList,
        territoryList: terrList,
      };
    })
    .map((add) => buildAddress(add));

  const mappedErrors = errors.map((err) => {
    if (err.error === "invalid-email") {
      return `Address Name: ${err.Name}, Error: Some email(s) were invalid`;
    } else if (err.error === "missing-required") {
      return `Address Name: ${err.Name}, Error: Missing required address fields`;
    } else if (err.error === "invalid-state") {
      return `Address Name: ${err.Name}, Error: Invalid State (Either the state code didn't match any we have on file, or the state isn't in the current territory)`;
    } else if (err.error === "invalid-country") {
      return `Address Name: ${err.Name}, Error: Invalid Country (Either the country name didn't match any we have on file, or the country isn't in the current territory)`;
    } else if (err.error === "invalid-zip") {
      return `Address Name: ${err.Name}, Error: Invalid Zip Code (USA zip codes should be 5 characters)`;
    } else return err.error;
  });

  return { validData: mappedData, errors: mappedErrors };
};
