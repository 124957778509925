import React, { memo } from "react";

import { Typography } from "@mui/material";

import clsx from "clsx";
import PropTypes from "prop-types";

import SingleChannelAutoComplete from "../../Utility/AutoCompleteFields/SingleChannelAutoComplete";
import OptionTable from "../../Utility/OptionTable";

const ChannelSection = ({
  classes,
  handleAddChannel,
  resetField,
  setResetField,
  isUpdateLoading,
  currentChannel,
  handleRemoveChannel,
  currentItems,
}) => {
  return (
    <>
      <div className={clsx(classes.fullWidth, classes.center)}>
        <Typography className={classes.headerText}>Channels</Typography>
      </div>
      <br />
      <section
        className={clsx(classes.settingsMargin, classes.fullWidthCenterColumn)}
      >
        <SingleChannelAutoComplete
          classes={classes}
          handleChange={handleAddChannel}
          reset={resetField}
          setReset={setResetField}
          disabled={isUpdateLoading || currentItems.length > 0}
        />
        {currentChannel && (
          <OptionTable
            headCells={[
              { id: "id", label: "Id" },
              { id: "name", label: "Name" },
            ]}
            options={[currentChannel]}
            handleRemove={handleRemoveChannel}
            disabled={isUpdateLoading}
            isRemoveable
          />
        )}
      </section>
    </>
  );
};

ChannelSection.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAddChannel: PropTypes.func.isRequired,
  resetField: PropTypes.bool.isRequired,
  setResetField: PropTypes.func.isRequired,
  isUpdateLoading: PropTypes.bool.isRequired,
  currentChannel: PropTypes.object,
  handleRemoveChannel: PropTypes.func.isRequired,
  currentItems: PropTypes.array,
};

export default memo(ChannelSection);
