import { useSelector } from "react-redux";

import _ from "lodash";
import { useSWRConfig } from "swr";

import { OrderSetStatus } from "@models/OrderSet";
import client from "@services/api";
import asyncPool from "@utility/asyncPool";
import permissions from "@utils/permissions";

const isInfiniteKey = (key: string, resource: string) =>
  key.startsWith(`$inf$@"${resource}"`);

export default function useApprovalActions() {
  const { cache, mutate } = useSWRConfig();

  const {
    role,
    organization: { approvalFlows },
  } = useSelector((state: any) => state.currentUser);

  const nextApprovedStatus =
    permissions.reviewApprovals.includes(role) || approvalFlows.length === 1
      ? "approved"
      : "approval-review";

  const mutateOrderSets = (mutation, options?) => {
    // as of swr v2.2.0, modifing infinity keys needs this work around
    // https://github.com/vercel/swr/issues/1670#issuecomment-1625977770
    for (const key of cache.keys()) {
      if (isInfiniteKey(key, "order-sets")) {
        mutate(
          key,
          (cache: unknown) => {
            if (cache === undefined) return cache;
            if (Array.isArray(cache)) {
              return cache.map(mutation);
            }
            return mutation(cache);
          },
          { revalidate: false, ...options }
        );
      }
    }
  };

  function removeOrderSetsFromCache(ids: string[]) {
    const idsSet = new Set(ids);
    return mutateOrderSets((cache) => {
      return {
        ...cache,
        data: cache.data.filter((orderSet) => !idsSet.has(orderSet.id)),
      };
    });
  }

  function updateOrderSetStatuses(ids: string[], status: OrderSetStatus) {
    const idsSet = new Set(ids);
    return mutateOrderSets(
      (cache) => {
        return {
          ...cache,
          data: cache.data.map((orderSet) =>
            idsSet.has(orderSet.id) ? { ...orderSet, status } : orderSet
          ),
        };
      },
      { revalidate: true }
    );
  }

  async function approveOrderSets(ids: string[]) {
    const res = await asyncPool(5, ids, (id) =>
      client.post(`/order-sets/${id}/approve`)
    );
    if (res.errors) {
      mutateOrderSets((data) => data, { revalidate: true });
      throw new Error("Some orders failed to approve");
    }
    updateOrderSetStatuses(ids, nextApprovedStatus);
  }
  async function denyOrderSets(ids: string[], reason: string) {
    const res = await asyncPool(5, ids, (id) =>
      client.post(
        `/order-sets/${id}/cancel`,
        {
          id,
          "cancelation-type": "denial",
          "cancelation-note": reason,
        },
        { serializeBody: false }
      )
    );
    if (res.errors) {
      mutateOrderSets((data) => data, { revalidate: true });
      throw new Error("Some orders failed to approve");
    }
    removeOrderSetsFromCache(ids);
  }

  return { approveOrderSets, denyOrderSets };
}
