import { formatMoney, kebabCaseKeys } from "@utility/utilityFunctions";

import { buildFilters } from "../../api/apiFunctions";
import { axiosGet, axiosPatch, axiosPost } from "../../api/axiosCalls";
import { setError } from "./errorSlice";

const { createSlice } = require("@reduxjs/toolkit");

const costCenterSlice = createSlice({
  name: "costCenters",
  initialState: {
    costCenters: [],
    currentUserCostCenters: [],
    nextLink: null,
    currentCostCenter: null,
    isLoading: false,
    hasUpdated: false,
  },
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setHasUpdated(state, { payload }) {
      state.hasUpdated = payload.value;
    },
    createOrUpdateCostCenterSuccess(state, action) {
      const { costCenter } = action.payload;
      let currentCostCenter = state.costCenters.find(
        (b) => b.id === costCenter.id
      );
      state.costCenters = !currentCostCenter
        ? state.costCenters.concat(costCenter)
        : state.costCenters.map((b) => {
            if (b.id === costCenter.id) return costCenter;
            else return b;
          });
      state.currentCostCenter = costCenter;
      state.isLoading = false;
      state.hasUpdated = true;
    },
    getCallCenterSuccess(state, action) {
      state.currentCostCenter = action.payload;
      state.isLoading = false;
    },
    getCurrentUserCostCentersSuccess(state, action) {
      state.currentUserCostCenters = action.payload.costCenters;
      state.isLoading = false;
    },
    getCostCentersSuccess(state, action) {
      const { nextLink, costCenters } = action.payload;
      state.costCenters = [...costCenters];
      state.nextLink = nextLink;
      state.isLoading = false;
    },
    getNextCostCentersSuccess(state, action) {
      const { nextLink, costCenters } = action.payload;
      state.costCenters = state.costCenters.concat(costCenters);
      state.nextLink = nextLink;
      state.isLoading = false;
    },
  },
});

export const {
  setIsLoading,
  setHasUpdated,
  createOrUpdateCostCenterSuccess,
  getCallCenterSuccess,
  getCostCentersSuccess,
  getNextCostCentersSuccess,
  getCurrentUserCostCentersSuccess,
} = costCenterSlice.actions;
export default costCenterSlice.reducer;

export const mapCostCenter = (cc) => ({
  id: cc.id,
  name: cc.name,
  total: cc.total,
  ...(cc.users && { users: cc.users.map(({ id, name }) => ({ id, name })) }),
});
export const mapCostCenterReport = (cc) => ({
  id: cc.id,
  name: cc.name,
  total: formatMoney(cc.total),
  users: cc.users.map(({ name }) => name).join(", "),
});

export const fetchCostCenter = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const res = await axiosGet(`/api/cost-centers/${id}`);
    if (res.error) throw res.error;
    dispatch(getCallCenterSuccess(mapCostCenter(res.data)));
  } catch (error) {
    console.error(error);
    setError({ error: error.toString(), source: "Fetch Cost Center" });
  }
};

export const fetchCostCenters = (filters) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const queryUrl = buildFilters(filters, null, null, `/api/cost-centers`);
    const res = await axiosGet(queryUrl);
    if (res.error) throw res.error;
    dispatch(
      getCostCentersSuccess({
        costCenters: res.data.map(mapCostCenter),
        nextLink: res.data.nextLink,
      })
    );
  } catch (error) {
    dispatch(setError({ error, source: "Fetch cost centers" }));
  }
};
export const fetchCurrentUserCostCenters = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const queryUrl = `/api/cost-centers?filter[user-ids][]=${id}`;
    const res = await axiosGet(queryUrl);
    if (res.error) throw res.error;
    dispatch(
      getCurrentUserCostCentersSuccess({
        costCenters: res.data.map(mapCostCenter),
      })
    );
  } catch (error) {
    dispatch(setError({ error, source: "Fetch cost centers" }));
  }
};
export const fetchNextCostCenters = (url) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const res = await axiosGet(url);
    if (res.error) throw res.error;
    dispatch(
      getNextCostCentersSuccess({
        costCenters: res.data.data.map(mapCostCenter),
        nextLink: res.data.nextLink,
      })
    );
  } catch (error) {
    dispatch(setError({ error, source: "Fetch next cost centers" }));
  }
};

export const createOrUpdateCostCenter = (data) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    let res = null;
    const body = {
      data: {
        attributes: kebabCaseKeys({
          name: data.name,
        }),
        relationships: { users: { data: data.users } },
      },
    };
    if (data.id) {
      res = await axiosPatch(`/api/cost-centers/${data.id}`, body);
    } else {
      res = await axiosPost(`/api/cost-centers`, body);
    }

    if (res.error) throw res.error;

    dispatch(
      createOrUpdateCostCenterSuccess({ costCenter: mapCostCenter(res.data) })
    );
  } catch (error) {
    dispatch(setError({ error, source: "Create/Update cost center" }));
  }
};
