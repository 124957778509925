import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { AddressSearch } from "@features/addresses";
import {
  resetUpdate,
  updateVariantAddress,
  updateVariantQty,
} from "@redux/slices/orders/orderHistorySlice";

import { useNumberOnlyInput } from "../../hooks/inputs/useNumberOnlyInput";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const EditOrderVariantModal = ({ open, handleClose, editObject, order }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newAddress, setNewAddress] = useState(null);

  const { isUpdateLoading, hasUpdated, newOrderId } = useSelector(
    (state) => state.orderHistory
  );

  const {
    value: qty,
    bind: bindQty,
    reset: resetQty,
  } = useNumberOnlyInput(editObject.count);

  const handleQuantityUpdate = () => {
    dispatch(updateVariantQty(editObject.id, qty));
  };

  const handleAddressUpdate = () => {
    dispatch(updateVariantAddress(editObject.id, newAddress.id));
  };

  return (
    <div className={classes.relativeContainer}>
      <Dialog
        open={open}
        disableScrollLock
        onClose={() => {
          dispatch(resetUpdate());
          resetQty();
          handleClose("edit");
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {isUpdateLoading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "100px",
                paddingBottom: "100px",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!isUpdateLoading && hasUpdated && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "100px",
                paddingBottom: "100px",
                textAlign: "center",
              }}
            >
              <Typography className={classes.headerText}>
                Update Successful!
              </Typography>
              {newOrderId && (
                <>
                  <br />
                  <Typography className={classes.bodyText}>
                    {`Updating the address on an order item has removed it from this order.  The new order can be found under order number ${newOrderId}`}
                  </Typography>
                </>
              )}
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                  onClick={() => dispatch(resetUpdate())}
                >
                  CONTINUE EDITING
                </Button>
                {newOrderId && (
                  <Button
                    className={classes.largeButton}
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      resetQty();
                      setNewAddress(null);
                      handleClose("edit", `/orders/history/${newOrderId}`);
                      dispatch(resetUpdate());
                    }}
                  >
                    VIEW NEW ORDER
                  </Button>
                )}
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    dispatch(resetUpdate());
                    resetQty();
                    setNewAddress(null);
                    handleClose("edit");
                  }}
                >
                  EXIT
                </Button>
              </div>
            </div>
          )}
          {!isUpdateLoading && !hasUpdated && (
            <>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  dispatch(resetUpdate());
                  resetQty();
                  setNewAddress(null);
                  handleClose("edit");
                }}
                size="large"
              >
                <CancelIcon fontSize="large" color="secondary" />
              </IconButton>
              <div className={classes.fullWidthCenterColumn}>
                <br />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography className={classes.headerText}>
                    Update Item Quantity
                  </Typography>
                  <br />
                  <br />
                </div>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  {...bindQty}
                />
                <br />
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleQuantityUpdate()}
                >
                  UPDATE QUANTITY
                </Button>
                <br />
                <Divider />
                <br />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography className={classes.headerText}>
                    Change Items Address
                  </Typography>
                  <br />
                  <Typography className={classes.bodyText}>
                    Current Address:
                  </Typography>
                  <Typography className={classes.bodyText}>
                    {order.address.name}
                  </Typography>
                  <Typography className={classes.bodyText}>
                    {order.address.streetOne}
                  </Typography>
                  {order.address.streetTwo && (
                    <Typography className={classes.bodyText}>
                      {order.address.streetTwo}
                    </Typography>
                  )}
                  <Typography className={classes.bodyText}>
                    {`${order.address.city}, ${order.address.stateOrRegion}, ${order.address.zip}`}
                  </Typography>
                  {order.address.country && (
                    <Typography className={classes.bodyText}>
                      {order.address.country}
                    </Typography>
                  )}
                  <br />
                  <Typography className={classes.bodyText}>
                    * Updating the address on an order item will remove it from
                    the current order and create a new order for that item for
                    the new address.
                  </Typography>
                </div>
                <br />
                <AddressSearch
                  onChange={setNewAddress}
                  validateTerritory={order.territoryId}
                />
                <br />
                {newAddress && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography className={classes.bodyText}>
                      New Address:
                    </Typography>
                    <br />
                    <Typography className={classes.bodyText}>
                      {newAddress.name}
                    </Typography>
                    <Typography className={classes.bodyText}>
                      {newAddress.streetAddress1}
                    </Typography>
                    {newAddress.streetAddress2 && (
                      <Typography className={classes.bodyText}>
                        {newAddress.streetAddress2}
                      </Typography>
                    )}
                    <Typography className={classes.bodyText}>
                      {`${newAddress.city}, ${
                        newAddress.state?.code ?? newAddress.region
                      }, ${newAddress.zip}`}
                    </Typography>
                    {newAddress.country && (
                      <Typography className={classes.bodyText}>
                        {newAddress.country.name}
                      </Typography>
                    )}
                    <br />
                    <br />
                  </div>
                )}
                <Button
                  className={classes.largeButton}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddressUpdate()}
                >
                  UPDATE ADDRESS
                </Button>
                <br />
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditOrderVariantModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  item: PropTypes.object,
  order: PropTypes.object,
};

export default React.memo(EditOrderVariantModal);
