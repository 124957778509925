/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useParams } from "react-router-dom";

import { MenuItem, Select } from "@mui/material";

import stateCodeMap from "@utility/stateCodeMap";

import { useBeaconProgram } from "../../hooks/beaconHooks";

const StateSelector = (props) => {
  const { programId } = useParams();
  const { states } = useBeaconProgram({ programId }, "states");
  const stateOptions = states.map((state) => ({
    value: state.stateCode,
    name: stateCodeMap[state.stateCode],
  }));

  return (
    <Select
      size="small"
      css={{
        fieldset: tw`border-none`,
      }}
      {...props}
    >
      {stateOptions.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StateSelector;
