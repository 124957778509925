import { cloneDeep } from "lodash";
import { KeyedMutator } from "swr";

import { axiosOrApiError } from "./clientHelpers";

const toArray = (value: any) => (Array.isArray(value) ? value : [value]);

const updateSwrCacheList = async (mutate: KeyedMutator<any>, update: any) => {
  let modifiedEntity: any = null;
  await mutate(update, {
    populateCache: (response, cache) => {
      if (!cache) throw new Error("Update to cache before initialization");
      if (!Array.isArray(cache))
        throw new Error(
          "Expected cache to be array of pages at updateSwrCacheList"
        );
      if (response?.error) {
        throw axiosOrApiError(response.error);
      }

      modifiedEntity = response.data;
      const cacheCopy = cloneDeep(cache);
      const dataArray = toArray(response.data);
      dataArray.forEach((data) => {
        let pageEntityIndex = -1;
        const pageIndex = cache.findIndex((page) =>
          page.data.some((entity, index) => {
            if (entity.id === data.id) {
              pageEntityIndex = index;
              return true;
            }
            return false;
          })
        );
        if (pageEntityIndex === -1) {
          cacheCopy[0]?.data.unshift(data);
          cacheCopy[0].meta.total_entries += 1;
        } else {
          cacheCopy[pageIndex].data[pageEntityIndex] = data;
        }
      });
      return cacheCopy;
    },
    // Since the API already gives us the updated information,
    // we don't need to revalidate here.
    revalidate: false,
  });
  return modifiedEntity;
};
export default updateSwrCacheList;
