/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";

import { StyledButton } from "@components/StyledComponents";

import BulkUploadOrdersModal from "./BulkUploadOrdersModal";

const BulkUploadOrdersButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {modalOpen && (
        <BulkUploadOrdersModal onClose={() => setModalOpen(false)} />
      )}
      <StyledButton outlined onClick={() => setModalOpen(true)}>
        Bulk Upload Orders
      </StyledButton>
    </>
  );
};

export default BulkUploadOrdersButton;
