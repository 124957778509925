/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import {
  useTerritoriesListQuery,
  useUpdateTerritoryMutation,
} from "@features/territories";
import asyncPool from "@utility/asyncPool";

const uniqByKey = (arr, keyName) =>
  arr.filter(
    (a, i, rest) => i === rest.findIndex((r) => a[keyName] === r[keyName])
  );

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const AddToTerritoriesModal = ({ country, handleClose }) => {
  const classes = useStyles();
  const { data: territories, isLoading } = useTerritoriesListQuery();

  const updateTerritories = useUpdateTerritoryMutation();

  const { name, states } = country;

  const [errors, setErrors] = useState([]);
  const [isOperationComplete, setIsOperationComplete] = useState(false);

  const handleAddToTerr = async () => {
    const iteratorFn = async (terr) => {
      const newCountries = uniqByKey([...terr.countries, country], "id");
      const newStates = states
        ? uniqByKey([...terr.states, ...states], "id")
        : terr.states;

      return updateTerritories.mutateAsync({
        ...terr,
        countries: newCountries,
        states: newStates,
      });
    };

    const { errors } = await asyncPool(5, territories, iteratorFn);
    console.error("errors", errors);
    if (errors.length > 0) {
      setErrors(errors);
    }
    setIsOperationComplete(true);
  };

  return (
    <Dialog open={!!country} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h4" className={classes.headerText}>
          Would you like to add {name} to all territories?
        </Typography>
        <br />
        {states && (
          <DialogContentText tw="mb-4">
            This will also add the {states.length} states associated with this
            country.
          </DialogContentText>
        )}
        {errors.length > 0 && (
          <Typography color="error">
            {errors.map((error, index) => (
              <div key={index} tw="mb-1">
                {error.config.url ?? index}: {error.message}
              </div>
            ))}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={handleClose}
          disabled={isLoading}
        >
          No
        </Button>
        {!isOperationComplete ? (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleAddToTerr}
            disabled={isLoading}
          >
            Yes, add to all territories
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddToTerritoriesModal;
