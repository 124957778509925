import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import clsx from "clsx";
import _ from "lodash";
import { useInput } from "src/hooks/inputs/useInput";

import { updateOrderSetPromotions } from "@redux/slices/orders/currentOrderSetSlice";
import { useApiResource } from "@services/api";

const useStyles = makeStyles((theme: any) => ({
  ...theme.global,
}));

const OrderSetPromotions = () => {
  const classes = useStyles() as any;
  const dispatch = useDispatch();
  const { id, type, status, promotionIds, orderSetOrders } = useSelector(
    (state: any) => state.currentOrderSet
  );

  const checkoutType = orderSetOrders[0]?.checkoutType;

  const { data } = useApiResource(promotionIds.length > 0 && "promotions", {
    filter: { ids: promotionIds },
  });

  const appliedPromotions = data ?? [];

  const { role } = useSelector((state: any) => state.currentUser);
  const { currentUserPromotions } = useSelector(
    (state: any) => state.promotions
  );
  const availablePromotionAssignments = currentUserPromotions.filter(
    (p) =>
      !promotionIds.includes(+p.id) &&
      !(p.isOneTimeUse && p.hasRedeemed) &&
      p.redemptionType !== "code" &&
      (!p.orderType || p.orderType === type)
  );

  const canEditPromotions =
    status === "draft" || (role === "super" && checkoutType !== "stripe");

  const { value: code, bind: bindCode, reset: resetCode } = useInput("");

  const getUpdatePromotions = (ids) => {
    let codePromotions = currentUserPromotions
      .filter((p) => p.redemptionType === "code" && ids.includes(+p.id))
      .map((p) => p.promotionCode);
    let idPromotions = currentUserPromotions
      .filter((p) => p.redemptionType === "assignment" && ids.includes(+p.id))
      .map((p) => +p.id);

    return { codePromotions, idPromotions };
  };

  const removePromotion = (promoId) => {
    let currentPromotionIds = promotionIds.filter((pId) => +pId !== +promoId);
    const { codePromotions, idPromotions } =
      getUpdatePromotions(currentPromotionIds);
    dispatch(
      updateOrderSetPromotions(id, idPromotions, codePromotions, [
        ...promotionIds,
      ])
    );
  };

  const applyPromotionId = (promoId) => {
    const { codePromotions, idPromotions } = getUpdatePromotions(promotionIds);
    let newIds = idPromotions.concat(+promoId);
    dispatch(
      updateOrderSetPromotions(id, newIds, codePromotions, [...promotionIds])
    );
  };

  const applyPromotionCode = () => {
    const { codePromotions, idPromotions } = getUpdatePromotions(promotionIds);
    dispatch(
      updateOrderSetPromotions(id, idPromotions, codePromotions.concat(code), [
        ...promotionIds,
      ])
    );
    resetCode();
  };
  return (
    <>
      {promotionIds.length > 0 && (
        <>
          <Typography
            className={clsx(classes.headerText, classes.settingsMargin)}
          >
            Applied Promotions:
          </Typography>
          {appliedPromotions.map((promo) => {
            return (
              <div
                key={promo.id}
                className={clsx(classes.settingsMargin, classes.center)}
              >
                <Typography
                  style={{ marginBottom: "0px" }}
                  className={clsx(classes.bodyText, classes.settingsMargin)}
                >
                  {promo.description}
                </Typography>
                {canEditPromotions && (
                  <Button
                    style={{ marginLeft: "20px" }}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => removePromotion(promo.id)}
                  >
                    REMOVE
                  </Button>
                )}
              </div>
            );
          })}
        </>
      )}
      {availablePromotionAssignments.length > 0 && (
        <>
          <Typography
            className={clsx(classes.headerText, classes.settingsMargin)}
          >
            Available Promotions:
          </Typography>
          {availablePromotionAssignments.map((p) => (
            <div
              key={p.id}
              className={clsx(classes.settingsMargin, classes.center)}
            >
              <Typography
                className={classes.bodyText}
                style={{ marginBottom: "0px" }}
              >
                {p.description}
              </Typography>
              <Button
                style={{ marginLeft: "20px" }}
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={() => applyPromotionId(p.id)}
              >
                APPLY
              </Button>
            </div>
          ))}
        </>
      )}

      {canEditPromotions && (
        <div
          className={clsx(
            classes.settingsMargin,
            classes.center,
            classes.fullWidth
          )}
        >
          <TextField
            style={{ width: "40%", minWidth: "200px", marginRight: "20px" }}
            variant="outlined"
            color="secondary"
            name="code"
            type="text"
            label="Promotion Code"
            size="small"
            {...bindCode}
          />
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => applyPromotionCode()}
            disabled={code.length === 0}
          >
            APPLY
          </Button>
        </div>
      )}
    </>
  );
};

export default OrderSetPromotions;
