/** @jsxImportSource @emotion/react */
import tw, { TwStyle } from "twin.macro";

import { OrderStatus as TOrderStatus } from "@models/Order";
import { OrderSetStatus } from "@models/OrderSet";
import { upCase } from "@utility/utilityFunctions";

type Status = TOrderStatus | OrderSetStatus;

const colors = {
  success: tw`bg-green-300`,
  error: tw`bg-red-300`,
  warning: tw`bg-yellow-300`,
  info: tw`bg-blue-300`,
  neutral: tw`bg-neutral-300`,
};

const statusCss: Record<Status, TwStyle> = {
  draft: colors.neutral,
  submitted: colors.info,
  "approval-review": colors.info,
  approved: colors.success,
  canceled: colors.error,
  error: colors.error,
  "partial-approval-error": colors.warning,
  "missed-payment": colors.warning,
  inactive: colors.neutral,
};

const OrderStatus = ({ status }: { status: Status }) => (
  <div tw="flex items-center">
    <div
      css={[tw`w-2 h-2 mr-2 rounded-full flex-shrink-0`, statusCss[status]]}
    />
    {upCase(status, "-")}
  </div>
);

export default OrderStatus;
