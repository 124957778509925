import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isLoading: false,
  isUpdating: false,
  isStepperLoad: false,
  stepperValue: 0,
  stepperTitle: null,
  redirectUrl: null,
  willRedirect: false,
  productView: "table",
};

const globalStateSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    startGlobalLoad(state) {
      state.isLoading = true;
    },
    stopGlobalLoad(state) {
      state.isLoading = false;
    },
    startUpdating(state) {
      state.isUpdating = true;
    },
    stopUpdating(state) {
      state.isUpdating = false;
    },
    setIsStepper(state, action) {
      const { stepBool, stepTitle } = action.payload;
      state.isStepperLoad = stepBool;
      state.stepperTitle = stepTitle;
    },
    updateStepperValue(state, action) {
      const { value } = action.payload;
      state.stepperValue += value;
    },
    setRedirect(state, action) {
      const { redirectBool, url } = action.payload;
      state.redirectUrl = url;
      state.willRedirect = redirectBool;
    },
    resetStepperValue(state) {
      state.stepperValue = 0;
      state.stepperTitle = null;
      state.isStepperLoad = false;
    },
    resetGlobalLoad(state) {
      Object.assign(state, initialState);
    },
    setProductView(state, action) {
      state.productView = action.payload;
    },
  },
});

export const {
  startGlobalLoad,
  stopGlobalLoad,
  startUpdating,
  stopUpdating,
  setIsStepper,
  updateStepperValue,
  setRedirect,
  resetStepperValue,
  resetGlobalLoad,
  setProductView,
} = globalStateSlice.actions;

export default globalStateSlice.reducer;
