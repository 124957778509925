import { Transformer } from "@services/api";

import { Image } from "./Image";
import { Item } from "./Item";
import { VariantAllocation } from "./VariantAllocation";
import { VariantOption } from "./VariantOption";

export type Variant = {
  id: string;
  variantSku: string;
  isActive: boolean;
  isDefault: boolean;
  orderPosition: number;
  relationshipNames: ["selectedVariantOptions", "variantAllocations"];
  selectedVariantOptions: VariantOption[];
  upcharge: number;
  variantAllocations: VariantAllocation[];
  availableToOrderQty?: number;
  cachedWarehouseQty?: number;
  quantityOnHand?: number;
  externalWarehouseId?: string;
  globalAvailableToOrderQty: number;
  item: Item;
  imageId: number | null;
  // Not always loaded
  image?: Image;
};

const variantTransformer: Transformer = {
  type: ["variant", "variants"],
  deserialize: (data) => ({
    ...data,
    upcharge: data.upcharge ? Number(data.upcharge) : 0,
  }),
  serialize: (data) => ({
    ...data,
  }),
};

export default variantTransformer;
