import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { clearGroups } from "@redux/slices/groupCategories/groupCategorySlice";

const GroupAutoComplete = ({
  handleChange,
  reset,
  setReset,
  filterType,
  categoryLabel,
  options,
  forBudget = false,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState("");
  const [currentGroups, setCurrentGroups] = useState([]);

  const currentFilters = useSelector((state) => state.filters.groupIds);
  const isGlobalLoading = useSelector((state) => state.globalState.isLoading);

  const handleGroups = (value) => {
    setCurrentGroups(value);
  };

  useEffect(() => {
    if (currentFilters.length !== currentGroups.length) {
      setCurrentGroups(currentFilters);
    }
  }, [currentFilters, currentGroups.length]);

  useEffect(() => {
    if (reset) {
      setGroup("");
      setCurrentGroups([]);
      setReset(false);
    }
  }, [reset, setGroup, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        id={`${categoryLabel}-group-auto-complete`}
        open={open}
        onFocus={() => dispatch(clearGroups())}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        inputValue={group}
        onInputChange={(_evt, value) => setGroup(value)}
        onChange={(_evt, value) => {
          handleChange(
            value,
            forBudget ? "budgetGroupIds" : "groupIds",
            filterType
          );
          handleGroups(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        options={options}
        value={currentGroups}
        disabled={isGlobalLoading}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label={categoryLabel}
            id={`${categoryLabel}-group-auto-search`}
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              autoComplete: "off",
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </>
  );
};

GroupAutoComplete.propTypes = {
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  categoryLabel: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default GroupAutoComplete;
