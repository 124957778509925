import { axiosGet, axiosPatch, axiosPost } from "src/api/axiosCalls";

import { createSlice } from "@reduxjs/toolkit";

import { setError } from "../errorSlice";
import {
  setFailure as patchFailure,
  setIsLoading as patchLoading,
  patchSuccess,
} from "../patchOrderSlice";
import { buildSupplier } from "./helpers";
import { mapSuppliers } from "./maps";

let initialState = {
  isLoading: false,
  isUpdateLoading: false,
  suppliers: [],
  currentSupplier: null,
  hasUpdated: false,
  error: null,
};

const supplierSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsUpdateLoading(state) {
      state.isUpdateLoading = true;
    },
    getSuppliersSuccess(state, action) {
      const { suppliers } = action.payload;
      state.suppliers = suppliers;
      state.isLoading = false;
      state.error = null;
    },
    getSupplierSuccess(state, action) {
      const { supplier } = action.payload;
      state.currentSupplier = supplier;
      state.isLoading = false;
      state.error = null;
    },
    createOrUpdateSupplierSuccess(state, action) {
      const { supplier } = action.payload;
      let currentSupplier = state.suppliers.find((s) => s.id === supplier.id);
      state.suppliers = !currentSupplier
        ? state.suppliers
            .concat(supplier)
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        : state.suppliers.map((s) => {
            if (s.id === supplier.id) {
              return supplier;
            } else return s;
          });
      state.currentSupplier = supplier;
      state.hasUpdated = true;
      state.isUpdateLoading = false;
      state.error = null;
    },
    setHasUpdated(state, action) {
      const { value } = action.payload;
      state.hasUpdated = value;
    },
    resetCurrentSupplier(state) {
      state.currentSupplier = null;
    },
    setFailure(state, action) {
      const { error } = action.payload;
      state.isLoading = false;
      state.isUpdateLoading = false;
      state.error = error;
    },
  },
});

export const {
  setIsLoading,
  setIsUpdateLoading,
  getSuppliersSuccess,
  getSupplierSuccess,
  createOrUpdateSupplierSuccess,
  setHasUpdated,
  resetCurrentSupplier,
  setFailure,
} = supplierSlice.actions;

export default supplierSlice.reducer;

export const fetchSuppliers =
  (name, isActive = true) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      let queryString =
        name && name.length > 0
          ? `/api/suppliers?filter[name]=${name}&filter[is-active]=${isActive}`
          : `/api/suppliers?filter[is-active]=${isActive}`;
      const response = await axiosGet(queryString);
      if (response.error) throw response.error;
      const mappedData = mapSuppliers(response.data);
      dispatch(getSuppliersSuccess({ suppliers: mappedData }));
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Suppliers" }));
    }
  };

export const fetchSupplier = (id) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    const response = await axiosGet(`/api/suppliers/${id}`);
    if (response.error) throw response.error;
    const mappedData = mapSuppliers([response.data])[0];
    dispatch(getSupplierSuccess({ supplier: mappedData }));
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Suppliers" }));
  }
};

export const createOrUpdateSupplier = (attrs, id) => async (dispatch) => {
  try {
    dispatch(patchLoading());
    dispatch(setIsUpdateLoading());
    const data = buildSupplier(attrs);
    const response = id
      ? await axiosPatch(`/api/suppliers/${id}`, data)
      : await axiosPost("/api/suppliers", data);
    if (response.error) throw response.error;
    const mappedData = mapSuppliers([response.data])[0];
    dispatch(createOrUpdateSupplierSuccess({ supplier: mappedData }));
    dispatch(patchSuccess());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(patchFailure());
    dispatch(setError({ error: err.toString(), source: "Supplier Admin" }));
  }
};
